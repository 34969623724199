/**
 * LinksContent.tsx
 * Display the links tab of view profile page
 */
/* packages */
import { memo, useContext } from 'react';

/* context */
import { PersonContext } from './PersonProvider';

/* components */
import { FormattedMessage } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ShadowedButton from 'components/ShadowedButton/ShadowedButton';
import StatusTag from 'components/StatusTag/StatusTag';

// import { AvatarIcon } from 'icons/avatar/avatar';
// import { OrganisationIcon } from 'icons/organisation/organisation';
// import { ShipIcon } from 'icons/ship/ship';
import { UnknownIcon } from 'icons/unknown/unknown';

/* utilities */
import { internalPageMaxWidth } from './PersonProfile';

/* types */
import { MatchingDataType } from 'models/matchingData';
interface LinksContentProps {}

/* elements */

const LinksContent = memo((props: LinksContentProps) => {
  const { details, hasLinks, changeProfile } = useContext(PersonContext);

  if (!details || !details.links || !hasLinks) return <></>;

  return (
    <Box width={'100%'} maxWidth={internalPageMaxWidth} mx={'auto'} borderRadius={'5px'} sx={{ border: '1px solid var(--color-grayHeaderBorder)' }}>
      <Box display="grid" gridTemplateColumns={'1fr'} columnGap={'1.5rem'} rowGap={0} sx={{ '& > div:not(:last-of-type)': { borderBottom: '1px solid var(--color-grayHeaderBorder)' } }}>
        {details?.links
          ?.filter((detail) => detail.linked && (detail.linked.dataID || detail.linked.caption))
          .map((link, index) => {
            const title = link?.linked?.caption ?? link.linked?.dataID ?? '';
            const fs = '20px';
            let headerIcon = <UnknownIcon className="avatar" sx={{ fontSize: fs, color: 'var(--color-lightgray4)' }} />;

            // // entity type
            // switch (link?.linked?.dataset?.entityType) {
            //   case searchConstant.ENTITIES.INDIVIDUAL:
            //     headerIcon = <AvatarIcon className="avatar" sx={{ fontSize: fs, color: 'var(--color-lightgray4)' }} />;
            //     break;
            //   case searchConstant.ENTITIES.ORGANIZATION:
            //     headerIcon = <OrganisationIcon className="avatar" sx={{ fontSize: fs, color: 'var(--color-lightgray4)' }} />;
            //     break;
            //   case searchConstant.ENTITIES.VESSEL:
            //     headerIcon = <ShipIcon className="avatar" sx={{ fontSize: fs, color: 'var(--color-lightgray4)' }} />;
            //     break;
            //   default:
            //     headerIcon = <UnknownIcon className="avatar" sx={{ fontSize: fs, color: 'var(--color-lightgray4)' }} />;
            // }

            return (
              <Box key={index} px={2} py={2}>
                <Box flex={1} display={'flex'} gap={'.5rem'} alignItems={'flex-start'} sx={{ minWidth: 0 }}>
                  <Box py={'7px'}>{headerIcon}</Box>

                  <Box flex={1} sx={{ minWidth: 0 }} color={'var(--color-gray2)'}>
                    <Box display="flex" sx={{ flexFlow: 'row wrap' }} columnGap={'.5rem'}>
                      <Typography py={'7px'} fontSize={'var(--fs-14)'} fontWeight={500} sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {title}
                      </Typography>
                      {link.linked?.dataset?.label && <StatusTag withoutOverflow tag_label={link.linked?.dataset?.label} hideActive type="label" />}
                    </Box>
                    {link.caption && (
                      <Box pt={1} sx={{ fontSize: 'var(--fs-12)' }}>
                        {link.caption}
                      </Box>
                    )}
                  </Box>

                  {link?.linked?.dataID && link.linked.dataset && (link.linked.dataset.label || link.linked.dataset.id) && (
                    <ShadowedButton
                      onClick={() => {
                        const newProfile: MatchingDataType = {
                          dataID: link.linked?.dataID,
                          dataset: link.linked?.dataset,
                          names: [
                            {
                              fullName: link?.linked?.caption ?? link?.linked?.dataID ?? '',
                            },
                          ],
                        };
                        changeProfile?.(newProfile);
                      }}
                    >
                      <FormattedMessage id="viewProfile" defaultMessage="View profile" />
                    </ShadowedButton>
                  )}
                </Box>
              </Box>
            );
          })}
      </Box>
    </Box>
  );
});

export default LinksContent;
