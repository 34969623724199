/**
 * GaugeMeter.tsx
 * Display a gauge
 * value must be between 0 and 1
 */
/* packages */
import { CSSProperties, ReactNode } from 'react';

/* compoonents */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { InfoIcon } from 'icons/info/info';

/* types */
interface GaugeProps {
  value: number;
  info?: boolean;
  backgroundColor?: string;
  additionalText?: ReactNode;
  large?: boolean;
}

/* elements */
const GaugeMeter = ({ value, info, backgroundColor, large, additionalText }: GaugeProps) => {
  if (value < 0 || value > 1) {
    return (
      <Box px={0.625} border={1} borderColor={'var(--color-grayHeaderBorder)'} borderRadius={9999} display={'flex'} alignItems={'center'} gap={0.625}>
        <Typography fontSize={'.875rem'} color="var(--color-gray2)">
          invalid
        </Typography>
      </Box>
    );
  }

  let gaugeColor = '#FF0000'; // default high
  if (value <= 0.2) gaugeColor = '#5EBE70';
  else if (value <= 0.4) gaugeColor = '#2C3890';
  else if (value <= 0.6) gaugeColor = '#FCD059';
  else if (value <= 0.8) gaugeColor = '#ED8E1F';

  const boxSx = {} as CSSProperties;
  if (backgroundColor) {
    boxSx.backgroundColor = backgroundColor;
  }

  return (
    <Box
      px={'10px'}
      py={'6px'}
      border={large ? 0 : 1}
      borderColor={'var(--color-grayHeaderBorder)'}
      borderRadius={9999}
      display={'flex'}
      alignItems={'center'}
      gap={large ? 1 : 0.625}
      width={'fit-content'}
      sx={boxSx}
    >
      <Box
        className="circular-gauge"
        sx={{ backgroundColor: 'transparent', '--gauge-size': large ? '2rem' : '.875rem', '--gauge-ratio': value, '--gauge-color': gaugeColor, '--gauge-width': large ? '4px' : '2px' }}
      ></Box>
      <Box>
        <Typography fontSize={large ? 'var(--fs-20)' : 'var(--fs-14)'} color={'#141414'} fontWeight={large ? '600' : 'normal'} sx={{ lineHeight: 1.2 }}>
          {Math.round(value * 100)}%
        </Typography>
        {additionalText && (
          <Typography fontSize={'var(--fs-10)'} color="var(--color-gray2)" fontWeight={'normal'}>
            {additionalText}
          </Typography>
        )}
      </Box>
      {info && <InfoIcon sx={{ fontSize: '.75rem', color: 'var(--color-gray1)' }} />}
    </Box>
  );
};

export default GaugeMeter;
