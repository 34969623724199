import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const AvatarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31">
        <g data-name="Groupe 453" transform="translate(0 0)">
          <circle cx="15.5" cy="15.5" r="15.5" transform="translate(0 0)" fill="currentColor" />
          <path
            d="M8.6,9.678A4.839,4.839,0,1,0,3.764,4.839,4.84,4.84,0,0,0,8.6,9.678Zm4.3,1.075H11.053a5.85,5.85,0,0,1-4.9,0H4.3a4.3,4.3,0,0,0-4.3,4.3v.538a1.613,1.613,0,0,0,1.613,1.613H15.592a1.613,1.613,0,0,0,1.613-1.613v-.538A4.3,4.3,0,0,0,12.9,10.753Z"
            transform="translate(6.815 7.134)"
            fill="var(--avatar-color, #fff)"
          />
        </g>
      </svg>
    </SvgIcon>
  );
};
