/**
 * InvestigateSearch.tsx
 */
/* packages */
import { useCallback, useState, memo } from 'react';
import { useIntl } from 'react-intl';

/* hooks */
import { useAuthenticatedRequest } from 'contextProviders/AuthProvider';
// import { AllUsersContext } from 'contextProviders/AllUsersProvider';

/* components */
import Box from '@mui/material/Box';
import { SelectChangeEvent } from '@mui/material';

import TitleLayout from 'components/Layouts/TitleLayout';
import Loader from 'components/Loader/Loader';
import SearchSelect from 'components/SearchElements/SearchText/SearchSelect';
import { URLConstants } from 'common/URLconstants';

/* utilities */
// import { URLConstants } from 'common/URLconstants';

/* types */
interface DisplayLibraryDataProps {
  libraryData?: [];
}
/* elements */
const ALLOWEDLIBRARIES = ['highRiskLocations', 'stopwordsLocations', 'stopwordsIdentifiers', 'stopwordsNames', 'whitelistedNames', 'datasetFilters', 'wckeyword'] as const;

const ManageLibraries = () => {
  const intl = useIntl();
  const { getAuthenticatedRequest } = useAuthenticatedRequest();

  const [library, setlibrary] = useState<string>('');
  const [loadinglibrary, setLoadinglibrary] = useState<boolean>(false);
  // const [libraryData, setlibraryData] = useState([])

  const getlibrary = useCallback(
    async (lib: string) => {
      setLoadinglibrary(true);
      // setAlertSceenings(undefined);
      try {
        const libraryUrl = URLConstants.getLibraryData + `${lib}`;
        const results = await getAuthenticatedRequest(libraryUrl); // as ListAlertScreeningsResponse;
        console.log(results);
      } catch (searchError: any) {}
      setLoadinglibrary(false);
    },
    [getAuthenticatedRequest]
  );

  // const { postAuthenticatedRequest, getAuthenticatedRequest } = useAuthenticatedRequest();

  // const [initialLoading, setInitialLoading] = useState(true);
  // const { loadingAllUsers, listAllUsers } = useContext(AllUsersContext);

  // const listlibraries = useCallback(
  //   async (abortController?: AbortController): Promise<any> => {
  //     console.log('change return type');
  //     try {
  //       const libraryURL = URLConstants.allCountries;
  //       // const auditCountUrl = URLConstants.auditCount.replace('*auditType*', type);
  //       // const [auditResults, countResults] = (await Promise.all([
  //       //   postAuthenticatedRequest(auditUrl, payload, abortController ?? undefined),
  //       //   getAuthenticatedRequest(auditCountUrl, abortController ?? undefined),
  //       // ])) as [SearchAuditsResponse, SearchAuditsCountResponse];

  //       const libraryResults = (await getAuthenticatedRequest(libraryURL, abortController ?? undefined)) as any;
  //       console.log(libraryResults);
  //       // if (!auditResults.audits) {
  //       //   throw Error('invalid');
  //       // }
  //       // return {
  //       //   audits: auditResults.audits,
  //       //   nbAudits: auditResults.numberOfObjects,
  //       //   // nbAudits: countResults.numberOfObjects ?? auditResults.audits.length,
  //       // };
  //     } catch (listError: any) {
  //       throw listError;
  //     }
  //   },
  //   [getAuthenticatedRequest]
  // );

  // useEffect(() => {
  //   const abortController = new AbortController();
  //   const getList = async () => {
  //     try {
  //       await listlibraries(abortController);
  //     } catch (listError) {
  //       console.error(listError);
  //     }
  //   };
  //   getList();

  //   return () => {
  //     abortController.abort();
  //   };
  // }, [listlibraries]);

  const handleChangelibrary = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const newLib = event.target.value as string;
      setlibrary(newLib);
      getlibrary(newLib);
    },
    [getlibrary]
  );

  return (
    <TitleLayout pageTitle={intl.formatMessage({ id: 'ManageLibrariesTitle', defaultMessage: 'Libraries' })}>
      <Box display="flex" sx={{ flexFlow: 'row wrap' }}>
        <SearchSelect
          name="library"
          value={library}
          onChange={handleChangelibrary}
          placeholder={intl.formatMessage({ id: 'select', defaultMessage: 'Select...' })}
          choices={ALLOWEDLIBRARIES.map((lib) => ({ key: lib, value: lib }))}
          MenuProps={{ classes: { paper: 'custom-scrollbar' }, sx: { maxHeight: 350 } }}
          fullWidth
          disabled={loadinglibrary}
          sx={{ maxWidth: { xs: '100%', md: '200px' } }}
        />
      </Box>

      {loadinglibrary ? (
        <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Loader />
        </Box>
      ) : (
        <DisplayLibraryData />
      )}
    </TitleLayout>
  );
};

const DisplayLibraryData = memo(({ libraryData }: DisplayLibraryDataProps) => {
  return <Box> NYI </Box>;
});
export default ManageLibraries;

/* <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <ReloadButton
          onClick={() => {
            load();
          }}
        />
      </Box> */
