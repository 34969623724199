/**
 * AlertProvider.tsx
 * provide the value of the alert to subcomponents
 */
/* packages */
import { useRef, useState, useMemo, createContext, PropsWithChildren, useCallback } from 'react';

/* contexts */
import { useAuthenticatedRequest } from 'contextProviders/AuthProvider';

/* utilities */
import { URLConstants } from 'common/URLconstants';

/* types */
import {
  Alert,
  SingleAlertResponse,
  AlertDocumentsResponse,
  AlertDocumentUploadResponse,
  AlertCommentFavoriteResponse,
  AlertNewCommentQuery,
  AlertNewCommentResponse,
  AlertChangeWorkflowStatusQuery,
  AlertChangeWorkflowStatusResponse,
} from 'models/alerts';
import { FileType } from 'models/file';
import { CommentType } from 'models/comments';

export interface AlertContextType {
  alert?: Alert;
  defineAlert?(newAlert?: Alert): void;
  updatingTarget?: { [key: number]: boolean };
  setTrueHit?(targetId: number, isTrueHit: boolean): Promise<number | undefined>;
  documents?: FileType[];
  loadingDocuments?: boolean;
  listAlertDocuments?(alertId?: number, abortController?: AbortController): void;
  uploadAlertDocument?(formData: FormData, abortController?: AbortController): Promise<FileType | undefined>;
  updatingComment?: { [key: number]: boolean };
  comments?: CommentType[];
  setCommentAsFavorite?(newFavoriteState: boolean, commentId?: number, alertId?: number): void;
  addComment?(comment: string, alertId?: number, fileId?: number, replyTo?: number): void;
  editAlertStatus?(payload: EditAlertStatusPayload): void;
}

export interface EditAlertStatusPayload {
  newStatusId: number;
  comment: string;
  alertId?: number;
  editUser?: boolean;
  userType?: 'user' | 'group' | null;
  userId?: number;
}
const AlertContext = createContext<AlertContextType>({});

const AlertProvider = ({ children }: PropsWithChildren) => {
  const { postAuthenticatedRequest } = useAuthenticatedRequest();

  const [alert, setAlert] = useState<Alert | undefined>(undefined);
  const [updatingTarget, setUpdatingTarget] = useState<{ [key: number]: boolean }>({});

  const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false);
  const [documents, setDocuments] = useState<FileType[] | undefined>(undefined);

  // const [comments, setComments] = useState<CommentType[] | undefined>(undefined);
  const [updatingComment, setUpdatingComment] = useState<{ [key: number]: boolean }>({});

  const defineAlert = useCallback((newAlert?: Alert) => {
    setAlert(newAlert);
    // setComments(newAlert?.comments || [])
  }, []);

  //   set true hit
  const setTrueHit = useCallback(
    async (targetId: number, isTrueHit: boolean) => {
      const alertId = alert?.id;
      if (!alertId) return;
      if (!alert) return;

      setUpdatingTarget((curUpdateTarget) => ({ ...curUpdateTarget, [targetId]: true }));

      const payload = {
        alert: {
          id: alertId,
        },
        matchingDetailsIds: [targetId],
      };
      try {
        const listUrl = isTrueHit ? URLConstants.setTrueHit : URLConstants.setFalseHit;
        const results = (await postAuthenticatedRequest(listUrl, payload)) as SingleAlertResponse;

        setAlert(results.alert);

        setUpdatingTarget((curUpdateTarget) => ({ ...curUpdateTarget, [targetId]: false }));
        return 0;
      } catch (searchError: any) {
        if (searchError?.code === 'ERR_CANCELED') return;

        // setStatusQueried(true);
        setUpdatingTarget((curUpdateTarget) => ({ ...curUpdateTarget, [targetId]: false }));
      }
    },
    [alert, postAuthenticatedRequest]
  );

  // list documents
  const listingAlertDocuments = useRef(false);
  const listAlertDocuments = useCallback(
    async (alertId?: number, abortController?: AbortController) => {
      if (listingAlertDocuments.current) return;
      if (!alertId) return;

      listingAlertDocuments.current = true;

      setLoadingDocuments(true);
      setDocuments(undefined);

      try {
        const getAlertDocumentsUrl = URLConstants.listDocuments;
        const payload = {
          alert: {
            id: alertId,
          },
        };
        const result = (await postAuthenticatedRequest(getAlertDocumentsUrl, payload, abortController)) as AlertDocumentsResponse;

        const documentsResult = result.files ?? [];
        setDocuments(documentsResult);
      } catch (searchError) {}

      setLoadingDocuments(false);
      listingAlertDocuments.current = false;
    },
    [postAuthenticatedRequest]
  );

  const uploadAlertDocument = useCallback(
    async (formData: FormData, abortController?: AbortController) => {
      try {
        const uploadAlertDocumentsUrl = URLConstants.uploadAlertFile;

        const result = (await postAuthenticatedRequest(uploadAlertDocumentsUrl, formData, abortController, {
          contentType: 'multipart/form-data',
          skipStringify: true,
        })) as AlertDocumentUploadResponse;

        // const documentsResult = result.files ?? undefined;
        setDocuments((documentsResult) => (documentsResult ? [result.file, ...documentsResult] : [result.file]));
        return result.file;
      } catch (uploadError) {
        throw new Error('upload error');
      }
    },
    [postAuthenticatedRequest]
  );

  // set comment as favorite
  const setCommentAsFavorite = useCallback(
    async (newFavoriteState: boolean, commentId?: number, alertId?: number) => {
      if (!commentId) return;
      if (!alertId) return;

      setUpdatingComment((curUpdateTarget) => ({ ...curUpdateTarget, [commentId]: true }));

      const payload = {
        commentId: commentId,
      };
      try {
        const setUrl = URLConstants.commentAsFavorite;
        const results = (await postAuthenticatedRequest(setUrl, payload)) as AlertCommentFavoriteResponse;

        if (results.operationResult) {
          setAlert((currentAlert) => {
            if (!currentAlert) return currentAlert;
            const newAlert = currentAlert;
            newAlert.comments = (newAlert.comments ?? []).map((com) => {
              if (com.id !== commentId) return com;
              const newCom = com;
              newCom.favorite = newFavoriteState;
              return newCom;
            });

            return newAlert;
          });
        }

        setUpdatingComment((curUpdateTarget) => ({ ...curUpdateTarget, [commentId]: false }));
        return 0;
      } catch (searchError: any) {
        if (searchError?.code === 'ERR_CANCELED') return;

        // setStatusQueried(true);
        setUpdatingComment((curUpdateTarget) => ({ ...curUpdateTarget, [commentId]: false }));
      }
    },
    [postAuthenticatedRequest]
  );

  const addComment = useCallback(
    async (comment: string, alertId?: number, fileId?: number, replyTo?: number) => {
      if (!comment) return;
      if (!alertId) return;

      const payload: AlertNewCommentQuery = {
        alert: {
          id: alertId,
        },
        comment: {
          comment: comment,
        },
      };
      if (fileId) {
        payload.comment.fileId = fileId;
      }
      if (replyTo) {
        payload.comment.replyTo = replyTo;
      }

      try {
        const addCommentUrl = URLConstants.alertAddComment;
        const results = (await postAuthenticatedRequest(addCommentUrl, payload)) as AlertNewCommentResponse;

        if (!results.alert) {
          throw new Error('Invalid response');
        }

        setAlert(results.alert);

        // if (results.operationResult) {
        //   setAlert((currentAlert) => {
        //     if (!currentAlert) return currentAlert;
        //     const newAlert = currentAlert;
        //     newAlert.comments = (newAlert.comments ?? []).map((com) => {
        //       if (com.id !== commentId) return com;
        //       const newCom = com;
        //       newCom.favorite = newFavoriteState;
        //       return newCom;
        //     });

        //     return newAlert;
        //   });
        // }

        return 0;
      } catch (newCommentError: any) {
        if (newCommentError?.code === 'ERR_CANCELED') return;
        throw new Error('An error occured while creating your comment');
      }
    },
    [postAuthenticatedRequest]
  );

  // edit Status
  const editAlertStatus = useCallback(
    async (payload: EditAlertStatusPayload) => {
      const { newStatusId, comment, alertId, editUser, userType, userId } = payload;
      if (!alertId) return;
      if (!comment) return;
      if (newStatusId === undefined) return;

      const queryPayload: AlertChangeWorkflowStatusQuery = {
        alert: { id: alertId },
        comment: { comment },
      };

      let editStatusUrl;

      if (editUser && userId) {
        editStatusUrl = URLConstants.alertChangeAssigned;
        switch (userType) {
          case 'user':
            if (userId === -1) return;
            queryPayload['user'] = { id: userId };
            break;
          case 'group':
            queryPayload['group'] = { id: userId };
            break;
          case null:
            queryPayload['unassigned'] = true;
            break;
          default:
            break;
        }
      } else {
        editStatusUrl = URLConstants.alertExecuteWorkflow;
        queryPayload.alertNextStatus = {
          id: newStatusId,
        };
      }

      try {
        const results = (await postAuthenticatedRequest(editStatusUrl, queryPayload)) as AlertChangeWorkflowStatusResponse;
        if (!results.alert) {
          throw new Error('Invalid response');
        }
        setAlert(results.alert);

        return 0;
      } catch (editStatusError: any) {
        if (editStatusError?.code === 'ERR_CANCELED') return;
        throw new Error('An error occured while editing the status');
      }
    },
    [postAuthenticatedRequest]
  );

  const value = useMemo(() => {
    return {
      alert,
      defineAlert,
      updatingTarget,
      setTrueHit,
      documents,
      loadingDocuments,
      listAlertDocuments,
      uploadAlertDocument,
      updatingComment,
      setCommentAsFavorite,
      addComment,
      editAlertStatus,
    };
  }, [alert, defineAlert, updatingTarget, setTrueHit, listAlertDocuments, documents, loadingDocuments, uploadAlertDocument, updatingComment, setCommentAsFavorite, addComment, editAlertStatus]);

  return <AlertContext.Provider value={value}>{children}</AlertContext.Provider>;
};

export { AlertContext, AlertProvider };
