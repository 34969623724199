/**
 * datasets.ts
 * Define datasets (client related)
 */

import { DateString } from 'models/utils';
import { ApiResponse } from './axiosRequest';
// import { MatchingNameType, MatchingDataType } from 'models/matchingData';
import { OrganizationUnit, UserType } from './user';
import { TeamType } from './teams';
import { PersonType } from './person';
import { TransactionType } from './transaction';
import { Alert } from './alerts';
import { BankAccountType } from './account';
import { Audit } from './audit';

// Search dataset Types
export interface DatasetsResponse extends ApiResponse {
  datasets: DatasetsType;
}

export type DatasetsType = Dataset[];

export const AllowedDatasetSourceOfData = ['accounts', 'transactions', 'persons', 'alerts'] as const; //, 'aircrafts', 'vessel'
export type AllowedDatasetSourceOfDataType = (typeof AllowedDatasetSourceOfData)[number];

export const AllowedDatasetType = ['Source', 'Watchlist'] as const; //, 'aircrafts', 'vessel'
export type AllowedDatasetTypeT = (typeof AllowedDatasetType)[number];

// export const AllowedDatasetsType
export interface Dataset {
  id: number;
  label: string;
  description?: string;
  classification?: string;
  // category?: string;
  active?: boolean;
  totalCount?: number;

  deleted?: boolean;
  //fullName?: string;

  // Type can be watchlist / Customer, Beneficiary
  type?: AllowedDatasetTypeT; //string; // Sources - Watchlist

  // Person, Alerts, Transactions, ....
  // sourceOfData?: number;
  sourceOfDataType?: AllowedDatasetSourceOfDataType;

  isEditable?: boolean;
  isImportable?: boolean;

  // Folder
  // datasetFolder?: DatasetFolder;
  datasetFolder?: DatasetFolder;

  creationDate?: DateString;
  createdBy?: string;
  updatedDate?: DateString;
  updatedBy?: string;

  // Security
  organizations?: OrganizationUnit[];
  groups?: TeamType[];
  // readOnlyGroups?: TeamType[];
  users?: UserType[];
}

export interface EditDatasetResponse extends ApiResponse {
  dataset: Dataset;
}
export interface DeleteDatasetResponse extends ApiResponse {
  operationResult: boolean;
}

export interface ImportDatasetReponse extends ApiResponse {
  audits?: Audit[];
}

export interface DatasetClassificationResponse extends ApiResponse {
  listOfClassifications: string[];
}
export interface DatasetFolder {
  id: number;
  active?: boolean;
  // parentId?: number;
  label: string;
  description?: string;

  organizations?: OrganizationUnit[];
  groups?: TeamType[];
  users?: UserType[];

  creationDate: DateString;
  createdBy?: string;
  updatedDate?: DateString;
  updatedBy?: string;
}
export interface DatasetFoldersResponse extends ApiResponse {
  datasetFolders: DatasetFolder[];
}

export interface EditDatasetFoldersResponse extends ApiResponse {
  datasetFolder: DatasetFolder;
}
export interface DeleteDatasetFoldersResponse extends ApiResponse {
  operationResult: boolean;
}

export interface SearchDatasetResponse extends ApiResponse {
  persons?: PersonType[];
  transactions?: TransactionType[];
  accounts?: BankAccountType[];
  alerts?: Alert[];

  sortField?: string;
  sortOrder?: 'asc' | 'desc';
}

export interface SearchDatasetCountResponse extends ApiResponse {
  numberOfObjects?: number;
}

export interface SearchDatasetPayload {
  maxPerPage: number;
  pageNumber: number;

  keyword?: string;
  id?: number;
  sortField?: string;
  sortOrder?: 'ASC' | 'DESC';
  date1?: string;
  date2?: string;

  score1?: number;
  score2?: number;

  persons?: DatasetSourceOfDataType;
  transactions?: DatasetSourceOfDataType;
  alerts?: DatasetSourceOfDataType;
  accounts?: DatasetSourceOfDataType;
  // aircrafts?: DatasetSourceOfDataType;
  // vessel?: DatasetSourceOfDataType;
}

export interface DatasetSourceOfDataType {
  dataID?: number;
  id?: number;
  dataset: {
    id?: number;
  };
}
