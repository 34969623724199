// Date string type - can be more specific or hit recursion limit
export type DateString = `${number}-${number}-${number} ${number}:${number}:${number}`;
export type DateStringISO = `${number}-${number}-${number}T${number}:${number}:${number}.${number}`;

export const DateStringFormat = 'YYYY-MM-DD HH:mm:ss';
export const DateStringISOFormat = 'YYYY-MM-DDTHH:mm:ss';

export interface StatusPromiseResponse {
  status: 'error' | 'success';
}
