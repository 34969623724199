/**
 * SerachFilter.tsx
 */
/* packages */
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

/* contexts */

/* hooks */

/* components */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';

import ShadowedButton from 'components/ShadowedButton/ShadowedButton';

/* utilities */

/* types */
import { FilterValueType } from './SearchFilters';

interface FilterCheckboxesProps {
  title: string;
  minimum: number;
  maximum: number;
  step?: number;
  label?: string;
  percent?: boolean;
  currentValue?: { minValue?: number; maxValue?: number };
  setNewFilterValue?(newValue: FilterValueType): void;
}

/* elements */
const FilterRange = ({ title, minimum, maximum, step, label, percent, currentValue, setNewFilterValue }: FilterCheckboxesProps) => {
  const [selectedValue, setSelectedValue] = useState<number[]>([currentValue?.minValue ?? minimum, currentValue?.maxValue ?? maximum]);

  const applyFilterValue = useCallback(
    (newValue: typeof selectedValue) => {
      const hasValue = newValue[0] !== minimum || newValue[1] !== maximum;
      if (setNewFilterValue) setNewFilterValue({ nbValues: hasValue ? 1 : 0, minValue: newValue[0], maxValue: newValue[1] });
    },
    [setNewFilterValue, minimum, maximum]
  );

  const clearFilter = () => {
    const newValue: typeof selectedValue = [minimum, maximum];
    setSelectedValue(newValue);
    applyFilterValue(newValue);
  };

  // propagate on value change
  const validateSelection = () => {
    applyFilterValue(selectedValue);
    // if (setNewFilterValue) setNewFilterValue({ nbValues: selectedValues.length, values: selectedValues });
  };

  const handleChange = useCallback((_: Event, newValue: number | number[]) => {
    setSelectedValue(newValue as number[]);
  }, []);

  let textValue = `${selectedValue[0]}  -  ${selectedValue[1]}`;
  if (percent) {
    textValue = `${Math.round(selectedValue[0] * 10000 + Number.EPSILON) / 100}%  -  ${Math.round(selectedValue[1] * 10000 + Number.EPSILON) / 100}%`;
  }

  return (
    <Box borderRadius={'5px'} sx={{ border: '1px solid var(--color-grayHeaderBorder)', background: 'white', mt: 0.5 }}>
      {/* filter title */}
      <Box display="flex" alignItems={'center'} gap={'1rem'} px={2} py={1} sx={{ borderBottom: '1px solid var(--color-grayHeaderBorder)' }}>
        <ShadowedButton onClick={clearFilter} size="small" disabled={selectedValue[0] === minimum && selectedValue[1] === maximum}>
          <FormattedMessage id="Clear" defaultMessage="Clear" />
        </ShadowedButton>

        <Typography color={'darkgray'} flex={1} fontSize={14} px={2} fontWeight={500} textAlign={'center'}>
          {title}
        </Typography>

        <Button variant="contained" disableElevation size="small" onClick={validateSelection} disabled={!selectedValue}>
          <FormattedMessage id="done" defaultMessage="Done" />
        </Button>
      </Box>

      <Box px={2} py={1}>
        <TextField
          variant="outlined"
          inputProps={{
            style: {
              paddingBlock: 8,
              fontSize: '.8rem',
              color: 'var(--color-gray2)',
              textAlign: 'center',
              WebkitTextFillColor: 'unset',
            },
          }}
          fullWidth
          disabled
          value={textValue}
          sx={{ '& .MuiInputBase-root': { borderRadius: 0 } }}
        />
      </Box>

      <Box px={4} py={1} display={'flex'} flexDirection={'column'}>
        <FormGroup>
          <Slider
            valueLabelFormat={(value) => {
              if (percent) return `${Math.round(value * 10000 + Number.EPSILON) / 100}%`;
              return value;
            }}
            getAriaLabel={() => label ?? 'Range'}
            min={minimum}
            max={maximum}
            step={step ?? 1}
            value={selectedValue}
            onChange={handleChange}
            valueLabelDisplay="auto"
            sx={{
              '& .MuiSlider-rail': {
                opacity: 0.5,
                backgroundColor: 'var(--color-gray1)',
              },
              '& .MuiSlider-valueLabel': {
                fontSize: '0.8rem',
              },
            }}
          />
        </FormGroup>
      </Box>
    </Box>
  );
};

export default FilterRange;
