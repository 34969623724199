/**
 * Login.tsx
 * Display the login page
 */
/* packages */
import { useContext, useState, PropsWithoutRef, ReactNode } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

/* components */
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import DisplayBrandLogo from 'utilities/DisplayBrandLogo';
import FullPageBox from 'components/FullPageBox/FullPageBox';

/* contexts */
import { ConfigContext } from 'contextProviders/ConfigProvider';
import { useAuthenticationFunction, useIsAuthenticated } from 'contextProviders/AuthProvider';

/* hooks */
import { useAddSnackbar } from 'contextProviders/SnackbarProvider';

/* utilities */
import { routerPages } from 'AppRouter';

/* elements */
const LoginPage = ({ form }: PropsWithoutRef<{ form: ReactNode }>) => {
  const { config } = useContext(ConfigContext);

  return (
    <FullPageBox>
      <Stack spacing={3} alignItems="center" className="bckg-lightgray" px={2} py={5}>
        <DisplayBrandLogo full className="in-page-logo" />
        <Box width={400} maxWidth={'100%'}>
          <Stack sx={{ backgroundColor: '#FFFFFF', border: '1px solid var(--color-grayHeaderBorder)', borderRadius: '5px' }}>
            <Typography sx={{ color: 'var(--color-darkgray)', textAlign: 'center', fontSize: '1.6rem', borderBottom: '1px solid var(--color-grayHeaderBorder)' }} fontWeight={600} px={3} py={1}>
              <FormattedMessage id="loginTitle" defaultMessage="Sign in" />
            </Typography>

            {form}
          </Stack>

          <Box sx={{ border: '1px solid #CFD1D2', borderRadisu: '5px' }} width={'100%'} mt={2}>
            <Typography px={2} py={1} fontSize={14} textAlign={'center'}>
              <FormattedMessage id="loginNeedHelp" defaultMessage="Do you need help?" />{' '}
              <Link href={`mailto:${config?.contactEmail}`}>
                <FormattedMessage id="loginContact" defaultMessage="Contact us" />
              </Link>
            </Typography>
          </Box>
        </Box>
        <Stack direction="row" spacing={2} fontSize={14}>
          <Link target="_blank" rel="noreferrer" href={process.env.REACT_APP_TOS_URL}>
            <FormattedMessage id="loginTerms" defaultMessage="Terms of Use" />
          </Link>
          <Link target="_blank" rel="noreferrer" href={process.env.REACT_APP_PRIVACY_URL}>
            <FormattedMessage id="loginPrivacy" defaultMessage="Privacy & security" />
          </Link>
          <Link target="_blank" rel="noreferrer" href={process.env.REACT_APP_HELP_URL}>
            <FormattedMessage id="loginHelpCenter" defaultMessage="Help Center" />
          </Link>
        </Stack>
      </Stack>
    </FullPageBox>
  );
};

const LoginForm = () => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [submitting, setSubmitting] = useState<boolean>(false);

  const [error, setError] = useState<string>();

  const navigate = useNavigate();
  const addSnackbar = useAddSnackbar();

  const { authenticateUser, getApiKey } = useAuthenticationFunction();
  const { isAuthenticated } = useIsAuthenticated();
  const intl = useIntl();

  // redirect if already logged in
  if (isAuthenticated) return <Navigate to={routerPages.registeredEntryPage} replace />;

  const submitLogin: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
    event.preventDefault();
    setSubmitting(true);
    setError(undefined);

    try {
      const authResponse = await authenticateUser?.({ userName: username, password: password });
      const jwt = authResponse?.token;

      if (!jwt) throw new Error('Invalid jwt');

      await getApiKey?.(jwt);

      addSnackbar(
        intl.formatMessage({
          id: 'loginAuthenticationSuccess',
          defaultMessage: 'Authentication success',
        })
      );

      // auth success : navigate
      navigate(routerPages.registeredEntryPage);
    } catch (error: any) {
      let message =
        error?.message ??
        intl.formatMessage({
          id: 'loginAuthenticationError',
          defaultMessage: 'Authentication failed',
        });

      setError(message);
      // addSnackbar(message, 'error');
    }

    setSubmitting(false);
  };

  return (
    <form action="">
      <Stack px={4} pt={2} pb={3} spacing={2}>
        <FormControl variant="outlined">
          <FormHelperText id="username-helper-text" sx={{ margin: 0, marginBottom: 1, fontWeight: 500 }}>
            <FormattedMessage id="loginUsername" defaultMessage="User name" />
          </FormHelperText>
          <OutlinedInput
            disabled={submitting}
            id="username"
            aria-describedby="username-helper-text"
            type="text"
            autoComplete="username"
            inputProps={{ sx: { padding: 1 } }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setUsername(event.target.value);
            }}
          />
        </FormControl>

        <FormControl variant="outlined">
          <FormHelperText id="password-helper-text" sx={{ margin: 0, marginBottom: 1, fontWeight: 500 }}>
            <FormattedMessage id="loginPassword" defaultMessage="Password" />
          </FormHelperText>
          <OutlinedInput
            disabled={submitting}
            id="passwrod"
            aria-describedby="password-helper-text"
            type="password"
            autoComplete="current-password"
            inputProps={{ sx: { padding: 1 } }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setPassword(event.target.value);
            }}
          />
        </FormControl>

        {error && (
          <Box>
            <Typography fontWeight="medium" color={'error'} sx={{ fontSize: 'var(--fs-12)' }}>
              {error}
            </Typography>
          </Box>
        )}

        <Button type="submit" variant="contained" disableElevation sx={{ textTransform: 'none' }} disabled={!username || !password || submitting} onClick={submitLogin}>
          <FormattedMessage id="loginButton" defaultMessage="Sign in" />
        </Button>
      </Stack>
    </form>
  );
};

const Login = () => <LoginPage form={<LoginForm />} />;

export default Login;
