/**
 * ShadowedButton.tsx
 */
/* packages */
import { forwardRef, PropsWithChildren } from 'react';

/* components */
import Button, { ButtonProps } from '@mui/material/Button';

/* elemensts */
const ShadowedButton = forwardRef<HTMLButtonElement, PropsWithChildren<ButtonProps>>(({ children, ...others }, ref) => {
  const { sx, ...othersNonSX } = others;

  const forcedBorderColor: { borderColor?: string } = {};
  if (!othersNonSX.color || othersNonSX.color === 'darkgray') {
    forcedBorderColor.borderColor = 'var(--color-lightgray3)';
  }

  let color = othersNonSX.color ?? 'var(--color-darkgray)';
  let iconColor = 'var(--color-gray2)';
  if (othersNonSX?.disabled) {
    iconColor = 'rgba(0, 0, 0, 0.26)';
  }

  return (
    <Button
      ref={ref}
      variant="outlined"
      color={'darkgray'}
      {...others}
      onClick={(event) => {
        event.currentTarget.blur();
        others?.onClick?.(event);
      }}
      className="button-box-shadow"
      sx={{
        ...forcedBorderColor,
        fontSize: 'var(--fs-14)',
        fontWeight: 500,
        letterSpacing: '0.01em',
        lineHeight: 1.5,
        // boxShadow: '0px 0px 6px #0000001A',
        '& .MuiSvgIcon-root': {
          color: iconColor,
          overflow: 'visible', // safarit view size bug
        },
        color: color,
        '&:hover, &:focus': {
          // backgroundColor: '#2FB6B4',
          backgroundColor: 'white',
          // color: 'white',
          // borderColor: 'transparent',
          borderColor: 'var(--color-lightgray4)',
          // '& .MuiSvgIcon-root': {
          //   color: 'white!important',
          //   '--avatar-color': '#2FB6B4',
          // },
        },
        '&:focus': {
          opacity: 0.8,
        },
        ...sx,
      }}
    >
      {children}
    </Button>
  );
});

export default ShadowedButton;
