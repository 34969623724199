/**
 * EditDataset.tsx
 * Edit dataset modal
 */
/* packages */
import { useState, useCallback, useMemo, memo, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import cronstrue from 'cronstrue';

/* context */

/* hooks */
import { useAddModal } from 'contextProviders/ModalProvider';
import { useAddSnackbar } from 'contextProviders/SnackbarProvider';

/* components */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import SearchSelect from 'components/SearchElements/SearchText/SearchSelect';
import { SelectChangeEvent } from '@mui/material/Select';
import SearchText from 'components/SearchElements/SearchText/SearchText';
import SwitchWithLabel from 'components/SearchElements/SearchText/SearchSwitch';

import ShadowedButton from 'components/ShadowedButton/ShadowedButton';
import { ContentWithDarkTooltip } from 'components/InvestigateSearch/utils';
import Loader from 'components/Loader/Loader';

/* utilities */
import { StatusPromiseResponse } from 'models/utils';
import { AllowedJobParamsKeyType, AllowedJobType, AllowedJobTypeTypeT, JobType, paramsFromJobType } from 'models/job';
import { Dataset } from 'models/datasets';

/* types */
interface EditScheduleProps {
  schedule?: JobType;
  setSchedule(data: Partial<JobType>, schedule?: JobType): Promise<StatusPromiseResponse>;
  datasets?: Dataset[];
  // classifications?: string[];
  // folders?: DatasetFolder[];
  // allUsers?: UserType[];
  // teams?: TeamType[];
  // organizations?: OrganizationUnit[];
}

/* elements */
const getCronText = (cronExpression: string): string => {
  try {
    const cronText = cronstrue.toString(cronExpression, {
      verbose: true,
      use24HourTimeFormat: true,
    });
    return cronText;
  } catch {
    return '';
  }
};
const EditSchedule = memo(({ schedule, setSchedule, datasets }: EditScheduleProps) => {
  const { closeModal, blockModal } = useAddModal();
  const addSnackbar = useAddSnackbar();
  const intl = useIntl();

  const [scheduleParams, setScheduleParams] = useState<Partial<JobType>>(schedule ?? {});
  const [creatingSchedule, setCreatingSchedule] = useState(false);

  const [cronError, setCronError] = useState<boolean>(false);
  const [cronText, setCronText] = useState<string>(getCronText(schedule?.cronExpression ?? ''));
  const [scheduleJobParams, setScheduleJobParams] = useState<{ [key in AllowedJobParamsKeyType]: string }>(Object.fromEntries((schedule?.jobParams || []).map((p) => [p.key, p.value])));

  const formRef = useRef<HTMLFormElement>(null);
  // const foldersId = useMemo(() => (folders ?? []).map((f) => f.id), [folders]);

  const handleChangeText = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setScheduleParams((params) => ({ ...params, [event.target.name]: event.target.value }));

    if (event.target.name === 'cronExpression') {
      setCronError(false);
    }
  }, []);
  const handleBlurCron = useCallback(() => {
    setCronText('');
    if (!scheduleParams.cronExpression) {
      return setCronError(false);
    }
    const cronText = getCronText(scheduleParams.cronExpression);
    if (cronText) {
      setCronError(false);
      setCronText(cronText);
    } else {
      setCronError(true);
    }
    // try {
    //   const cronText = cronstrue.toString(scheduleParams.cronExpression, {
    //     verbose: true,
    //     use24HourTimeFormat: true,
    //   });
    //   setCronError(false);
    //   setCronText(cronText);
    // } catch {
    //   setCronError(true);
    // }
  }, [scheduleParams.cronExpression]);

  const handleChangeSelect = useCallback((event: SelectChangeEvent<unknown>) => {
    setScheduleParams((params) => ({ ...params, [event.target.name]: event.target.value }));
  }, []);
  const handleChangeSwitch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setScheduleParams((params) => ({ ...params, [event.target.name]: event.target.checked }));
  }, []);

  const datasetLabels = useMemo(() => (datasets ?? []).map((d) => d.label), [datasets]);
  const setParamsInSchedule = useCallback((key: AllowedJobParamsKeyType, value: string) => {
    setScheduleParams((params) => ({
      ...params,
      jobParams: [...(params.jobParams ?? []).filter((p) => p.key !== key), { key, value }],
    }));
  }, []);

  const handleChangeSelectJobParam = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      setScheduleJobParams((params) => ({ ...params, [event.target.name]: event.target.value }));
      setParamsInSchedule(event.target.name as AllowedJobParamsKeyType, event.target.value as string);
    },
    [setParamsInSchedule]
  );

  const handleChangeTextJobParam = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setScheduleJobParams((params) => ({ ...params, [event.target.name]: event.target.value }));
      setParamsInSchedule(event.target.name as AllowedJobParamsKeyType, event.target.value.trim() as string);
    },
    [setParamsInSchedule]
  );

  const createOrUpdateSchedule = useCallback(async () => {
    if (!formRef.current) return;

    blockModal?.(true);
    setCreatingSchedule(true);

    const result = await setSchedule(scheduleParams, schedule);

    setCreatingSchedule(false);
    blockModal?.(false);

    if (result.status === 'success') {
      const updateSuccessMessage = schedule
        ? intl.formatMessage({
            id: 'updateScheduleSuccess',
            defaultMessage: 'Schedule updated',
          })
        : intl.formatMessage({
            id: 'createScheduleSuccess',
            defaultMessage: 'New schedule created',
          });

      addSnackbar(updateSuccessMessage, 'success');
      closeModal?.();
    } else {
      const updateErrorMessage = schedule
        ? intl.formatMessage({
            id: 'updateScheduleError',
            defaultMessage: 'An error occured while updating the schedule',
          })
        : intl.formatMessage({
            id: 'createScheduleError',
            defaultMessage: 'An error occured while creating the schedule',
          });
      addSnackbar(updateErrorMessage, 'error');
    }
  }, [blockModal, closeModal, scheduleParams, schedule, setSchedule, addSnackbar, intl]);

  const setMessage = schedule ? intl.formatMessage({ id: 'save', defaultMessage: 'Save' }) : intl.formatMessage({ id: 'create', defaultMessage: 'Create' });
  const MemoInputProps = useMemo(
    () => ({
      startAdornment: null,
    }),
    []
  );

  const hasJobParams = scheduleParams.jobParams && scheduleParams.jobParams.length >= 0;
  const hasJobParamsValue = (scheduleParams.jobParams || []).filter((p) => p.value && p.value.trim().length > 0).length > 0;
  const saveDisabled = creatingSchedule || !scheduleParams.name || !scheduleParams.cronExpression || cronError || !scheduleParams.jobType || !hasJobParams || !hasJobParamsValue;

  let buttonTooltip: string = '';
  if (!scheduleParams.name) {
    buttonTooltip = intl.formatMessage({ id: 'missingScheduleName', defaultMessage: 'You must set a name' });
  } else if (!scheduleParams.cronExpression || cronError) {
    buttonTooltip = intl.formatMessage({ id: 'missingScheduleCronExpression', defaultMessage: 'You must set a valid cron expression' });
  } else if (!scheduleParams.jobType) {
    buttonTooltip = intl.formatMessage({ id: 'missingScheduleJobType', defaultMessage: 'You must set a job type' });
  } else if (!hasJobParams) {
    buttonTooltip = intl.formatMessage({ id: 'missingScheduleJobParams', defaultMessage: 'You must set at least one job parameter' });
  } else if (!hasJobParamsValue) {
    buttonTooltip = intl.formatMessage({ id: 'missingScheduleJobParamsValue', defaultMessage: 'You must set at least one job parameter value' });
  }

  return (
    <Box width={'min(85vw, 400px)'}>
      <form ref={formRef}>
        <Box px={3}>
          <Box>
            <Typography className="modal-label">
              <FormattedMessage id="scheduleName" defaultMessage={'Schedule name'} />
            </Typography>
            <SearchText
              fullWidth
              name="name"
              value={scheduleParams.name ?? ''}
              onChange={handleChangeText}
              placeholder={intl.formatMessage({ id: 'chooseScheduleName', defaultMessage: 'Schedule name' })}
              InputProps={MemoInputProps}
              disabled={creatingSchedule}
            />
          </Box>

          <Box mt={2}>
            <Typography className="modal-label">
              <FormattedMessage id="scheduleCron" defaultMessage={'Cron expression'} />
            </Typography>
            <SearchText
              fullWidth
              name="cronExpression"
              value={scheduleParams.cronExpression ?? ''}
              onChange={handleChangeText}
              placeholder={intl.formatMessage({ id: 'cronExpressionDefault', defaultMessage: '0 0 0 * * ?' })}
              InputProps={MemoInputProps}
              disabled={creatingSchedule}
              onBlur={handleBlurCron}
            />
            <Box pt={'5px'}>
              {cronError ? (
                <Typography color="error" sx={{ fontSize: 'var(--fs-12)' }}>
                  <FormattedMessage id="invalidCronExpression" defaultMessage={'Invalid cron expression'} />
                </Typography>
              ) : (
                <Typography sx={{ color: 'var(--color-gray2)', fontSize: 'var(--fs-12)' }}>{cronText}</Typography>
              )}
            </Box>
            <Box pt={0.5}>
              <Typography sx={{ fontStyle: 'italic', color: 'var(--color-gray2)', fontSize: 'var(--fs-12)' }}>
                <FormattedMessage id="cronHelp" defaultMessage="You need help to create your Cron expression?" />{' '}
                <Link target="_blank" rel="noreferrer" href={'https://crontab.cronhub.io/'}>
                  <FormattedMessage id="clickHere" defaultMessage="Click here" />
                </Link>
              </Typography>
            </Box>
          </Box>

          <Box mt={2}>
            <Typography className="modal-label">
              <FormattedMessage id="type" defaultMessage={'Type'} />
            </Typography>
            <SearchSelect
              name="jobType"
              value={AllowedJobType.includes(scheduleParams.jobType as AllowedJobTypeTypeT) ? scheduleParams.jobType : ''}
              sx={{ height: '100%' }}
              onChange={handleChangeSelect}
              placeholder={intl.formatMessage({ id: 'selectJobType', defaultMessage: 'Select job type' })}
              choices={AllowedJobType.map((t) => ({ key: t, value: t }))}
              MenuProps={{ classes: { paper: 'custom-scrollbar' }, sx: { maxHeight: 350 } }}
              disabled={creatingSchedule || schedule?.jobType !== undefined}
            />
          </Box>
          <Box mt={2} display="grid">
            <SwitchWithLabel
              disabled={creatingSchedule}
              label={intl.formatMessage({ id: 'active', defaultMessage: 'Active' })}
              labelClassName="modal-label"
              inputSwitchProps={{ checked: scheduleParams.active ?? false, onChange: handleChangeSwitch, name: 'active' }}
            />
          </Box>

          {scheduleParams.jobType &&
            paramsFromJobType[scheduleParams.jobType] &&
            paramsFromJobType[scheduleParams.jobType].map((param) => {
              return (
                <Box key={param} mt={2}>
                  <Typography className="modal-label">
                    <FormattedMessage id={`label_${param}`} />
                  </Typography>
                  {param === 'sourceDataSet' && (
                    <SearchSelect
                      fullWidth
                      name={param}
                      value={datasetLabels.includes(scheduleJobParams[param]) ? scheduleJobParams[param] : ''}
                      sx={{ height: '100%' }}
                      onChange={handleChangeSelectJobParam}
                      placeholder={intl.formatMessage({ id: 'selectDataset', defaultMessage: 'Select dataset' })}
                      choices={[{ key: '', value: 'None' }].concat(datasetLabels.map((t) => ({ key: t, value: t })))}
                      MenuProps={{ classes: { paper: 'custom-scrollbar' }, sx: { maxHeight: 350 } }}
                      disabled={creatingSchedule || datasetLabels.length <= 0}
                    />
                  )}
                  {param === 'matchingProfile' && (
                    <SearchText
                      fullWidth
                      name={param}
                      value={scheduleJobParams[param] ?? ''}
                      onChange={handleChangeTextJobParam}
                      // placeholder={intl.formatMessage({ id: 'chooseScheduleName', defaultMessage: 'Schedule name' })}
                      InputProps={MemoInputProps}
                      disabled={creatingSchedule}
                    />
                  )}
                </Box>
              );
            })}
        </Box>

        <Box display={'flex'} justifyContent={'flex-end'} gap={'1rem'} px={3} pt={2} mt={2} sx={{ borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
          {creatingSchedule ? (
            <Box>
              <Loader cssStyle={{ width: '33px' }} />
            </Box>
          ) : (
            <>
              <ShadowedButton
                onClick={() => {
                  closeModal?.();
                }}
                sx={{ whiteSpace: 'nowrap' }}
              >
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </ShadowedButton>

              <ContentWithDarkTooltip tooltip={buttonTooltip} placement="top">
                <Button type="button" variant="contained" onClick={() => createOrUpdateSchedule()} disabled={saveDisabled} disableElevation sx={{ textTransform: 'none' }}>
                  {setMessage}
                </Button>
              </ContentWithDarkTooltip>
            </>
          )}
        </Box>
      </form>
    </Box>
  );
});

export default EditSchedule;
