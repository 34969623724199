/**
 * InvestigateDashboard.tsx
 */
/* packages */
import { useContext, PropsWithChildren, memo, useState, useEffect, useCallback, useMemo } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

/* contexts */
import { UserContext } from 'contextProviders/UserProvider';
import { UnreadAlertsContext } from 'contextProviders/UnreadAlertsProvider';
import { useAuthenticatedRequest } from 'contextProviders/AuthProvider';
import { useAddSnackbar } from 'contextProviders/SnackbarProvider';
import { useViewAlert } from './InvestigateAlert';
import { useAddModal } from 'contextProviders/ModalProvider';
import { AllUsersContext } from 'contextProviders/AllUsersProvider';

/* components */
import { Link } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import EditProfile from 'components/EditProfile/EditProfile';

import TableResults from 'components/TableResults/TableResults';
import { TableHeadElement } from 'components/TableResults/TableHead';
import { TableRowData } from 'components/TableResults/TableRow';

import StatusTag from 'components/StatusTag/StatusTag';
import ShadowedButton from 'components/ShadowedButton/ShadowedButton';
import GaugeMeter from 'components/GaugeMeter/GaugeMeter';

import PageHeader from 'components/PageHeader/PageHeader';
import PageTitle from 'components/PageTitle/PageTitle';

import Loader from 'components/Loader/Loader';

import { GearFilledIcon } from 'icons/gearFilled/gearFilled';
import { AvatarIcon } from 'icons/avatar/avatar';

import ex_explore from 'media/images/ex_explore-min.png';
import ex_search from 'media/images/ex_search-min.png';

/* utilities */
import { checkPermissions } from 'utilities/CheckUserPermissions';
import { routerPages } from 'AppRouter';
import { URLConstants } from 'common/URLconstants';
import { Alert, SearchAlertsPayloadType } from 'models/alerts';
import { formatMatchingName } from 'models/matchingData';
import { prependBase64Photo } from 'models/user';

/* types */
import { SearchAlertsResponse } from 'models/alerts';

/* elements */
const InvestigateDashboard = () => {
  return (
    <InvestigateDashboardLayout>
      <InvestigateDashboardContent />
    </InvestigateDashboardLayout>
  );
};

const InvestigateDashboardLayout = ({ children }: PropsWithChildren) => {
  const intl = useIntl();
  const { permissions } = useContext(UserContext);

  return (
    <>
      <PageHeader>
        <PageTitle title={intl.formatMessage({ id: 'investigateDashboardTitle', defaultMessage: 'Dashboard' })} />

        {checkPermissions('manage', permissions) && (
          <Box>
            <Link to={routerPages.manage}>
              <ShadowedButton>
                <GearFilledIcon fontSize="inherit" sx={{ mr: 1 }} />
                <FormattedMessage id="dashboardManageSettings" defaultMessage="Manage Settings" />
              </ShadowedButton>
            </Link>
          </Box>
        )}
      </PageHeader>
      {children}
    </>
  );
};

const InvestigateDashboardContent = () => {
  return (
    <Box>
      <ProfileBox />

      <LinksBox />

      <AlertBox />
    </Box>
  );
};

export const ProfileBox = memo(() => {
  const { currentUser, changePassword, editUser, uploadAvatar, deleteAvatar } = useContext(UserContext);
  const { editSingleUser } = useContext(AllUsersContext);
  const intl = useIntl();

  const { toggleModal, blockModal, closeModal } = useAddModal();

  const handleEditProfile = useCallback(() => {
    if (!currentUser) return;
    toggleModal?.({
      title: intl.formatMessage({ id: 'editProfile', defaultMessage: 'Edit profile' }),
      modalContent: <EditProfile {...{ currentUser, blockModal, closeModal, changePassword, editUser, editSingleUser, uploadAvatar, deleteAvatar }} />,
    });
  }, [intl, toggleModal, currentUser, blockModal, closeModal, changePassword, editUser, editSingleUser, uploadAvatar, deleteAvatar]);

  const userOrganizationUnits = currentUser?.organizationUnits?.filter((o) => !o.deleted);
  const userTeams = currentUser?.groups?.filter((t) => !t.deleted);
  return (
    <Box className="bordered rounded-border" mb={2} display="flex" gap={'1rem'} px={3} pt={3} pb={4} sx={{ flexFlow: 'row wrap' }}>
      <Box display="grid" sx={{ width: 'min(30%, 120px)', fontSize: '120px', lineHeight: 1 }}>
        {currentUser?.photo ? <img src={prependBase64Photo + currentUser.photo} alt="" className="user-avatar" /> : <AvatarIcon sx={{ fontSize: 'inherit', color: 'var(--color-lightgray4)' }} />}
      </Box>
      <Box flex={1} pl={2} sx={{ minWidth: 0, wordBreak: 'break-word' }}>
        <Typography fontWeight={600} mb={1} sx={{ fontSize: 'var(--fs-20)', color: 'var(--color-darkgray)' }}>
          <FormattedMessage id="hi" defaultMessage={'Hi'} />, {currentUser?.fullName ?? currentUser?.userName}!
        </Typography>
        <Typography fontWeight={500} mb={1} sx={{ fontSize: 'var(--fs-14)', color: 'var(--color-darkgray)' }}>
          {currentUser?.email}
        </Typography>
        {userOrganizationUnits && (
          <Typography mb={1} fontWeight={500} sx={{ fontSize: 'var(--fs-14)', color: 'var(--color-darkgray)' }}>
            <Typography component={'span'} sx={{ fontSize: 'inherit', color: 'var(--color-gray2)' }}>
              {userOrganizationUnits.length > 1 ? <FormattedMessage id="organizations" defaultMessage={'Organizations'} /> : <FormattedMessage id="organization" defaultMessage={'Organization'} />}:{' '}
            </Typography>
            {userOrganizationUnits
              .slice(0, 3)
              .map((o) => o.name)
              .join(', ')}
          </Typography>
        )}
        {userTeams && (
          <Typography fontWeight={500} sx={{ fontSize: 'var(--fs-14)', color: 'var(--color-darkgray)' }}>
            <Typography component={'span'} sx={{ fontSize: 'inherit', color: 'var(--color-gray2)' }}>
              {userTeams.length > 1 ? <FormattedMessage id="teams" defaultMessage={'Teams'} /> : <FormattedMessage id="team" defaultMessage={'Team'} />}:{' '}
            </Typography>
            {userTeams
              .slice(0, 3)
              .map((g) => g.name)
              .join(', ')}
          </Typography>
        )}
      </Box>
      <Box display="flex" gap={'.5rem'} alignSelf={'flex-start'} sx={{ width: { xs: '100%', md: 'auto' } }}>
        <ShadowedButton onClick={handleEditProfile}>
          <FormattedMessage id="editProfile" defaultMessage="Edit profile" />
        </ShadowedButton>
        <ShadowedButton disabled>
          <FormattedMessage id="editPreferences" defaultMessage="Edit preferences" />
        </ShadowedButton>
      </Box>
    </Box>
  );
});

const LinksBox = memo(() => {
  return (
    <Grid container spacing={2} mb={2}>
      <Grid item xs={12} md={6}>
        <Box className="bordered rounded-border">
          <Box px={3} py={2} sx={{ borderBottom: '1px solid var(--color-grayHeaderBorder)' }}>
            <Typography fontWeight={600} sx={{ fontSize: 'var(--fs-20)' }}>
              <FormattedMessage id="search" defaultMessage={'Search'} />
            </Typography>
          </Box>
          <Box px={2} pt={1}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <img className="cover top" src={ex_search} alt="" style={{ maxHeight: 400 }} />
              </Grid>
              <Grid item xs={6}>
                <Box height={'100%'} display={'flex'} justifyContent={'center'} flexDirection={'column'}>
                  <Typography textAlign="center" sx={{ color: 'var(--color-gray2)', fontSize: 'var(--fs-14)' }}>
                    <FormattedMessage id="dashboardSearchMessage" defaultMessage={'Search individual, organization and vessels on watchlists'} />
                  </Typography>
                  <Box mt={2} sx={{ textAlign: 'center' }}>
                    <Link to={routerPages.investigateSearch}>
                      <Button color="primary" variant="contained" fullWidth sx={{ maxWidth: 350 }}>
                        <FormattedMessage id="search" defaultMessage={'Search'} />
                      </Button>
                    </Link>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} md={6}>
        <Box className="bordered rounded-border">
          <Box px={3} py={2} sx={{ borderBottom: '1px solid var(--color-grayHeaderBorder)' }}>
            <Typography fontWeight={600} sx={{ fontSize: 'var(--fs-20)' }}>
              <FormattedMessage id="explore" defaultMessage={'Explore'} />
            </Typography>
          </Box>
          <Box px={2} pt={1}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Box height={'100%'} display={'flex'} justifyContent={'center'} flexDirection={'column'}>
                  <Typography textAlign="center" sx={{ color: 'var(--color-gray2)', fontSize: 'var(--fs-14)' }}>
                    <FormattedMessage id="dashboardExploreMessage" defaultMessage={'Access datasets to explore entities, their profiles and history'} />
                  </Typography>
                  <Box mt={2} sx={{ textAlign: 'center' }}>
                    <Link to={routerPages.investigateExplore}>
                      <Button color="primary" variant="contained" fullWidth sx={{ maxWidth: 350 }}>
                        <FormattedMessage id="explore" defaultMessage={'Explore'} />
                      </Button>
                    </Link>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <img className="cover top" src={ex_explore} alt="" style={{ maxHeight: 400 }} />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
});

const MAX_ALERTS = 5;
const AlertBox = memo(() => {
  const intl = useIntl();
  const { postAuthenticatedRequest } = useAuthenticatedRequest();
  const addSnackbar = useAddSnackbar();
  const { currentUserId } = useContext(UserContext);

  const [title, setTitle] = useState<string>(intl.formatMessage({ id: 'alerts', defaultMessage: 'Alerts' }));

  const [alerts, setAlerts] = useState<Alert[] | null>(null);
  const [loadingAlert, setLoadingAlert] = useState<boolean>(true);
  const { loadingUnread, nbUnread, listUnread } = useContext(UnreadAlertsContext);

  // load alerts on mount
  const listAlerts = useCallback(async () => {
    setLoadingAlert(true);
    const unreadAlertsPayload: SearchAlertsPayloadType = {
      maxPerPage: MAX_ALERTS,
      pageNumber: 0,
      unread: true,
    };
    const allAlertsPayload: SearchAlertsPayloadType = {
      maxPerPage: MAX_ALERTS,
      pageNumber: 0,
    };

    try {
      const queries = [postAuthenticatedRequest(URLConstants.alertList, unreadAlertsPayload), postAuthenticatedRequest(URLConstants.alertList, allAlertsPayload)];

      const [unreadAlertResponse, allAlertResponse] = (await Promise.all(queries)) as [SearchAlertsResponse, SearchAlertsResponse];

      if (unreadAlertResponse.alerts && unreadAlertResponse.alerts.length > 0) {
        setTitle(
          intl.formatMessage({
            id: 'newAlerts',
            defaultMessage: 'New Alerts',
          })
        );
        setAlerts(unreadAlertResponse.alerts);
      } else if (allAlertResponse.alerts && allAlertResponse.alerts.length > 0) {
        setTitle(
          intl.formatMessage({
            id: 'alerts',
            defaultMessage: 'Alerts',
          })
        );
        setAlerts(allAlertResponse.alerts);
      }
      setLoadingAlert(false);
    } catch {
      addSnackbar(
        intl.formatMessage({
          id: 'listAlertError',
          defaultMessage: 'An error occured while listing your alerts',
        }),
        'error'
      );
      setLoadingAlert(false);
    }
  }, [postAuthenticatedRequest, addSnackbar, intl]);

  const loadContent = useCallback(() => {
    listUnread?.();
    listAlerts();
  }, [listUnread, listAlerts]);

  useEffect(() => {
    if (!currentUserId) return;

    loadContent();
  }, [loadContent, currentUserId]);

  return (
    <Box className="bordered rounded-border">
      <Box px={3} py={2} sx={{ borderBottom: '1px solid var(--color-grayHeaderBorder)' }} display="flex">
        <Badge
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          color="red"
          badgeContent={loadingAlert || loadingUnread ? 0 : nbUnread}
          max={99}
          sx={{ '& .MuiBadge-badge': { fontWeight: '700', transform: 'scale(1) translate(calc(100% - 5px), -50%)' } }}
        >
          <Typography fontWeight={600} sx={{ fontSize: 'var(--fs-20)' }}>
            {title}
          </Typography>
        </Badge>

        <Box ml={'auto'}>
          <Link to={routerPages.investigateAlerts}>
            <ShadowedButton>
              <FormattedMessage id="viewAllAlerts" defaultMessage={'View all alerts'} />
            </ShadowedButton>
          </Link>
        </Box>
      </Box>

      {loadingAlert ? (
        <Box p={4}>
          <Loader />
        </Box>
      ) : (
        <AlertsContent alerts={alerts} />
      )}
    </Box>
  );
});

const AlertsContent = memo(({ alerts }: { alerts: Alert[] | null }) => {
  const intl = useIntl();
  const viewAlert = useViewAlert();

  const alertsHeader = useMemo((): TableHeadElement[] => {
    return [
      // {
      //   id: 'alertId',
      //   label: intl.formatMessage({
      //     id: 'alertId',
      //     defaultMessage: 'Alert Id',
      //   }),
      // },
      {
        id: 'name',
        label: intl.formatMessage({
          id: 'name',
          defaultMessage: 'Name',
        }),
        minWidth: '180px',
      },
      {
        id: 'bestMatch',
        label: intl.formatMessage({
          id: 'bestMatch',
          defaultMessage: 'Best Match',
        }),
        minWidth: '180px',
      },
      // {
      //   id: 'bestMatchList',
      //   label: intl.formatMessage({
      //     id: 'bestMatchList',
      //     defaultMessage: 'Best Match List',
      //   }),
      // },
      {
        id: 'score',
        label: intl.formatMessage({
          id: 'score',
          defaultMessage: 'Score',
        }),
      },
      {
        id: 'status',
        label: intl.formatMessage({
          id: 'status',
          defaultMessage: 'Status',
        }),
      },
    ];
  }, [intl]);

  const alertsRows = useMemo((): TableRowData[] => {
    if (!alerts) return [];
    return (
      alerts.map((a, a_ind) => ({
        id: String(a.id) ?? String(a_ind),
        columns: {
          // alertId: (
          //   <Box display={'flex'} alignItems={'center'}>
          //     <Typography fontSize="inherit" fontWeight="inherit" sx={{ color: 'inherit' }} mr={2}>
          //       {a.id}
          //     </Typography>
          //   </Box>
          // ),

          name: a.name ?? a.description ?? '',
          bestMatch: formatMatchingName(a.target?.[0].watchListPersons?.names?.[0]),

          // bestMatchList: a.target?.[0].watchListPersons?.label ? <StatusTag tag_label={a.target?.[0].watchListPersons.label} hideActive={true} bg={'white'} /> : '',
          score: <GaugeMeter value={a.matchScore} />,
          status: <StatusTag tag_label={a.status?.description} active={a.status?.closeStatus} bg={'white'} />,
        },
        unread: a.unread ?? false,
        rowClick: () => {
          viewAlert(a.id);
        },
      })) ?? []
    );
  }, [alerts, viewAlert]);

  if (!alerts || alerts.length <= 0) {
    return (
      <Box p={2}>
        <Typography>
          <FormattedMessage id="noAlertDashboard" defaultMessage={"You don't have any alert"} />
        </Typography>
      </Box>
    );
  }
  return (
    <Box>
      {/* searchResults.alerts.map(sr=>({})) */}
      <TableResults hasUnread={false} hasSelection={false} noBorders tableData={alertsRows} tableHead={alertsHeader} />
    </Box>
  );
});

export default InvestigateDashboard;
