/**
 * GeneralContent.tsx
 * Display the main content of view profile page
 */
/* packages */
import { memo, useContext, Fragment } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';

/* contexts */
import { PersonContext } from './PersonProvider';

/* components */
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Box';

import ShadowedButton from 'components/ShadowedButton/ShadowedButton';
import StatusTag from 'components/StatusTag/StatusTag';
import OrganisationContent from 'components/InvestigateSearch/OrganisationContent';
import IndividualContent from 'components/InvestigateSearch/IndividualContent';
import VesselContent from 'components/InvestigateSearch/VesselContent';
import UnknownContent from 'components/InvestigateSearch/UnknownContent';

import { LinkIcon } from 'icons/link/link';
// import { AvatarIcon } from 'icons/avatar/avatar';
// import { OrganisationIcon } from 'icons/organisation/organisation';
// import { ShipIcon } from 'icons/ship/ship';
import { UnknownIcon } from 'icons/unknown/unknown';
import { CalendarIcon } from 'icons/calendar/calendar';

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { AutoFit, Markers, leafletSvgIcon, locationKeys } from './MapContent';
import { Flag } from 'components/InvestigateSearch/utils';

/* utilities */
import { searchConstant } from 'models/searchDatasets';

/* types */
// import { PersonType } from 'models/person';
import { MatchingDataType } from 'models/matchingData';
interface GeneralContentProps {
  setTab: (tab: string) => void;
  // details?: PersonType;
}

/* elements */
const BoxItem = styled(Box)(({ theme }) => ({
  border: '1px solid var(--color-grayHeaderBorder)',
  borderRadius: '5px',
  display: 'flex',
  flexDirection: 'column',
  minWidth: 0,
}));

const BoxContent = styled(Box)(({ theme }) => ({
  fontSize: '.875rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
}));

const NoContent = styled(Typography)(({ theme }) => ({
  color: 'var(--color-gray2)',
  fontSize: 'var(--fs-14)',
  fontWeight: 500,
  textAlign: 'center',
}));
const BoxNoContent = memo(({ message }: { message: string }) => {
  return (
    <Box height={'100%'} display={'flex'} flexDirection={'column'} justifyContent={'center'}>
      <NoContent>{message}</NoContent>
    </Box>
  );
});
const BoxTitle = memo(({ title }: { title: string }) => {
  return (
    <Box flex={1} display={'flex'} gap={'.5rem'} alignItems={'center'} sx={{ minWidth: 0 }}>
      <Typography flex={1} fontSize={'var(--fs-20)'} fontWeight={600} color={'var(--color-darkgray)'} sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {title}
      </Typography>
    </Box>
  );
});
const maxNbSources = 4;

const GeneralContent = memo(({ setTab }: GeneralContentProps) => {
  const { details, hasSources, hasLinks, hasMore, hasMap, hasTimeline, changeProfile } = useContext(PersonContext);

  const intl = useIntl();

  // const moreContent = useRef<HTMLDivElement>(null);
  // useEffect(() => {
  //   // check if more is overflowing
  //   const moreIsOverflowing = () => {
  //     if (!moreContent) return;

  //     const moreElement = moreContent.current;
  //     const moreTextElement = moreElement?.querySelector('.content');

  //     if (!moreElement || !moreTextElement) return;

  //     const moreElementHeight = moreElement.clientHeight;
  //     const moreTextElementHeight = moreTextElement.clientHeight;

  //     if (moreElementHeight < moreTextElementHeight) moreElement.classList.add('overflowing');
  //     else moreElement.classList.remove('overflowing');
  //   };

  //   moreIsOverflowing();
  //   window.addEventListener('resize', moreIsOverflowing);

  //   return () => {
  //     window.removeEventListener('resize', moreIsOverflowing);
  //   };
  // }, []);

  if (!details) return <></>;

  let cardContent = null;
  //   let viewProfileButton = false;
  //   const currentTargetData = result.targetData[0] ?? ({} as MatchingDataType);
  switch (details.entityType) {
    case searchConstant.ENTITIES.INDIVIDUAL:
      cardContent = <IndividualContent data={details} resume />;
      break;
    case searchConstant.ENTITIES.ORGANIZATION:
      cardContent = <OrganisationContent data={details} />;
      break;
    case searchConstant.ENTITIES.VESSEL:
      cardContent = <VesselContent data={details} />;
      break;
    default:
      cardContent = <UnknownContent data={details} resume />;
  }

  return (
    <Box display={'grid'} gridTemplateColumns={'1fr 1fr'} gap={'1rem'}>
      {/* PROPERTIES */}
      <BoxItem>
        <Box px={2} py={2} display="flex" sx={{ borderBottom: '1px solid var(--color-grayHeaderBorder)' }}>
          <BoxTitle title={intl.formatMessage({ id: 'properties', defaultMessage: 'Properties' })} />
          <ShadowedButton
            onClick={() => {
              setTab('properties');
            }}
          >
            <FormattedMessage id="viewAllProperties" defaultMessage="View all properties" />
          </ShadowedButton>
        </Box>

        <Box px={2} py={2} flex={1}>
          <BoxContent>{cardContent}</BoxContent>
        </Box>
      </BoxItem>

      {/* MAP */}
      <BoxItem>
        <Box px={2} py={2} display="flex" sx={{ borderBottom: '1px solid var(--color-grayHeaderBorder)' }}>
          <BoxTitle title={intl.formatMessage({ id: 'map', defaultMessage: 'Map' })} />

          {hasMap && (
            <ShadowedButton
              onClick={() => {
                setTab('map');
              }}
            >
              <FormattedMessage id="viewAll" defaultMessage="View all" />
            </ShadowedButton>
          )}
        </Box>

        <Box px={2} py={2} flex={1}>
          {hasMap ? (
            <BoxContent height={'100%'} minHeight={'200px'}>
              <Minimap />
            </BoxContent>
          ) : (
            <BoxNoContent message="The profile has no location information" />
          )}
        </Box>
      </BoxItem>

      {/* SOURCES */}
      <BoxItem>
        <Box px={2} py={2} display="flex" sx={{ borderBottom: '1px solid var(--color-grayHeaderBorder)' }}>
          <BoxTitle title={intl.formatMessage({ id: 'sources', defaultMessage: 'Sources' })} />

          {hasSources && (
            <ShadowedButton
              onClick={() => {
                setTab('sources');
              }}
            >
              <FormattedMessage id="viewAllSources" defaultMessage="View all sources" />
            </ShadowedButton>
          )}
        </Box>

        <Box px={2} py={2} flex={1}>
          {hasSources ? (
            <BoxContent>
              {details.sources
                ?.filter((source) => source.path)
                .slice(0, maxNbSources)
                .map((source, index) => {
                  return (
                    <Box key={index} display="flex" gap={'.5rem'}>
                      <Box pt={0.5} sx={{ color: 'var(--color-lightgray4)' }}>
                        <LinkIcon sx={{ fontSize: '1rem', color: 'red' }} />
                      </Box>
                      <Box flex={1} minWidth={0}>
                        <a target="_blank" rel="noreferrer" href={source.path} style={{ display: 'block', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {source.path}
                        </a>
                        <Box sx={{ color: 'var(--color-gray2)' }}>{source.title}</Box>
                      </Box>
                    </Box>
                  );
                })}
            </BoxContent>
          ) : (
            <BoxNoContent message="The profile has no sources" />
          )}
        </Box>
      </BoxItem>

      {/* TIMELINE */}
      <BoxItem>
        <Box px={2} py={2} display="flex" sx={{ borderBottom: '1px solid var(--color-grayHeaderBorder)' }}>
          <BoxTitle title={intl.formatMessage({ id: 'timeline', defaultMessage: 'Timeline' })} />

          {hasTimeline && (
            <ShadowedButton
              onClick={() => {
                setTab('timeline');
              }}
            >
              <FormattedMessage id="selectPeriod" defaultMessage="Select period" />
            </ShadowedButton>
          )}
        </Box>

        <Box px={2} py={2} flex={1}>
          {hasTimeline ? <MiniTimeline /> : <BoxNoContent message="The profile has no timeline" />}
        </Box>
      </BoxItem>

      {/* LINKS */}
      <BoxItem>
        <Box px={2} py={2} display="flex" sx={{ borderBottom: '1px solid var(--color-grayHeaderBorder)' }}>
          <BoxTitle title={intl.formatMessage({ id: 'links', defaultMessage: 'Links' })} />

          {hasLinks && (
            <ShadowedButton
              onClick={() => {
                setTab('links');
              }}
            >
              <FormattedMessage id="viewAllLinks" defaultMessage="View all links" />
            </ShadowedButton>
          )}
        </Box>

        <Box flex={1}>
          {hasLinks ? (
            <BoxContent sx={{ rowGap: 0, '& > div:not(:last-of-type)': { borderBottom: '1px solid var(--color-grayHeaderBorder)' } }}>
              {details?.links
                ?.filter((detail) => detail.linked && (detail.linked.dataID || detail.linked.caption))
                .slice(0, 3)
                .map((link, index) => {
                  const title = link?.linked?.caption ?? link.linked?.dataID ?? '';
                  const fs = '20px';
                  let headerIcon = <UnknownIcon className="avatar" sx={{ fontSize: fs, color: 'var(--color-lightgray4)' }} />;

                  // // entity type
                  // switch (link?.linked?.dataset?.entityType) {
                  //   case searchConstant.ENTITIES.INDIVIDUAL:
                  //     headerIcon = <AvatarIcon className="avatar" sx={{ fontSize: fs, color: 'var(--color-lightgray4)' }} />;
                  //     break;
                  //   case searchConstant.ENTITIES.ORGANIZATION:
                  //     headerIcon = <OrganisationIcon className="avatar" sx={{ fontSize: fs, color: 'var(--color-lightgray4)' }} />;
                  //     break;
                  //   case searchConstant.ENTITIES.VESSEL:
                  //     headerIcon = <ShipIcon className="avatar" sx={{ fontSize: fs, color: 'var(--color-lightgray4)' }} />;
                  //     break;
                  //   default:
                  //     headerIcon = <UnknownIcon className="avatar" sx={{ fontSize: fs, color: 'var(--color-lightgray4)' }} />;
                  // }

                  return (
                    <Box key={index} px={2} py={2}>
                      <Box flex={1} display={'flex'} gap={'.5rem'} alignItems={'flex-start'} sx={{ minWidth: 0 }}>
                        <Box py={'7px'}>{headerIcon}</Box>

                        <Box flex={1} sx={{ minWidth: 0 }} color={'var(--color-gray2)'}>
                          <Box display="flex" sx={{ flexFlow: 'row wrap' }} columnGap={'.5rem'}>
                            <Typography py={'7px'} fontSize={'var(--fs-14)'} fontWeight={500} sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                              {title}
                            </Typography>
                            {link.linked?.dataset?.label && <StatusTag withoutOverflow tag_label={link.linked?.dataset?.label} hideActive type="label" />}
                          </Box>
                          {link.caption && (
                            <Box pt={1} sx={{ fontSize: 'var(--fs-12)' }}>
                              {link.caption}
                            </Box>
                          )}
                        </Box>

                        {link?.linked?.dataID && link.linked.dataset && (link.linked.dataset.label || link.linked.dataset.id) && (
                          <ShadowedButton
                            onClick={() => {
                              const newProfile: MatchingDataType = {
                                dataID: link.linked?.dataID,
                                dataset: link.linked?.dataset,
                                names: [
                                  {
                                    fullName: link?.linked?.caption ?? link?.linked?.dataID ?? '',
                                  },
                                ],
                              };
                              changeProfile?.(newProfile);
                            }}
                          >
                            <FormattedMessage id="viewProfile" defaultMessage="View profile" />
                          </ShadowedButton>
                        )}
                      </Box>
                    </Box>
                  );
                })}
            </BoxContent>
          ) : (
            <BoxNoContent message="The profile has no links" />
          )}
        </Box>
      </BoxItem>

      {/* MORE */}
      <BoxItem>
        <Box px={2} py={2} display="flex" sx={{ borderBottom: '1px solid var(--color-grayHeaderBorder)' }}>
          <BoxTitle title={intl.formatMessage({ id: 'more', defaultMessage: 'More' })} />

          {hasMore && (
            <ShadowedButton
              onClick={() => {
                setTab('more');
              }}
            >
              <FormattedMessage id="browse" defaultMessage="Browse" />
            </ShadowedButton>
          )}
        </Box>

        <Box px={2} py={2} flex={1}>
          {hasMore ? (
            <Box
              // ref={moreContent}
              display="flex"
              flexDirection={'column'}
              // className="custom-ellipsis"
            >
              <BoxContent className="content" flex={1}>
                {details.additionalInformations
                  ?.filter((info) => info.value)
                  .slice(0, 1)
                  .map((info, index) => {
                    return (
                      <Box key={index} display="flex" gap={'.5rem'}>
                        <Box
                          flex={1}
                          minWidth={0}
                          sx={{
                            '--max-lines': '6',
                            maxHeight: 'calc(25px + var(--max-lines,5) * 21px)',
                            overflow: 'hidden',
                            color: 'var(--color-gray2)',
                            display: '-webkit-box',
                            WebkitLineClamp: 'var(--max-lines,5)',
                            WebkitBoxOrient: 'vertical',
                            // '&:after': { display: 'none' },
                            // '&.overflowing:after': { display: 'block' },
                          }}
                        >
                          <Box pb={0.5} sx={{ fontWeight: 500 }}>
                            {info.type}
                          </Box>
                          <Box>{info.value}</Box>
                        </Box>
                      </Box>
                    );
                  })}
              </BoxContent>
            </Box>
          ) : (
            <BoxNoContent message="The profile has no additional information" />
          )}
        </Box>
        {/* <Box px={2} py={2} display="flex" gap={'1rem'} sx={{ flexFlow: 'row wrap', borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
          <StatusTag tag_label={'test1test1'} type="label" active activeColor="var(--color-fushia)" />
          <StatusTag tag_label={'test2test2'} type="label" active activeColor="var(--color-green)" />
          <StatusTag tag_label={'test3test3'} type="label" active activeColor="var(--color-orange)" />
          <StatusTag tag_label={'test4test4'} type="label" active activeColor="var(--color-azure)" />
        </Box> */}
      </BoxItem>
    </Box>
  );
});

const Minimap = memo(() => {
  const { mapLocations } = useContext(PersonContext);

  if (!mapLocations) return <></>;
  // compute markers
  const markers: Markers = {};

  Object.entries(mapLocations).forEach(([locKey, locations]) => {
    const location = locations[0];

    const markerKey = `${location.latitude}//${location.longitude}`;
    if (markerKey in markers) {
      if (locKey in locationKeys) {
        // markers[markerKey].label.add(locationKeys[locKey as keyof typeof locationKeys].label);
        markers[markerKey].label.add(locKey);
      }
    } else {
      if (locKey in locationKeys) {
        markers[markerKey] = {
          latitude: location.latitude ?? '',
          longitude: location.longitude ?? '',
          country: location.country ?? '',
          countryName: location.countryName ?? '',
          // label: new Set([locationKeys[locKey as keyof typeof locationKeys].label]),
          label: new Set([locKey]),
        };
      }
    }
  });

  const zoom = 3;
  const pos = Object.entries(markers).map(([_, marker]) => {
    return [Number(marker.latitude), Number(marker.longitude)];
  });

  const latitudeCenter = pos.map((p) => p[0]).reduce((a, c) => a + c, 0) / pos.length;
  const longitudeCenter = pos.map((p) => p[1]).reduce((a, c) => a + c, 0) / pos.length;

  return (
    <Box height={'100%'}>
      <MapContainer
        center={[isNaN(latitudeCenter) ? 0 : latitudeCenter, isNaN(longitudeCenter) ? 0 : longitudeCenter]}
        // center={parentMap.getCenter()}
        minZoom={2}
        maxZoom={8}
        zoom={zoom}
        scrollWheelZoom={false}
        zoomControl={false}
        doubleClickZoom={false}
        touchZoom={false}
        boxZoom={false}
        dragging={false}
        style={{ height: '100%' }}
      >
        <TileLayer attribution="Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ" url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}" />

        {Object.entries(markers).map(([key, marker]) => {
          const lat = Number(marker.latitude);
          const long = Number(marker.longitude);
          const labels = Array.from(marker.label);
          const color = 'black';

          const country = marker.country;
          const countryName = marker.countryName;
          return (
            <Marker
              key={key}
              position={[lat, long]}
              icon={leafletSvgIcon(color)}
              eventHandlers={{
                mouseover: (event) => event.target.openPopup(),
                mouseout: (event) => event.target.closePopup(),
              }}
            >
              <Popup>
                <Box sx={{ fontSize: 'var(--fs-14)', color: 'white' }}>
                  <Box mb={1} display="flex" alignItems={'flex-start'}>
                    <Box component={'span'} display={'inline-flex'} pt={0.5} sx={{ height: '.8rem', verticalAlign: 'middle', mr: '5px' }}>
                      <Flag country={country} />
                    </Box>
                    <Box display="inline" className="capitalize" sx={{ verticalAlign: 'middle' }}>
                      <Typography fontSize={'inherit'}>{countryName}</Typography>
                    </Box>
                  </Box>
                  <Box>
                    {labels.map((l) => (
                      <Typography fontSize={'inherit'} color="inherit" key={l}>
                        {locationKeys[l as keyof typeof locationKeys].label ?? l}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              </Popup>
            </Marker>
          );
        })}

        <AutoFit markers={markers} />
      </MapContainer>
    </Box>
  );
});

const miniTimelineLimit = 3;
const MiniTimeline = memo(() => {
  const { timelineElements } = useContext(PersonContext);

  if (!timelineElements || timelineElements.length <= 0) return <></>;

  const nbElement = Math.min(timelineElements.length, miniTimelineLimit);

  return (
    <Box display="grid" gridTemplateColumns={'auto 1fr'} columnGap={'1rem'} className="timeline">
      {timelineElements.slice(0, miniTimelineLimit).map((elem, elem_index) => {
        let color = 'var(--color-lightgray5)';
        if (elem.type === 'started') color = 'var(--color-azure)';
        else if (elem.type === 'ended') color = 'var(--color-fushia)';

        let lineClass = '';
        let bottomPadding = 0;
        const pb = 4;
        if (elem_index < nbElement - 1) {
          bottomPadding = pb;
          lineClass = 'timeline-line';
        } else {
          if (timelineElements.length > miniTimelineLimit) {
            bottomPadding = pb;
            lineClass = 'timeline-line dashed';
          }
        }

        return (
          <Fragment key={elem_index}>
            <Box px={2} display={'flex'} flexDirection={'column'} alignItems={'center'}>
              <Box p={'5px'} sx={{ background: 'var(--color-lightgray2)', color: color, borderRadius: '4px' }}>
                <CalendarIcon sx={{ display: 'block', fontSize: 'var(--fs-14)' }} />
              </Box>

              <Box flex={1} className={lineClass}></Box>
            </Box>

            <Box pb={bottomPadding} pt={0} sx={{ minWidth: 0 }}>
              <Box className="date">
                {elem.type === 'started' && <FormattedMessage id="started" defaultMessage={'Started'} />}
                {elem.type === 'ended' && <FormattedMessage id="ended" defaultMessage={'Ended'} />}
                {elem.type === 'single' && <FormattedMessage id="occured" defaultMessage={'Occured'} />} on {elem.date.format(elem.dateFormat)}
              </Box>
              <Box className="text nowrap">{elem.texts[0].value}</Box>
            </Box>
          </Fragment>
        );
      })}

      {timelineElements.length > miniTimelineLimit && (
        <>
          <Box px={2} display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Box p={'5px'} sx={{ background: 'var(--color-lightgray2)', color: 'var(--color-lightgray5)', borderRadius: '4px' }}>
              <CalendarIcon sx={{ display: 'block', fontSize: 'var(--fs-14)' }} />
            </Box>
          </Box>

          <Box pb={0} pt={0}>
            <Box className="date">
              {timelineElements.length - miniTimelineLimit} <FormattedMessage id="more" defaultMessage={'more'} />
              ...
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
});
export default GeneralContent;
