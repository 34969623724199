/**
 * EditAlertScreening.tsx
 * Edit Alert Screening modal
 */
/* packages */
import { useState, useCallback, useMemo, memo, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

/* context */

/* hooks */
import { useAddModal } from 'contextProviders/ModalProvider';
import { useAddSnackbar } from 'contextProviders/SnackbarProvider';

/* components */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import SearchText from 'components/SearchElements/SearchText/SearchText';
import ShadowedButton from 'components/ShadowedButton/ShadowedButton';
import { ContentWithDarkTooltip } from 'components/InvestigateSearch/utils';
import Loader from 'components/Loader/Loader';

/* utilities */
import { StatusPromiseResponse } from 'models/utils';
import { AlertScreeningType } from 'models/alertScreening';
import { Dataset } from 'models/datasets';
import { SelectChangeEvent } from '@mui/material';
import SearchSelect from 'components/SearchElements/SearchText/SearchSelect';
import { WorkflowStatusType } from 'models/workflow';

/* types */
interface EditAlertScreeningProps {
  alertScreening?: AlertScreeningType;
  setAlertScreening(data: Partial<AlertScreeningType>, alertScreening?: AlertScreeningType): Promise<StatusPromiseResponse>;
  datasets?: Dataset[];
  workflowStatus?: WorkflowStatusType[];
}

/* elements */
const EditAlertScreening = memo(({ alertScreening, setAlertScreening, datasets, workflowStatus }: EditAlertScreeningProps) => {
  const { closeModal, blockModal } = useAddModal();
  const addSnackbar = useAddSnackbar();
  const intl = useIntl();

  const [alertScreeningParams, setAlertScreeningParams] = useState<Partial<AlertScreeningType>>(alertScreening ?? {});
  const [creatingAlertScreening, setCreatingAlertScreening] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);
  // const nextStepsRef = useRef<FilterButtonRefType | null>(null);
  // const actionsRef = useRef<FilterButtonRefType | null>(null);

  const handleChangeText = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setAlertScreeningParams((params) => ({ ...params, [event.target.name]: event.target.value }));
  }, []);
  const handleChangeScoringText = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setAlertScreeningParams((params) => ({
      ...params,
      matchingRuleScoring: {
        ...(params.matchingRuleScoring ?? {}),
        [event.target.name]: event.target.value,
      },
    }));
  }, []);
  // const handleChangeScoringNumber = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
  //   setAlertScreeningParams((params) => ({
  //     ...params,
  //     matchingRuleScoring: {
  //       ...(params.matchingRuleScoring ?? {}),
  //       [event.target.name]: Number(event.target.value),
  //     },
  //   }));
  // }, []);

  const handleChangeSelectSourceDataset = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      if (!datasets) return;
      const selectedDatasetIndex = datasets?.findIndex((dataset) => dataset.label === event.target.value) ?? -1;
      if (selectedDatasetIndex >= 0) setAlertScreeningParams((params) => ({ ...params, sourceDataSet: datasets[selectedDatasetIndex] }));
    },
    [datasets]
  );
  const handleChangeSelectAlertDataset = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      if (!datasets) return;
      const selectedDatasetIndex = datasets?.findIndex((dataset) => dataset.label === event.target.value) ?? -1;
      if (selectedDatasetIndex >= 0)
        setAlertScreeningParams((params) => ({
          ...params,
          matchingRuleScoring: {
            ...(params.matchingRuleScoring ?? {}),
            dataset: datasets[selectedDatasetIndex],
          },
        }));
    },
    [datasets]
  );

  const handleChangeSelectWorkflowStatus = useCallback((event: SelectChangeEvent<unknown>) => {
    // if (!workflowStatus) return;
    // const selectedWorkflowStatusIndex = workflowStatus?.findIndex((ws) => ws.code === event.target.value) ?? -1;
    // if (selectedWorkflowStatusIndex >= 0)
    //   setAlertScreeningParams((params) => ({
    //     ...params,
    //     matchingRuleScoring: {
    //       ...(params.matchingRuleScoring ?? {}),
    //       status: workflowStatus[selectedWorkflowStatusIndex].code,
    //     },
    //   }));
    setAlertScreeningParams((params) => ({
      ...params,
      matchingRuleScoring: {
        ...(params.matchingRuleScoring ?? {}),
        status: event.target.value as string,
      },
    }));
  }, []);

  const createOrUpdateAlertScreening = useCallback(async () => {
    if (!formRef.current) return;

    blockModal?.(true);
    setCreatingAlertScreening(true);

    const parsedParams: Partial<AlertScreeningType> = { ...alertScreeningParams };

    if (parsedParams.matchingRuleScoring?.priority) {
      parsedParams.matchingRuleScoring.priority = Number(parsedParams.matchingRuleScoring.priority);
    }
    if (parsedParams.matchingRuleScoring?.score) {
      parsedParams.matchingRuleScoring.score = Number(parsedParams.matchingRuleScoring.score);
    }

    const result = await setAlertScreening(alertScreeningParams, alertScreening);

    setCreatingAlertScreening(false);
    blockModal?.(false);

    if (result.status === 'success') {
      const updateSuccessMessage = alertScreening
        ? intl.formatMessage({
            id: 'updateAlertScreeningSuccess',
            defaultMessage: 'Alert screening updated',
          })
        : intl.formatMessage({
            id: 'createAlertScreeningSuccess',
            defaultMessage: 'New alert screening created',
          });

      addSnackbar(updateSuccessMessage, 'success');
      closeModal?.();
    } else {
      const updateErrorMessage = alertScreening
        ? intl.formatMessage({
            id: 'updateAlertScreeningError',
            defaultMessage: 'An error occured while updating the alert screening',
          })
        : intl.formatMessage({
            id: 'createAlertScreeningError',
            defaultMessage: 'An error occured while creating the alert screening',
          });
      addSnackbar(updateErrorMessage, 'error');
    }
  }, [blockModal, closeModal, alertScreeningParams, alertScreening, setAlertScreening, addSnackbar, intl]);

  const setMessage = alertScreening ? intl.formatMessage({ id: 'save', defaultMessage: 'Save' }) : intl.formatMessage({ id: 'create', defaultMessage: 'Create' });
  const MemoInputProps = useMemo(
    () => ({
      startAdornment: null,
    }),
    []
  );

  const isValidPriority = alertScreeningParams?.matchingRuleScoring?.priority
    ? String(alertScreeningParams?.matchingRuleScoring?.priority) === Number(alertScreeningParams?.matchingRuleScoring?.priority).toString()
    : true;
  const isValidScore = alertScreeningParams?.matchingRuleScoring?.score
    ? String(alertScreeningParams?.matchingRuleScoring?.score) === Number(alertScreeningParams?.matchingRuleScoring?.score).toString()
    : true;

  const saveDisabled =
    creatingAlertScreening ||
    !alertScreeningParams.dataId ||
    !alertScreeningParams.profile ||
    !alertScreeningParams.sourceDataSet ||
    !alertScreeningParams.matchingRuleScoring?.name ||
    !alertScreeningParams.matchingRuleScoring?.description ||
    !alertScreeningParams.matchingRuleScoring?.status ||
    !alertScreeningParams.matchingRuleScoring?.dataset ||
    !isValidPriority ||
    !isValidScore;

  let buttonTooltip: string = '';
  if (!alertScreeningParams.dataId) {
    buttonTooltip = intl.formatMessage({ id: 'missingAlertScreeningName', defaultMessage: 'You must set a name' });
  } else if (!alertScreeningParams.profile) {
    buttonTooltip = intl.formatMessage({ id: 'missingAlertScreeningProfile', defaultMessage: 'You must set a profile' });
  } else if (!alertScreeningParams.sourceDataSet) {
    buttonTooltip = intl.formatMessage({ id: 'missingAlertScreeningSourceDataset', defaultMessage: 'You must set a source dataset' });
  } else if (!alertScreeningParams.matchingRuleScoring?.name) {
    buttonTooltip = intl.formatMessage({ id: 'missingAlertScreeningRuleScoringName', defaultMessage: 'You must set a name for the alert definition' });
  } else if (!alertScreeningParams.matchingRuleScoring?.description) {
    buttonTooltip = intl.formatMessage({ id: 'missingAlertScreeningRuleScoringDescription', defaultMessage: 'You must set a description for the alert definition' });
  } else if (!alertScreeningParams.matchingRuleScoring?.status) {
    buttonTooltip = intl.formatMessage({ id: 'missingAlertScreeningRuleScoringStatus', defaultMessage: 'You must set a status for the alert definition' });
  } else if (!alertScreeningParams.matchingRuleScoring?.dataset) {
    buttonTooltip = intl.formatMessage({ id: 'missingAlertScreeningRuleScoringDataset', defaultMessage: 'You must set a dataset for the alert definition' });
  } else if (!isValidPriority) {
    buttonTooltip = intl.formatMessage({ id: 'invalidPriority', defaultMessage: 'You must set a valid number for priority' });
  } else if (!isValidScore) {
    buttonTooltip = intl.formatMessage({ id: 'invalidScorfe', defaultMessage: 'You must set a valid number for score' });
  }

  // validate current source
  const sourceIsValid = useMemo(() => {
    if (!alertScreening || !alertScreening.sourceDataSet) return true;
    if (!datasets) return false;

    const sourceLabel = alertScreening.sourceDataSet.label;
    return datasets.findIndex((dataset) => dataset.label === sourceLabel) >= 0;
  }, [datasets, alertScreening]);

  const allowedSourceDataset = useMemo(() => {
    const allowed = datasets?.filter((dataset) => dataset.type === 'Source').map((dataset) => ({ key: dataset.label, value: dataset.label })) ?? [];

    if (!sourceIsValid && alertScreening?.sourceDataSet) {
      const sourceLabel = alertScreening.sourceDataSet.label;
      allowed.push({ key: sourceLabel, value: sourceLabel });
    }
    return allowed;
  }, [datasets, alertScreening, sourceIsValid]);

  // validate current alert dataset
  const alertDatasetIsValid = useMemo(() => {
    if (!alertScreening || !alertScreening.matchingRuleScoring || !alertScreening.matchingRuleScoring.dataset) return true;
    if (!datasets) return false;

    const sourceLabel = alertScreening.matchingRuleScoring.dataset.label;
    return datasets.findIndex((dataset) => dataset.label === sourceLabel) >= 0;
  }, [datasets, alertScreening]);

  const allowedAlertDataset = useMemo(() => {
    const allowed = datasets?.filter((dataset) => dataset.type === 'Source' && dataset.sourceOfDataType === 'alerts').map((dataset) => ({ key: dataset.label, value: dataset.label })) ?? [];

    if (!alertDatasetIsValid && alertScreening?.matchingRuleScoring && alertScreening.matchingRuleScoring.dataset) {
      const alertLabel = alertScreening.matchingRuleScoring.dataset.label;
      allowed.push({ key: alertLabel, value: alertLabel });
    }
    return allowed;
  }, [datasets, alertScreening, alertDatasetIsValid]);

  // validate current workflow status
  const alertworkflowStatusIsValid = useMemo(() => {
    if (!alertScreening || !alertScreening.matchingRuleScoring || !alertScreening.matchingRuleScoring.status) return true;
    if (!workflowStatus) return false;

    // const code = alertScreening.matchingRuleScoring.status.code;
    const code = alertScreening.matchingRuleScoring.status;
    return workflowStatus.findIndex((ws) => ws.code === code) >= 0;
  }, [workflowStatus, alertScreening]);

  const allowedWorkflowStatus = useMemo(() => {
    const allowed = workflowStatus?.map((ws) => ({ key: ws.code, value: ws.code })) ?? [];

    if (!alertworkflowStatusIsValid && alertScreening?.matchingRuleScoring && alertScreening.matchingRuleScoring.status) {
      // const code = alertScreening.matchingRuleScoring.status.code;
      const code = alertScreening.matchingRuleScoring.status;
      allowed.push({ key: code, value: code });
    }
    return allowed;
  }, [workflowStatus, alertScreening, alertworkflowStatusIsValid]);

  return (
    <Box width={'min(85vw, 650px)'}>
      <form ref={formRef}>
        <Box px={3}>
          <Box display="grid" rowGap={2} columnGap={4} sx={{ gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' } }}>
            <Box>
              <Typography className="modal-label">
                <FormattedMessage id="AlertScreeningName" defaultMessage={'Name'} />
              </Typography>
              <SearchText
                fullWidth
                name="dataId"
                value={alertScreeningParams.dataId ?? ''}
                onChange={handleChangeText}
                placeholder={intl.formatMessage({ id: 'chooseAlertScreeningName', defaultMessage: 'Alert screening name' })}
                InputProps={MemoInputProps}
                disabled={creatingAlertScreening}
              />
            </Box>

            <Box>
              <Typography className="modal-label">
                <FormattedMessage id="AlertScreeningProfile" defaultMessage={'Profile'} />
              </Typography>
              <SearchText
                fullWidth
                name="profile"
                value={alertScreeningParams.profile ?? ''}
                onChange={handleChangeText}
                placeholder={intl.formatMessage({ id: 'chooseAlertScreeningProfile', defaultMessage: 'Profile' })}
                InputProps={MemoInputProps}
                disabled={creatingAlertScreening}
              />
            </Box>

            <Box>
              <Typography className="modal-label">
                <FormattedMessage id="AlertScreeningSourceDataset" defaultMessage={'Customer source dataset'} />
              </Typography>
              <SearchSelect
                name="type"
                value={alertScreeningParams.sourceDataSet ? alertScreeningParams.sourceDataSet.label : ''}
                onChange={handleChangeSelectSourceDataset}
                fullWidth
                placeholder={intl.formatMessage({ id: 'select', defaultMessage: 'Select...' })}
                choices={allowedSourceDataset}
                MenuProps={{ classes: { paper: 'custom-scrollbar' }, sx: { maxHeight: 350 } }}
                disabled={creatingAlertScreening || !sourceIsValid || !datasets}
              />
            </Box>

            <Box>
              <Typography className="modal-label">
                <FormattedMessage id="AlertScreeningApiKey" defaultMessage={'API Key'} />
              </Typography>
              <SearchText
                fullWidth
                name="apiKey"
                value={alertScreeningParams.apiKey ?? ''}
                onChange={handleChangeText}
                placeholder={intl.formatMessage({ id: 'chooseAlertScreeningApiKey', defaultMessage: 'API key' })}
                InputProps={MemoInputProps}
                disabled={creatingAlertScreening}
              />
            </Box>
          </Box>

          <Typography mt={2} sx={{ fontWeight: 'medium', color: 'var(--color-darkgray)' }}>
            <FormattedMessage id="Alert definition" defaultMessage={'Alert Definition'} />
          </Typography>

          <Box mt={2} display="grid" rowGap={2} columnGap={4} sx={{ gridTemplateColumns: { xs: '1fr', md: '1fr 1fr' } }}>
            <Box>
              <Typography className="modal-label">
                <FormattedMessage id="AlertScreeningScoringName" defaultMessage={'Name'} />
              </Typography>
              <SearchText
                fullWidth
                name="name"
                value={alertScreeningParams.matchingRuleScoring?.name ?? ''}
                onChange={handleChangeScoringText}
                placeholder={intl.formatMessage({ id: 'chooseAlertScreeningRuleScoringName', defaultMessage: 'Name' })}
                InputProps={MemoInputProps}
                disabled={creatingAlertScreening}
              />
            </Box>

            <Box>
              <Typography className="modal-label">
                <FormattedMessage id="AlertScreeningScoringDescription" defaultMessage={'Description'} />
              </Typography>
              <SearchText
                fullWidth
                name="description"
                value={alertScreeningParams.matchingRuleScoring?.description ?? ''}
                onChange={handleChangeScoringText}
                placeholder={intl.formatMessage({ id: 'chooseAlertScreeningRuleScoringDescription', defaultMessage: 'Description' })}
                InputProps={MemoInputProps}
                disabled={creatingAlertScreening}
              />
            </Box>

            <Box>
              <Typography className="modal-label">
                <FormattedMessage id="AlertScreeningWorkflowStatus" defaultMessage={'Status'} />
              </Typography>
              <SearchSelect
                name="type"
                value={alertScreeningParams.matchingRuleScoring?.status ?? ''}
                onChange={handleChangeSelectWorkflowStatus}
                fullWidth
                placeholder={intl.formatMessage({ id: 'select', defaultMessage: 'Select...' })}
                choices={allowedWorkflowStatus}
                MenuProps={{ classes: { paper: 'custom-scrollbar' }, sx: { maxHeight: 350 } }}
                disabled={creatingAlertScreening || !alertworkflowStatusIsValid || !workflowStatus}
              />
            </Box>

            <Box>
              <Typography className="modal-label">
                <FormattedMessage id="AlertScreeningAlertDataset" defaultMessage={'Alert dataset'} />
              </Typography>
              <SearchSelect
                name="type"
                value={alertScreeningParams.matchingRuleScoring?.dataset?.label ?? ''}
                onChange={handleChangeSelectAlertDataset}
                fullWidth
                placeholder={intl.formatMessage({ id: 'select', defaultMessage: 'Select...' })}
                choices={allowedAlertDataset}
                MenuProps={{ classes: { paper: 'custom-scrollbar' }, sx: { maxHeight: 350 } }}
                disabled={creatingAlertScreening || !alertDatasetIsValid || !datasets}
              />
            </Box>

            <Box>
              <Typography className="modal-label">
                <FormattedMessage id="AlertScreeningScoringScore" defaultMessage={'Score'} />
              </Typography>
              <SearchText
                fullWidth
                name="score"
                value={alertScreeningParams.matchingRuleScoring?.score ?? ''}
                onChange={handleChangeScoringText}
                placeholder={intl.formatMessage({ id: 'chooseAlertScreeningRuleScoringScore', defaultMessage: 'Score' })}
                InputProps={MemoInputProps}
                disabled={creatingAlertScreening}
                error={!isValidScore}
                inputMode="numeric"
              />
            </Box>

            <Box>
              <Typography className="modal-label">
                <FormattedMessage id="AlertScreeningScoringPriority" defaultMessage={'Priority'} />
              </Typography>
              <SearchText
                fullWidth
                name="priority"
                value={alertScreeningParams.matchingRuleScoring?.priority ?? ''}
                onChange={handleChangeScoringText}
                placeholder={intl.formatMessage({ id: 'chooseAlertScreeningRuleScoringPriority', defaultMessage: 'Priority' })}
                InputProps={MemoInputProps}
                disabled={creatingAlertScreening}
                error={!isValidPriority}
                inputMode="numeric"
              />
            </Box>
          </Box>
        </Box>

        <Box display={'flex'} justifyContent={'flex-end'} gap={'1rem'} px={3} pt={2} mt={2} sx={{ borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
          {creatingAlertScreening ? (
            <Box>
              <Loader cssStyle={{ width: '33px' }} />
            </Box>
          ) : (
            <>
              <ShadowedButton
                onClick={() => {
                  closeModal?.();
                }}
                sx={{ whiteSpace: 'nowrap' }}
              >
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </ShadowedButton>

              <ContentWithDarkTooltip tooltip={buttonTooltip} placement="top">
                <Button type="button" variant="contained" onClick={() => createOrUpdateAlertScreening()} disabled={saveDisabled} disableElevation sx={{ textTransform: 'none' }}>
                  {setMessage}
                </Button>
              </ContentWithDarkTooltip>
            </>
          )}
        </Box>
      </form>
    </Box>
  );
});

export default EditAlertScreening;
