/**
 * CheckUserPermissions.ts
 * Check that current user has the right permissions
 */

export type PermissionString =
  | 'administration'
  | 'alerts'
  | 'datasets'
  | 'search'
  | 'audits'
  | 'UserManagement'
  | 'RoleManagement'
  | 'GroupManagement'
  | 'DatasetManagement'
  | 'SchedulerManagement'
  | 'OrganizationUnitManagement'
  | 'AuditManagement'
  | 'screenaaudits'
  | 'WorlkflowManagement'
  | 'ScreeningManagement';

export const PermissionsPrint: { [key: string]: string } = {
  administration: 'Administration',
  alerts: 'Alerts',
  datasets: 'Datasets',
  search: 'Search',
  audits: 'Audits',
  UserManagement: 'User management',
  RoleManagement: 'Role management',
  GroupManagement: 'Group management',
  DatasetManagement: 'Dataset management',
  SchedulerManagement: 'Scheduler management',
  OrganizationUnitManagement: 'Organization unit management',
  AuditManagement: 'Audit management',
  screenaaudits: 'Screena audits',
  WorlkflowManagement: 'Worlkflow management',
  ScreeningManagement: 'Screening management',
};

export type AllowedPageCheck =
  | 'investigate'
  | 'investigateSearch'
  | 'investigateExplore'
  | 'investigateAlerts'
  | 'investigateAlert'
  | 'investigateRisks'
  | 'manage'
  | 'manageSetupOrganizations'
  | 'manageSetupTeams'
  | 'manageSetupRoles'
  | 'manageUsers'
  | 'manageDatasets'
  | 'manageLibrairies'
  | 'manageScreeningsWorkflow'
  | 'manageScreeningsAlert'
  | 'manageScreeningsRisk'
  | 'manageScreeningsSchedule'
  | 'manageAudits'
  | 'listWorkflowStatus'
  | 'listTeams'
  | 'listAllUsers'
  | 'listNotifications';

const PermissionsMap: { [key in AllowedPageCheck]: PermissionString | boolean } = {
  // pages
  investigate: true,
  investigateSearch: 'search',
  investigateExplore: 'datasets',
  investigateAlerts: 'alerts',
  investigateAlert: 'alerts',
  // investigateRisks: 'risks',
  investigateRisks: false,

  manage: true,

  manageSetupOrganizations: 'OrganizationUnitManagement',
  manageSetupTeams: 'GroupManagement',
  manageSetupRoles: 'RoleManagement',

  manageUsers: 'UserManagement',
  manageDatasets: 'DatasetManagement',
  manageLibrairies: 'DatasetManagement',

  manageScreeningsWorkflow: 'WorlkflowManagement',
  manageScreeningsAlert: 'ScreeningManagement',
  manageScreeningsRisk: 'ScreeningManagement',
  manageScreeningsSchedule: 'SchedulerManagement',

  manageAudits: 'AuditManagement',

  // list API
  listWorkflowStatus: 'alerts',
  listTeams: 'alerts',
  listAllUsers: 'alerts',
  listNotifications: true,
};

// check the a page is accessible wrt permissions array
const checkPermissions = (page: AllowedPageCheck, permissions: string[]): boolean => {
  if (!Object.keys(PermissionsMap).includes(page)) return false;

  const requiredPermission = PermissionsMap[page];
  if (typeof requiredPermission === 'boolean') return requiredPermission;
  return permissions.includes(requiredPermission);
};

export { checkPermissions };
