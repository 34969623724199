/**
 * utils.tsx
 * a set of utilities for results cards
 */
/* packages */
import { PropsWithChildren, ReactNode, memo } from 'react';
import { FormattedMessage } from 'react-intl';

/* contexts */

/* hooks */

/* components */
import Badge from '@mui/material/Badge';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';

/* utilities */

/* types */
import { SearchResultsElement } from 'models/searchDatasets';

export interface SearchCardType {
  result: SearchResultsElement;
}

export const StyledBadge = ({ badgeContent, tooltip, children }: PropsWithChildren<{ badgeContent: number; tooltip?: ReactNode }>) => {
  const badgeStyle: React.CSSProperties = {
    fontSize: '.6rem',
    height: 'auto',
    lineHeight: 1,
    // paddingBlock: '3px',
    padding: 0,
    border: '1px solid var(--color-grayHeaderBorder)',
    backgroundColor: 'white',
    cursor: 'pointer',
    minWidth: 0,
  };
  if (badgeContent) {
    badgeStyle['transform'] = 'scale(1) translate(calc(0%), -50%)';
    // badgeStyle['transform'] = 'scale(1) translate(calc(100%), -50%)';
    // badgeStyle['transform'] = 'scale(1) translate(-50%, calc(-100% + 4px))';
  }

  let internalBadgeContent: ReactNode = badgeContent ? `+${badgeContent}` : badgeContent;

  if (tooltip) {
    internalBadgeContent = <ContentWithDarkTooltip tooltip={tooltip}> {internalBadgeContent}</ContentWithDarkTooltip>;
  }
  // '& .MuiBox-root': {
  //   paddingInline: '5px',
  //   paddingBlock: '3px',
  // },
  return (
    <Badge
      className="badge-container"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      // color="lightgray"
      badgeContent={internalBadgeContent}
      sx={{
        maxWidth: '100%',
        '& .MuiBadge-badge': badgeStyle,
        '& .MuiBadge-badge .MuiBox-root': {
          paddingInline: '5px',
          paddingBlock: '3px',
        },
      }}
    >
      <Box className="badge-content">{children}</Box>
    </Badge>
  );
};

// export const StyledBadge = ({ badgeContent, tooltip, children }: PropsWithChildren<{ badgeContent: number; tooltip?: ReactNode }>) => {
//   // const badgeStyle: React.CSSProperties = {
//   //   fontSize: '.6rem',
//   //   height: 'auto',
//   //   lineHeight: 1,
//   //   // paddingBlock: '3px',
//   //   padding: 0,
//   //   border: '1px solid var(--color-grayHeaderBorder)',
//   //   backgroundColor: 'white',
//   //   cursor: 'pointer',
//   //   minWidth: 0,
//   // };
//   // if (badgeContent) {
//   //   badgeStyle['transform'] = 'scale(1) translate(calc(0%), -50%)';
//   //   // badgeStyle['transform'] = 'scale(1) translate(calc(100%), -50%)';
//   //   // badgeStyle['transform'] = 'scale(1) translate(-50%, calc(-100% + 4px))';
//   // }

//   let internalBadgeContent: ReactNode = badgeContent ? `+${badgeContent}` : badgeContent;

//   if (tooltip) {
//     internalBadgeContent = <ContentWithTooltip tooltip={tooltip}> {internalBadgeContent}</ContentWithTooltip>;
//   }
//   // '& .MuiBox-root': {
//   //   paddingInline: '5px',
//   //   paddingBlock: '3px',
//   // },
//   return (
//     <Box display="flex" gap={'.5rem'} alignItems="center">
//       {children}

//       {badgeContent > 0 && internalBadgeContent && <Box sx={{ border: '1px solid var(--color-grayHeaderBorder)', px: '8px', py: '3px', borderRadius: '1000px' }}>{internalBadgeContent}</Box>}
//     </Box>
//   );
// };

export const Flag = ({ country }: { country?: string }) => {
  if (!country) return <></>;

  const height = '0.8125rem';
  return (
    <img src={process.env.REACT_APP_DIRECTORY + `flags/${country.toLowerCase()}.svg#${country}`} alt="" style={{ height: height, width: 'auto', border: '1px solid var(--color-grayHeaderBorder)' }} />
  );
};

export const UnknownMessage = ({ color }: { color?: string }) => {
  return (
    <Typography fontSize={'inherit'} fontWeight={'inherit'} lineHeight={'inherit'} sx={{ color: color ?? '#DAD8D8' }}>
      <FormattedMessage id="unkown" defaultMessage={'Unknown'} />
    </Typography>
  );
};

interface ContentWithDarkTooltipProps {
  tooltip: ReactNode;
  placement?: TooltipProps['placement'];
  pad?: number;
  boxProps?: BoxProps;
}
export const ContentWithDarkTooltip = memo(({ tooltip, placement, pad, boxProps, children }: PropsWithChildren<ContentWithDarkTooltipProps>) => {
  return (
    <Tooltip
      placement={placement ?? 'left'}
      title={tooltip}
      slotProps={{ tooltip: { sx: { px: pad ?? 2, py: pad ?? 2, backgroundColor: 'var(--color-darkgray)', fontSize: 'var(--fs-12)', fontWeight: 400 } } }}
    >
      <Box {...boxProps}>{children}</Box>
    </Tooltip>
  );
});
