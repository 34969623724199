export class URLConstants {
  public static baseUrl: string = '';
  //   public static segrateBaseUrl: string = '';
  public static screenaBaseUrl: string = '';

  public static setURL(baseUrl: string, screenaURL: string) {
    this.baseUrl = baseUrl;
    this.screenaBaseUrl = screenaURL;
  }

  public static clearUrl() {
    this.baseUrl = '';
    this.screenaBaseUrl = '';
  }

  /* connected user */
  // Login
  public static get login(): string {
    return URLConstants.baseUrl + 'login';
  }
  // Get screena apikey
  public static get screenaApiKey(): string {
    return URLConstants.baseUrl + 'screena/apikey';
  }
  // Get user information
  public static getUserDetails(userName: string): string {
    return URLConstants.baseUrl + `user/${userName}`;
  }

  public static get userChangePassword(): string {
    return URLConstants.baseUrl + 'user/changepassword';
  }

  public static get userUpdate(): string {
    return URLConstants.baseUrl + 'user/update';
  }
  public static get userCreate(): string {
    return URLConstants.baseUrl + 'user/create';
  }

  public static get userDelete(): string {
    return URLConstants.baseUrl + 'user/delete/';
  }

  public static get userSetAvatar(): string {
    return URLConstants.baseUrl + 'user/upload/avatar';
  }
  public static get userDeleteAvatar(): string {
    return URLConstants.baseUrl + 'user/avatar';
  }

  // Get notifications
  public static get getNotifications(): string {
    return URLConstants.baseUrl + 'notification';
  }

  /* Screena Dataset Search */
  public static get screenaGetDatasets(): string {
    return URLConstants.screenaBaseUrl + 'datasets';
  }

  public static get screenaSearchInDatasetsEngine(): string {
    return URLConstants.screenaBaseUrl + 'dataset-search-engine';
  }

  public static get screenaSearchInDatasetsEngineExport(): string {
    return URLConstants.screenaBaseUrl + 'dataset-search-engine-export';
  }

  public static get screenaSearchInDatasetsEngineExportPDF(): string {
    return URLConstants.screenaBaseUrl + 'dataset-search-engine-export-pdf';
  }

  // alerts
  public static get alertList(): string {
    return URLConstants.baseUrl + 'alert/list/page';
  }

  public static get alertCount(): string {
    return URLConstants.baseUrl + 'alert/list/amountOfRecords';
  }

  public static get alertUnreadCount(): string {
    return URLConstants.baseUrl + 'alert/unread/count';
  }

  public static get alertSetAsRead(): string {
    return URLConstants.baseUrl + 'alert/read';
  }
  public static get alertSetAsUnread(): string {
    return URLConstants.baseUrl + 'alert/unread';
  }

  public static get alertExportCSV(): string {
    return URLConstants.baseUrl + 'alert/export/csv';
  }
  // public static get alertExportPDF(): string {
  //   return URLConstants.baseUrl + 'alert/export/pdf';
  // }
  // Saved search management
  public static get savedSearchList(): string {
    return URLConstants.baseUrl + 'savedsearch/list/';
  }

  public static get savedSearchCreate(): string {
    return URLConstants.baseUrl + 'savedsearch/create';
  }

  public static get savedSearchUpdate(): string {
    return URLConstants.baseUrl + 'savedsearch/update';
  }

  public static get savedSearchDelete(): string {
    return URLConstants.baseUrl + `savedsearch/delete/`;
  }

  // Workflow
  public static get workflowList(): string {
    return URLConstants.baseUrl + 'workflow/list';
  }

  public static get createWorkflowStatus(): string {
    return URLConstants.baseUrl + 'workflow/create';
  }

  public static get updateWorkflowStatus(): string {
    return URLConstants.baseUrl + 'workflow/update';
  }

  public static get deleteWorkflowStatus(): string {
    return URLConstants.baseUrl + 'workflow/delete/';
  }

  public static get workflowActionsList(): string {
    return URLConstants.baseUrl + 'workflow/actions';
  }

  /* Countries */
  public static get allCountries(): string {
    return URLConstants.baseUrl + 'library/countries';
  }
  public static get importCountries(): string {
    return URLConstants.baseUrl + 'library/countries/import';
  }

  // Librairy
  public static get getLibraryData(): string {
    return URLConstants.baseUrl + 'library/screena/';
  }

  // Group
  public static get listTeams(): string {
    return URLConstants.baseUrl + 'group/list';
  }
  public static get teamCreate(): string {
    return URLConstants.baseUrl + 'group/create';
  }

  public static get teamUpdate(): string {
    return URLConstants.baseUrl + 'group/update';
  }

  public static get teamDelete(): string {
    return URLConstants.baseUrl + 'group/delete/';
  }

  // All users
  public static get listAllUsers(): string {
    return URLConstants.baseUrl + 'user/list';
  }

  // Explore Search
  public static get allDatasets(): string {
    return URLConstants.baseUrl + 'dataset/list';
  }
  public static get datasetSearch(): string {
    return URLConstants.baseUrl + 'dataset/search';
  }

  public static get datasetSearchCount(): string {
    return URLConstants.baseUrl + 'dataset/search/count';
  }

  public static get datasetExport(): string {
    return URLConstants.baseUrl + 'dataset/export';
  }

  // Risk Search
  public static get riskPersonsList(): string {
    return URLConstants.baseUrl + 'person/risk/list/page';
  }

  public static get riskPersonsCount(): string {
    return URLConstants.baseUrl + 'person/risk/list/amountOfRecords';
  }

  public static get riskPersonsasCsv(): string {
    return URLConstants.baseUrl + 'person/risk/export';
  }

  // Single Alert
  public static get getSingleAlert(): string {
    return URLConstants.baseUrl + 'alert/get/';
  }
  public static get setTrueHit(): string {
    return URLConstants.baseUrl + 'alert/truehit';
  }
  public static get setFalseHit(): string {
    return URLConstants.baseUrl + 'alert/falsehit';
  }

  public static get uploadAlertFile(): string {
    return URLConstants.baseUrl + 'document/alert/upload';
  }
  public static get listDocuments(): string {
    return URLConstants.baseUrl + 'document/list';
  }

  public static get downloadFile(): string {
    return URLConstants.baseUrl + 'document/get/';
  }

  public static get commentAsFavorite(): string {
    return URLConstants.baseUrl + 'comment/favorite';
  }

  public static get alertAddComment(): string {
    return URLConstants.baseUrl + 'alert/addcomment';
  }

  public static get alertExecuteWorkflow(): string {
    return URLConstants.baseUrl + 'alert/workflow';
  }

  public static get alertChangeAssigned(): string {
    return URLConstants.baseUrl + 'alert/changeassigned';
  }

  // View person profile
  public static get personSingleDetails(): string {
    return URLConstants.baseUrl + 'person/details';
  }

  // Datasets & Folders
  public static get datasetClassifications(): string {
    return URLConstants.baseUrl + 'dataset/classifications';
  }

  public static get datasetCreate(): string {
    return URLConstants.baseUrl + 'dataset/create';
  }

  public static get datasetUpdate(): string {
    return URLConstants.baseUrl + 'dataset/update';
  }

  public static get datasetImport(): string {
    return URLConstants.baseUrl + 'dataset/import';
  }

  public static get datasetDelete(): string {
    return URLConstants.baseUrl + 'dataset/delete/';
  }

  public static get fullDatasetFolderList(): string {
    return URLConstants.baseUrl + 'dataset/folder/list';
  }

  public static get createDatasetFolder(): string {
    return URLConstants.baseUrl + 'dataset/folder/create';
  }

  public static get updateDatasetFolder(): string {
    return URLConstants.baseUrl + 'dataset/folder/update';
  }

  public static get deleteDatasetFolder(): string {
    return URLConstants.baseUrl + 'dataset/folder/delete/';
  }

  public static get auditList(): string {
    return URLConstants.baseUrl + `audit/*auditType*/page`;
  }

  public static get auditCount(): string {
    return URLConstants.baseUrl + 'audit/*auditType*/amountOfRecords';
  }

  // public static get personRelated(): string {
  //   return URLConstants.baseUrl + 'person/related/';
  // }

  //   public static get screenaSearchInDatasets(): string {
  //     return URLConstants.screenaBaseUrl + 'dataset-search';
  //   }

  //   // Alerts Display

  //   public static get getAlertStatsBetweenTwoDate(): string {
  //     return URLConstants.baseUrl + 'alert/stats/status';
  //   }

  //   // Persons Display
  //   public static get personsList(): string {
  //     return URLConstants.baseUrl + 'person/list/page';
  //   }

  //   public static get personsCount(): string {
  //     return URLConstants.baseUrl + 'person/list/amountOfRecords';
  //   }

  //   public static get personAddComment(): string {
  //     return URLConstants.baseUrl + 'person/addcomment';
  //   }

  //   public static get personScoreCard(): string {
  //     return URLConstants.baseUrl + 'person/scorecard/';
  //   }

  //   // Account display
  //   public static get accountsRelated(): string {
  //     return URLConstants.baseUrl + 'account/person/';
  //   }

  //   // Datasets

  //   public static get customerDatasetList(): string {
  //     return URLConstants.baseUrl + 'dataset/list/customer';
  //   }

  //   public static get datasetList(): string {
  //     return URLConstants.baseUrl + 'dataset/list/page';
  //   }

  //   public static get datasetCount(): string {
  //     return URLConstants.baseUrl + 'dataset/list/amountOfRecords';
  //   }

  // Jobs
  public static get fullSchedulerList(): string {
    return URLConstants.baseUrl + 'scheduler/list';
  }

  //   public static get schedulerList(): string {
  //     return URLConstants.baseUrl + 'scheduler/list/page';
  //   }

  //   public static get schedulerCount(): string {
  //     return URLConstants.baseUrl + 'scheduler/list/amountOfRecords';
  //   }

  public static get schedulerCreate(): string {
    return URLConstants.baseUrl + 'scheduler/create';
  }

  public static get schedulerUpdate(): string {
    return URLConstants.baseUrl + 'scheduler/update';
  }

  public static get schedulerDelete(): string {
    return URLConstants.baseUrl + 'scheduler/delete/';
  }

  public static get schedulerStart(): string {
    return URLConstants.baseUrl + 'scheduler/start/';
  }
  public static get schedulerStop(): string {
    return URLConstants.baseUrl + 'scheduler/stop/';
  }

  //   // Users

  //   public static get userList(): string {
  //     return URLConstants.baseUrl + 'user/list/page';
  //   }

  //   public static get userCount(): string {
  //     return URLConstants.baseUrl + 'user/list/amountOfRecords';
  //   }

  //   public static get userPreferencesUpdate(): string {
  //     return URLConstants.baseUrl + 'userpreferences/update';
  //   }

  //   public static get userPreferencesDashboardUpdate(): string {
  //     return URLConstants.baseUrl + 'userpreferences/dashboard/update';
  //   }

  //   public static get userPreferencesDashboard(): string {
  //     return URLConstants.baseUrl + 'userpreferences/dashboard';
  //   }

  //   // Group

  //   public static get groupList(): string {
  //     return URLConstants.baseUrl + 'group/list/page';
  //   }

  //   public static get groupCount(): string {
  //     return URLConstants.baseUrl + 'group/list/amountOfRecords';
  //   }

  // Role
  public static get fullRoleList(): string {
    return URLConstants.baseUrl + 'role/list';
  }

  //   public static get roleList(): string {
  //     return URLConstants.baseUrl + 'role/list/page';
  //   }

  //   public static get roleCount(): string {
  //     return URLConstants.baseUrl + 'role/list/amountOfRecords';
  //   }

  public static get roleCreate(): string {
    return URLConstants.baseUrl + 'role/create';
  }

  public static get roleUpdate(): string {
    return URLConstants.baseUrl + 'role/update';
  }

  public static get roleDelete(): string {
    return URLConstants.baseUrl + 'role/delete/';
  }

  // Organization Unit
  public static get fullOrganizationUnitList(): string {
    return URLConstants.baseUrl + 'organizationunit/list';
  }

  //   public static get organizationUnitList(): string {
  //     return URLConstants.baseUrl + 'organizationunit/list/page';
  //   }

  //   public static get organizationUnitCount(): string {
  //     return URLConstants.baseUrl + 'organizationunit/list/amountOfRecords';
  //   }

  public static get organizationUnitCreate(): string {
    return URLConstants.baseUrl + 'organizationunit/create';
  }

  public static get organizationUnitUpdate(): string {
    return URLConstants.baseUrl + 'organizationunit/update';
  }

  public static get organizationUnitDelete(): string {
    return URLConstants.baseUrl + 'organizationunit/delete/';
  }

  //   // Audit User Management
  //   public static get auditUserList(): string {
  //     return URLConstants.baseUrl + 'audit/user/list/page';
  //   }

  //   public static get auditUserCount(): string {
  //     return URLConstants.baseUrl + 'audit/user/list/amountOfRecords';
  //   }

  //   // Audit Radar Application
  //   public static get auditApplicationList(): string {
  //     return URLConstants.baseUrl + 'audit/application/list/page';
  //   }

  //   public static get auditApplicationCount(): string {
  //     return URLConstants.baseUrl + 'audit/application/list/amountOfRecords';
  //   }

  //   // Audit Radar Scheduler
  //   public static get auditSchedulerList(): string {
  //     return URLConstants.baseUrl + 'audit/scheduler/list/page';
  //   }

  //   public static get auditSchedulerCount(): string {
  //     return URLConstants.baseUrl + 'audit/scheduler/list/amountOfRecords';
  //   }

  // Alert Screenings - Matching Rule
  public static get getAlertScreenings(): string {
    return URLConstants.baseUrl + 'matching/configuration';
  }

  // public static get createAlertScreening(): string {
  //   return URLConstants.baseUrl + 'matching/configuration/create';
  // }

  public static get updateAlertScreening(): string {
    return URLConstants.baseUrl + 'matching/configuration/update';
  }

  public static get deleteAlertScreening(): string {
    return URLConstants.baseUrl + 'matching/configuration/delete/';
  }

  // Screena Audit
  //   public static get screenaAuditCrawl(): string {
  //     return URLConstants.screenaBaseUrl + 'system/log/crawl';
  //   }

  //   public static get screenaAuditCrawlCount(): string {
  //     return URLConstants.screenaBaseUrl + 'system/log/crawl/count';
  //   }

  //   public static get screenaAuditSystem(): string {
  //     return URLConstants.screenaBaseUrl + 'system/log/application';
  //   }

  //   public static get screenaAuditSystemCount(): string {
  //     return URLConstants.screenaBaseUrl + 'system/log/application/count';
  //   }

  // public static get screenaAuditImport(): string {
  //   return URLConstants.screenaBaseUrl + 'import/audit/log';
  // }

  // public static get screenaAuditImportSummary(): string {
  //   return URLConstants.screenaBaseUrl + 'import/watchlist/audit/summary';
  // }

  // public static get screenaAuditImportCount(): string {
  //   return URLConstants.screenaBaseUrl + 'import/audit/count';
  // }

  //   public static get screenaAuditWatchListImportAuditErrorLog(): string {
  //     return URLConstants.screenaBaseUrl + 'import/watchlist/audit/error/log';
  //   }

  //   public static get screenaAuditWatchListImportAuditErrorLogCount(): string {
  //     return URLConstants.screenaBaseUrl + 'import/watchlist/audit/error/count';
  //   }

  //   public static get screenaAuditWatchListImport(): string {
  //     return URLConstants.screenaBaseUrl + 'import/watchlist/audit/log';
  //   }

  //   public static get screenaAuditWatchListImportCount(): string {
  //     return URLConstants.screenaBaseUrl + 'import/watchlist/audit/count';
  //   }

  //   public static get screenaAuditWatchlistImportDownloadAsCsv(): string {
  //     return URLConstants.screenaBaseUrl + 'import/watchlist/audit/csv';
  //   }

  //   public static get screenaTaskImport(): string {
  //     return URLConstants.screenaBaseUrl + 'tasks/log';
  //   }

  //   public static get screenaTaskImportCount(): string {
  //     return URLConstants.screenaBaseUrl + 'tasks/log/count';
  //   }

  //   // Upload file
  //   public static get uploadFilePerson(): string {
  //     return URLConstants.baseUrl + 'document/person/upload';
  //   }

  //   // Transactions management
  //   public static get transactionLinkedToPerson(): string {
  //     return URLConstants.baseUrl + 'transaction/person/';
  //   }

  //   public static get transactionPaginatedLinkedToPerson(): string {
  //     return URLConstants.baseUrl + 'transaction/person/list/page';
  //   }

  //   public static get transactionCountLinkedToPerson(): string {
  //     return URLConstants.baseUrl + 'transaction/person/list/amountOfRecords';
  //   }

  //   // Screena Dataset Search
  //   public static get screenaSearchInDatasets(): string {
  //     return URLConstants.screenaBaseUrl + 'dataset-search';
  //   }

  //   public static get screenaSearchInDatasetsEngine(): string {
  //     return URLConstants.screenaBaseUrl + 'dataset-search-engine';
  //   }

  //   public static get screenaSearchInDatasetsEngineExport(): string {
  //     return URLConstants.screenaBaseUrl + 'dataset-search-engine-export';
  //   }

  //   public static get screenaSearchInDatasetsEngineExportPDF(): string {
  //     return URLConstants.screenaBaseUrl + 'dataset-search-engine-export-pdf';
  //   }

  //   public static get screenaGetFullRecordData(): string {
  //     return URLConstants.screenaBaseUrl + 'datasets/browse-records';
  //   }

  //   public static get screenaBrowseInDatasets(): string {
  //     return URLConstants.screenaBaseUrl + 'datasets/browse-records';
  //   }

  //   public static get screenaAmountOfRecordsBrowseInDatasets(): string {
  //     return URLConstants.screenaBaseUrl + 'datasets/browse-records/amountofrecords';
  //   }

  //   public static get screenaExportDatasets(): string {
  //     return URLConstants.screenaBaseUrl + 'datasets/export-records';
  //   }

  //   // Screena Entity Match
  //   public static get screenaEntityMatch(): string {
  //     return URLConstants.screenaBaseUrl + 'entity-match';
  //   }

  //   // SegRate
  //   public static get segRateGetConfigurations(): string {
  //     return URLConstants.segrateBaseUrl + 'risk/configuration';
  //   }

  //   public static get segRateUploadConfigurations(): string {
  //     return URLConstants.segrateBaseUrl + 'risk/upload';
  //   }

  //   public static get segRateUploadDatasetConfigurations(): string {
  //     return URLConstants.segrateBaseUrl + 'risk/configuration/dataset';
  //   }

  //   public static get segRateDeleteRiskMapping(): string {
  //     return URLConstants.segrateBaseUrl + 'risk/configuration/';
  //   }

  //   public static get segRateRiskGlobalScoreConfiguration(): string {
  //     return URLConstants.segrateBaseUrl + 'risk/configuration/globalscore/';
  //   }

  //   public static get segRateAllScenario(): string {
  //     return URLConstants.segrateBaseUrl + 'risk/configuration/scenario';
  //   }

  //   public static get segRateRiskConfigurationAudit(): string {
  //     return URLConstants.segrateBaseUrl + 'audit/risk/configuration';
  //   }

  //   public static get segRateRiskConfigurationAuditCount(): string {
  //     return URLConstants.segrateBaseUrl + 'audit/risk/configuration/count';
  //   }

  //   public static get segRateEntityScoringAudit(): string {
  //     return URLConstants.segrateBaseUrl + 'audit/risk/entity';
  //   }

  //   public static get segRateEntityScoringAuditCount(): string {
  //     return URLConstants.segrateBaseUrl + 'audit/risk/entity/count';
  //   }

  //   public static get segRateRuleListAudit(): string {
  //     return URLConstants.segrateBaseUrl + 'audit/risk/rule/hits';
  //   }

  //   public static get segRateGlobalScoreCategoryCreate(): string {
  //     return URLConstants.segrateBaseUrl + 'risk/configuration/globalscore/create/';
  //   }

  //   public static get segRateGlobalScoreCategoryUpdate(): string {
  //     return URLConstants.segrateBaseUrl + 'risk/configuration/globalscore/update';
  //   }
}
