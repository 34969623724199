/**
 * IndividualContent.tsx
 * content of individual card in search results
 */
/* packages */
import { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

/* contexts */
import { CountriesContext } from 'contextProviders/CountriesProvider';

/* hooks */

/* components */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import { StyledBadge, Flag, UnknownMessage } from './utils';

/* utilities */
import { GenerateTooltip, getCountryFromIso, maxTooltipElements, FullNameTooltip } from './IndividualContent';

/* types */
import { formatMatchingName, MatchingDataType } from 'models/matchingData';

const VesselContent = (props: { data: MatchingDataType }) => {
  const { countries } = useContext(CountriesContext);
  const intl = useIntl();

  const { data } = props;

  const fullName = data.names[0];
  const nbFullName = data.names.length - 1 ?? 0;

  let nameTooltip = null;
  if (nbFullName > 0 && data.names) {
    nameTooltip = GenerateTooltip(
      data.names.slice(1, maxTooltipElements + 1).map((name) => formatMatchingName(name)),
      nbFullName - maxTooltipElements
    );
  }

  // fullname tooltip
  let fullNameTooltip = null;
  if (data.names[0]) {
    const curName = data.names[0];
    if (curName.fullName) {
      fullNameTooltip = <FullNameTooltip content={[[intl.formatMessage({ id: 'fullName', defaultMessage: 'Full name' }), curName.fullName]]} />;
    } else {
      const content: [string, string][] = [];
      if (curName.givenName) {
        content.push([intl.formatMessage({ id: 'givenName', defaultMessage: 'Given name' }), curName.givenName]);
      }
      if (curName.fatherName) {
        content.push([intl.formatMessage({ id: 'fatherName', defaultMessage: 'Father name' }), curName.fatherName]);
      }
      if (curName.motherName) {
        content.push([intl.formatMessage({ id: 'motherName', defaultMessage: 'Mother name' }), curName.motherName]);
      }
      if (curName.surname) {
        content.push([intl.formatMessage({ id: 'surname', defaultMessage: 'Surname' }), curName.surname]);
      }

      if (content.length > 0) {
        fullNameTooltip = <FullNameTooltip content={content} />;
      }
    }
  }

  const flagsCountryCode = data.flags ? data.flags[0]?.country : null;
  const flagsCountry = getCountryFromIso(flagsCountryCode, countries);
  const nbFlags = (data.flags ?? [0]).length - 1;

  let flagTooltip = null;
  if (nbFlags > 0 && data.flags) {
    flagTooltip = GenerateTooltip(
      data.flags.slice(1, maxTooltipElements + 1).map((flag) => getCountryFromIso(flag.country, countries) ?? 'unkown'),
      nbFlags - maxTooltipElements
    );
  }

  const dateOfBuild = data.datesOfBuild ? data.datesOfBuild[0]?.date : null;
  const nbDatesOfBuild = (data.datesOfBuild ?? [0]).length - 1;

  let dateOfBuildTooltip = null;
  if (nbDatesOfBuild > 0 && data.datesOfBuild) {
    dateOfBuildTooltip = GenerateTooltip(
      data.datesOfBuild.slice(1, maxTooltipElements + 1).map((dob) => dob.date),
      nbDatesOfBuild - maxTooltipElements
    );
  }

  // const identityDocument = data.identityDocuments ? data.identityDocuments[0]?.number : null;
  // const nbIdentityDocument = (data.identityDocuments ?? [0]).length - 1;

  // let identityDocumentTooltip = null;
  // if (nbIdentityDocument > 0 && data.identityDocuments) {
  //   identityDocumentTooltip = GenerateTooltip(
  //     data.identityDocuments.slice(1, maxTooltipElements + 1).map((id) => id.number ?? 'unknown'),
  //     nbIdentityDocument - maxTooltipElements
  //   );
  // }

  return (
    <>
      <Box className="search-card-content">
        <Typography fontSize="inherit" className="search-card-content-title">
          <FormattedMessage id="fullName" defaultMessage={'Full name'} />:
        </Typography>
        <Box className="search-card-content-text">
          <StyledBadge badgeContent={nbFullName} tooltip={nameTooltip}>
            {fullName ? (
              <Tooltip
                placement="bottom"
                title={fullNameTooltip}
                slotProps={{ tooltip: { sx: { mt: '5px!important', px: 2, py: 2, backgroundColor: 'var(--color-darkgray)', fontSize: 'var(--fs-12)', fontWeight: 400 } } }}
              >
                <Box sx={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{formatMatchingName(fullName)}</Box>
              </Tooltip>
            ) : (
              <UnknownMessage />
            )}
          </StyledBadge>
        </Box>
      </Box>

      <Box className="search-card-content">
        <Typography fontSize="inherit" className="search-card-content-title">
          <FormattedMessage id="flag" defaultMessage={'Flags'} />:
        </Typography>
        <Box className="search-card-content-text">
          <Box className="search-card-content-text capitalize">
            <StyledBadge badgeContent={nbFlags} tooltip={flagTooltip}>
              {flagsCountry ? (
                <Box>
                  <Box component={'span'} display={'inline-flex'} sx={{ height: '.8rem', verticalAlign: 'middle', mr: '5px' }}>
                    <Flag country={flagsCountryCode ?? undefined} />
                  </Box>
                  <Box display="inline" sx={{ verticalAlign: 'middle' }}>
                    {flagsCountry.toLowerCase()}
                  </Box>
                </Box>
              ) : (
                <UnknownMessage />
              )}
            </StyledBadge>
          </Box>
        </Box>
      </Box>

      <Box className="search-card-content">
        <Typography fontSize="inherit" className="search-card-content-title">
          <FormattedMessage id="dateOfBuild" defaultMessage={'Date of build'} />:
        </Typography>
        <Box className="search-card-content-text">
          <StyledBadge badgeContent={nbDatesOfBuild} tooltip={dateOfBuildTooltip}>
            {dateOfBuild ? dateOfBuild : <UnknownMessage />}
          </StyledBadge>
        </Box>
      </Box>

      {/* <Box className="search-card-content">
        <Typography fontSize="inherit" className="search-card-content-title">
          <FormattedMessage id="idDocumentNumber" defaultMessage={'Identification number'} />:
        </Typography>
        <Box className="search-card-content-text">
          <StyledBadge badgeContent={nbIdentityDocument} tooltip={identityDocumentTooltip}>
            {identityDocument ? identityDocument : <UnknownMessage />}
          </StyledBadge>
        </Box>
      </Box> */}
    </>
  );
};

export default VesselContent;
