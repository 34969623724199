/**
 * TableHead.tsx
 * Display a header for table results
 */
/* packages */
// import {  } from 'react';

/* components */
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
// import TableSortLabel from '@mui/material/TableSortLabel';
import Checkbox from '@mui/material/Checkbox';

import { DoubleChevronIcon } from 'icons/doubleChevron/doubleChevron';
import { ChevronIcon } from 'icons/chevron/chevron';

/* types */
export type SortedDirectionType = false | 'desc' | 'asc';
export interface TableHeadElement {
  id: string;
  label: string;
  direction?: SortedDirectionType;
  active?: boolean;
  minWidth?: string;
  maxWidth?: string;
  width?: string;
  sorted?(): void;
  align?: 'left' | 'center' | 'right';
}

interface EnhancedTableProps {
  elements: TableHeadElement[];
  // onRequestSort: (event: React.MouseEvent<unknown>) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  rowCount: number;
  numSelected: number;
  hasSelection?: boolean;
  hasUnread?: boolean;
}

const EnhancedTableHead = (props: EnhancedTableProps) => {
  const { elements, onSelectAllClick, rowCount, hasSelection, hasUnread, numSelected } = props;

  const checked = rowCount > 0 && numSelected === rowCount;
  return (
    <TableHead>
      <TableRow>
        {hasUnread && <TableCell className="unread-cell">{''}</TableCell>}
        {hasSelection && (
          <TableCell padding="checkbox">
            <Checkbox
              name={'table-checkall'}
              color="primary"
              checked={checked}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all',
              }}
              sx={{ py: 1, '& .MuiSvgIcon-root': { fontSize: 20, color: checked ? 'unset' : 'var(--color-grayHeaderBorder)' } }}
            />
          </TableCell>
        )}
        {elements.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align ?? 'left'}
            sortDirection={headCell.direction ?? false}
            {...(headCell.sorted
              ? {
                  onClick: headCell.sorted,
                }
              : {})}
            sx={{
              cursor: headCell.sorted ? 'pointer' : 'initial',
              minWidth: headCell.minWidth ?? 0,
              maxWidth: headCell.maxWidth ?? 'unset',
              width: headCell.width ?? 'auto',
              textTransform: 'uppercase',
              whiteSpace: 'nowrap',
              fontWeight: 500,
              fontSize: 'var(--fs-12)',
            }}
          >
            {headCell.sorted &&
              (headCell.direction ? (
                <ChevronIcon sx={{ fontSize: 10, color: headCell.direction ? 'var(--color-azure)' : 'inherit', rotate: headCell.direction === 'asc' ? '-90deg' : '90deg' }} />
              ) : (
                <DoubleChevronIcon sx={{ fontSize: 10, color: headCell.direction ? 'var(--color-azure)' : 'inherit' }} />
              ))}{' '}
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
