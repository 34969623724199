import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const AvatarFemaleIcon = (props: SvgIconProps) => {
  const scale = 0.7;
  const base_shift = (512 - 448) / 2;

  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
        <circle cx="256" cy="256" r="256" transform="translate(0 0)" fill="currentColor" />
        <path
          transform={`translate(${((1 - scale) / 2) * 448 + base_shift} ${((1 - scale) / 2) * 512}), scale(${scale})`}
          d="M304 128c0-11.4-2.4-22.2-6.7-32H296c-20.5 0-38.7-9.6-50.4-24.5C231.9 95.7 205.8 112 176 112H145.6c-1 5.2-1.6 10.5-1.6 16v16c0 44.2 35.8 80 80 80s80-35.8 80-80V128zM96 128C96 57.3 153.3 0 224 0s128 57.3 128 128v11c0 33.9 13.5 66.5 37.5 90.5l3.9 3.9c4.2 4.2 6.6 10 6.6 16c0 12.5-10.1 22.6-22.6 22.6H224 70.6C58.1 272 48 261.9 48 249.4c0-6 2.4-11.8 6.6-16l3.9-3.9c24-24 37.5-56.6 37.5-90.5V128zm61.3 199.1l53.9 71.8c6.4 8.5 19.2 8.5 25.6 0l53.9-71.8c3.2-4.3 8.4-6.7 13.7-6.2C385.1 329.7 448 398.2 448 481.3c0 17-13.8 30.7-30.7 30.7H30.7C13.8 512 0 498.2 0 481.3C0 398.2 62.9 329.7 143.7 321c5.3-.6 10.5 1.9 13.7 6.2z"
          fill="var(--avatar-color, #fff)"
        />
      </svg>
    </SvgIcon>
  );
};
