/**
 * InvestigateSearch.tsx
 */
/* packages */
import { useCallback, useContext, useEffect, memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

/* hooks */
import { OrganizationsContext } from 'contextProviders/OrganizationsProvider';
import { RolesContext } from 'contextProviders/RolesProvider';
import { TeamsContext } from 'contextProviders/TeamsProvider';
import { UserContext } from 'contextProviders/UserProvider';
import { WorkflowContext } from 'contextProviders/WorkflowProvider';

/* components */
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import TitleLayout from 'components/Layouts/TitleLayout';
import { ProfileBox } from 'pages/Investigate/InvestigateDashboard';
import { ChevronIcon } from 'icons/chevron/chevron';
import Loader from 'components/Loader/Loader';
import ShadowedButton from 'components/ShadowedButton/ShadowedButton';

import { ValidatedIcon } from 'icons/validated/validated';
import ex_search from 'media/images/ex_search-min.png';

/* utilities */
import { checkPermissions } from 'utilities/CheckUserPermissions';
import { routerPages } from 'AppRouter';
import { CrossFilledIcon } from 'icons/crossFilled/crossFilled';
import { SchedulesContext } from 'contextProviders/SchedulesProvider';

/* elements */
const ManageDashboard = () => {
  const intl = useIntl();

  // return (
  //   <>
  //     <PageHeader>
  //       <PageTitle title={intl.formatMessage({ id: 'ManageDashboardTitle', defaultMessage: 'Manage Dashboard' })} />
  //     </PageHeader>
  //     <div> coming soon &trade;</div>
  //   </>
  // );
  return (
    <TitleLayout pageTitle={intl.formatMessage({ id: 'ManageDashboardTitle', defaultMessage: 'Manage Dashboard' })}>
      <ManageDashboardContent />
    </TitleLayout>
  );
};

const ManageDashboardContent = () => {
  const intl = useIntl();
  const { permissions } = useContext(UserContext);

  const canManageUsers = checkPermissions('manageUsers', permissions);
  const canManageDatasets = checkPermissions('manageDatasets', permissions);
  const canManageLibrairies = checkPermissions('manageLibrairies', permissions);
  const canManageAudits = checkPermissions('manageAudits', permissions);

  return (
    <Box>
      <ProfileBox />

      <GettingStarted />

      <Box mb={2} display="grid" gap={2} sx={{ gridAutoFlow: { xs: 'row', lg: 'column' } }}>
        <InfoBox
          title={intl.formatMessage({ id: 'users', defaultMessage: 'Users' })}
          message={intl.formatMessage({ id: 'manageDashboardUsersMessage', defaultMessage: 'Create and manage users' })}
          image={ex_search}
          buttons={[
            {
              id: 'manageUsers',
              authorized: canManageUsers,
              link: routerPages.manageUsers,
              buttonText: intl.formatMessage({ id: 'editUsers', defaultMessage: 'Edit users' }),
            },
          ]}
        />
        <InfoBox
          title={intl.formatMessage({ id: 'datasetsAndLibrairies', defaultMessage: 'Datasets and libraries' })}
          message={intl.formatMessage({ id: 'manageDashboardDatasetsLibrairiesMessage', defaultMessage: 'Create and manage your datasets and libraries' })}
          image={ex_search}
          textLeft
          buttons={[
            {
              id: 'manageDatasets',
              authorized: canManageDatasets,
              link: routerPages.manageDatasets,
              buttonText: intl.formatMessage({ id: 'editDatasets', defaultMessage: 'Edit datasets' }),
            },
            {
              id: 'manageLibrairies',
              authorized: canManageLibrairies,
              link: routerPages.manageLibrairies,
              buttonText: intl.formatMessage({ id: 'editLibrairies', defaultMessage: 'Edit librairies' }),
            },
          ]}
        />
      </Box>

      <ScreeningsBox />

      <Box mb={2} display="grid" gap={2} sx={{ gridAutoFlow: { xs: 'row', lg: 'column' } }}>
        <InfoBox
          title={intl.formatMessage({ id: 'notifications', defaultMessage: 'Notifications' })}
          message={intl.formatMessage({ id: 'manageDashboardNotificationsMessage', defaultMessage: 'Set up your notifications' })}
          image={ex_search}
          buttons={[
            {
              id: 'manageNotifications',
              authorized: false,
              buttonText: intl.formatMessage({ id: 'editNotifications', defaultMessage: 'Edit notifications' }),
              // link: routerPages.manageUsers,
            },
          ]}
        />
        <InfoBox
          title={intl.formatMessage({ id: 'audits', defaultMessage: 'Audits' })}
          message={intl.formatMessage({ id: 'manageDashboardAuditsMessage', defaultMessage: 'Explore your audits and logs' })}
          image={ex_search}
          textLeft
          buttons={[
            {
              id: 'manageAudits',
              authorized: canManageAudits,
              buttonText: intl.formatMessage({ id: 'exploreAudits', defaultMessage: 'Explore audits' }),
              link: routerPages.manageAudits,
            },
          ]}
        />
      </Box>
    </Box>
  );
};

const GettingStarted = () => {
  const { permissions } = useContext(UserContext);
  const intl = useIntl();
  const { loadingOrganizations, listOrganizations, organizations } = useContext(OrganizationsContext);
  const { loadingRoles, listRoles, roles } = useContext(RolesContext);
  const { loadingTeams, listTeams, teams } = useContext(TeamsContext);

  const load = useCallback(() => {
    listOrganizations?.();
    listRoles?.();
    listTeams?.();
  }, [listOrganizations, listRoles, listTeams]);

  useEffect(() => {
    load();
  }, [load]);

  const canManageOrganizations = checkPermissions('manageSetupOrganizations', permissions);
  const canManageTeams = checkPermissions('manageSetupTeams', permissions);
  const canManageRoles = checkPermissions('manageSetupRoles', permissions);

  return (
    <Box mb={2}>
      <Accordion
        elevation={0}
        defaultExpanded
        sx={{
          '& .MuiAccordionSummary-root': { px: 5 },
          borderRadius: '5px',
          border: '1px solid var(--color-grayHeaderBorder)',
          backgroundColor: 'var(--color-lightgray6)',
        }}
      >
        <AccordionSummary
          expandIcon={<ChevronIcon sx={{ fontSize: 'var(--fs-12)', color: 'var(--color-gray2)' }} />}
          sx={{
            px: 2,
            py: 3,
            minHeight: 0,
            '&.Mui-expanded': {
              minHeight: 0,
            },
            '& .MuiAccordionSummary-content': {
              m: 0,
              '&.Mui-expanded': {
                m: 0,
              },
            },
            '& .MuiAccordionSummary-expandIconWrapper': { rotate: '180deg' },
            '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': { rotate: '90deg' },
          }}
        >
          <Box pr={2}>
            <Typography fontWeight={600} fontSize="var(--fs-20)" sx={{ color: 'var(--color-darkgray)' }}>
              <FormattedMessage id={'getStarted'} defaultMessage={'Get Started'} />
            </Typography>
            <Typography fontSize="var(--fs-14)" sx={{ color: 'var(--color-gray2)' }}>
              <FormattedMessage id={'getStartedText'} defaultMessage={'Configure organizations, teams and roles to ease your screening work processes.'} />
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 5, py: 3, borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
          <Box display="grid" columnGap={2} rowGap={3} sx={{ maxWidth: '1200px', mx: 'auto', gridAutoFlow: { xs: 'row', lg: 'column' } }}>
            <GettingStartedBox
              authorized={canManageOrganizations}
              loading={loadingOrganizations}
              valid={organizations !== undefined && organizations.length > 0}
              message={intl.formatMessage({ id: 'manageDashboardOrganizationsMessage', defaultMessage: 'Create and manage organizations' })}
              link={routerPages.manageSetupOrganizations}
              linkButtonText={intl.formatMessage({ id: 'editOrganizations', defaultMessage: 'Edit organizations' })}
              image={ex_search}
            />
            <GettingStartedBox
              authorized={canManageTeams}
              loading={loadingTeams}
              valid={teams !== undefined && teams.length > 0}
              message={intl.formatMessage({ id: 'manageDashboardTeamsMessage', defaultMessage: 'Create and manage teams' })}
              link={routerPages.manageSetupTeams}
              linkButtonText={intl.formatMessage({ id: 'editTeams', defaultMessage: 'Edit teams' })}
              image={ex_search}
            />
            <GettingStartedBox
              authorized={canManageRoles}
              loading={loadingRoles}
              valid={roles !== undefined && roles.length > 0}
              message={intl.formatMessage({ id: 'manageDashboardRolesMessage', defaultMessage: 'Create and manage roles' })}
              link={routerPages.manageSetupRoles}
              linkButtonText={intl.formatMessage({ id: 'editRoles', defaultMessage: 'Edit roles' })}
              image={ex_search}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

interface LinkBoxProps {
  authorized: boolean;
  loading: boolean;
  valid: boolean;
  message: string;
  link: string;
  linkButtonText: string;
}
interface GettingStartedBoxProps extends LinkBoxProps {
  image: string;
}
const GettingStartedBox = memo(({ authorized, loading, valid, message, link, linkButtonText, image }: GettingStartedBoxProps) => {
  return (
    <Box sx={{ width: '100%', minWidth: 0, maxWidth: '250px', marginInline: 'auto' }}>
      {/* <Box display="flex" gap={0.5} justifyContent={'center'} sx={{ marginInline: 'auto', fontSize: 'var(--fs-14)', color: 'var(--color-gray2)' }}> */}
      <Box sx={{ textAlign: 'center', marginInline: 'auto', fontSize: 'var(--fs-14)', color: 'var(--color-gray2)' }}>
        {loading ? (
          <Loader containerStyle={{ verticalAlign: 'middle', display: 'inline-flex', marginRight: '5px' }} cssStyle={{ width: '14px', borderWidth: '3px' }} />
        ) : (
          <Typography component={'span'} sx={{ verticalAlign: 'middle' }}>
            {authorized ? (
              <ValidatedIcon sx={{ fontSize: 'var(--fs-14)', color: valid ? 'var(--color-azure)' : 'var(--color-lightgray5)' }} />
            ) : (
              <CrossFilledIcon sx={{ fontSize: 'var(--fs-14)', color: 'var(--color-fushia)' }} />
            )}{' '}
          </Typography>
        )}
        <Typography component="span" sx={{ fontSize: 'inherit', color: 'inherit' }}>
          {message}
        </Typography>
      </Box>

      <Box mt={2} display="flex" justifyContent={'center'}>
        <Link to={link} style={{ pointerEvents: authorized ? 'all' : 'none' }}>
          <ShadowedButton disabled={!authorized}>{linkButtonText}</ShadowedButton>
        </Link>
      </Box>
      <Box>
        <img className="cover top" src={image} alt="" style={{}} />
      </Box>
    </Box>
  );
});

interface InfoBoxProps {
  title: string;
  message: string;
  image: string;
  buttons: {
    id: string;
    authorized: boolean;
    buttonText: string;
    link?: string;
  }[];
  textLeft?: boolean;
}
const InfoBox = memo(({ title, image, message, buttons, textLeft }: InfoBoxProps) => {
  return (
    <Box className="bordered rounded-border">
      <Box px={3} py={2} sx={{ borderBottom: '1px solid var(--color-grayHeaderBorder)' }}>
        <Typography fontWeight={600} sx={{ fontSize: 'var(--fs-20)' }}>
          {title}
        </Typography>
      </Box>
      <Box px={2} pt={1} display="flex" gap={3} justifyContent={'center'} alignItems={'center'}>
        <img className="cover top" src={image} alt="" style={{ maxWidth: 300, maxHeight: 400, order: textLeft ? 3 : 1 }} />

        <Box order={2} height={'100%'} display={'flex'} justifyContent={'center'} flexDirection={'column'}>
          <Typography textAlign="center" sx={{ color: 'var(--color-gray2)', fontSize: 'var(--fs-14)', maxWidth: 200 }}>
            {message}
          </Typography>
          <Box mt={2} display="flex" flexDirection={'column'} alignItems={'center'} gap={2}>
            {buttons.map((button) => {
              if (button.link) {
                return (
                  <Link key={button.id} to={button.link} style={{ pointerEvents: button.authorized ? 'all' : 'none' }}>
                    <ShadowedButton disabled={!button.authorized}>{button.buttonText}</ShadowedButton>
                  </Link>
                );
              } else {
                return (
                  <ShadowedButton key={button.id} disabled={!button.authorized}>
                    {button.buttonText}
                  </ShadowedButton>
                );
              }
            })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

const ScreeningsBox = memo(() => {
  const { permissions } = useContext(UserContext);
  const intl = useIntl();
  const { loadingStatus, listStatus, workflowStatus } = useContext(WorkflowContext);
  const { loadingSchedule, listSchedules, schedules } = useContext(SchedulesContext);

  const canManageScreeningsWorkflow = checkPermissions('manageScreeningsWorkflow', permissions);
  const canManageScreeningsRisk = checkPermissions('manageScreeningsRisk', permissions);
  const canManageScreeningsSchedule = checkPermissions('manageScreeningsSchedule', permissions);

  const load = useCallback(() => {
    if (canManageScreeningsWorkflow) listStatus?.();
    if (canManageScreeningsSchedule) listSchedules?.();
  }, [listStatus, listSchedules, canManageScreeningsWorkflow, canManageScreeningsSchedule]);

  useEffect(() => {
    load();
  }, [load]);

  return (
    <Box mb={2}>
      <Accordion
        elevation={0}
        defaultExpanded
        sx={{
          '& .MuiAccordionSummary-root': { px: 5 },
          borderRadius: '5px',
          border: '1px solid var(--color-grayHeaderBorder)',
        }}
      >
        <AccordionSummary
          expandIcon={<ChevronIcon sx={{ fontSize: 'var(--fs-12)', color: 'var(--color-gray2)' }} />}
          sx={{
            px: 2,
            py: 3,
            minHeight: 0,
            '&.Mui-expanded': {
              minHeight: 0,
            },
            '& .MuiAccordionSummary-content': {
              m: 0,
              '&.Mui-expanded': {
                m: 0,
              },
            },
            '& .MuiAccordionSummary-expandIconWrapper': { rotate: '180deg' },
            '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': { rotate: '90deg' },
          }}
        >
          <Box pr={2}>
            <Typography fontWeight={600} fontSize="var(--fs-20)" sx={{ color: 'var(--color-darkgray)' }}>
              <FormattedMessage id={'screenings'} defaultMessage={'Screenings'} />
            </Typography>
            <Typography fontSize="var(--fs-14)" sx={{ color: 'var(--color-gray2)' }}>
              <FormattedMessage id={'manageDashboardScreeningsText'} defaultMessage={'Follow the steps to set up and schedule your screening work processes.'} />
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 0, py: 0, borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
          <ScreeningsBoxRow
            authorized={canManageScreeningsWorkflow}
            loading={loadingStatus}
            valid={workflowStatus !== undefined && workflowStatus.length > 0}
            message={intl.formatMessage({ id: 'manageDashboardWorkflowMessage', defaultMessage: 'Set up the statuses of your alert workflows' })}
            link={routerPages.manageScreeningsWorkflow}
            linkButtonText={intl.formatMessage({ id: 'editWorkflows', defaultMessage: 'Edit workflows' })}
          />
          <ScreeningsBoxRow
            authorized={canManageScreeningsRisk}
            loading={true}
            valid={false}
            message={intl.formatMessage({ id: 'manageDashboardRiskScreeningsMessage', defaultMessage: 'Set up your screening processes' })}
            link={routerPages.manageScreeningsRisk}
            linkButtonText={intl.formatMessage({ id: 'editScreenings', defaultMessage: 'Edit screenings' })}
          />
          <ScreeningsBoxRow
            authorized={canManageScreeningsSchedule}
            loading={loadingSchedule}
            valid={schedules !== undefined && schedules.length > 0}
            message={intl.formatMessage({ id: 'manageDashboardScheduleMessage', defaultMessage: 'Schedule your screening processes' })}
            link={routerPages.manageScreeningsSchedule}
            linkButtonText={intl.formatMessage({ id: 'scheduleScreenings', defaultMessage: 'Schedule screenings' })}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
});

const ScreeningsBoxRow = memo(({ authorized, loading, valid, message, link, linkButtonText }: LinkBoxProps) => {
  return (
    <Box px={5} py={3} display="flex" alignItems={'center'} gap={2} sx={{ width: '100%', minWidth: 0, '&:not(:last-of-type)': { borderBottom: '1px solid var(--color-grayHeaderBorder)' } }}>
      {/* <Box display="flex" gap={0.5} justifyContent={'center'} sx={{ marginInline: 'auto', fontSize: 'var(--fs-14)', color: 'var(--color-gray2)' }}> */}
      <Box pl={3} flex={1} sx={{ minWidth: 0, marginInline: 'auto', fontSize: 'var(--fs-14)', color: 'var(--color-gray2)' }}>
        {loading ? (
          <Loader containerStyle={{ verticalAlign: 'middle', display: 'inline-flex', marginRight: '5px' }} cssStyle={{ width: '14px', borderWidth: '3px' }} />
        ) : (
          <Typography component={'span'} sx={{ verticalAlign: 'middle' }}>
            {authorized ? (
              <ValidatedIcon sx={{ fontSize: 'var(--fs-14)', color: valid ? 'var(--color-azure)' : 'var(--color-lightgray5)' }} />
            ) : (
              <CrossFilledIcon sx={{ fontSize: 'var(--fs-14)', color: 'var(--color-fushia)' }} />
            )}{' '}
          </Typography>
        )}
        <Typography component="span" sx={{ fontSize: 'inherit', color: 'inherit' }}>
          {message}
        </Typography>
      </Box>

      <Box display="flex" justifyContent={'center'}>
        <Link to={link} style={{ pointerEvents: authorized ? 'all' : 'none' }}>
          <ShadowedButton disabled={!authorized}>{linkButtonText}</ShadowedButton>
        </Link>
      </Box>
    </Box>
  );
});
export default ManageDashboard;
