/**
 * ListWorkflowStatus.tsx
 */
/* packages */
import { useContext, useState, useMemo, memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { styled } from '@mui/material/styles';

/* context */
import { AllUsersContext } from 'contextProviders/AllUsersProvider';

/* hooks */
/* components */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';

import Loader from 'components/Loader/Loader';
import { SortedDirectionType, TableHeadElement } from 'components/TableResults/TableHead';

import { DoubleChevronIcon } from 'icons/doubleChevron/doubleChevron';
import { ChevronIcon } from 'icons/chevron/chevron';
import { GarbageIcon } from 'icons/garbage/garbage';

/* utilities */
import { displayWarning } from 'utilities/Logger';
import { GetUser } from 'components/GetUser/GetUser';

/* types */
import { WorfklowAction, WorkflowStatusType } from 'models/workflow';
import { ValidatedIcon } from 'icons/validated/validated';

interface ListWorkflowStatusProps {
  searching?: boolean;
  filtered?: boolean;
  listOfWorkflowStatus?: WorkflowStatusType[];
  handleCreateWorkflowStatus(workflowStatus?: WorkflowStatusType): void;
  handleDeleteWorkflowStatus(workflowStatus: WorkflowStatusType): void;
  workflowActions?: WorfklowAction[];
}

interface DisplayWorkflowStatusProps {
  workflowStatus: WorkflowStatusType;
  handleCreateWorkflowStatus: ListWorkflowStatusProps['handleCreateWorkflowStatus'];
  handleDeleteWorkflowStatus: ListWorkflowStatusProps['handleDeleteWorkflowStatus'];
}

/* elements */
// const DEFAULTCOLUMNWIDTH = '180px';
const COLUMNTEMPLATE = 'auto minmax(200px, 1fr) minmax(300px, 1fr) auto repeat(2, minmax(180px, 1fr)) auto';

const getSortedWorkflowStatus = (listOfStatus: WorkflowStatusType[] | undefined, sortedColumn: string, sortedDirection: SortedDirectionType) => {
  if (!listOfStatus) return listOfStatus;

  if (!sortedDirection || !sortedDirection) {
    return listOfStatus;
  }

  const direction = sortedDirection === 'asc' ? 1 : -1;

  switch (sortedColumn) {
    case 'id':
      return listOfStatus.sort((s1, s2) => direction * ((s1.id ?? 0) - (s2.id ?? 0)));
    case 'description':
    case 'code':
      const key = sortedColumn;
      return listOfStatus.sort((s1, s2) => direction * (s1[key] ?? '').localeCompare(s2[key] ?? ''));
    case 'closeStatus':
      return listOfStatus.sort((s1, s2) => direction * (Number(s1.closeStatus ?? false) - Number(s2.closeStatus ?? false)));
    case 'updatedOn':
      return listOfStatus.sort((s1, s2) => direction * (s1.updatedDate ?? '').localeCompare(s2.updatedDate ?? ''));
    default:
      displayWarning(`unknown sorting column: ${sortedColumn}`);
      return listOfStatus;
  }
};

const ListWorkflowStatus = memo(({ searching, filtered, listOfWorkflowStatus, handleCreateWorkflowStatus, handleDeleteWorkflowStatus, workflowActions }: ListWorkflowStatusProps) => {
  const intl = useIntl();

  // const [sortedColumn, setSortedColumn] = useState<string>('');
  // const [sortedDirection, setSortedDirection] = useState<SortedDirectionType>(false);
  const [sortedColumn, setSortedColumn] = useState<string>('id');
  const [sortedDirection, setSortedDirection] = useState<SortedDirectionType>('asc');

  const tableHeaders = useMemo((): TableHeadElement[] => {
    return [
      {
        id: 'id',
        label: intl.formatMessage({
          id: 'id',
          defaultMessage: 'id',
        }),
        sorted: () => {
          const newDirection = sortedColumn !== 'id' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc';
          setSortedColumn('id');
          setSortedDirection(newDirection);
        },
        direction: sortedColumn === 'id' ? sortedDirection : false,
      },
      {
        id: 'code',
        label: intl.formatMessage({
          id: 'name',
          defaultMessage: 'name',
        }),
        sorted: () => {
          const newDirection = sortedColumn !== 'code' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc';
          setSortedColumn('code');
          setSortedDirection(newDirection);
        },
        direction: sortedColumn === 'code' ? sortedDirection : false,
      },
      {
        id: 'description',
        label: intl.formatMessage({
          id: 'description',
          defaultMessage: 'description',
        }),
        sorted: () => {
          const newDirection = sortedColumn !== 'description' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc';
          setSortedColumn('description');
          setSortedDirection(newDirection);
        },
        direction: sortedColumn === 'description' ? sortedDirection : false,
      },
      {
        id: 'closeStatus',
        label: intl.formatMessage({
          id: 'closeStatus',
          defaultMessage: 'Close',
        }),
        sorted: () => {
          const newDirection = sortedColumn !== 'closeStatus' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc';
          setSortedColumn('closeStatus');
          setSortedDirection(newDirection);
        },
        direction: sortedColumn === 'closeStatus' ? sortedDirection : false,
      },
      {
        id: 'updatedBy',
        label: intl.formatMessage({
          id: 'updatedBy',
          defaultMessage: 'Updated by',
        }),
      },
      {
        id: 'updatedOn',
        label: intl.formatMessage({
          id: 'updatedOn',
          defaultMessage: 'Updated on',
        }),
        sorted: () => {
          const newDirection = sortedColumn !== 'updatedOn' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc';
          setSortedColumn('updatedOn');
          setSortedDirection(newDirection);
        },
        direction: sortedColumn === 'updatedOn' ? sortedDirection : false,
      },
      {
        id: 'edit',
        label: '',
      },
    ];
  }, [intl, sortedColumn, sortedDirection]);

  if (searching || !listOfWorkflowStatus) {
    return (
      <Box pt={'30vh'}>
        <Loader />
      </Box>
    );
  }
  return (
    <Box mt={2}>
      {!listOfWorkflowStatus || listOfWorkflowStatus.length <= 0 ? (
        <Box p={1} mb={2}>
          <Typography fontWeight={500} sx={{ color: 'var(--color-gray1)' }}>
            {filtered ? (
              <FormattedMessage id="noMatchedSchedules" defaultMessage={'No schedule matches your search'} />
            ) : (
              <FormattedMessage id="noSchedule" defaultMessage={'No schedule has been defined'} />
            )}
          </Typography>
        </Box>
      ) : (
        <Box className="custom-scrollbar-horizontal table-results no-border " display="grid" gridTemplateColumns={COLUMNTEMPLATE} sx={{ overflowX: 'auto' }}>
          {tableHeaders.map((th, thIndex) => {
            return (
              <Box
                key={th.id}
                className={`header-element with-border noBottomRadius ${thIndex === 0 ? 'first' : ''} ${thIndex === tableHeaders.length - 1 ? 'last' : ''}`}
                {...(th.sorted
                  ? {
                      onClick: th.sorted,
                    }
                  : {})}
              >
                {th.sorted &&
                  (th.direction ? (
                    <ChevronIcon sx={{ fontSize: 10, color: th.direction ? 'var(--color-azure)' : 'inherit', rotate: th.direction === 'asc' ? '-90deg' : '90deg' }} />
                  ) : (
                    <DoubleChevronIcon sx={{ fontSize: 10, color: th.direction ? 'var(--color-azure)' : 'inherit' }} />
                  ))}{' '}
                {th.label.toUpperCase()}
              </Box>
            );
          })}

          {getSortedWorkflowStatus(listOfWorkflowStatus, sortedColumn, sortedDirection)?.map((workflowStatus) => {
            return <DisplayWorkflowStatus key={workflowStatus.id} {...{ workflowStatus, sortedColumn, sortedDirection, handleCreateWorkflowStatus, handleDeleteWorkflowStatus }} />;
          })}
        </Box>
      )}
    </Box>
  );
});

const StyledWorkflowStatusElement = styled(Box)(({ theme }) => ({
  color: 'var(--color-darkgray)',
  fontSize: 'var(--fs-14)',
  padding: theme.spacing(2),
  wordBreak: 'break-word',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // alignSelf: 'center',
}));
const DisplayWorkflowStatus = memo(({ workflowStatus, handleCreateWorkflowStatus, handleDeleteWorkflowStatus }: DisplayWorkflowStatusProps) => {
  // const borderKey = topBorderOnly ? 'borderTop' : 'border';
  const { allUsers } = useContext(AllUsersContext);

  return (
    <Box
      display={'contents'}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
        handleCreateWorkflowStatus(workflowStatus);
      }}
      sx={{
        cursor: 'pointer',
        '& > div': {
          borderBottom: '1px solid var(--color-grayHeaderBorder)',
          '&:first-of-type': {
            borderLeft: '1px solid var(--color-grayHeaderBorder)',
          },
          '&:last-of-type': {
            borderRight: '1px solid var(--color-grayHeaderBorder)',
          },
        },
        '&:hover > div': { backgroundColor: 'var(--color-lightgray2)' },
        position: 'relative',
      }}
    >
      {/* sx={{ borderRadius: '5px', [borderKey]: '1px solid var(--color-grayHeaderBorder)' }} */}
      {/* <Box display={'grid'} gridTemplateColumns={COLUMNTEMPLATE} alignItems={'center'} sx={{ borderInline: '1px solid var(--color-grayHeaderBorder)' }}> */}
      <StyledWorkflowStatusElement sx={{ textAlign: 'center' }}>
        <span>{workflowStatus.id}</span>
      </StyledWorkflowStatusElement>
      <StyledWorkflowStatusElement>
        <span>{workflowStatus.code}</span>
      </StyledWorkflowStatusElement>
      <StyledWorkflowStatusElement>
        <span>{workflowStatus.description}</span>
      </StyledWorkflowStatusElement>
      <StyledWorkflowStatusElement sx={{ alignItems: 'center' }}>
        {workflowStatus.closeStatus && <ValidatedIcon sx={{ color: 'var(--color-lightgray5)', fontSize: 'var(--fs-14)' }} />}
      </StyledWorkflowStatusElement>

      <StyledWorkflowStatusElement>{GetUser(workflowStatus.updatedBy ?? '', allUsers, true, '20px')}</StyledWorkflowStatusElement>
      <StyledWorkflowStatusElement>{workflowStatus.updatedDate ?? ''}</StyledWorkflowStatusElement>

      <StyledWorkflowStatusElement sx={{ alignItems: 'center' }}>
        <Box display="flex" columnGap={'10px'} sx={{ justifyContent: 'flex-end', alignItems: 'center' }}>
          <IconButton
            sx={{ fontSize: 'var(--fs-14)', color: 'var(--color-lightgray4)', '&:hover': { color: 'var(--color-fushia)' } }}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              handleDeleteWorkflowStatus(workflowStatus);
            }}
          >
            <GarbageIcon fontSize="inherit" sx={{ color: 'inherit' }} />
          </IconButton>
        </Box>
      </StyledWorkflowStatusElement>
      {/* </Box> */}
    </Box>
  );
});

export default ListWorkflowStatus;
