/**
 * WorkflowProvider.ts
 * Provide data for Worflow
 */
/* packages */
import { createContext, useState, useMemo, useCallback, useRef, useContext, useEffect } from 'react';

/* context */
import { useAuthenticatedRequest } from './AuthProvider';
import { UserContext } from './UserProvider';

/* utilities */
import { URLConstants } from 'common/URLconstants';
import { checkPermissions } from 'utilities/CheckUserPermissions';

/* types */
import { Notification, ListNotificationsResponse, ListNotificationsQuery } from 'models/notifications';

interface NotificationsContextType {
  // statusQueried: boolean;
  loadingNotifications: boolean;
  notifications?: Notification[];
  listNotifications?(pageNumber: number, abortController?: AbortController): void;
}
/* elements */
const NotificationsContext = createContext<NotificationsContextType>({
  // statusQueried: false,
  loadingNotifications: false,
  notifications: undefined,
  listNotifications: undefined,
});

const NotificationsProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { postAuthenticatedRequest } = useAuthenticatedRequest();
  const { currentUserId, permissions } = useContext(UserContext);

  const listNotificationsOngoing = useRef(false);

  // const [statusQueried, setStatusQueried] = useState<boolean>(false);
  const [loadingNotifications, setLoadingNotifications] = useState<boolean>(true);
  const [notifications, setNotifications] = useState<Notification[] | undefined>(undefined);

  const listNotifications = useCallback(
    async (pageNumber: number = 0, abortController?: AbortController) => {
      if (listNotificationsOngoing.current) return;
      if (!currentUserId) return;

      // check permissions
      const allowed = checkPermissions('listNotifications', permissions);
      if (!allowed) return;

      listNotificationsOngoing.current = true;
      setLoadingNotifications(true);
      setNotifications(undefined);
      try {
        const listNotificationsUrl = URLConstants.getNotifications; // + `/${currentUser.id}`;
        const notificationsPayload: ListNotificationsQuery = {
          pageNumber,
          maxPerPage: 10,
        };
        const results = (await postAuthenticatedRequest(listNotificationsUrl, notificationsPayload, abortController)) as ListNotificationsResponse;
        console.log('Notifications results', results);
        // const results: ListNotificationsResponse = {
        //   notifications: [] as Notification[],
        // };

        const notificationsResults = results.notifications ?? [];

        // setStatusQueried(true);
        setLoadingNotifications(false);
        setNotifications(notificationsResults);
      } catch (searchError: any) {
        if (searchError?.code === 'ERR_CANCELED') return;

        // setStatusQueried(true);
        setLoadingNotifications(false);
        setNotifications(undefined);
      }
      listNotificationsOngoing.current = false;
    },

    [permissions, postAuthenticatedRequest, currentUserId]
  );

  // trigger list on load
  useEffect(() => {
    if (!currentUserId) return;

    listNotifications();
  }, [currentUserId, listNotifications]);

  const outputValue = useMemo(
    (): NotificationsContextType => ({
      // statusQueried,
      loadingNotifications,
      notifications,
      listNotifications,
    }),
    [loadingNotifications, notifications, listNotifications]
  );
  return <NotificationsContext.Provider value={outputValue}>{children}</NotificationsContext.Provider>;
};

/* exports */
export { NotificationsContext, NotificationsProvider };
