/**
 * SearchText.tsx
 */
/* packages */
import { ReactNode, forwardRef } from 'react';

/* components */
import Select, { SelectProps } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import { ChevronIcon } from 'icons/chevron/chevron';
/* elements */
const SearchSelect = forwardRef<HTMLSelectElement, SelectProps & { choices: { key: string; value: ReactNode }[]; placeholder?: string }>((props, ref) => {
  const { choices, placeholder, ...otherProps } = props;

  return (
    <Select
      {...otherProps}
      size="small"
      sx={{
        lineHeight: 1.5,
        backgroundColor: 'white',
        color: !otherProps.value ? '#7B7E84' : 'inherit',
        // fontSize: otherProps?.value === '' ? 'var(--fs-12)' : 'inherit',
        fontSize: 'inherit',
        ...otherProps.sx,
        '& .MuiSelect-select.MuiSelect-outlined': {
          display: 'flex',
          paddingBlock: '10px',
          // color: '#151414',
          color: otherProps?.value === '' ? 'var(--color-gray1)' : 'var(--color-gray2)',
        },
      }}
      displayEmpty={true}
      IconComponent={(_props) => {
        const rotate = _props.className.toString().includes('iconOpen');
        return <ChevronIcon sx={{ fontSize: '10px', rotate: rotate ? '90deg' : '-90deg', position: 'absolute', right: '7px', top: 'calc(50% - 5px)', pointerEvents: 'none' }} />;
      }}
    >
      {placeholder && (
        <MenuItem value={''} disabled sx={{ fontSize: 'var(--fs-14)' }}>
          {placeholder}
        </MenuItem>
      )}
      {choices.map((c) => (
        <MenuItem key={c.key} value={c.key} sx={{ fontSize: 'var(--fs-14)' }}>
          {c.value}
        </MenuItem>
      ))}
    </Select>
  );
});

export default SearchSelect;
