/**
 * SerachFilter.tsx
 */
/* packages */
import React, { useCallback, useState, useRef, useEffect, MutableRefObject } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import dayjs, { Dayjs } from 'dayjs';

/* contexts */

/* hooks */

/* components */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';

import ShadowedButton from 'components/ShadowedButton/ShadowedButton';
import { PopoverActions } from '@mui/material/Popover';

/* utilities */
// import { searchConstant } from 'models/searchDatasets';

/* types */
import { PickerSelectionState } from '@mui/x-date-pickers/internals';
import { FilterValueType } from './SearchFilters';

interface DateRangeFilterProps {
  title: string;
  currentValue?: FilterValueType;
  setNewFilterValue?(newValue: FilterValueType): void;
  popoverRef?: MutableRefObject<PopoverActions>;
  algoFields?: string[];
  algoValue?: string[];
  minDate?: Dayjs;
  maxDate?: Dayjs;
  onClearValue?: [Dayjs, Dayjs];
}

interface AlgoSelectionType {
  [key: string]: { checked: boolean; value: string };
}

interface ResetAlgoInput {
  currentAlgo?: { [key: string]: string };
  algoFields: string[];
  algoValue: string[];
}

/* elements */
const resetAlgo = ({ currentAlgo, algoFields, algoValue }: ResetAlgoInput) => {
  const initAlgo = algoFields.reduce((acc, cf) => ({ ...acc, [cf]: { checked: false, value: algoValue[0] } }), {} as AlgoSelectionType);

  if (currentAlgo) {
    Object.entries(currentAlgo).forEach(([algo, state]) => {
      if (Object.keys(initAlgo).includes(algo)) {
        initAlgo[algo].checked = true;
        initAlgo[algo].value = state;
      }
    });
  }
  return initAlgo;
};
const parseAlgoSelected = (algoSelected: AlgoSelectionType) => {
  return Object.entries(algoSelected)
    .filter(([_, state]) => state.checked)
    .reduce((acc, [algo, state]) => ({ ...acc, [algo]: state.value }), {});
};

const today = dayjs();
// const minDate = dayjs().subtract(20, 'year');
const defaultMinDate = dayjs('2000-01-01');
const defaultMaxDate = today;

const DateRangeFilter = ({ title, currentValue, setNewFilterValue, popoverRef, algoFields, algoValue, minDate, maxDate, onClearValue }: DateRangeFilterProps) => {
  const intl = useIntl();
  const containerRef = useRef<HTMLDivElement>(null);

  const [selectedValue, setSelectedValue] = React.useState<(Dayjs | null)[]>([currentValue?.dayValue ?? null, currentValue?.dayEndValue || null]);

  const [algoSelected, setAlgoSelected] = useState<AlgoSelectionType>(() => {
    return resetAlgo({ currentAlgo: currentValue?.algo, algoFields: algoFields ?? [], algoValue: algoValue ?? [] });
  });

  useEffect(() => {
    if (!containerRef.current) return;

    const box = containerRef.current;

    const updateOnResize = () => {
      popoverRef?.current?.updatePosition();
    };
    const resizeObserver = new ResizeObserver(updateOnResize);

    resizeObserver.observe(box);

    return () => {
      resizeObserver.unobserve(box);
    };
  }, [containerRef, popoverRef]);

  const applyFilterValue = useCallback(
    (newValue: typeof selectedValue, selectedAlgo: FilterValueType['algo']) => {
      if (setNewFilterValue) setNewFilterValue({ nbValues: newValue[0] != null && newValue[1] !== null ? 1 : 0, dayValue: newValue[0], dayEndValue: newValue[1], algo: selectedAlgo });
    },
    [setNewFilterValue]
  );

  const clearFilter = () => {
    const newValue: typeof selectedValue = onClearValue ?? [null, null];
    setSelectedValue(newValue);

    const newAlgos = resetAlgo({
      currentAlgo: undefined,
      algoFields: algoFields ?? [],
      algoValue: algoValue ?? [],
    });

    setAlgoSelected(newAlgos);

    applyFilterValue(newValue, parseAlgoSelected(newAlgos));
  };

  const selectAlgoField = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const checkboxName = event.target.name;
    const checked = event.target.checked;

    setAlgoSelected((currentFields) => ({ ...currentFields, [checkboxName]: { ...currentFields[checkboxName], checked: checked } }));
  }, []);

  const setAlgoForCountry = useCallback((event: SelectChangeEvent) => {
    const selectName = event.target.name;
    const selectValue = event.target.value;
    setAlgoSelected((currentFields) => ({ ...currentFields, [selectName]: { ...currentFields[selectName], value: selectValue } }));
  }, []);

  // propagate on value change
  const validateSelection = () => {
    const selectedAlgo = parseAlgoSelected(algoSelected);
    applyFilterValue(selectedValue, selectedAlgo);
    // if (setNewFilterValue)
    //   setNewFilterValue({ nbValues: selectedValue[0] != null && selectedValue[1] !== null ? 1 : 0, dayValue: selectedValue[0], dayEndValue: selectedValue[1], algo: selectedAlgo });
  };

  const hasAlgoSelected: boolean = !algoFields || Object.values(algoSelected).findIndex((v) => v.checked) >= 0;
  // const hasValueSelected: boolean = selectedValues.length > 0;

  let textValue = intl.formatMessage({ id: 'chooseADateRange', defaultMessage: 'Choose a date range' });
  if (selectedValue[0]) {
    textValue = selectedValue[0].format('DD / MM / YYYY');

    if (selectedValue[1]) {
      textValue += ' - ' + selectedValue[1].format('DD / MM / YYYY');
    }
  }

  const onChangeCalendar = (value: Dayjs | null, selectionState?: PickerSelectionState) => {
    if (selectionState !== 'finish') return;
    setSelectedValue((currentValues) => {
      if (currentValues[0] === null) {
        return [value, null];
      } else {
        if (currentValues[1] === null) {
          const prev = currentValues[0];
          if (prev.isBefore(value)) {
            return [prev, value];
          } else return [value, prev];
        } else {
          return [value, null];
        }
      }
    });
  };

  return (
    <Box
      ref={containerRef}
      className="custom-scrollbar"
      borderRadius={'5px'}
      sx={{ border: '1px solid var(--color-grayHeaderBorder)', background: 'white', mt: 0.5, maxHeight: '60vh', overflowY: 'auto', width: 320, maxWidth: 320 }}
    >
      {/* filter title */}
      <Box display="flex" alignItems={'center'} gap={'1rem'} px={2} py={1} sx={{ borderBottom: '1px solid var(--color-grayHeaderBorder)' }}>
        <ShadowedButton onClick={clearFilter} size="small" disabled={selectedValue[0] === null && selectedValue[1] === null}>
          <FormattedMessage id="Clear" defaultMessage="Clear" />
        </ShadowedButton>

        <Typography color={'darkgray'} flex={1} fontSize={14} px={2} fontWeight={500} textAlign={'center'}>
          {title}
        </Typography>

        <Button variant="contained" disableElevation size="small" onClick={validateSelection} disabled={(selectedValue[0] !== null && selectedValue[1] === null) || !hasAlgoSelected}>
          <FormattedMessage id="done" defaultMessage="Done" />
        </Button>
      </Box>

      {/* algo selection */}
      {algoFields && algoValue && (
        <Box sx={{ borderBottom: '1px solid var(--color-grayHeaderBorder)' }}>
          {algoFields.map((af) => {
            const checked = algoSelected[af].checked;
            return (
              <Box key={af}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name={af}
                      color={'primary'}
                      checked={checked}
                      onChange={selectAlgoField}
                      inputProps={{ 'aria-label': 'controlled' }}
                      sx={{ py: 1, '& .MuiSvgIcon-root': { fontSize: 20, color: checked ? 'unset' : 'var(--color-grayHeaderBorder)' } }}
                    />
                  }
                  label={
                    <Typography fontSize={'.875rem'} pt={1} pb={1} sx={{ color: 'var(--color-gray2)' }}>
                      <FormattedMessage id={af} />
                    </Typography>
                  }
                  sx={{ ml: 0, mr: 0, px: 2 }}
                />

                {checked && (
                  <Box px={2} py={1} sx={{ background: 'vaf(--color-lightgray)', backgroundColor: '#F7F8FA', fontSize: '.8rem' }}>
                    <Select name={af} fullWidth size="small" value={algoSelected[af].value} onChange={setAlgoForCountry} sx={{ backgroundColor: 'white', color: 'inherit', fontSize: 'inherit' }}>
                      {algoValue.map((ca) => (
                        <MenuItem key={ca} value={ca} sx={{ fontSize: '.8rem' }}>
                          <FormattedMessage id={ca} />
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                )}
              </Box>
            );
          })}
        </Box>
      )}

      <Box display={'flex'} flexDirection={'column'}>
        <Box px={2} py={1}>
          <TextField
            variant="outlined"
            inputProps={{
              style: {
                paddingBlock: 8,
                fontSize: '.8rem',
                color: 'var(--color-gray2)',
                textAlign: 'center',
                WebkitTextFillColor: 'unset',
              },
            }}
            fullWidth
            disabled
            value={textValue}
            sx={{ '& .MuiInputBase-root': { borderRadius: 0 } }}
          />
        </Box>

        <Box px={2} py={1} sx={{ backgroundColor: '#F7F8FA' }}>
          <DateCalendar
            maxDate={maxDate ?? defaultMaxDate}
            minDate={minDate ?? defaultMinDate}
            className={'filter-date-picker'}
            value={selectedValue[0]}
            dayOfWeekFormatter={(day) => day}
            onChange={onChangeCalendar}
            slots={{
              day: CustomCalendarDay,
            }}
            slotProps={{
              day: {
                selectedDays: selectedValue,
              } as any,
            }}
            sx={{ width: '100%' }}
          />
        </Box>
      </Box>
    </Box>
  );
};

const CustomCalendarDay = (props: PickersDayProps<Dayjs> & { selectedDays?: (Dayjs | null)[] }) => {
  const { selectedDays = [], day, outsideCurrentMonth, ...other } = props;

  // const isSelected = !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;
  let ButtonClass = '';
  if (day.isSame(selectedDays[0]) || day.isSame(selectedDays[1])) ButtonClass = 'day-limit';
  else if (selectedDays[0] !== null && selectedDays[1] !== null) {
    if (day.isAfter(selectedDays[0]) && day.isBefore(selectedDays[1])) ButtonClass = 'day-between';
  }
  return <PickersDay {...other} className={`${other.className ?? ''} ${ButtonClass}`} outsideCurrentMonth={outsideCurrentMonth} day={day} />;
};

export default DateRangeFilter;
