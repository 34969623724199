/**
 * InvestigateSearch.tsx
 */
/* packages */
import { useState, useContext, useEffect, useCallback, memo, useRef, useMemo } from 'react';
import { useIntl } from 'react-intl';
import dayjs from 'dayjs';

/* hooks */
import { useAddModal } from 'contextProviders/ModalProvider';
import { AllUsersContext } from 'contextProviders/AllUsersProvider';
import { SchedulesContext } from 'contextProviders/SchedulesProvider';

/* components */
import Box from '@mui/material/Box';

import TitleLayout from 'components/Layouts/TitleLayout';
import Loader from 'components/Loader/Loader';
import TabsNavigation from 'components/TabsNavigation/TabsNavigation';
import EditSchedule from 'components/ManageSchedule/EditSchedule';
import ListSchedules from 'components/ManageSchedule/ListSchedules';
import SchedulesExecutions from 'components/ManageSchedule/SchedulesExecutions';
import DeleteSchedule from 'components/ManageSchedule/DeleteSchedule';
import RunSchedule from 'components/ManageSchedule/RunSchedule';
import FilterSchedulesForm, { SelectedFiltersSchedulesValues } from 'components/ManageSchedule/FilterSchedules';
import ReloadButton from 'components/ReloadButton/ReloadButton';

/* utilities */

/* types */
import { JobType } from 'models/job';
import { DateStringFormat, StatusPromiseResponse } from 'models/utils';
import { DatasetsContext } from 'contextProviders/DatasetsProvider';

interface ManageScreeningsScheduleContentProps {
  load(): void;
  handleCreateSchedule(schedule?: JobType): void;
  handleDeleteSchedule(schedule: JobType): void;
  handleRunSchedule(schedule: JobType, run: 'start' | 'stop'): void;
}

/* elements */
const ManageScreeningsSchedule = () => {
  const intl = useIntl();
  const { toggleModal } = useAddModal();

  const [initialLoading, setInitialLoading] = useState(true);
  const { loadingAllUsers, listAllUsers } = useContext(AllUsersContext);
  const { loadingSchedule, listSchedules, addOrUpdateSchedule, deleteSchedule, runSchedule } = useContext(SchedulesContext);
  const { loadingDatasets, listDatasets, datasets } = useContext(DatasetsContext);

  const load = useCallback(() => {
    listAllUsers?.();
    listSchedules?.();
    listDatasets?.();
  }, [listAllUsers, listSchedules, listDatasets]);

  useEffect(() => {
    load();
    setInitialLoading(false);
  }, [load]);

  const loading = initialLoading || loadingAllUsers || loadingSchedule || loadingDatasets;

  const setSchedule = useCallback(
    async (data: Partial<JobType>, schedule?: JobType): Promise<StatusPromiseResponse> => {
      try {
        const updatedDataset = await addOrUpdateSchedule?.(data, schedule ? false : true);

        if (!updatedDataset) {
          return { status: 'error' };
        }
        return { status: 'success' };
      } catch {
        return { status: 'error' };
      }
    },
    [addOrUpdateSchedule]
  );

  const handleCreateSchedule = useCallback(
    (schedule?: JobType) => {
      toggleModal?.({
        title: schedule ? intl.formatMessage({ id: 'editSchedule', defaultMessage: 'Edit schedule' }) : intl.formatMessage({ id: 'createSchedule', defaultMessage: 'Create schedule' }),
        modalContent: <EditSchedule {...{ schedule, setSchedule, datasets }} />,
      });
    },
    [intl, toggleModal, setSchedule, datasets]
  );

  const removeSchedule = useCallback(
    async (removedId: number): Promise<StatusPromiseResponse> => {
      try {
        const deletedSchedule = await deleteSchedule?.(removedId);

        if (deletedSchedule) {
          return { status: 'success' };
        }
        return { status: 'error' };
      } catch {
        return { status: 'error' };
      }
    },
    [deleteSchedule]
  );

  const handleDeleteSchedule = useCallback(
    (schedule: JobType) => {
      toggleModal?.({
        title: intl.formatMessage({ id: 'deleteSchedule', defaultMessage: 'Delete schedule' }),
        modalContent: <DeleteSchedule {...{ schedule, removeSchedule }} />,
      });
    },
    [intl, toggleModal, removeSchedule]
  );

  const startOrStopSchedule = useCallback(
    async (removedId: number, run: 'start' | 'stop'): Promise<StatusPromiseResponse> => {
      try {
        const deletedSchedule = await runSchedule?.(removedId, run);

        if (deletedSchedule) {
          return { status: 'success' };
        }
        return { status: 'error' };
      } catch {
        return { status: 'error' };
      }
    },
    [runSchedule]
  );

  const handleRunSchedule = useCallback(
    (schedule: JobType, run: 'start' | 'stop') => {
      toggleModal?.({
        title: run === 'start' ? intl.formatMessage({ id: 'startSchedule', defaultMessage: 'Start schedule' }) : intl.formatMessage({ id: 'stopSchedule', defaultMessage: 'Stop schedule' }),
        modalContent: <RunSchedule {...{ schedule, startOrStopSchedule, run }} />,
      });
    },
    [intl, toggleModal, startOrStopSchedule]
  );

  // Schedule
  const addButton = useMemo(() => {
    return {
      addButtonText: intl.formatMessage({ id: 'createSchedule', defaultMessage: 'Create schedule' }),
      handleButtonClick: () => handleCreateSchedule(),
      disableAddButton: loading,
    };
  }, [intl, handleCreateSchedule, loading]);

  return (
    <TitleLayout pageTitle={intl.formatMessage({ id: 'ManageScreeningsScheduleTitle', defaultMessage: 'Schedule' })} addButton={addButton}>
      {loading ? (
        <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Loader />
        </Box>
      ) : (
        <ManageScreeningsScheduleContent {...{ load, handleCreateSchedule, handleDeleteSchedule, handleRunSchedule }} />
      )}
    </TitleLayout>
  );
};

const DEBOUNCETIMING = 500;
const ManageScreeningsScheduleContent = memo(({ load, handleCreateSchedule, handleDeleteSchedule, handleRunSchedule }: ManageScreeningsScheduleContentProps) => {
  const [activeTab, setActiveTab] = useState<'schedule' | 'lastExecutions'>('schedule');
  const { schedules } = useContext(SchedulesContext);

  const [visibleSchedules, setVisibleSchedules] = useState<typeof schedules | undefined>(schedules);
  const [filtered, setFiltered] = useState<boolean>(false);
  const [searching, setSearching] = useState<ReturnType<typeof setTimeout> | null>(null);

  const searchFormRef = useRef<HTMLFormElement>(null);
  const scheduleTabs = useMemo(
    () => [
      { text: 'Schedule', value: 'schedule' },
      { text: 'Last Executions', value: 'lastExecutions' },
    ],
    []
  );

  const makeSearch = useCallback(
    async (filters: SelectedFiltersSchedulesValues | null) => {
      let visSchedules = schedules;
      let isFiltered = false;

      if (filters) {
        if (filters.type.length > 0) {
          isFiltered = true;
          visSchedules = visSchedules?.filter((sched) => filters.type.includes(sched?.jobType as string));
        }
        if (filters.active.length > 0) {
          isFiltered = true;
          console.log(filters.active);
          visSchedules = visSchedules?.filter((sched) => filters.active.includes(sched?.active ? 'active' : 'unactive'));
        }
        if (filters.date) {
          isFiltered = true;
          const [dayStart, dayEnd] = filters.date;
          visSchedules = visSchedules?.filter((sched) => {
            const creationDate = dayjs(sched.creationDate, DateStringFormat);
            const nextExecDate = dayjs(sched.nextExecutionTime, DateStringFormat);
            return (
              (creationDate.isValid() && creationDate.isAfter(dayStart) && creationDate.isBefore(dayEnd)) || (nextExecDate.isValid() && nextExecDate.isAfter(dayStart) && nextExecDate.isBefore(dayEnd))
            );
          });
        }
        if (filters.search) {
          isFiltered = true;
          const searchTerm = filters.search.trim().toLowerCase();
          visSchedules = visSchedules?.filter((sched) => (sched.name ?? '').toLowerCase().includes(searchTerm) || (sched.description ?? '').toLowerCase().includes(searchTerm));
        }
      }

      setFiltered(isFiltered);
      setVisibleSchedules(visSchedules);
    },
    [schedules]
  );

  const onChangeFilter = useCallback(
    (newFilters: SelectedFiltersSchedulesValues, debounce = false) => {
      setSearching((currentSearch) => {
        if (currentSearch) {
          clearTimeout(currentSearch);
          return null;
        }
        return currentSearch;
      });

      // setFilters(newFilters);
      const applySearch = async () => {
        await makeSearch(newFilters);
      };

      if (debounce) {
        const filterTimeout = setTimeout(async () => {
          await applySearch();
          setSearching(null);
        }, DEBOUNCETIMING);

        setSearching(filterTimeout);
      } else {
        applySearch();
      }
    },
    [makeSearch]
  );

  const handleTabChange = useCallback((event: React.SyntheticEvent, tabValue: typeof activeTab) => {
    const newTab = tabValue;
    setActiveTab(newTab);
  }, []);

  let content;
  switch (activeTab) {
    case 'schedule':
      content = <ListSchedules {...{ searching, filtered, schedules: visibleSchedules, handleCreateSchedule, handleDeleteSchedule, handleRunSchedule }} />;
      break;
    case 'lastExecutions':
      content = <SchedulesExecutions />;
      break;
  }

  const isError = !schedules;
  if (isError) {
    return (
      <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <ReloadButton
          onClick={() => {
            load();
          }}
        />
      </Box>
    );
  }

  return (
    <Box flex={1} display={'flex'} flexDirection={'column'} sx={{ width: '100%' }}>
      {/* search */}
      <FilterSchedulesForm ref={searchFormRef} {...{ disabled: activeTab !== 'schedule', onChangeFilter }} />
      {/* <SearchForm ref={searchFormRef} {...{ submitAction: submitSearchForm }} /> */}
      {/* Search Tabs */}
      <TabsNavigation skipAll activeTab={activeTab} tabTitles={scheduleTabs} label={'scheduler tab'} onChange={handleTabChange} disabled={false} />
      {content}
    </Box>
  );
});

export default ManageScreeningsSchedule;
