/**
 * InvestigateExplore.tsx
 */
/* packages */
import { useState, useEffect, useContext, useRef, useCallback, useMemo, memo, PropsWithChildren, forwardRef, SyntheticEvent } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';

/* contexts */
import { DatasetsContext } from 'contextProviders/DatasetsProvider';
import { UserContext } from 'contextProviders/UserProvider';
import { useAddSnackbar } from 'contextProviders/SnackbarProvider';
import { useAuthenticatedRequest } from 'contextProviders/AuthProvider';
import { AllUsersContext } from 'contextProviders/AllUsersProvider';

/* hooks */
import { useViewAlert } from './InvestigateAlert';
import { useViewProfile } from 'components/PersonProfile/PersonProfile';

/* components */
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Popover from '@mui/material/Popover';
// import IconButton from '@mui/material/IconButton';
import { SelectChangeEvent } from '@mui/material/Select';

import Loader from 'components/Loader/Loader';
import PageHeader from 'components/PageHeader/PageHeader';
import PageTitle from 'components/PageTitle/PageTitle';

import FilterTextfield from 'components/SearchFilters/FilterTextfield';
import DateRangeFilter from 'components/SearchFilters/DateRangeFilter';
import FilterRange from 'components/SearchFilters/FilterRange';
import SearchFilters, { SearchFiltersButtonType, FilterButtonRefType, FilterValueType, SearchFiltersRefType } from 'components/SearchFilters/SearchFilters';

import Pagination, { numberPerPages } from 'components/Pagination/Pagination';

import SearchText from 'components/SearchElements/SearchText/SearchText';
import SearchList from 'components/SearchElements/SearchText/SearchList';
import ShadowedButton from 'components/ShadowedButton/ShadowedButton';

import GaugeMeter from 'components/GaugeMeter/GaugeMeter';
import StatusTag from 'components/StatusTag/StatusTag';

import TableResults from 'components/TableResults/TableResults';
import { TableHeadElement } from 'components/TableResults/TableHead';
import { TableRowData } from 'components/TableResults/TableRow';

import { RefreshIcon } from 'icons/refresh/refresh';
import { ExportIcon } from 'icons/export/export';
import { GearFilledIcon } from 'icons/gearFilled/gearFilled';
// import { ClockRotateLeftIcon } from 'icons/clockRotateLeft/clockRotateLeft';

import { GetUser } from 'components/GetUser/GetUser';

/* utilities */
import { checkPermissions } from 'utilities/CheckUserPermissions';
import { routerPages } from 'AppRouter';
import { URLConstants } from 'common/URLconstants';

/* types */
import { TableRefType } from 'components/TableResults/TableResults';
import { Dataset, SearchDatasetResponse, SearchDatasetCountResponse, SearchDatasetPayload, DatasetSourceOfDataType, AllowedDatasetSourceOfDataType } from 'models/datasets';
import { formatMatchingName } from 'models/matchingData';

interface SearchFormType {
  submitAction(event: SyntheticEvent): void;
  makingSearch: boolean;
  datasets: Dataset[];
  currentDataset: string | null;
  changeDataset(newDataset: SearchFormType['currentDataset']): void;
  type?: string;
}

interface InvestigateExploreContentProps {
  datasets: Dataset[];
  type?: string;
}

interface DisplaySearchResultsType {
  searchResults: (SearchDatasetResponse & SearchDatasetCountResponse) | null;
  exportResults(format: string): void;
  lastSearchQuery?: SearchDatasetPayload;
  makeSortedSearch?(sortField: string, sortOrder: 'asc' | 'desc'): void;
  dataset?: Dataset;
  type?: string;
}
interface ExportButtonProps {
  exportResults(format: string): void;
}

/* elements */
const FILTERNAMES = {
  entryId: 'id',
  dataId: 'dataId',
  period: 'period',
  score: 'score',
};

const InvestigateExplore = ({ type }: { type?: string }) => {
  const { datasetsQueried, loadingDatasets, datasets, listDatasets } = useContext(DatasetsContext);

  // trigger search dataset on load if required
  useEffect(() => {
    if (datasetsQueried) return;
    listDatasets?.();
  }, [datasetsQueried, listDatasets]);

  // refresh datasets
  const refreshDataset = () => {
    if (!datasets) listDatasets?.();
  };

  // display the loading layout
  if (!datasetsQueried || loadingDatasets)
    return (
      <InvestigateExploreLayout type={type}>
        <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Loader />
        </Box>
      </InvestigateExploreLayout>
    );

  // display a reload button if the required data are not fetched
  if (!datasets)
    return (
      <InvestigateExploreLayout type={type}>
        <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Button sx={{ color: 'var(--color-gray2)', flexDirection: 'column' }} onClick={refreshDataset}>
            <RefreshIcon sx={{ mb: 2 }} />
            <Box>
              <Typography>
                <FormattedMessage id="searchDatasetRefresh" defaultMessage="An error occured." />
              </Typography>
              <Typography>
                <FormattedMessage id="tryAgain" defaultMessage="Please try again." />
              </Typography>
            </Box>
          </Button>
        </Box>
      </InvestigateExploreLayout>
    );

  return (
    <InvestigateExploreLayout type={type}>
      <InvestigateExploreContent key={type || ''} type={type} datasets={datasets} />
    </InvestigateExploreLayout>
  );
};

const InvestigateExploreLayout = ({ type, children }: PropsWithChildren<{ type?: string }>) => {
  const intl = useIntl();
  const { permissions } = useContext(UserContext);

  let pageTitle;
  switch (type) {
    case 'risks':
      pageTitle = intl.formatMessage({ id: 'investigateRisksTitle', defaultMessage: 'Risks' });
      break;
    default:
      pageTitle = intl.formatMessage({ id: 'investigateExploreTitle', defaultMessage: 'Explore' });
      break;
  }

  return (
    <>
      <PageHeader>
        <PageTitle title={pageTitle} />

        {checkPermissions('manageDatasets', permissions) && (
          <Box>
            <Link to={routerPages.manageDatasets}>
              <ShadowedButton>
                <GearFilledIcon fontSize="inherit" sx={{ mr: 1 }} />
                <FormattedMessage id="datasetManageSettings" defaultMessage="Manage Datasets" />
              </ShadowedButton>
            </Link>
          </Box>
        )}
      </PageHeader>
      {children}
    </>
  );
};

const addPaginationToPayload = (params: { payload?: SearchDatasetPayload; currentPage: number; maxPerPage: string }) => {
  const { payload, currentPage, maxPerPage } = params;
  if (!payload) return payload;

  payload.pageNumber = currentPage;
  payload.maxPerPage = Number(maxPerPage);
  return payload;
};

const SearchPagination = memo(Pagination);

const InvestigateExploreContent = ({ datasets, type }: InvestigateExploreContentProps) => {
  const addSnackbar = useAddSnackbar();
  const intl = useIntl();
  const { postAuthenticatedRequest } = useAuthenticatedRequest();
  const { currentUserId } = useContext(UserContext);

  // states and refs
  const [currentDataset, setCurrentDataset] = useState<string | null>(null);
  const [makingSearch, setMakingSearch] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [maxPerPage, setMaxPerPage] = useState<string>(String(numberPerPages[0]));
  const [searchResults, setSearchResults] = useState<(SearchDatasetResponse & SearchDatasetCountResponse) | null>(null);
  const [lastSearchQuery, setLastSearchQuery] = useState<SearchDatasetPayload>();

  const searchFormRef = useRef<HTMLFormElement>(null);

  const computeSearchPayload = useCallback(
    (activeDataset: string, skipInputs = false) => {
      const formData = new FormData(searchFormRef.current ?? undefined);

      const searchPayload: SearchDatasetPayload = {
        pageNumber: 0,
        maxPerPage: numberPerPages[0],
      };

      const ds = datasets.filter((d) => d.label === activeDataset)[0];

      if (!ds) return undefined;
      if (!ds.sourceOfDataType) return undefined;

      const objectPayload: DatasetSourceOfDataType = { dataset: { id: ds.id } };

      if (!skipInputs) {
        const searchInput = formData.get('searchInput') as string;

        if (searchInput) {
          searchPayload.keyword = searchInput.trim();
        }

        const entryIdValue = JSON.parse(formData.get(FILTERNAMES.entryId) as string) as FilterValueType;
        if (entryIdValue && entryIdValue.nbValues > 0) {
          objectPayload.id = Number(entryIdValue.value);
        }

        const dataIdValue = JSON.parse(formData.get(FILTERNAMES.dataId) as string) as FilterValueType;
        if (dataIdValue && dataIdValue.nbValues > 0) {
          objectPayload.dataID = Number(dataIdValue.value);
        }

        const periodValues = JSON.parse(formData.get(FILTERNAMES.period) as string) as FilterValueType;

        if (periodValues && periodValues.nbValues > 0) {
          const dateStart = dayjs(periodValues.dayValue);
          const dateEnd = dayjs(periodValues.dayEndValue);

          if (dateStart && dateEnd) {
            searchPayload.date1 = dateStart.format('DD/MM/YYYY');
            searchPayload.date2 = dateEnd.format('DD/MM/YYYY');
          }
        }

        const scoreValues = JSON.parse(formData.get(FILTERNAMES.score) as string) as FilterValueType;
        if (scoreValues && scoreValues.nbValues > 0) {
          searchPayload.score1 = (scoreValues.minValue ?? 0) * 100;
          searchPayload.score2 = (scoreValues.maxValue ?? 1) * 100;
        }
      }

      searchPayload[ds.sourceOfDataType as AllowedDatasetSourceOfDataType] = objectPayload;
      return searchPayload;
    },
    [datasets]
  );

  const makeSearch = useCallback(
    async (searchPayload?: SearchDatasetPayload) => {
      if (!currentUserId) return;
      if (!searchPayload) return;

      if (makingSearch) return;

      setMakingSearch(true);
      setSearchResults(null);
      setLastSearchQuery(undefined);

      try {
        // const searchPayload = computeSearchPayload(formData, searchInput as string, currentPage, maxPerPage, activeStatus);
        let searchUrl = URLConstants.datasetSearch;
        let countUrl = URLConstants.datasetSearchCount;

        if (type === 'risks') {
          searchUrl = URLConstants.riskPersonsList;
          countUrl = URLConstants.riskPersonsCount;
        }
        const queries = [postAuthenticatedRequest(searchUrl, searchPayload), postAuthenticatedRequest(countUrl, searchPayload)];

        const [searchResponse, searchCount] = (await Promise.all(queries)) as [SearchDatasetResponse, SearchDatasetCountResponse];

        setLastSearchQuery(searchPayload);

        const newSearchResults = {
          ...searchResponse,
          numberOfObjects: searchCount.numberOfObjects ?? 0,
        } as SearchDatasetResponse & SearchDatasetCountResponse;

        if (searchPayload.sortField && searchPayload.sortOrder) {
          newSearchResults.sortField = searchPayload.sortField;
          newSearchResults.sortOrder = searchPayload.sortOrder.toLowerCase();
        }

        setSearchResults(newSearchResults);
      } catch (searchError) {
        addSnackbar(
          intl.formatMessage({
            id: 'searchRequestError',
            defaultMessage: 'An error occured for your request',
          }),
          'error'
        );
      }

      setMakingSearch(false);
    },
    [currentUserId, makingSearch, postAuthenticatedRequest, intl, addSnackbar, type]
  );

  const submitSearchForm = (event?: React.SyntheticEvent) => {
    if (event) event.preventDefault();

    if (!currentDataset) return;

    const newCurrentPage = 0;
    setCurrentPage(newCurrentPage);

    const searchPayload = computeSearchPayload(currentDataset);
    if (!searchPayload) return;
    const paginatedPayload = addPaginationToPayload({
      payload: searchPayload,
      currentPage: newCurrentPage,
      maxPerPage: maxPerPage,
    });

    makeSearch(paginatedPayload);
  };

  const changeDataset = useCallback(
    (newDataset: string | null) => {
      setCurrentDataset(newDataset);

      if (!newDataset) return;

      const newCurrentPage = 0;
      setCurrentPage(newCurrentPage);

      const searchPayload = computeSearchPayload(newDataset, true);
      if (!searchPayload) return;
      const paginatedPayload = addPaginationToPayload({
        payload: searchPayload,
        currentPage: newCurrentPage,
        maxPerPage: maxPerPage,
      });

      makeSearch(paginatedPayload);
    },
    [computeSearchPayload, makeSearch, maxPerPage]
  );

  const setMaxAndSearch = (event: SelectChangeEvent) => {
    const newMaxPerPage = event.target.value;
    if (newMaxPerPage === maxPerPage) return;

    const newCurrentPage = 0;
    setMaxPerPage(newMaxPerPage);
    setCurrentPage(newCurrentPage);

    // take the lastest search payload
    const paginatedPayload = addPaginationToPayload({ payload: lastSearchQuery, currentPage: newCurrentPage, maxPerPage: newMaxPerPage });

    makeSearch(paginatedPayload);
  };

  const changePage = (shift: 1 | -1) => {
    if (!searchResults || !searchResults.numberOfObjects) return;
    if (!lastSearchQuery) return;

    const newPage = currentPage + shift;

    // check page is in bounds
    if (newPage < 0) return;
    if (newPage > searchResults.numberOfObjects / Number(maxPerPage)) return;

    setCurrentPage(newPage);

    const paginatedPayload = addPaginationToPayload({ payload: lastSearchQuery, currentPage: newPage, maxPerPage: maxPerPage });

    makeSearch(paginatedPayload);
  };

  const makeSortedSearch = useCallback(
    async (sortField: string, sortOrder: 'asc' | 'desc') => {
      if (!lastSearchQuery) return;

      const searchPayload = lastSearchQuery;
      searchPayload.sortField = sortField;
      searchPayload.sortOrder = sortOrder.toUpperCase();

      const newCurrentPage = 0;
      setCurrentPage(newCurrentPage);

      searchPayload.pageNumber = newCurrentPage;

      makeSearch(searchPayload);
    },
    [makeSearch, lastSearchQuery]
  );

  const dataset = datasets.filter((d) => d.label === currentDataset)[0] ?? undefined;

  let activeDatasets = datasets;
  if (type === 'risks') {
    activeDatasets = datasets.filter((dataset) => dataset.type?.toLowerCase() === 'source' && dataset.sourceOfDataType?.toLowerCase() === 'persons');
  }
  const exportResults = async (format: 'csv' | 'pdf') => {
    if (!dataset) return;

    let exportUrl;
    let filename = '';

    switch (format) {
      case 'csv':
        exportUrl = URLConstants.datasetExport;
        filename = `${dataset.sourceOfDataType ?? 'dataset'}.zip`;

        if (type === 'risks') {
          exportUrl = URLConstants.riskPersonsasCsv;
          filename = `risks.zip`;
        }
        break;
      // case 'pdf':
      //   exportUrl = URLConstants.alertExportPDF;
      //   filename = 'search.pdf';
      //   break;
      default:
        break;
    }
    if (!exportUrl) return;
    if (!lastSearchQuery) return;

    setMakingSearch(true);

    try {
      const response = (await postAuthenticatedRequest(exportUrl, lastSearchQuery, undefined, { blob: true })) as BlobPart;

      const downloadUrl = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', filename); //any other extension
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (ExportError) {
      addSnackbar(
        intl.formatMessage({
          id: 'searchExportError',
          defaultMessage: 'An error occured while exporting your results',
        }),
        'error'
      );
    }

    setMakingSearch(false);
  };

  return (
    <Box flex={1} display={'flex'} flexDirection={'column'} sx={{ width: '100%' }}>
      {/* Search Form */}
      <SearchForm ref={searchFormRef} {...{ submitAction: submitSearchForm, type, makingSearch, datasets: activeDatasets, currentDataset, changeDataset }} />

      {/* Search results */}
      {makingSearch ? (
        <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Loader />
        </Box>
      ) : (
        <Box>{dataset ? <DisplaySearchResults {...{ searchResults, exportResults, type, lastSearchQuery, makeSortedSearch, dataset }} /> : <></>}</Box>
      )}

      {/* Search pagination */}
      {!makingSearch && dataset && searchResults && (searchResults.numberOfObjects ?? 0) > 0 && (
        <SearchPagination
          {...{
            maxPerPage: Number(maxPerPage),
            currentPage,
            nbResults: searchResults.numberOfObjects ?? 0,
            setMaxAndCallback: setMaxAndSearch,
            changePageCallback: changePage,
          }}
        />
      )}
    </Box>
  );
};

const SearchFiltersMemo = memo(SearchFilters);

const SearchForm = memo(
  forwardRef<HTMLFormElement, SearchFormType>((props, ref) => {
    const intl = useIntl();
    const searchFilterRef = useRef<SearchFiltersRefType | null>(null);
    const { submitAction, makingSearch, datasets, currentDataset, changeDataset, type } = props;

    const [searchInput, setSearchInput] = useState<string>('');

    // define required ref
    const inputIdRef = useRef<FilterButtonRefType | null>(null);
    const dataIdRef = useRef<FilterButtonRefType | null>(null);
    const dateRef = useRef<FilterButtonRefType | null>(null);
    const scoreRef = useRef<FilterButtonRefType | null>(null);

    const searchFiltersButtons: SearchFiltersButtonType[] = useMemo(() => {
      let searchElements: SearchFiltersButtonType[];
      switch (type) {
        case 'risks':
          searchElements = [
            {
              ref: scoreRef,
              text: <FormattedMessage id="score" defaultMessage="Score" />,
              inputName: FILTERNAMES.score,
              filterContent: <FilterRange title={intl.formatMessage({ id: 'score', defaultMessage: 'Score' })} minimum={0} maximum={1} step={0.01} percent={true} />,
              hideNbValues: true,
            },
          ];
          break;
        default:
          searchElements = [
            {
              ref: inputIdRef,
              text: <FormattedMessage id="entryId" defaultMessage="ID" />,
              inputName: FILTERNAMES.entryId,
              filterContent: <FilterTextfield title={intl.formatMessage({ id: 'entryId', defaultMessage: 'ID' })} />,
              placeholder: intl.formatMessage({ id: 'chooseEntryId', defaultMessage: 'Enter ID' }),
            },
            {
              ref: dataIdRef,
              text: <FormattedMessage id="dataId" defaultMessage="Data ID" />,
              inputName: FILTERNAMES.dataId,
              filterContent: <FilterTextfield title={intl.formatMessage({ id: 'dataId', defaultMessage: 'Data ID' })} />,
              placeholder: intl.formatMessage({ id: 'chooseDataId', defaultMessage: 'Enter Data ID' }),
            },
            {
              ref: dateRef,
              text: <FormattedMessage id="period" defaultMessage="Period" />,
              inputName: FILTERNAMES.period,
              filterContent: <DateRangeFilter title={intl.formatMessage({ id: 'selectPeriod', defaultMessage: 'Select period' })} />,
            },
          ];
      }
      return searchElements;
    }, [intl, type]);

    const availableDatasets = useMemo(() => {
      return datasets.filter((d) => d.label !== undefined).map((d) => d.label) as string[];
    }, [datasets]);

    const updateSearchInput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchInput(event.target.value);
    }, []);
    const searchInputProps = useMemo(() => {
      return { name: 'searchInput' };
    }, []);

    useEffect(() => {
      if (searchFilterRef.current && searchFilterRef.current.clearFilters) {
        searchFilterRef.current.clearFilters();
      }
      setSearchInput('');
    }, [currentDataset]);

    return (
      <Box width={'100%'} display={'flex'} px={0} pt={0} pb={4}>
        <form ref={ref} action="" style={{ width: '100%' }}>
          <Box display={'flex'} gap={'1.5rem'} alignItems={'center'} sx={{ flexFlow: 'row wrap' }}>
            <Box flex={1} sx={{ minWidth: 150, maxWidth: { xs: '100%', md: '200px' } }}>
              <SearchList
                list={availableDatasets}
                value={currentDataset}
                setValue={changeDataset}
                placeholder={intl.formatMessage({ id: 'selectDataset', defaultMessage: 'Select dataset' })}
                disabled={makingSearch}
              />
            </Box>

            <Divider orientation="vertical" flexItem sx={{ borderColor: 'var(--color-grayHeaderBorder)' }} />

            <Box flex={1} sx={{ minWidth: 150, maxWidth: { xs: '100%', md: '450px' } }}>
              <SearchText
                fullWidth
                value={searchInput}
                onChange={updateSearchInput}
                placeholder={intl.formatMessage({ id: 'searchPlaceholder', defaultMessage: 'Search' })}
                inputProps={searchInputProps}
                disabled={makingSearch}
                // sx={{ minWidth: 100, maxWidth: { xs: '100%', md: '200px' } }}
              />
            </Box>

            <Box>
              <Button type="submit" variant="contained" disableElevation disabled={!currentDataset || makingSearch} onClick={submitAction} sx={{ textTransform: 'none' }}>
                <FormattedMessage id="searchButton" defaultMessage="Search" />
              </Button>
            </Box>

            <Divider orientation="vertical" flexItem sx={{ borderColor: 'var(--color-grayHeaderBorder)' }} />

            <Box>
              <SearchFiltersMemo ref={searchFilterRef} disabled={makingSearch} {...{ searchFiltersButtons }} />
            </Box>
          </Box>
        </form>
      </Box>
    );
  })
);

const ExportButton = ({ exportResults }: ExportButtonProps) => {
  const [filterOpen, setFilterOpen] = useState<boolean>(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const onButtonClick = () => {
    setFilterOpen(true);
  };

  const handleClose = () => {
    setFilterOpen(false);
  };

  return (
    <>
      <Button
        ref={buttonRef}
        type="submit"
        variant="contained"
        disableElevation
        sx={{
          '.MuiButton-startIcon svg': {
            fontSize: '12px',
          },
        }}
        startIcon={<ExportIcon />}
        onClick={onButtonClick}
      >
        <FormattedMessage id="ExportList" defaultMessage="Export List" />
      </Button>
      <Popover
        id={'popover-button'}
        open={filterOpen}
        anchorEl={buttonRef.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={0}
      >
        <Box p={2} display="flex" flexDirection={'column'} sx={{ backgroundColor: 'white' }}>
          <ShadowedButton
            onClick={() => {
              handleClose();
              exportResults('csv');
            }}
            sx={{ whiteSpace: 'nowrap' }}
          >
            <FormattedMessage id="exportCSV" defaultMessage="Export as CSV" />
          </ShadowedButton>

          {/* <ShadowedButton
            onClick={() => {
              handleClose();
              exportResults('pdf');
            }}
            sx={{ whiteSpace: 'nowrap' }}
          >
            <FormattedMessage id="exportPDF" defaultMessage="Export as PDF" />
          </ShadowedButton> */}
        </Box>
      </Popover>
    </>
  );
};

const DisplaySearchResults = memo((props: DisplaySearchResultsType) => {
  const { searchResults, exportResults, lastSearchQuery, makeSortedSearch, dataset, type } = props;
  // const navigate = useNavigate();
  const viewAlert = useViewAlert();
  const viewProfile = useViewProfile();

  const { allUsers } = useContext(AllUsersContext);

  const intl = useIntl();

  const tableRef = useRef<TableRefType>(null);

  const resultData = useMemo(() => {
    if (!dataset?.sourceOfDataType) return [];
    return searchResults?.[dataset.sourceOfDataType as AllowedDatasetSourceOfDataType] ?? [];
  }, [searchResults, dataset]);

  // const [bulkAction, setBulkAction] = useState<string>('');

  const tableHeaders = useMemo((): TableHeadElement[] => {
    let sortedColumn = searchResults?.sortField ?? '';
    let sortedDirection = searchResults?.sortOrder ?? false;

    if (type === 'risks') {
      return [
        {
          id: 'id',
          label: intl.formatMessage({
            id: 'id',
            defaultMessage: 'ID',
          }),
          sorted: () => {
            makeSortedSearch?.('id', sortedColumn !== 'id' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
          },
          direction: sortedColumn === 'id' ? sortedDirection : false,
        },
        {
          id: 'fullName',
          label: intl.formatMessage({
            id: 'fullName',
            defaultMessage: 'Full Name',
          }),
          minWidth: '180px',
        },
        {
          id: 'entityType',
          label: intl.formatMessage({
            id: 'entityType',
            defaultMessage: 'Entity Type',
          }),
          sorted: () => {
            makeSortedSearch?.('entityType', sortedColumn !== 'entityType' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
          },
          direction: sortedColumn === 'entityType' ? sortedDirection : false,
        },
        {
          id: 'riskScore',
          label: intl.formatMessage({
            id: 'RiskScore',
            defaultMessage: 'Risk Score',
          }),
          // minWidth: '180px',
          sorted: () => {
            makeSortedSearch?.('riskScore', sortedColumn !== 'riskScore' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
          },
          direction: sortedColumn === 'riskScore' ? sortedDirection : false,
        },
        {
          id: 'since',
          label: intl.formatMessage({
            id: 'since',
            defaultMessage: 'Since',
          }),
          minWidth: '180px',
          sorted: () => {
            makeSortedSearch?.('creationDtg', sortedColumn !== 'creationDtg' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
          },
          direction: sortedColumn === 'creationDtg' ? sortedDirection : false,
        },
        // {
        //   id: 'history',
        //   label: '',
        // },
      ];
    } else {
      switch (dataset?.sourceOfDataType) {
        case 'accounts':
          return [
            {
              id: 'id',
              label: intl.formatMessage({
                id: 'id',
                defaultMessage: 'ID',
              }),
              sorted: () => {
                makeSortedSearch?.('id', sortedColumn !== 'id' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
              },
              direction: sortedColumn === 'id' ? sortedDirection : false,
            },
            {
              id: 'dataId',
              label: intl.formatMessage({
                id: 'dataId',
                defaultMessage: 'Data ID',
              }),
              sorted: () => {
                makeSortedSearch?.('dataId', sortedColumn !== 'dataId' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
              },
              direction: sortedColumn === 'dataId' ? sortedDirection : false,
            },
            {
              id: 'accountNumber',
              label: intl.formatMessage({
                id: 'accountNumber',
                defaultMessage: 'Account Number',
              }),
            },
            {
              id: 'bankIban',
              label: intl.formatMessage({
                id: 'bankIban',
                defaultMessage: 'IBAN',
              }),
            },
            {
              id: 'bic',
              label: intl.formatMessage({
                id: 'bic',
                defaultMessage: 'BIC',
              }),
            },
            {
              id: 'createdBy',
              label: intl.formatMessage({
                id: 'createdBy',
                defaultMessage: 'Created By',
              }),
              minWidth: '180px',
            },
            {
              id: 'createdOn',
              label: intl.formatMessage({
                id: 'createdOn',
                defaultMessage: 'Created On',
              }),
              minWidth: '180px',
              sorted: () => {
                makeSortedSearch?.('creationDtg', sortedColumn !== 'creationDtg' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
              },
              direction: sortedColumn === 'creationDtg' ? sortedDirection : false,
            },
          ];
        case 'transactions':
          return [
            {
              id: 'id',
              label: intl.formatMessage({
                id: 'id',
                defaultMessage: 'ID',
              }),
              sorted: () => {
                makeSortedSearch?.('id', sortedColumn !== 'id' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
              },
              direction: sortedColumn === 'id' ? sortedDirection : false,
            },
            {
              id: 'amount',
              label: intl.formatMessage({
                id: 'amount',
                defaultMessage: 'Amount',
              }),
            },
            {
              id: 'currency',
              label: intl.formatMessage({
                id: 'currency',
                defaultMessage: 'Currency',
              }),
            },
            {
              id: 'transactionCode',
              label: intl.formatMessage({
                id: 'transactionCode',
                defaultMessage: 'Transaction Code',
              }),
            },
            {
              id: 'transactionReference',
              label: intl.formatMessage({
                id: 'transactionReference',
                defaultMessage: 'Transaction Reference',
              }),
            },
            {
              id: 'createdBy',
              label: intl.formatMessage({
                id: 'createdBy',
                defaultMessage: 'Created By',
              }),
              minWidth: '180px',
            },
            {
              id: 'createdOn',
              label: intl.formatMessage({
                id: 'createdOn',
                defaultMessage: 'Created On',
              }),
              minWidth: '180px',
              sorted: () => {
                makeSortedSearch?.('creationDtg', sortedColumn !== 'creationDtg' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
              },
              direction: sortedColumn === 'creationDtg' ? sortedDirection : false,
            },
            // {
            //   id: 'history',
            //   label: intl.formatMessage({
            //     id: 'history',
            //     defaultMessage: 'History',
            //   }),
            // },
          ];
        case 'alerts':
          return [
            {
              id: 'name',
              label: intl.formatMessage({
                id: 'name',
                defaultMessage: 'Name',
              }),
              minWidth: '180px',
              sorted: () => {
                makeSortedSearch?.('name', sortedColumn !== 'name' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
              },
              direction: sortedColumn === 'name' ? sortedDirection : false,
            },
            {
              id: 'bestMatch',
              label: intl.formatMessage({
                id: 'bestMatch',
                defaultMessage: 'Best Match',
              }),
              minWidth: '180px',
            },
            {
              id: 'bestMatchList',
              label: intl.formatMessage({
                id: 'bestMatchList',
                defaultMessage: 'Best Match List',
              }),
            },
            {
              id: 'score',
              label: intl.formatMessage({
                id: 'score',
                defaultMessage: 'Score',
              }),
              sorted: () => {
                makeSortedSearch?.('matchScore', sortedColumn !== 'matchScore' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
              },
              direction: sortedColumn === 'matchScore' ? sortedDirection : false,
            },
            {
              id: 'status',
              label: intl.formatMessage({
                id: 'status',
                defaultMessage: 'Status',
              }),
            },
            {
              id: 'alertId',
              label: intl.formatMessage({
                id: 'alertId',
                defaultMessage: 'Alert Id',
              }),
              sorted: () => {
                makeSortedSearch?.('id', sortedColumn !== 'id' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
              },
              direction: sortedColumn === 'id' ? sortedDirection : false,
            },
          ];

        case 'persons':
        default:
          return [
            {
              id: 'id',
              label: intl.formatMessage({
                id: 'id',
                defaultMessage: 'ID',
              }),
              sorted: () => {
                makeSortedSearch?.('id', sortedColumn !== 'id' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
              },
              direction: sortedColumn === 'id' ? sortedDirection : false,
            },
            {
              id: 'dataId',
              label: intl.formatMessage({
                id: 'dataId',
                defaultMessage: 'Data ID',
              }),
              sorted: () => {
                makeSortedSearch?.('dataId', sortedColumn !== 'dataId' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
              },
              direction: sortedColumn === 'dataId' ? sortedDirection : false,
            },
            {
              id: 'fullName',
              label: intl.formatMessage({
                id: 'fullName',
                defaultMessage: 'Full Name',
              }),
              minWidth: '180px',
            },
            {
              id: 'entityType',
              label: intl.formatMessage({
                id: 'entityType',
                defaultMessage: 'Entity Type',
              }),
              sorted: () => {
                makeSortedSearch?.('entityType', sortedColumn !== 'entityType' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
              },
              direction: sortedColumn === 'entityType' ? sortedDirection : false,
            },
            {
              id: 'createdBy',
              label: intl.formatMessage({
                id: 'createdBy',
                defaultMessage: 'Created By',
              }),
              minWidth: '180px',
            },
            {
              id: 'createdOn',
              label: intl.formatMessage({
                id: 'createdOn',
                defaultMessage: 'Created On',
              }),
              minWidth: '180px',
              sorted: () => {
                makeSortedSearch?.('creationDtg', sortedColumn !== 'creationDtg' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc');
              },
              direction: sortedColumn === 'creationDtg' ? sortedDirection : false,
            },
            // {
            //   id: 'history',
            //   label: intl.formatMessage({
            //     id: 'history',
            //     defaultMessage: 'History',
            //   }),
            // },
          ];
      }
    }
  }, [intl, searchResults, makeSortedSearch, dataset, type]);

  const tableData = useMemo((): TableRowData[] => {
    let tableData;
    if (type === 'risks') {
      const personsResults = ((resultData ?? []) as SearchDatasetResponse['persons']) ?? [];
      tableData = personsResults.map((p, p_ind) => ({
        id: p.id ? String(p.id) : String(p_ind),
        columns: {
          id: p.id ? String(p.id) : '',
          fullName: formatMatchingName(p?.names?.[0]),
          entityType: (
            <Typography display={'inline-block'} fontSize={'0.825rem'} borderRadius={1.25} px={1} py={0.625} sx={{ backgroundColor: 'var(--color-lightgray2)', color: 'var(--color-gray2)' }}>
              {p.entityType ?? ''}
            </Typography>
          ),
          riskScore: <GaugeMeter value={(p?.score ?? 0) / 100} backgroundColor={'white'} />,
          since: p.creationDate ?? '',
          // history: (
          //   <Box display={'flex'} alignItems={'center'}>
          //     <ShadowedButton
          //       onClick={() => {
          //         viewProfile(p);
          //       }}
          //       sx={{ whiteSpace: 'nowrap', ml: 'auto' }}
          //     >
          //       <FormattedMessage id="viewProfile" defaultMessage="View Profile" />
          //     </ShadowedButton>
          //   </Box>
          // ),
        },
        rowClick: () => {
          viewProfile(p);
        },
      }));
    } else {
      switch (dataset?.sourceOfDataType) {
        case 'accounts':
          const accountsResults = ((resultData ?? []) as SearchDatasetResponse['accounts']) ?? [];
          tableData = accountsResults.map((a, a_ind) => ({
            id: a.id ? String(a.id) : String(a_ind),
            columns: {
              id: a.id ? String(a.id) : String(a_ind),
              dataId: a.dataID ? String(a.dataID) : '',
              accountNumber: a.accountNumber ?? '',
              bankIban: a.bankIban ?? '',
              bic: a.bic ?? '',
              createdBy: GetUser(a.createdBy ?? '', allUsers),
              createdOn: a.creationDate ?? '',
            },
          }));
          break;
        case 'transactions':
          const transactionsResults = ((resultData ?? []) as SearchDatasetResponse['transactions']) ?? [];
          tableData = transactionsResults.map((t, t_ind) => ({
            id: t.id ? String(t.id) : String(t_ind),
            columns: {
              id: t.id ? String(t.id) : String(t_ind),
              amount: t.baseAmount ?? '',
              currency: t.baseCurrency ?? '',
              transactionCode: t.transactionCode ?? '',
              transactionReference: t.transactionReference ?? '',
              createdBy: GetUser(t.createdBy ?? '', allUsers),
              createdOn: t.creationDate ?? '',
            },
          }));
          break;
        case 'alerts':
          const alertsResults = ((resultData ?? []) as SearchDatasetResponse['alerts']) ?? [];
          tableData = alertsResults.map((a, a_ind) => ({
            id: String(a.id) ?? String(a_ind),
            columns: {
              name: a.name ?? a.description ?? '',
              bestMatch: formatMatchingName(a.target?.[0].watchListPersons?.names?.[0]),
              bestMatchList: a.target?.[0].watchListPersons?.label ? <StatusTag tag_label={a.target?.[0].watchListPersons.label} hideActive={true} bg={'white'} /> : '',
              score: <GaugeMeter value={a.matchScore} />,
              status: <StatusTag tag_label={a.status?.description} active={a.status?.closeStatus} bg={'white'} />,
              alertId: (
                <Box display={'flex'} alignItems={'center'}>
                  <Typography fontSize="inherit" fontWeight="inherit" sx={{ color: 'inherit' }} mr={2}>
                    {a.id}
                  </Typography>
                  {/* <ShadowedButton
                    onClick={(e) => {
                      viewAlert(a.id);
                    }}
                    sx={{ whiteSpace: 'nowrap', ml: 'auto' }}
                  >
                    <FormattedMessage id="viewMore" defaultMessage="View More" />
                  </ShadowedButton> */}
                </Box>
              ),
            },
            unread: a.unread ?? false,
            rowClick: () => {
              viewAlert(a.id);
            },
          }));
          break;
        case 'persons':
        default:
          const personsResults = ((resultData ?? []) as SearchDatasetResponse['persons']) ?? [];
          tableData = personsResults.map((p, p_ind) => ({
            id: p.id ? String(p.id) : String(p_ind),
            columns: {
              id: p.id ? String(p.id) : '',
              dataId: p.dataID ? String(p.dataID) : '',
              fullName: formatMatchingName(p?.names?.[0]),
              entityType: (
                <Typography display={'inline-block'} fontSize={'0.825rem'} borderRadius={1.25} px={1} py={0.625} sx={{ backgroundColor: 'var(--color-lightgray2)', color: 'var(--color-gray2)' }}>
                  {p.entityType ?? ''}
                </Typography>
              ),
              createdBy: p.createdBy ? GetUser(p.createdBy ?? '', allUsers) : '',
              createdOn: p.creationDate ?? '',
              // history: (
              //   <Box display={'flex'} alignItems={'center'}>
              //     <IconButton aria-label="history" sx={{ mr: 0.5 }}>
              //       <ClockRotateLeftIcon sx={{ fontSize: '1rem', color: 'var(--color-lightgray4)' }} />
              //     </IconButton>
              //     <ShadowedButton
              //       onClick={(e) => {
              //         viewProfile();
              //       }}
              //       sx={{ whiteSpace: 'nowrap', ml: 'auto' }}
              //     >
              //       <FormattedMessage id="viewProfile" defaultMessage="View Profile" />
              //     </ShadowedButton>
              //   </Box>
              // ),
            },
            rowClick: () => {
              viewProfile(p);
            },
          }));
      }
    }
    return tableData;
  }, [resultData, dataset, viewProfile, viewAlert, allUsers, type]);

  // const selectBulkAction = (event: any) => {
  //   const selectValue = event.target.value;
  //   setBulkAction(selectValue);
  // };
  // const bulkActionChoices = useMemo(() => {
  //   return [
  //     // {
  //     //   key: 'editStatus',
  //     //   value: <FormattedMessage id="editStatus" defaultMessage="Edit Status" />,
  //     // },
  //     {
  //       key: 'setAsRead',
  //       value: <FormattedMessage id="setAsRead" defaultMessage="Set as Read" />,
  //       action: setAsRead,
  //     },
  //     {
  //       key: 'setAsUnread',
  //       value: <FormattedMessage id="setAsUnread" defaultMessage="Set as Unread" />,
  //       action: (selected: readonly string[]) => setAsRead?.(selected, true),
  //     },
  //   ];
  // }, [setAsRead]);

  // const applyBulk = () => {
  //   const selected = tableRef.current?.getSelected();
  //   if ((selected?.length ?? 0) <= 0) return;

  //   const action = bulkActionChoices.filter((ba) => ba.key === bulkAction)?.[0]?.action ?? null;

  //   if (action) {
  //     action(selected);
  //   }
  // };

  if (!searchResults) return <></>;

  return (
    <Box>
      <Box
        px={2}
        py={1}
        border={1}
        borderColor={'var(--color-grayHeaderBorder)'}
        display={'flex'}
        sx={{ flexFlow: 'row wrap', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}
        alignItems={'center'}
      >
        <Box flex="1">
          <Typography fontWeight={600} fontSize={'1.25rem'} sx={{ color: 'var(--color-darkgray)', textTransform: 'capitalize' }}>
            {/* <Typography component="span" fontWeight={'inherit'} fontSize={'inherit'} sx={{ color: 'var(--color-gray1)' }}> */}
            {dataset?.label}
          </Typography>
        </Box>
        <Box display="flex" alignItems={'center'}>
          {(searchResults.numberOfObjects ?? 0) > 0 && lastSearchQuery && <ExportButton {...{ exportResults }} />}
        </Box>
      </Box>

      {!searchResults.numberOfObjects && (
        <Box p={2} border={1} borderColor={'var(--color-grayHeaderBorder)'} borderTop={0} sx={{ borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px' }}>
          <Typography fontWeight={500} fontSize={'inherit'} sx={{ color: 'var(--color-gray1)' }}>
            0 <FormattedMessage id="result" defaultMessage="result" />
          </Typography>
        </Box>
      )}

      {(searchResults.numberOfObjects ?? 0) > 0 && resultData.length > 0 && (
        <Box>
          {/* <Box display="flex" alignItems={'center'} gap="1.5rem" mb={2}>
            <Box width={200} fontSize={'.875rem'}>
              <SearchSelect
                value={bulkAction}
                onChange={selectBulkAction}
                fullWidth
                choices={bulkActionChoices}
                placeholder={intl.formatMessage({ id: 'bulkActions', defaultMessage: 'Bulk Actions' })}
              />
            </Box>
            <ShadowedButton onClick={applyBulk} disabled={!bulkAction}>
              <FormattedMessage id="apply" defaultMessage="Apply" />
            </ShadowedButton>
          </Box> */}

          <TableResults ref={tableRef} hasBoxTitle={true} hasUnread={false} hasSelection={true} tableData={tableData} tableHead={tableHeaders} />
        </Box>
      )}
    </Box>
  );
});

export default InvestigateExplore;
