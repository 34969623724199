/**
 * AlertScreeningsProvider.ts
 */
/* packages */
import { useContext, createContext, useState, useMemo, useCallback, useRef } from 'react';

/* context */
import { useAuthenticatedRequest } from './AuthProvider';

/* utilities */
import { URLConstants } from 'common/URLconstants';
import { checkPermissions } from 'utilities/CheckUserPermissions';

/* types */
import { AlertScreeningType, DeleteAlertScreeningResponse, EditAlertScreeningResponse, ImportAlertScreeningReponse, ListAlertScreeningsResponse } from 'models/alertScreening';
import { UserContext } from './UserProvider';
import { StatusPromiseResponse } from 'models/utils';

interface AlertScreeningsContextType {
  loadingAlertScreenings?: boolean;
  alertScreenings?: AlertScreeningType[];
  listAlertScreenings?(abortController?: AbortController): void;
  addOrUpdateAlertScreenings?(data: Partial<AlertScreeningType>, create?: boolean, abortController?: AbortController): Promise<AlertScreeningType[] | undefined>;
  deleteAlertScreening?(alertScreeningId: number, abortController?: AbortController): Promise<DeleteAlertScreeningResponse | undefined>;
  exportAlertScreening?(alertScreening: AlertScreeningType, abortController?: AbortController): Promise<StatusPromiseResponse>;
  importAlertScreening?(file: File, alertScreening: AlertScreeningType): Promise<ImportAlertScreeningReponse>;
}
/* elements */
const AlertScreeningsContext = createContext<AlertScreeningsContextType>({});

const AlertScreeningsProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const { getAuthenticatedRequest, postAuthenticatedRequest, deleteAuthenticatedRequest } = useAuthenticatedRequest();
  const { permissions } = useContext(UserContext);

  const [loadingAlertScreenings, setLoadingAlertScreenings] = useState<boolean>(false);
  const [alertScreenings, setAlertSceenings] = useState<AlertScreeningType[] | undefined>(undefined);

  const listOngoing = useRef(false);
  const listAlertScreenings = useCallback(
    async (abortController?: AbortController) => {
      if (listOngoing.current) return;

      listOngoing.current = true;
      setLoadingAlertScreenings(true);
      setAlertSceenings(undefined);
      try {
        const alertScreeningsUrl = URLConstants.getAlertScreenings;
        const results = (await getAuthenticatedRequest(alertScreeningsUrl, abortController ?? undefined)) as ListAlertScreeningsResponse;

        setLoadingAlertScreenings(false);
        if (results.matchingRules) {
          setAlertSceenings(results.matchingRules);
        }
      } catch (searchError: any) {
        if (searchError?.code === 'ERR_CANCELED') return;

        setLoadingAlertScreenings(false);
        setAlertSceenings(undefined);
      }
      listOngoing.current = false;
    },
    [getAuthenticatedRequest]
  );
  const addOrUpdateAlertScreenings = useCallback(
    async (data: Partial<AlertScreeningType>, create = true, abortController?: AbortController): Promise<AlertScreeningType[] | undefined> => {
      // check permissions
      const allowed = checkPermissions('manageScreeningsAlert', permissions);
      if (!allowed) return undefined;

      try {
        const editAlertScreeningsUrl = create ? URLConstants.updateAlertScreening : URLConstants.updateAlertScreening;
        const results = (await postAuthenticatedRequest(editAlertScreeningsUrl, { matchingRules: [data] }, abortController ?? undefined)) as EditAlertScreeningResponse;

        const updatedAlertScreening = results.matchingRule;

        setAlertSceenings((currentAlertSceenings) => {
          if (!updatedAlertScreening) return currentAlertSceenings;
          if (!currentAlertSceenings) return updatedAlertScreening;

          const newAlertScreenings = [...currentAlertSceenings];

          updatedAlertScreening.forEach((AS) => {
            const folderIndex = currentAlertSceenings?.findIndex((r) => r.id === AS.id);
            if (folderIndex >= 0) {
              newAlertScreenings[folderIndex] = AS;
            } else {
              newAlertScreenings.push(AS);
            }
          });
          return newAlertScreenings;
        });

        return updatedAlertScreening;
      } catch (listError: any) {
        if (listError?.code === 'ERR_CANCELED') return undefined;

        throw new Error(listError);
      }
    },
    [postAuthenticatedRequest, permissions]
  );

  const deleteAlertScreening = useCallback(
    async (alertScreeningId: number, abortController?: AbortController): Promise<DeleteAlertScreeningResponse | undefined> => {
      // check permissions
      const allowed = checkPermissions('manageScreeningsAlert', permissions);
      if (!allowed) return undefined;

      try {
        const deleteAlertScreeningUrl = URLConstants.deleteAlertScreening + `${alertScreeningId}`;
        const results = (await deleteAuthenticatedRequest(deleteAlertScreeningUrl, abortController ?? undefined)) as DeleteAlertScreeningResponse;

        if (results.operationResult) {
          setAlertSceenings((currentAlertSceenings) => {
            return currentAlertSceenings?.filter((t) => t.id !== alertScreeningId);
          });
          return results;
        }

        throw new Error('operation failed');
      } catch (listError: any) {
        if (listError?.code === 'ERR_CANCELED') return undefined;

        throw new Error(listError);
      }
    },
    [deleteAuthenticatedRequest, permissions]
  );

  const exportAlertScreening = useCallback(async (alertScreening: AlertScreeningType, abortController?: AbortController): Promise<StatusPromiseResponse> => {
    if (!alertScreening) return { status: 'error' };

    console.log('NYI');
    return { status: 'error' };
    // if (!dataset.sourceOfDataType)

    // const exportUrl = URLConstants.datasetExport;
    // const filename = `${dataset.label.replaceAll(' ', '_') ?? 'dataset'}.zip`;

    // try {
    //   const response = (await postAuthenticatedRequest(
    //     exportUrl,
    //     {
    //       [dataset.sourceOfDataType]: { dataset: { id: dataset.id } },
    //     },
    //     undefined,
    //     { blob: true }
    //   )) as BlobPart;

    //   const downloadUrl = window.URL.createObjectURL(new Blob([response]));
    //   const link = document.createElement('a');
    //   link.href = downloadUrl;
    //   link.setAttribute('download', filename); //any other extension
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    //   return { status: 'success' };
    // } catch (ExportError) {
    //   return { status: 'error' };
    // }
  }, []);

  const importAlertScreening = useCallback(async (file: File, alertScreening: AlertScreeningType): Promise<ImportAlertScreeningReponse> => {
    console.log('import NYI');
    throw Error('NYI');
    // const importDatasetUrl = URLConstants.datasetImport;

    // const formData = new FormData();

    // formData.append('upload_file', file, file.name);
    // formData.append('dataset', String(dataset.id));
    // try {
    //   const result = (await postAuthenticatedRequest(importDatasetUrl, formData, undefined, {
    //     contentType: 'multipart/form-data',
    //     skipStringify: true,
    //   })) as ImportDatasetReponse;
    //   return result;
    // } catch (uploadError: any) {
    //   // console.error(uploadError);
    //   throw uploadError;
    // }
  }, []);

  const outputValue = useMemo(
    (): AlertScreeningsContextType => ({
      loadingAlertScreenings,
      alertScreenings,
      listAlertScreenings,
      addOrUpdateAlertScreenings,
      deleteAlertScreening,
      exportAlertScreening,
      importAlertScreening,
    }),
    [loadingAlertScreenings, alertScreenings, listAlertScreenings, addOrUpdateAlertScreenings, deleteAlertScreening, exportAlertScreening, importAlertScreening]
  );
  return <AlertScreeningsContext.Provider value={outputValue}>{children}</AlertScreeningsContext.Provider>;
};

/* exports */
export { AlertScreeningsProvider, AlertScreeningsContext };
