/**
 * user.ts
 * Define user
 */
import { ApiResponse } from './axiosRequest';
import { TeamType } from './teams';
import { DateString, DateStringISO } from './utils';
import { PermissionString } from 'utilities/CheckUserPermissions';

export const prependBase64Photo = 'data:image/png;base64,';

// User
export interface UserType extends ApiResponse {
  id: number;
  userName: string;
  firstname?: string;
  lastname?: string;
  password?: string;
  photo?: string; //base64
  email?: string;
  apiKey?: string;
  creationDate?: DateString;
  createdBy?: string;
  updatedDate?: DateString;
  updatedBy?: string;
  deleted?: boolean;
  groups?: TeamType[];
  roles?: RoleType[];
  organizationUnits?: OrganizationUnit[];
  amountOfFalseLogin?: number;
  dateOfPasswordNextExpiry?: DateString;
  locked?: boolean;
  deactivated?: boolean;
  deactivatedDtg?: DateStringISO;

  userPreferences?: {
    id: number;
    maxAmountOfRowForGrid: number;
  };

  fullName?: string;
  isAdmin?: boolean;
}

// Role
export interface RoleType {
  id: number;
  name: string;
  description?: string;
  creationDate?: DateString;
  createdBy?: string;
  updatedDate?: DateString;
  updatedBy?: string;
  deleted?: boolean;
  permissions: PermissionString[];
  // teams?: TeamType[];
}

// Organisation Unit
export interface OrganizationUnit {
  id: number;
  name: string;
  code: string;
  apiKey?: string;
  description?: string;
  creationDate?: DateString;
  createdBy?: string;
  updatedDate?: DateString;
  updatedBy?: string;
  deleted?: boolean;
  defaultSearchScore?: number;
  parent?: OrganizationUnit;
}

export interface EditUserResponse extends ApiResponse {
  user: UserType;
}
export interface DeleteUserResponseType extends ApiResponse {
  operationResult: boolean;
}

export interface DeleteAvatarResponseType extends ApiResponse {
  operationResult: boolean;
}

export interface UploadAvatarReponse extends ApiResponse {}
