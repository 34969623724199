/**
 * Pagination.tsx
 * generate a bottom pagination (table or list)
 */
/* packages */
import { FormattedMessage } from 'react-intl';

/* contexts */

/* hooks */

/* components */
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';

import Divider from '@mui/material/Divider';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import ShadowedButton from 'components/ShadowedButton/ShadowedButton';
import { ChevronIcon } from 'icons/chevron/chevron';

/* types */
interface PaginationType {
  maxPerPage: number;
  currentPage: number;
  nbResults: number;
  setMaxAndCallback(event: SelectChangeEvent): void;
  changePageCallback(shift: 1 | -1): void;
}

/* elements */
export const numberPerPages = [10, 30, 50];

const Pagination = (props: PaginationType) => {
  const { maxPerPage, currentPage, nbResults, setMaxAndCallback, changePageCallback } = props;

  const hasPrevious = currentPage > 0;
  const hasNext = (currentPage + 1) * maxPerPage < nbResults;

  return (
    <Box border={1} borderColor={'var(--color-grayHeaderBorder)'} px={2} py={2} sx={{ borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px' }}>
      <Box display={'flex'} gap={'2rem'} sx={{ flexFlow: 'row wrap' }}>
        <Box display={'flex'} flex={1} alignItems={'center'} gap={'1rem'} sx={{ flexFlow: 'row wrap', color: 'var(--color-gray2)', fontSize: 'var(--fs-14)' }}>
          <Box>
            <Typography color={'inherit'} whiteSpace={'nowrap'} sx={{ fontSize: 'inherit', lineHeight: 1.2, letterSpacing: '0.01em', color: '#151414' }}>
              <Box component={'span'} fontWeight={500}>
                {currentPage * maxPerPage + 1}-{Math.min((currentPage + 1) * maxPerPage, nbResults)}
              </Box>{' '}
              <FormattedMessage id="of" defaultMessage="of" />{' '}
              <Box component={'span'} fontWeight={500}>
                {nbResults}
              </Box>{' '}
              {nbResults > 1 ? <FormattedMessage id="results" defaultMessage="results" /> : <FormattedMessage id="result" defaultMessage="result" />}
            </Typography>
          </Box>
          <Divider orientation="vertical" flexItem sx={{ borderColor: 'var(--color-grayHeaderBorder)' }} />

          <Box display={'flex'} flex={1} gap={'5px'} alignItems={'center'} sx={{ fontSize: 'var(--fs-14)', lineHeight: 1.2, letterSpacing: '0.01em', color: '#151414' }}>
            <Typography color={'inherit'} sx={{ fontSize: 'inherit', lineHeight: 'inherit' }}>
              <FormattedMessage id="Show" defaultMessage="Show" />{' '}
            </Typography>

            <Select
              name={'pagination-select'}
              size="small"
              value={String(maxPerPage)}
              onChange={setMaxAndCallback}
              sx={{
                color: 'inherit',
                fontSize: 'inherit',
                '& .MuiSelect-select.MuiSelect-outlined': {
                  paddingBlock: '6px',
                  fontWeight: 500,
                },
              }}
              IconComponent={(_props) => {
                const rotate = _props.className.toString().includes('iconOpen');

                return <ChevronIcon sx={{ fontSize: '10px', rotate: rotate ? '90deg' : '-90deg', position: 'absolute', right: '7px', top: 'calc(50% - 5px)', pointerEvents: 'none' }} />;
              }}
            >
              {numberPerPages.map((npp) => (
                <MenuItem key={npp} value={Number(npp)}>
                  {npp}
                </MenuItem>
              ))}
            </Select>

            <Typography color={'inherit'} whiteSpace={'nowrap'} sx={{ fontSize: 'inherit', lineHeight: 'inherit' }}>
              <FormattedMessage id="resultsPerPage" defaultMessage="results per page" />
            </Typography>
          </Box>
        </Box>

        <Box display={'flex'} gap={'1rem'} alignItems={'center'}>
          {hasPrevious && (
            <ShadowedButton
              onClick={() => {
                changePageCallback(-1);
              }}
            >
              <FormattedMessage id="Previous" defaultMessage="Previous" />
            </ShadowedButton>
          )}
          {hasNext && (
            <ShadowedButton
              onClick={() => {
                changePageCallback(1);
              }}
            >
              <FormattedMessage id="Next" defaultMessage="Next" />
            </ShadowedButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Pagination;
