/**
 * DocumentsContent.tsx
 * show the documents content of a single alert page
 */
/* packages */
import { useRef, CSSProperties, memo, useCallback, useContext, useState, SyntheticEvent, DragEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import dayjs from 'dayjs';

/*  contexts */
import { AlertContext } from './alertProvider';
import { AllUsersContext } from 'contextProviders/AllUsersProvider';
import { useAuthenticatedRequest } from 'contextProviders/AuthProvider';
import { useAddSnackbar } from 'contextProviders/SnackbarProvider';

/* components */
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import ShadowedButton from 'components/ShadowedButton/ShadowedButton';
import Loader from 'components/Loader/Loader';

import { RefreshIcon } from 'icons/refresh/refresh';
import { FileIcon } from 'icons/file/file';
import { PaperClipSideIcon } from 'icons/paperClipSide/paperClipSide';
import { ExportIcon } from 'icons/export/export';
import { CrossFilledIcon } from 'icons/crossFilled/crossFilled';

import DocumentUploadBackgroud from './documentUploadBackground.svg';

/* utilities */
import { GetUser } from 'components/GetUser/GetUser';
import { URLConstants } from 'common/URLconstants';

/* types */
import { FileType } from 'models/file';
import { DateStringFormat } from 'models/utils';

/* elements */
const DocumentsContent = () => {
  const { alert, loadingDocuments, documents, listAlertDocuments } = useContext(AlertContext);

  const refreshDocuments = useCallback(() => {
    if (!alert) return;
    listAlertDocuments?.(alert.id);
  }, [alert, listAlertDocuments]);

  if (loadingDocuments) {
    return (
      <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <Loader />
      </Box>
    );
  }
  if (!documents) {
    return (
      <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <Button sx={{ color: 'var(--color-gray2)', flexDirection: 'column' }} onClick={refreshDocuments}>
          <RefreshIcon sx={{ mb: 2 }} />
          <Box>
            <Typography>
              <FormattedMessage id="searchDatasetRefresh" defaultMessage="An error occured." />
            </Typography>
            <Typography>
              <FormattedMessage id="tryAgain" defaultMessage="Please try again." />
            </Typography>
          </Box>
        </Button>
      </Box>
    );
  }

  return <DocumentsContainer documents={documents} />;
};

const DocumentsContainer = memo(({ documents }: { documents?: FileType[] }) => {
  return (
    <Box>
      {/* <Typography fontSize={'1.25rem'} fontWeight={600}>
        <FormattedMessage id="Documents" defaultMessage={'Documents'} />
      </Typography> */}

      <Box sx={{ maxWidth: 900, margin: 'auto' }}>
        <UploadDocument />

        <DocumentsList {...{ documents }} />
      </Box>
    </Box>
  );
});

export const allowedExtension = ['.docx', '.jpg', '.pdf', '.webp', '.xlsx', '.csv', '.txt', '.xls', '.zip'];
// const byteSize = 1048576;
// export const maxFileSize = 50 * 1000 * 1000; //50Mo
export const maxFileSize = 1 * 1000 * 1000; //50Mo

const getFileFromDrag = (event: DragEvent<HTMLDivElement>): File | null => {
  let dragFiles: (File | null)[];
  if (event.dataTransfer.items) {
    const items = Array.from(event.dataTransfer.items);
    dragFiles = items.filter((item) => item.kind === 'file').map((item) => item.getAsFile());
  } else {
    // Use DataTransfer interface to access the file(s)
    dragFiles = Array.from(event.dataTransfer.files);
  }

  if (dragFiles && dragFiles.length > 0 && dragFiles[0]) {
    return dragFiles[0];
  }
  return null;
};

export const clearRegularInput = (inputElement?: HTMLInputElement) => {
  if (!inputElement) return;

  const emptyFiles = new DataTransfer();
  inputElement.files = emptyFiles.files;
};

const UploadDocument = memo(() => {
  const addSnackbar = useAddSnackbar();
  const intl = useIntl();
  const { alert, uploadAlertDocument } = useContext(AlertContext);

  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  const [draging, setDraging] = useState<boolean>(false);
  const [fileError, setFileError] = useState<string | null>(null);
  const [uploadingFile, setUploadingFile] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const fileFormRef = useRef<HTMLFormElement>(null);

  const clearInputs = useCallback(() => {
    setSelectedFile(undefined);
    if (!inputRef.current) return;
    clearRegularInput(inputRef.current);
  }, []);

  const validateFile = useCallback(
    (receivedFile: File | null | undefined) => {
      if (!receivedFile) {
        return clearInputs();
      }

      const fileExtension = receivedFile.name.split('.').pop();
      if (!allowedExtension.includes(`.${fileExtension}`)) {
        const fileErrorMessage = intl.formatMessage({
          id: 'fileInvalidExtension',
          defaultMessage: 'Invalid file type',
        });
        addSnackbar(fileErrorMessage, 'error');

        setFileError(fileErrorMessage);

        return clearInputs();
      }

      if (receivedFile.size > maxFileSize) {
        const fileErrorMessage = intl.formatMessage({
          id: 'fileSizeTooLarge',
          defaultMessage: 'File size too large',
        });
        addSnackbar(fileErrorMessage, 'error');
        setFileError(fileErrorMessage);

        return clearInputs();
      }
      setSelectedFile(receivedFile);
    },
    [intl, addSnackbar, clearInputs]
  );

  const checkFile = useCallback(
    (event: SyntheticEvent) => {
      const target = event.target as HTMLInputElement;
      const receivedFile = target.files?.[0];
      setFileError(null);
      validateFile(receivedFile);
    },
    [validateFile]
  );

  const sendFile = useCallback(async () => {
    if (uploadingFile) return;
    if (!fileFormRef.current) return;
    if (!uploadAlertDocument) return;
    if (!alert || !alert.id) return;
    if (!selectedFile) return;

    setUploadingFile(true);

    const uploadData = new FormData();

    const file = selectedFile;
    uploadData.append('file', file, file.name);

    uploadData.append('alertId', String(alert.id));
    uploadData.append('description', '');

    try {
      await uploadAlertDocument(uploadData);
      setUploadingFile(false);
      clearInputs();
    } catch (uploadError) {
      const uploadErrorMessage = intl.formatMessage({
        id: 'uploadError',
        defaultMessage: 'An error occured while upload your file',
      });
      addSnackbar(uploadErrorMessage, 'error');
      setUploadingFile(false);
    }
  }, [alert, uploadAlertDocument, addSnackbar, intl, uploadingFile, selectedFile, clearInputs]);

  const handleDragStart = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      if (uploadingFile) return;

      setFileError(null);
      setDraging(true);

      // browser limitations : we can't get file information during drag
      // const dragFile = getFileFromDrag(event);
      // if (!dragFile) return;
      // validateFile(dragFile)
    },
    [uploadingFile]
  );

  const handleDragLeave = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      if (uploadingFile) return;

      setDraging(false);
    },
    [uploadingFile]
  );

  const handleDragOver = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  }, []);

  const handleDrop = useCallback(
    (event: DragEvent<HTMLDivElement>) => {
      event.preventDefault();
      if (uploadingFile) return;

      setDraging(false);
      const dragFile = getFileFromDrag(event);

      if (!dragFile) return;
      validateFile(dragFile);
    },
    [uploadingFile, validateFile]
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent={'center'}
      // mt={2}
      p={4}
      sx={{ height: 350, backgroundColor: 'var(--color-lightgray6)', border: '1px solid var(--color-grayHeaderBorder)', borderRadius: '5px' }}
      onDragEnter={handleDragStart}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <form name="upload-file-form" ref={fileFormRef} style={{ width: '100%', pointerEvents: draging ? 'none' : 'all' }}>
        <Input
          id="fileDnD"
          onChange={checkFile}
          disableUnderline
          type={'file'}
          name={'fileDnD'}
          inputProps={{
            ref: inputRef,
            accept: allowedExtension.join(', '),
            hidden: true,
            sx: {
              display: 'none',
            },
          }}
        />

        <Typography textAlign={'center'} fontSize={'var(--fs-16)'} fontWeight={'medium'} sx={{ pointerEvents: 'none' }}>
          <FormattedMessage id="addNewDocument" defaultMessage={'Add a new document'} />
        </Typography>

        {draging ? (
          <Box
            display="flex"
            py={'50px'}
            m={'auto'}
            mt={2}
            sx={{ color: 'red', pointerEvents: 'none', maxWidth: 'min(450px,80%)', borderRadius: '5px', backgroundImage: `url(${DocumentUploadBackgroud})` }}
          >
            <Typography flex={1} textAlign="center" sx={{ minWidth: 0, fontSize: 'var(--fs-14)', color: 'var(--color-gray2)' }}>
              <FormattedMessage id="dropHere" defaultMessage="Drop your file here" />
            </Typography>
          </Box>
        ) : selectedFile ? (
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Typography textAlign={'center'} fontSize={'var(--fs-14)'} sx={{ color: 'var(--color-gray2)' }}>
              <FormattedMessage id="selected" defaultMessage={'Selected file'} />
            </Typography>

            <Box
              className="button-box-shadow"
              display="flex"
              alignItems={'center'}
              mt={2}
              px={2}
              py={0.5}
              sx={{ maxWidth: 'min(450px,80%)', background: 'white', borderRadius: '5px', border: '1px solid var(--color-grayHeaderBorder)' }}
            >
              <PaperClipSideIcon sx={{ mr: 1, fontSize: 'var(--fs-14)', color: 'var(--color-gray2)' }} />
              <Typography flex={1} sx={{ minWidth: 0, fontSize: 'var(--fs-12)', color: 'var(--color-gray2)', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                {selectedFile.name}
              </Typography>

              <IconButton disabled={uploadingFile} onClick={clearInputs} sx={(theme) => ({ ml: 1, color: 'var(--color-gray2)', '&:hover': { color: theme.palette.red.main } })}>
                <CrossFilledIcon sx={{ fontSize: 'var(--fs-12)', color: 'inherit' }} />
              </IconButton>
            </Box>
            <Box mt={3} display="flex" gap={'2rem'}>
              <ShadowedButton onClick={clearInputs} disabled={uploadingFile}>
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </ShadowedButton>

              <Button variant="contained" onClick={sendFile} disabled={uploadingFile} sx={{ fontSize: '.8rem', lineHeight: 1.5 }}>
                <FormattedMessage id="send" defaultMessage="Send" />
              </Button>
            </Box>
          </Box>
        ) : (
          <>
            <Typography textAlign={'center'} fontSize={'var(--fs-14)'} sx={{ color: 'var(--color-gray2)' }}>
              <FormattedMessage id="dragAndDrop" defaultMessage={'Drag and drop or select a file'} />
            </Typography>

            <Box display={'flex'} justifyContent={'center'} mt={3}>
              <label htmlFor={'fileDnD'}>
                <ShadowedButton component={'span'}>
                  <PaperClipSideIcon sx={{ fontSize: '1rem' }} />
                  <FormattedMessage id="UploadDocument" defaultMessage="Upload document" />
                </ShadowedButton>
              </label>
            </Box>

            <Typography mt={4} textAlign={'center'} fontSize={'var(--fs-12)'} sx={{ color: 'var(--color-lightgray5)' }}>
              <FormattedMessage id="uploadLimit" defaultMessage={'Upload a file up to 50MB of the following types:'} />
            </Typography>
            <Typography textAlign={'center'} fontSize={'var(--fs-12)'} sx={{ color: 'var(--color-lightgray5)' }}>
              {allowedExtension.join(', ')}
            </Typography>
            {fileError && (
              <Typography mt={1} textAlign={'center'} fontWeight={'medium'} fontSize={'var(--fs-12)'} sx={{ color: 'var(--color-fushia)' }}>
                {fileError}
              </Typography>
            )}
          </>
        )}
      </form>
    </Box>
  );
});
const DocumentsList = memo(({ documents }: { documents?: FileType[] }) => {
  return (
    <Box mt={2} display="grid" gap={'1rem'} gridAutoFlow={'columns'} sx={{ gridTemplateColumns: { xs: '1fr', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' } }}>
      {documents?.map((d) => {
        return <DocumentElement key={d.id} document={d} />;
      })}
    </Box>
  );
});

const thumbnailStyle: CSSProperties = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  objectPosition: 'center top',
};
export const DocumentElement = memo(({ document }: { document: FileType }) => {
  const { allUsers } = useContext(AllUsersContext);
  const [downloadingDocument, setDownloadingDocument] = useState<boolean>(false);
  const { getAuthenticatedRequest } = useAuthenticatedRequest();
  const addSnackbar = useAddSnackbar();
  const intl = useIntl();

  const dowloadDocument = useCallback(
    async (open = false) => {
      setDownloadingDocument(true);

      try {
        const downloadFileUrl = URLConstants.downloadFile + `${document.id}`;
        const response = (await getAuthenticatedRequest(downloadFileUrl, undefined, true)) as Blob;

        const windowDocument = window.document;

        if (open) {
          let downloadUrl;
          if (document.fileName?.endsWith('.pdf')) {
            downloadUrl = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
          } else {
            downloadUrl = window.URL.createObjectURL(response);
          }

          const link = windowDocument.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('rel', 'noreferrer');
          link.setAttribute('target', '_blank');
          windowDocument.body.appendChild(link);
          link.click();
          windowDocument.body.removeChild(link);
        } else {
          const downloadUrl = window.URL.createObjectURL(response);

          const link = windowDocument.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', document.fileName ?? 'file'); //any other extension
          windowDocument.body.appendChild(link);
          link.click();
          windowDocument.body.removeChild(link);
          window.URL.revokeObjectURL(downloadUrl);
        }
      } catch (ExportError) {
        console.warn(ExportError);
        addSnackbar(
          intl.formatMessage({
            id: 'downloadDocumentError',
            defaultMessage: 'An error while downloading your document',
          }),
          'error'
        );
      }
      setDownloadingDocument(false);
    },
    [document, getAuthenticatedRequest, addSnackbar, intl]
  );

  const initDownload = useCallback(() => {
    dowloadDocument();
  }, [dowloadDocument]);
  const initView = useCallback(() => {
    dowloadDocument(true);
  }, [dowloadDocument]);

  return (
    <Box p={1} borderRadius={'5px'} sx={{ minWidth: 0, border: '1px solid var(--color-grayHeaderBorder)' }}>
      <Box sx={{ width: '100%', aspectRatio: '2/1', backgroundColor: 'var(--color-lightgray2)', border: '1px solid var(--color-grayHeaderBorder)' }}>
        {document.thumbnail ? (
          <img src={`data:image/png;base64, ${document.thumbnail}`} alt={''} style={thumbnailStyle} />
        ) : (
          <Box display="flex" justifyContent={'center'} alignItems={'center'} sx={{ height: '100%' }}>
            <FileIcon sx={{ color: 'var(--color-lightgray4)', fontSize: '2rem' }} />
          </Box>
        )}
      </Box>
      <Box mt={1}>
        <Typography fontSize={'0.875rem'} fontWeight={'medium'} sx={{ color: 'var(--color-darkgray)', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {document.fileName}
        </Typography>
      </Box>
      <Box mt={0} display="flex" alignItems={'center'}>
        {document.creationDate && document.createdBy && (
          <>
            <Typography component="span" fontSize={'var(--fs-10)'} fontWeight={'medium'} sx={{ color: 'var(--color-lightgray5)', whiteSpace: 'nowrap' }}>
              <FormattedMessage id="Effective" defaultMessage={'Effective'} /> {dayjs(document.creationDate, DateStringFormat).format('MMM. DD, YYYY')}
            </Typography>
            <Box display="inline-flex" sx={{ aspectRatio: '1/1', width: '3px', ml: '5px', mr: '5px', backgroundColor: 'var(--color-lightgray5)', borderRadius: '50%' }}></Box>
            <Box sx={{ minWidth: 0, fontSize: 'var(--fs-10)', color: 'var(--color-lightgray5)', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} component={'span'}>
              {GetUser(document.createdBy, allUsers, false)}
            </Box>
          </>
        )}
      </Box>

      <Box mt={2} display={'flex'} sx={{ flexFlow: 'row wrap' }} gap={'.5rem'}>
        <ShadowedButton onClick={initView} disabled={downloadingDocument}>
          <FormattedMessage id="View" defaultMessage="View" />
        </ShadowedButton>
        <ShadowedButton onClick={initDownload} disabled={downloadingDocument}>
          <ExportIcon fontSize="inherit" sx={{ mr: 1 }} />
          <FormattedMessage id="Download" defaultMessage="Download" />
        </ShadowedButton>

        {/* <Button color={'darkgray'} sx={{ minWidth: 0, ml: 'auto' }}>
          ...
        </Button> */}
      </Box>
    </Box>
  );
});

export default DocumentsContent;
