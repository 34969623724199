/**
 * InvestigateAlert.tsx
 */
/* packages */
import { useState, useCallback, PropsWithChildren, memo, useMemo, useRef, useContext, useEffect, ReactNode, Fragment } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';

/* contexts */
import { AllUsersContext } from 'contextProviders/AllUsersProvider';
import { useAuthenticatedRequest } from 'contextProviders/AuthProvider';
import { useAddSnackbar } from 'contextProviders/SnackbarProvider';
import { TeamsContext } from 'contextProviders/TeamsProvider';
import { useAddModal } from 'contextProviders/ModalProvider';
import { AlertContext, AlertProvider, EditAlertStatusPayload } from 'components/InvestigateAlert/alertProvider';

/* hooks */

/* components */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import Loader from 'components/Loader/Loader';
import PageHeader from 'components/PageHeader/PageHeader';
import PageTitle from 'components/PageTitle/PageTitle';

import GaugeMeter from 'components/GaugeMeter/GaugeMeter';

import TabsNavigation from 'components/TabsNavigation/TabsNavigation';

import TableResults from 'components/TableResults/TableResults';
import { TableHeadElement } from 'components/TableResults/TableHead';
import { TableRowData } from 'components/TableResults/TableRow';

import GeneralContent from 'components/InvestigateAlert/GeneralContent';
import DocumentsContent from 'components/InvestigateAlert/DocumentsContent';
import { WorkflowContent } from 'components/InvestigateAlert/WorkflowContent';

import ShadowedButton from 'components/ShadowedButton/ShadowedButton';
import StatusTag from 'components/StatusTag/StatusTag';

import { ExportIcon } from 'icons/export/export';
import { RefreshIcon } from 'icons/refresh/refresh';
import { CrossIcon } from 'icons/cross/cross';

import SearchText from 'components/SearchElements/SearchText/SearchText';
import SwitchWithLabel from 'components/SearchElements/SearchText/SearchSwitch';
import SearchSelect from 'components/SearchElements/SearchText/SearchSelect';
import { SelectChangeEvent } from '@mui/material/Select';

import { usePageModal } from 'contextProviders/PageModalProvider';

/* utilities */
import { GetTeam, GetUser } from 'components/GetUser/GetUser';
import { URLConstants } from 'common/URLconstants';

/* types */
import { DateStringFormat } from 'models/utils';
import { Alert, SingleAlertResponse, SearchSetAsReadQuery } from 'models/alerts';
import { formatMatchingName } from 'models/matchingData';
import { UserType } from 'models/user';
import { TeamType } from 'models/teams';

interface AlertContentProps {
  alertId: number;
  alert?: Alert;
  listAlertDetails?(alertId?: string, abortController?: AbortController): Promise<void>;
  activeTab: string;
  handleTabChange(_: React.SyntheticEvent, tabValue: 'string'): void;
  changeAlertStatus(payload: EditAlertStatusPayload): void;
  onUpdateAlert?: InvestigateAlertProps['onUpdateAlert'];
}

interface AlertLayoutProps {
  alert?: Alert;
  alertId: number;
  asModal?: boolean;
  changeAlertStatus(payload: EditAlertStatusPayload): void;
}

interface EditStatusOrOwnerProps {
  alert: Alert;
  setAction(payload: SetActionPayload): void;
  editOwner?: boolean;
  allUsers?: UserType[];
  teams?: TeamType[];
}

interface SetActionPayload {
  newStatusId: number;
  comment: string;
  editOwner?: boolean;
  userType?: 'user' | 'group' | null;
  userId?: number | '';
}

// interface AlertRefType {
//   sendAlert?(): void;
// }
interface InvestigateAlertProps {
  alertId?: string;
  asModal?: boolean;
  onUpdateAlert?(alert: Alert): void;
}
// /* elements */
const InvestigateAlert = ({ alertId, asModal, onUpdateAlert }: InvestigateAlertProps) => {
  // const { alertId } = useParams();

  return (
    <AlertProvider>
      <InvestigateAlertContainer alertId={alertId} asModal={asModal} onUpdateAlert={onUpdateAlert} />
    </AlertProvider>
  );
};

const InvestigateAlertContainer = ({ alertId, asModal, onUpdateAlert }: { alertId?: string; asModal?: boolean; onUpdateAlert?: InvestigateAlertProps['onUpdateAlert'] }) => {
  const addSnackbar = useAddSnackbar();
  const intl = useIntl();
  const { getAuthenticatedRequest, postAuthenticatedRequest } = useAuthenticatedRequest();
  // const { closeModal } = useAddModal();

  const { loadingTeams } = useContext(TeamsContext);
  const { loadingAllUsers } = useContext(AllUsersContext);

  const [loadingAlert, setLoadingAlert] = useState<boolean>(true);
  // const [alert, setAlert] = useState<Alert | undefined>(undefined);

  const { alert, defineAlert, listAlertDocuments, editAlertStatus } = useContext(AlertContext);

  const [activeTab, setActiveTab] = useState<string>('');

  const listingAlertDetails = useRef(false);

  const handleTabChange = useCallback((_: React.SyntheticEvent, tabValue: 'string') => {
    const newTab = tabValue;
    setActiveTab(newTab);
  }, []);

  const listAlertDetails = useCallback(
    async (alertId?: string, abortController?: AbortController) => {
      if (listingAlertDetails.current) return;
      if (!alertId) return;

      listingAlertDetails.current = true;

      setLoadingAlert(true);
      // setAlert(undefined);
      defineAlert?.(undefined);

      try {
        const getAlertUrl = URLConstants.getSingleAlert + `${alertId}`;
        const result = (await getAuthenticatedRequest(getAlertUrl, abortController)) as SingleAlertResponse;

        const alertResult = result.alert ?? undefined;
        // setAlert(alertResult);
        defineAlert?.(alertResult);
      } catch (searchError) {
        addSnackbar(
          intl.formatMessage({
            id: 'searchRequestError',
            defaultMessage: 'An error occured for your request',
          }),
          'error'
        );
      }

      setLoadingAlert(false);

      listingAlertDetails.current = false;
    },
    [addSnackbar, intl, getAuthenticatedRequest, defineAlert]
  );

  const changeAlertStatus = useCallback(
    async (payload: EditAlertStatusPayload) => {
      setLoadingAlert(true);

      try {
        await editAlertStatus?.(payload);
      } catch {
        addSnackbar(
          intl.formatMessage({
            id: 'editStatusError',
            defaultMessage: 'An error occured while editing alert status',
          }),
          'error'
        );
      }
      setLoadingAlert(false);
    },
    [editAlertStatus, addSnackbar, intl]
  );

  useEffect(() => {
    //const abortController = new AbortController();
    listAlertDetails(alertId);
    listAlertDocuments?.(Number(alertId));
    // return () => {
    //   abortController.abort();
    // };
  }, [alertId, listAlertDetails, listAlertDocuments]);

  // set alert as read on open
  useEffect(() => {
    if (!alert || !alert.id || !alert.unread) return;

    const setAsRead = async () => {
      const payload: SearchSetAsReadQuery = {
        alertIds: [alert.id],
      };

      const unreadUrl = URLConstants.alertSetAsRead;
      await postAuthenticatedRequest(unreadUrl, payload);
    };

    setAsRead();
  }, [alert, postAuthenticatedRequest]);

  // propagate alert on update
  useEffect(() => {
    if (!alert || !alert.id) return;
    onUpdateAlert?.(alert);
  }, [alert, onUpdateAlert]);

  const content = useMemo(() => {
    if (loadingAlert || loadingAllUsers || loadingTeams) {
      // closeModal?.();
      return (
        <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Loader />
        </Box>
      );
    }
    return <AlertContent alert={alert} alertId={Number(alertId)} {...{ listAlertDetails, activeTab, handleTabChange, changeAlertStatus }} />;
  }, [loadingAlert, loadingTeams, loadingAllUsers, alert, alertId, listAlertDetails, handleTabChange, activeTab, changeAlertStatus]);

  return (
    <Box display="flex" flexDirection="column" sx={{ '--main-inline-padding': 0, px: '50px', height: '100%' }}>
      <InvestigateAlertLayout alert={alert} alertId={Number(alertId)} asModal={asModal} changeAlertStatus={changeAlertStatus}>
        {content}
      </InvestigateAlertLayout>
    </Box>
  );
};

const InvestigateAlertLayout = memo(({ alert, alertId, asModal, changeAlertStatus, children }: PropsWithChildren<AlertLayoutProps>) => {
  // const { permissions } = useContext(UserContext);
  const { closePageModal } = usePageModal();
  const intl = useIntl();
  const { toggleModal } = useAddModal();
  const { allUsers } = useContext(AllUsersContext);
  const { teams } = useContext(TeamsContext);

  const setStatus = useCallback(
    (payload: SetActionPayload) => {
      changeAlertStatus?.({
        newStatusId: payload.newStatusId,
        comment: payload.comment,
        alertId: alertId,
        editUser: false,
      });
    },
    [changeAlertStatus, alertId]
  );

  const handleEditStatus = useCallback(() => {
    if (!alert) return;
    toggleModal?.({
      title: intl.formatMessage({ id: 'editAlertStatus', defaultMessage: 'Edit alert status' }),
      modalContent: <EditStatusOrOwner {...{ alert, allUsers, teams, setAction: setStatus }} />,
    });
  }, [intl, toggleModal, alert, setStatus, allUsers, teams]);

  return (
    <>
      <PageHeader>
        {asModal && (
          <Box>
            <IconButton color={'gray2'} className={'button-trigger'} sx={{ fontSize: '1rem' }} onClick={closePageModal}>
              {<CrossIcon fontSize="inherit" />}
            </IconButton>
          </Box>
        )}
        <Box flex={1}>
          <PageTitle
            title={
              <Box component={'span'}>
                <FormattedMessage id="investigateAlertTitle" defaultMessage="Alert" />{' '}
                <Box component="span" sx={{ color: 'var(--color-lightgray5)' }}>
                  {alertId ? `#${alertId}` : ''}
                </Box>
              </Box>
            }
          />
        </Box>

        <Box>
          {alert && (
            <>
              <ShadowedButton>
                <ExportIcon fontSize="inherit" sx={{ mr: 1 }} />
                <FormattedMessage id="exportAlert" defaultMessage="Export alert" />
              </ShadowedButton>

              {alert.status?.nextSteps && (
                <Button
                  type="button"
                  variant="contained"
                  disableElevation
                  onClick={handleEditStatus}
                  sx={{
                    ml: 1,
                    '.MuiButton-startIcon svg': {
                      fontSize: '12px',
                    },
                  }}
                >
                  <FormattedMessage id="EditStatus" defaultMessage="Edit status" />
                </Button>
              )}
            </>
          )}
        </Box>
      </PageHeader>
      {children}
    </>
  );
});

const TableResultsMemo = memo(TableResults);

const AlertContent = memo(({ alert, alertId, listAlertDetails, activeTab, handleTabChange, changeAlertStatus }: AlertContentProps) => {
  const intl = useIntl();
  const { allUsers } = useContext(AllUsersContext);
  const { teams } = useContext(TeamsContext);

  const { toggleModal } = useAddModal();

  const refreshAlert = useCallback(() => {
    listAlertDetails?.(String(alertId));
  }, [alertId, listAlertDetails]);

  const tabs = useMemo(
    () => [
      {
        text: 'Workflow',
        value: 'workflow',
      },
      {
        text: 'Documents',
        value: 'documents',
      },
    ],
    []
  );

  const setOwner = useCallback(
    (payload: SetActionPayload) => {
      changeAlertStatus?.({
        newStatusId: payload.newStatusId,
        comment: payload.comment,
        alertId: alertId,
        editUser: true,
        userType: payload.userType,
        userId: payload.userId ? Number(payload.userId) : -1,
      });
    },
    [changeAlertStatus, alertId]
  );

  const handleEditOwner = useCallback(() => {
    if (!alert) return;
    toggleModal?.({
      title: intl.formatMessage({ id: 'editAlertOwner', defaultMessage: 'Edit alert owner' }),
      modalContent: <EditStatusOrOwner {...{ alert, allUsers, teams, setAction: setOwner, editOwner: true }} />,
    });
  }, [intl, toggleModal, alert, setOwner, allUsers, teams]);

  // table content
  const alertsHeader = useMemo((): TableHeadElement[] => {
    return [
      {
        id: 'name',
        label: intl.formatMessage({
          id: 'alert',
          defaultMessage: 'alert',
        }),
        minWidth: '180px',
      },
      {
        id: 'date',
        label: intl.formatMessage({
          id: 'date',
          defaultMessage: 'Date',
        }),
      },
      {
        id: 'clientData',
        label: intl.formatMessage({
          id: 'clientData',
          defaultMessage: 'Client Data',
        }),
        minWidth: '180px',
      },
      {
        id: 'bestMatch',
        label: intl.formatMessage({
          id: 'highestHit',
          defaultMessage: 'Highest Hit',
        }),
        minWidth: '180px',
      },
      {
        id: 'bestMatchList',
        label: intl.formatMessage({
          id: 'watchlists',
          defaultMessage: 'Watchlists',
        }),
      },
      {
        id: 'score',
        label: intl.formatMessage({
          id: 'highestScore',
          defaultMessage: 'Highest Score',
        }),
      },
      // {
      //   id: 'alertId',
      //   label: intl.formatMessage({
      //     id: 'alertId',
      //     defaultMessage: 'Alert Id',
      //   }),
      // },
      {
        id: 'owner',
        label: intl.formatMessage({
          id: 'owner',
          defaultMessage: 'Owner',
        }),
        minWidth: '150px',
      },
      {
        id: 'status',
        label: intl.formatMessage({
          id: 'status',
          defaultMessage: 'Status',
        }),
        align: 'right',
      },
    ];
  }, [intl]);

  const row = useMemo((): TableRowData[] => {
    let owner: string | ReactNode = '';
    if (alert?.assignedToGroup) {
      owner = GetTeam(alert.assignedToGroup.name, teams);
    } else if (alert?.assignedToUser) {
      owner = GetUser(alert?.assignedToUser.userName, allUsers);
    }

    let alertDate = null;
    if (alert?.alertDate) {
      const parsedDate = dayjs(alert?.alertDate, DateStringFormat);
      if (parsedDate.isValid()) {
        alertDate = (
          <Typography fontSize="inherit" fontWeight="inherit" sx={{ color: 'inherit', whiteSpace: 'nowrap' }}>
            {parsedDate.format('MMM DD YYYY, HH:mm')}
          </Typography>
        );
      }
    }

    const allMatchListLabels = Array.from(
      new Set(
        alert?.target
          ?.map((t) => {
            if (!t.watchListPersons) return null;
            return t.watchListPersons.label ?? null;
          })
          .filter((l) => l !== null)
      )
    );

    return [
      {
        id: String(alert?.id) ?? '0',
        columns: {
          name: (
            <Box>
              <Typography fontSize="inherit" fontWeight="inherit" sx={{ color: 'inherit' }}>
                {alert?.name ?? alert?.description ?? ''}
              </Typography>
              <Typography fontSize="inherit" fontWeight="inherit" sx={{ color: '#545352', fontSize: 'var(--fs-12)' }}>
                {alert?.id ?? ''}
              </Typography>
            </Box>
          ),
          date: alertDate ?? '',
          clientData: (
            <Box>
              <Typography fontSize="inherit" fontWeight="inherit" sx={{ color: 'inherit' }}>
                {formatMatchingName(alert?.source?.names?.[0]) ?? ''}
              </Typography>
              <Typography fontSize="inherit" fontWeight="inherit" sx={{ color: '#545352', fontSize: 'var(--fs-12)' }}>
                {alert?.source?.dataID ?? ''}
              </Typography>
            </Box>
          ),
          // bestMatch: formatMatchingName(alert?.target?.[0].watchListPersons?.names?.[0]),
          bestMatch: (
            <Box>
              <Typography fontSize="inherit" fontWeight="inherit" sx={{ color: 'inherit' }}>
                {formatMatchingName(alert?.target?.[0].watchListPersons?.names?.[0]) ?? ''}
              </Typography>
              <Typography fontSize="inherit" fontWeight="inherit" sx={{ color: '#545352', fontSize: 'var(--fs-12)' }}>
                {alert?.target?.[0].watchListPersons?.dataID ?? ''}
              </Typography>
            </Box>
          ),

          // bestMatchList: alert?.target?.[0].watchListPersons?.label ? <StatusTag tag_label={alert?.target?.[0].watchListPersons.label} hideActive={true} bg={'white'} textColor="#6E6C6B" /> : '',
          bestMatchList:
            allMatchListLabels.length > 0 ? (
              <Box display="flex" columnGap={'.5rem'} alignItems={'center'}>
                <StatusTag tag_label={allMatchListLabels[0] ?? ''} hideActive={true} bg={'white'} textColor="#6E6C6B" />
                {allMatchListLabels.length > 1 && (
                  <Tooltip
                    placement="right"
                    slotProps={{
                      tooltip: {
                        className: 'custom-scrollbar',
                        sx: {
                          backgroundColor: 'white',
                          border: '1px solid var(--color-grayHeaderBorder)',
                          maxHeight: 200,
                          overflow: 'auto',
                        },
                      },
                    }}
                    title={
                      <Box px={1} py={1} display="flex" flexDirection="column" rowGap={'.5rem'}>
                        {allMatchListLabels.slice(1).map((l) => (
                          <Box key={l}>{l && <StatusTag tag_label={l} hideActive={true} bg={'white'} textColor="#6E6C6B" />}</Box>
                        ))}
                      </Box>
                    }
                  >
                    <Box>
                      <StatusTag tag_label={`+${allMatchListLabels.length - 1}`} hideActive={true} bg={'white'} textColor="#6E6C6B" />
                    </Box>
                  </Tooltip>
                )}
              </Box>
            ) : (
              ''
            ),

          score: alert?.matchScore ? <GaugeMeter value={alert.matchScore} /> : '',
          // alertId: alert?.id ?? '',
          owner: (
            <Box display="flex" sx={{ height: '100%', '&:hover .edit-box': { visibility: 'visible', pointerEvents: 'all' } }}>
              {owner}
              <Box ml={0.5} className="edit-box" sx={{ visibility: owner ? 'hidden' : 'hidden', pointerEvents: owner ? 'none' : 'none' }}>
                <ShadowedButton sx={{ px: 1, py: 0.5, minWidth: 0 }} onClick={handleEditOwner}>
                  <FormattedMessage id="edit" defaultMessage="Edit" />
                </ShadowedButton>
              </Box>
            </Box>
          ),
          status: <StatusTag tag_label={alert?.status?.description} active={alert?.status?.closeStatus} bg={'white'} />,
        },
      },
    ];
  }, [alert, allUsers, teams, handleEditOwner]);

  if (!alert) {
    return (
      <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <Button sx={{ color: 'var(--color-gray2)', flexDirection: 'column' }} onClick={refreshAlert}>
          <RefreshIcon sx={{ mb: 2 }} />
          <Box>
            <Typography>
              <FormattedMessage id="searchDatasetRefresh" defaultMessage="An error occured." />
            </Typography>
            <Typography>
              <FormattedMessage id="tryAgain" defaultMessage="Please try again." />
            </Typography>
          </Box>
        </Button>
      </Box>
    );
  }

  let tabContent;

  switch (activeTab) {
    case '':
      tabContent = <GeneralContent />;
      break;
    case 'workflow':
      tabContent = <WorkflowContent />;
      break;
    case 'documents':
      tabContent = <DocumentsContent />;
      break;
    default:
      break;
  }

  return (
    <Box flex={1} display={'flex'} flexDirection={'column'} pb={4} sx={{ minHeight: 0 }}>
      <Box display="flex" alignItems="center">
        <Box flex={1}>
          <TabsNavigation allLabel="General" activeTab={activeTab} tabTitles={tabs} onChange={handleTabChange} label={'alert tabs'} disabled={false} />
        </Box>
        {/* <Box ml={3}>
          <Button
            type="button"
            variant="contained"
            disableElevation
            onClick={handleEditStatus}
            sx={{
              '.MuiButton-startIcon svg': {
                fontSize: '12px',
              },
            }}
          >
            <FormattedMessage id="EditStatus" defaultMessage="Edit status" />
          </Button>
        </Box> */}
      </Box>

      {/* alerts mini table */}
      <Box mt={2}>
        <TableResultsMemo borderBottom={true} hasUnread={false} hasSelection={false} tableData={row} tableHead={alertsHeader} />
      </Box>

      <Box mt={2} flex={1} className="custom-scrollbar" sx={{ minHeight: 0, overflow: 'auto' }}>
        {tabContent}
      </Box>
    </Box>
  );
});

const MemoComment = memo(({ comment, handleChangeComment, placeholder }: { comment: string; handleChangeComment(event: React.ChangeEvent<HTMLInputElement>): void; placeholder: string }) => {
  return (
    <SearchText
      fullWidth
      value={comment}
      onChange={handleChangeComment}
      placeholder={placeholder}
      multiline
      rows={4}
      InputProps={{
        startAdornment: null,
      }}
    />
  );
});
const EditStatusOrOwner = memo(({ alert, allUsers, teams, editOwner, setAction }: EditStatusOrOwnerProps) => {
  const { closeModal } = useAddModal();
  const intl = useIntl();

  const [newStatus, setNewStatus] = useState<string>('');
  const [comment, setComment] = useState<string>('');

  const [userType, setUserType] = useState<SetActionPayload['userType']>(alert.assignedToUser ? 'user' : alert.assignedToGroup ? 'group' : null);
  const [userId, setUserId] = useState<SetActionPayload['userId']>(alert.assignedToUser ? alert.assignedToUser.id : alert.assignedToGroup ? alert.assignedToGroup.id : '');

  const currentOwner = useMemo(() => {
    let o: {
      type: typeof userType;
      id: typeof userId;
      name?: string;
    } = {
      type: null,
      id: '',
    };

    if (alert.assignedToUser) {
      o.type = 'user';
      o.id = alert.assignedToUser.id;
      o.name = alert.assignedToUser.userName;
    } else if (alert.assignedToGroup) {
      o.type = 'group';
      o.id = alert.assignedToGroup.id;
      o.name = alert.assignedToGroup.name;
    }
    return o;
  }, [alert]);

  const changeNewStatus = useCallback((event: SelectChangeEvent<unknown>) => {
    const selectValue = event.target.value as string;
    setNewStatus(selectValue);
  }, []);

  const changeUserId = useCallback((event: SelectChangeEvent<unknown>) => {
    const selectValue = event.target.value as number;
    setUserId(selectValue);
  }, []);

  const handleChangeComment = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setComment(event.target.value);
  }, []);

  const handleSetAction = () => {
    const actionPayload: SetActionPayload = {
      newStatusId: Number(newStatus),
      comment,
      editOwner,
      userType,
      userId,
    };
    setAction(actionPayload);
    closeModal?.();
  };

  const switches = useMemo(() => {
    const s: { type: typeof userType; label: string; disabled: boolean }[] = [
      {
        type: null,
        label: intl.formatMessage({ id: 'unassigned', defaultMessage: 'Unassigned' }),
        disabled: false,
      },
      {
        type: 'user',
        label: intl.formatMessage({ id: 'assignToUser', defaultMessage: 'Assign to user' }),
        disabled: (allUsers?.length || 0) <= 0,
      },
      {
        type: 'group',
        label: intl.formatMessage({ id: 'assignToTeam', defaultMessage: 'Assign to team' }),
        disabled: (teams?.length || 0) <= 0,
      },
    ];
    return s;
  }, [intl, allUsers, teams]);

  let saveDisabled = true;
  if (!editOwner) {
    if (newStatus) saveDisabled = false;
  } else {
    if (userType !== currentOwner.type) {
      if (userType === null) saveDisabled = false;
      else if (userId !== '') saveDisabled = false;
    } else {
      if (userType !== null && userId !== '' && userId !== currentOwner.id) saveDisabled = false;
    }
  }

  const setMessage = editOwner ? intl.formatMessage({ id: 'setOwner', defaultMessage: 'Set owner' }) : intl.formatMessage({ id: 'setStatus', defaultMessage: 'Set status' });
  return (
    <Box width={'min(85vw, 450px)'}>
      <Box px={3}>
        <Typography className="modal-label">
          <FormattedMessage id="currentAlertStatus" defaultMessage={'Current alert status'} />
        </Typography>
        <StatusTag tag_label={alert?.status?.description} active={alert?.status?.closeStatus} />
        {!editOwner && (
          <Box mt={2}>
            <Typography className="modal-label">
              <FormattedMessage id="selectNewStatus" defaultMessage={'Select new status'} />
            </Typography>
            <SearchSelect
              value={newStatus}
              onChange={changeNewStatus}
              fullWidth
              placeholder={intl.formatMessage({ id: 'selectStatus', defaultMessage: 'Select status' })}
              choices={(alert.status?.nextSteps || []).map((ns) => ({
                key: String(ns.id),
                value: ns.description,
              }))}
            />
          </Box>
        )}

        {editOwner && (
          <Box mt={2}>
            <Box mb={1}>
              <Typography className="modal-label">
                <FormattedMessage id="currentAlertOwner" defaultMessage={'Current alert owner'} />
              </Typography>
              <Box px={1}>
                {currentOwner.type === null && (
                  <Typography sx={{ fontSize: 'var(--fs-14)', color: 'var(--color-gray2)' }}>
                    <FormattedMessage id="unassigned" defaultMessage="Unassigned" />
                  </Typography>
                )}
                {currentOwner.type === 'user' && GetUser(currentOwner.name ?? '', allUsers)}
                {currentOwner.type === 'group' && GetTeam(currentOwner.name ?? '', teams)}
              </Box>
            </Box>

            <Typography className="modal-label">
              <FormattedMessage id="selectNewOwner" defaultMessage={'Select new owner'} />
            </Typography>

            <Box>
              <FormGroup>
                {switches.map((v) => (
                  <Fragment key={v.type ?? 'null'}>
                    <SwitchWithLabel
                      label={v.label}
                      inputSwitchProps={{
                        checked: userType === v.type,
                        onChange: () => {
                          setUserId('');
                          setUserType(v.type);
                        },
                      }}
                      disabled={v.disabled}
                    />
                    {v.type === 'user' && (
                      <Collapse in={userType === 'user'} timeout="auto">
                        <Box sx={{ mb: 1 }}>
                          <SearchSelect
                            value={userType === 'user' ? String(userId) : ''}
                            onChange={changeUserId}
                            fullWidth
                            placeholder={intl.formatMessage({ id: 'selectUser', defaultMessage: 'Select user' })}
                            choices={(allUsers ?? []).map((user) => ({
                              key: String(user.id),
                              value: GetUser(user.userName, allUsers),
                            }))}
                          />
                        </Box>
                      </Collapse>
                    )}
                    {v.type === 'group' && (
                      <Collapse in={userType === 'group'} timeout="auto">
                        <Box sx={{ mb: 1 }}>
                          <SearchSelect
                            value={userType === 'group' ? String(userId) : ''}
                            onChange={changeUserId}
                            fullWidth
                            placeholder={intl.formatMessage({ id: 'selectTeam', defaultMessage: 'Select team' })}
                            choices={(teams ?? []).map((team) => ({
                              key: String(team.id),
                              value: GetTeam(team.name, teams),
                            }))}
                          />
                        </Box>
                      </Collapse>
                    )}
                  </Fragment>
                ))}
              </FormGroup>
            </Box>
          </Box>
        )}
        <Box mt={2}>
          <MemoComment
            {...{
              comment,
              handleChangeComment,
              placeholder: intl.formatMessage({
                id: 'addComment',
                defaultMessage: 'Add a comment',
              }),
            }}
          />
        </Box>
      </Box>
      <Box display={'flex'} justifyContent={'flex-end'} gap={'1rem'} px={3} pt={2} mt={2} sx={{ borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
        <ShadowedButton
          onClick={() => {
            closeModal?.();
          }}
          sx={{ whiteSpace: 'nowrap' }}
        >
          <FormattedMessage id="cancel" defaultMessage="Cancel" />
        </ShadowedButton>

        <Button type="button" variant="contained" disabled={saveDisabled || !comment} disableElevation onClick={handleSetAction} sx={{ textTransform: 'none' }}>
          {setMessage}
        </Button>
      </Box>
    </Box>
  );
});

export default InvestigateAlert;

export const useViewAlert = () => {
  const { togglePageModal } = usePageModal();

  const viewAlert = useCallback(
    (alertId?: number, onUpdateAlert?: (alert: Alert) => void) => {
      if (!alertId) return 0;

      togglePageModal?.({ modalContent: <InvestigateAlert alertId={String(alertId)} asModal onUpdateAlert={onUpdateAlert} /> });
    },
    [togglePageModal]
  );
  return viewAlert;
};
