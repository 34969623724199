/**
 * EditProfile.tsx
 * Edit profile information in a modal
 */
/* packages */
import { memo, useState, useCallback, useMemo, useRef, SyntheticEvent } from 'react';
import { useIntl, FormattedMessage, IntlShape } from 'react-intl';

/* hooks */
import { useAddSnackbar } from 'contextProviders/SnackbarProvider';

/* components */
import Input from '@mui/material/Input';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import SearchText from 'components/SearchElements/SearchText/SearchText';
import ShadowedButton from 'components/ShadowedButton/ShadowedButton';
import TabsNavigation from 'components/TabsNavigation/TabsNavigation';

import Loader from 'components/Loader/Loader';

import { AvatarIcon } from 'icons/avatar/avatar';
import { CrossFilledIcon } from 'icons/crossFilled/crossFilled';
/* utilities */

/* types */
import { UserContextType } from 'contextProviders/UserProvider';
import { UserType, prependBase64Photo } from 'models/user';
import { AllUsersContextType } from 'contextProviders/AllUsersProvider';
import { displayError } from 'utilities/Logger';

interface EditProfileProps {
  currentUser: UserType;
  blockModal?(blocked: boolean): void;
  closeModal?(): void;
  changePassword?: UserContextType['changePassword'];
  editUser?: UserContextType['editUser'];
  editSingleUser?: AllUsersContextType['editSingleUser'];
  uploadAvatar?: UserContextType['uploadAvatar'];
  deleteAvatar?: UserContextType['deleteAvatar'];
}

interface EditPasswordProps {
  handleBlockTab(blocked: boolean): void;
  currentUser: UserType;
  closeModal?(): void;
  blockModal?(blocked: boolean): void;
  changePassword: UserContextType['changePassword'];
}
interface EditPersonalInformationProps {
  handleBlockTab(blocked: boolean): void;
  currentUser: UserType;
  blockModal?(blocked: boolean): void;
  closeModal?(): void;
  editUser?: UserContextType['editUser'];
  editSingleUser?: AllUsersContextType['editSingleUser'];
  uploadAvatar?: UserContextType['uploadAvatar'];
  deleteAvatar?: UserContextType['deleteAvatar'];
}

const EditProfile = memo(({ currentUser, blockModal, closeModal, changePassword, editUser, editSingleUser, uploadAvatar, deleteAvatar }: EditProfileProps) => {
  const intl = useIntl();

  const [activeTab, setActiveTab] = useState<string>('');
  const [blockTab, setBlockTab] = useState<boolean>(false);

  const handleTabChange = (_: React.SyntheticEvent, tabValue: 'string') => {
    const newTab = tabValue;
    setActiveTab(newTab);
  };

  const handleBlockTab = useCallback((blocked: boolean) => {
    setBlockTab(blocked);
  }, []);

  const tabs = useMemo(
    () => [
      {
        text: intl.formatMessage({ id: 'password', defaultMessage: 'Password' }),
        value: 'password',
      },
    ],
    [intl]
  );

  let tabContent;

  switch (activeTab) {
    case '':
      tabContent = <EditPersonnalInformation {...{ handleBlockTab, currentUser, blockModal, closeModal, editUser, editSingleUser, uploadAvatar, deleteAvatar }} />;
      break;
    case 'password':
      tabContent = <EditPassword {...{ handleBlockTab, currentUser, blockModal, closeModal, changePassword }} />;
      break;
    default:
      break;
  }

  return (
    <Box width={'min(85vw, 450px)'}>
      <Box px={3}>
        <TabsNavigation
          allLabel={intl.formatMessage({ id: 'personalInformation', defaultMessage: 'Personal information' })}
          activeTab={activeTab}
          tabTitles={tabs}
          onChange={handleTabChange}
          label={'profile tabs'}
          disabled={blockTab}
          alwaysShowScrollButton={false}
          shiftByMargin={false}
        />

        <Box mt={2}>{tabContent}</Box>
      </Box>
    </Box>
  );
});

export const validateEmail = (email?: string) => {
  if (!email) return false;

  const re = /^([^<>()[\]\\.,;:\s]+(\.[^<>()[\]\\.,;:\s]+)*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email.match(re);
};

export const allowedPhotosExtension = ['.jpg', '.jpeg', '.png'];

const EditPersonnalInformation = memo(({ handleBlockTab, currentUser, blockModal, closeModal, editUser, editSingleUser, uploadAvatar, deleteAvatar }: EditPersonalInformationProps) => {
  const intl = useIntl();
  const addSnackbar = useAddSnackbar();

  const [firstname, setFirstname] = useState<string>(currentUser.firstname ?? '');
  const [lastname, setLastname] = useState<string>(currentUser.lastname ?? '');
  // const [login, setLogin] = useState<string>(currentUser.userName ?? '');
  const [email, setEmail] = useState<string>(currentUser.email ?? '');

  const [photos, setPhotos] = useState<string>(currentUser.photo ? prependBase64Photo + currentUser.photo : '');
  const [selectedPhoto, setSelectedPhotos] = useState<File | undefined>();
  const [photoChanged, setPhotoChanged] = useState<boolean>(false);
  // const [selectedPhotoContent, setSelectedPhotoContent] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);

  const [baseUser, setBaseUser] = useState({
    firstname: currentUser.firstname ?? '',
    lastname: currentUser.lastname ?? '',
    // userName: currentUser.userName ?? '',
    email: currentUser.email ?? '',
  });

  const [settingUser, setSettingUser] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const handleEditText = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;

    switch (name) {
      case 'firstname':
        setFirstname(event.target.value);
        break;
      case 'lastname':
        setLastname(event.target.value);
        break;
      // case 'login':
      //   setLogin(event.target.value);
      //   break;
      case 'email':
        setEmail(event.target.value.toLowerCase());
        break;
      default:
        return;
    }
  }, []);

  const clearInputs = useCallback(() => {
    setSelectedPhotos(undefined);
    setPhotos('');
    setPhotoChanged(true);
  }, []);

  const validateFile = useCallback(
    (receivedFile: File | null | undefined) => {
      if (!receivedFile) {
        return clearInputs();
      }

      const fileExtension = receivedFile.name.split('.').pop();
      if (!allowedPhotosExtension.includes(`.${fileExtension}`)) {
        const fileErrorMessage = intl.formatMessage({
          id: 'fileInvalidExtension',
          defaultMessage: 'Invalid file type',
        });
        // addSnackbar(fileErrorMessage, 'error');
        setError(fileErrorMessage);

        return clearInputs();
      }

      setSelectedPhotos(receivedFile);

      const reader = new FileReader();
      reader.addEventListener(
        'load',
        () => {
          // convert image file to base64 string
          const result = reader.result;
          setPhotos(result as string);
        },
        false
      );
      reader.readAsDataURL(receivedFile);
    },
    [intl, clearInputs]
  );

  const checkFile = useCallback(
    (event: SyntheticEvent) => {
      const target = event.target as HTMLInputElement;
      const receivedFile = target.files?.[0];
      setError('');
      validateFile(receivedFile);
    },
    [validateFile]
  );

  const setUser = async () => {
    setError('');

    if (!editUser) return;

    const isValidEmail = validateEmail(email);
    if (!isValidEmail) {
      return setError(intl.formatMessage({ id: 'invalidEmail', defaultMessage: 'Invalid email' }));
    }

    setSettingUser(true);
    blockModal?.(true);
    handleBlockTab(true);

    let avatarBase64 = null;
    let errorUpload = false;
    if (selectedPhoto) {
      // upload the photo
      const uploadData = new FormData();

      const file = selectedPhoto;
      uploadData.append('image', file, file.name);
      if (!uploadAvatar) console.error('invalid function: uploadAvatar');

      try {
        const newAvatar = await uploadAvatar?.(uploadData);
        avatarBase64 = newAvatar;
      } catch (uploadError: any) {
        const uploadErrorMessage =
          uploadError.message ??
          intl.formatMessage({
            id: 'uploadAvatarError',
            defaultMessage: 'An error occurred while uploading user information',
          });
        setError(uploadErrorMessage);
        errorUpload = true;
      }
    } else if (currentUser.photo && !photos) {
      if (!deleteAvatar) {
        displayError('deleteAvatar dependecy missing');
        errorUpload = true;
      } else {
        try {
          await deleteAvatar?.();
        } catch (deleteError: any) {
          const uploadErrorMessage =
            deleteError.message ??
            intl.formatMessage({
              id: 'deleteAvatarError',
              defaultMessage: 'An error occurred while deleting user profile picture',
            });
          setError(uploadErrorMessage);
          errorUpload = true;
        }
      }
    }

    if (!errorUpload) {
      try {
        const newUserData = {
          ...currentUser,
          firstname,
          lastname,
          // userName: login,
          email,
        };

        const updatedUser = await editUser(newUserData);
        if (avatarBase64) {
          updatedUser.photo = avatarBase64;
        }
        editSingleUser?.(updatedUser);

        // success message
        const editUserSuccessMessage = intl.formatMessage({
          id: 'editUserSuccess',
          defaultMessage: 'User information has been saved',
        });
        addSnackbar(editUserSuccessMessage, 'success');
        setBaseUser({
          firstname,
          lastname,
          // userName: login,
          email,
        });
      } catch (editUserError: any) {
        // error message
        const editUserErrorMessage =
          editUserError.message ??
          intl.formatMessage({
            id: 'editUserError',
            defaultMessage: 'An error occurred while editing user information',
          });
        setError(editUserErrorMessage);
      }
    }

    setSettingUser(false);
    blockModal?.(false);

    handleBlockTab(false);
  };

  const noChange = baseUser.firstname === firstname && baseUser.lastname === lastname && baseUser.email === email && !selectedPhoto && !photoChanged; // && baseUser.userName === login;

  const memoizedInputProps = useMemo(() => {
    return {
      startAdornment: null,
    };
  }, []);
  const inputProps = useMemo(() => {
    return { maxLength: 200 };
  }, []);

  const avatarSize = 120;

  const p = useMemo(() => {
    const sqrt2 = 1.4142135623730951;
    return (((2 - sqrt2) / 2) * avatarSize) / 2;
  }, [avatarSize]);

  return (
    <Box>
      <Box>
        <Typography className="modal-label">
          <FormattedMessage id="firstname" defaultMessage={'Firstname'} />
        </Typography>
        <SearchText fullWidth name={'firstname'} value={firstname} onChange={handleEditText} InputProps={memoizedInputProps} inputProps={inputProps} />
      </Box>
      <Box mt={1}>
        <Typography className="modal-label">
          <FormattedMessage id="lastname" defaultMessage={'Lastname'} />
        </Typography>
        <SearchText fullWidth name={'lastname'} value={lastname} onChange={handleEditText} InputProps={memoizedInputProps} inputProps={inputProps} />
      </Box>
      {/* <Box mt={1}>
        <Typography className="modal-label">
          <FormattedMessage id="login" defaultMessage={'Login'} />
        </Typography>
        <SearchText fullWidth name={'login'} value={login} onChange={handleEditText} InputProps={memoizedInputProps} inputProps={inputPropsLogin} />
      </Box> */}
      <Box mt={1}>
        <Typography className="modal-label">
          <FormattedMessage id="email" defaultMessage={'Email'} />
        </Typography>
        <SearchText fullWidth name={'email'} value={email} onChange={handleEditText} InputProps={memoizedInputProps} />
      </Box>

      <Box mt={1}>
        <Typography className="modal-label">
          <FormattedMessage id="picture" defaultMessage={'Picture'} />
        </Typography>

        {photos ? (
          <Box display="flex" flexDirection="column" alignItems={'center'}>
            <Box sx={{ position: 'relative' }}>
              <img src={photos} alt="" style={{ width: `${avatarSize}px`, height: `${avatarSize}px`, borderRadius: '50%', objectFit: 'cover', objectPosition: 'center' }} />
              <IconButton
                disabled={settingUser}
                onClick={clearInputs}
                sx={(theme) => ({
                  transform: 'translate(60%, -60%)',
                  position: 'absolute',
                  zIndex: 10,
                  right: `${p}px`,
                  top: `${p}px`,
                  ml: 1,
                  color: 'var(--color-lightgray5)',
                  '&:hover': { color: theme.palette.red.main },
                })}
              >
                <CrossFilledIcon sx={{ fontSize: 'var(--fs-16)', color: 'inherit' }} />
              </IconButton>
            </Box>
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" alignItems={'center'}>
            <Input
              id="filePhotos"
              onChange={checkFile}
              disableUnderline
              type={'file'}
              name={'filePhotos'}
              inputProps={{
                ref: inputRef,
                accept: allowedPhotosExtension.join(', '),
                hidden: true,
                sx: {
                  display: 'none',
                },
              }}
            />
            <AvatarIcon sx={{ fontSize: `${avatarSize}px`, color: 'var(--color-lightgray4)' }} />
            <label htmlFor={'filePhotos'}>
              <ShadowedButton component={'span'} sx={{ whiteSpace: 'nowrap', mt: 0.5 }}>
                <FormattedMessage id="choosePicture" defaultMessage="Choose picture" />
              </ShadowedButton>
            </label>
          </Box>
        )}
      </Box>

      <Box>
        {error && (
          <Typography mt={1} fontWeight="medium" color={'error'} sx={{ fontSize: 'var(--fs-12)' }}>
            {error}
          </Typography>
        )}
      </Box>

      <Box display={'flex'} justifyContent={'flex-end'} gap={'1rem'} pt={2} mt={2} sx={{ borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
        <ShadowedButton
          onClick={() => {
            closeModal?.();
          }}
          sx={{ whiteSpace: 'nowrap' }}
        >
          <FormattedMessage id="cancel" defaultMessage="Cancel" />
        </ShadowedButton>

        <Button type="button" variant="contained" disabled={settingUser || noChange} disableElevation onClick={setUser} sx={{ textTransform: 'none' }}>
          {settingUser ? <Loader cssStyle={{ width: '1rem' }} /> : intl.formatMessage({ id: 'save', defaultMessage: 'Save' })}
        </Button>
      </Box>
    </Box>
  );
});

export const validatePassword = (newPassword: string, intl: IntlShape) => {
  if (newPassword.length < 8) {
    return intl.formatMessage({ id: 'passwordLengthMin', defaultMessage: 'The password must contains at least 8 characters' });
  }
  if (newPassword.length > 20) {
    return intl.formatMessage({ id: 'passwordLengthMax', defaultMessage: 'The password must contains at most 20 characters' });
  }

  const digitReg = /[0-9]/;
  if (!newPassword.match(digitReg)) {
    return intl.formatMessage({ id: 'passwordNoDigit', defaultMessage: 'The password must contains at least one digit' });
  }

  const lowercaseReg = /[a-z]/;
  if (!newPassword.match(lowercaseReg)) {
    return intl.formatMessage({ id: 'passwordNoLowercase', defaultMessage: 'The password must contains at least one lowercase character' });
  }

  const uppercaseReg = /[A-Z]/;
  if (!newPassword.match(uppercaseReg)) {
    return intl.formatMessage({ id: 'passwordNoUppercase', defaultMessage: 'The password must contains at least one uppercase character' });
  }

  const specialReg = /[!@#&_()[{}\]:;',?/*~$^+=<>]/;
  if (!newPassword.match(specialReg)) {
    return intl.formatMessage({ id: 'passwordNoSpecial', defaultMessage: 'The password must contains at least one special character' });
  }

  return null;
};
const EditPassword = memo(({ handleBlockTab, currentUser, blockModal, closeModal, changePassword }: EditPasswordProps) => {
  const intl = useIntl();
  const addSnackbar = useAddSnackbar();
  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [newPassword, setNewPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [settingPassword, setSettingPassword] = useState<boolean>(false);

  const [passwordError, setPasswordError] = useState<string>('');
  const [newPasswordError, setNewPasswordError] = useState<string>('');

  const handleEditPassword = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentPassword(event.target.value);
  }, []);
  const handleEditNewPassword = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setNewPassword(event.target.value);
  }, []);
  const handleEditConfirmPassword = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(event.target.value);
  }, []);

  const setPassword = async () => {
    setPasswordError('');
    setNewPasswordError('');

    if (newPassword !== confirmPassword) {
      return setNewPasswordError(intl.formatMessage({ id: 'passwordMismatch', defaultMessage: 'Password mismatch' }));
    }

    const invalidPassword = validatePassword(newPassword, intl);
    if (invalidPassword) {
      return setNewPasswordError(invalidPassword);
    }

    setSettingPassword(true);
    blockModal?.(true);
    handleBlockTab(true);

    try {
      await changePassword?.(currentUser, currentPassword, newPassword);

      // success message
      const changePasswordSuccessMessage = intl.formatMessage({
        id: 'changePasswordSuccess',
        defaultMessage: 'The password has been changed',
      });
      addSnackbar(changePasswordSuccessMessage, 'success');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (changePasswordError: any) {
      // error message
      const changePasswordErrorMessage =
        changePasswordError.message ??
        intl.formatMessage({
          id: 'changePasswordError',
          defaultMessage: 'An error occurred while changing password',
        });
      setNewPasswordError(changePasswordErrorMessage);
    }

    setSettingPassword(false);
    blockModal?.(false);
    handleBlockTab(false);
  };

  const memoizedProps = useMemo(() => {
    return {
      startAdornment: null,
    };
  }, []);

  return (
    <Box>
      <Box>
        <Typography className="modal-label">
          <FormattedMessage id="currentPassword" defaultMessage={'Current password'} />
        </Typography>
        <SearchText fullWidth value={currentPassword} onChange={handleEditPassword} placeholder="********" InputProps={memoizedProps} type="password" />

        {passwordError && (
          <Typography mt={1} fontWeight="medium" color={'error'} sx={{ fontSize: 'var(--fs-12)' }}>
            {passwordError}
          </Typography>
        )}
      </Box>
      <Box mt={1}>
        <Typography className="modal-label">
          <FormattedMessage id="newPassword" defaultMessage={'New password'} />
        </Typography>
        <SearchText fullWidth value={newPassword} onChange={handleEditNewPassword} placeholder="********" InputProps={memoizedProps} type="password" />
      </Box>
      <Box mt={1}>
        <Typography className="modal-label">
          <FormattedMessage id="confirmPassword" defaultMessage={'Confirm password'} />
        </Typography>
        <SearchText fullWidth value={confirmPassword} onChange={handleEditConfirmPassword} placeholder="********" InputProps={memoizedProps} type="password" />

        {newPasswordError && (
          <Typography mt={1} fontWeight="medium" color={'error'} sx={{ fontSize: 'var(--fs-12)' }}>
            {newPasswordError}
          </Typography>
        )}
      </Box>

      <Box display={'flex'} justifyContent={'flex-end'} gap={'1rem'} pt={2} mt={2} sx={{ borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
        <ShadowedButton
          onClick={() => {
            closeModal?.();
          }}
          sx={{ whiteSpace: 'nowrap' }}
        >
          <FormattedMessage id="cancel" defaultMessage="Cancel" />
        </ShadowedButton>

        <Button
          type="button"
          variant="contained"
          disabled={settingPassword || !currentPassword || !newPassword || !confirmPassword}
          disableElevation
          onClick={setPassword}
          sx={{ textTransform: 'none' }}
        >
          {settingPassword ? <Loader /> : intl.formatMessage({ id: 'setPassword', defaultMessage: 'Set password' })}
        </Button>
      </Box>
    </Box>
  );
});

export default EditProfile;
