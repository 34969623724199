import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const AvatarMaleIcon = (props: SvgIconProps) => {
  const scale = 0.7;
  const base_shift = (512 - 448) / 2;
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="512" height="512" viewBox="0 0 512 512">
        <circle cx="256" cy="256" r="256" transform="translate(0 0)" fill="currentColor" />
        <path
          transform={`translate(${((1 - scale) / 2) * 448 + base_shift} ${((1 - scale) / 2) * 512}), scale(${scale})`}
          d="M304 128v16c0 44.2-35.8 80-80 80s-80-35.8-80-80V128c0-5.5 .6-10.8 1.6-16H176c29.8 0 55.9-16.3 69.6-40.5C257.3 86.4 275.5 96 296 96h1.3c4.3 9.8 6.7 20.6 6.7 32zM96 128v16c0 70.7 57.3 128 128 128s128-57.3 128-128V128C352 57.3 294.7 0 224 0S96 57.3 96 128zm61.3 199.1c-3.2-4.3-8.4-6.7-13.7-6.2C62.9 329.7 0 398.2 0 481.3c0 17 13.8 30.7 30.7 30.7H417.3c17 0 30.7-13.8 30.7-30.7c0-83.1-62.9-151.5-143.7-160.3c-5.3-.6-10.5 1.9-13.7 6.2l-53.9 71.8c-6.4 8.5-19.2 8.5-25.6 0l-53.9-71.8z"
          fill="var(--avatar-color, #fff)"
        />
      </svg>
    </SvgIcon>
  );
};
