/**
 * InvestigateSearch.tsx
 */
/* packages */
import { ReactNode, useContext, useState, useCallback, useEffect, memo, PropsWithChildren, useMemo, useRef, forwardRef } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import dayjs from 'dayjs';

/* contexts */
import { AllUsersContext } from 'contextProviders/AllUsersProvider';
import { OrganizationsContext } from 'contextProviders/OrganizationsProvider';
import { RolesContext } from 'contextProviders/RolesProvider';
import { TeamsContext } from 'contextProviders/TeamsProvider';
import { UserContext } from 'contextProviders/UserProvider';

/* hooks */
import { useAddModal } from 'contextProviders/ModalProvider';
import { useAddSnackbar } from 'contextProviders/SnackbarProvider';

/* components */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import TableResults, { TableRefType } from 'components/TableResults/TableResults';
import { TableHeadElement } from 'components/TableResults/TableHead';
import { SelectChangeEvent } from '@mui/material/Select';

import { ContentWithDarkTooltip } from 'components/InvestigateSearch/utils';
import StatusTag from 'components/StatusTag/StatusTag';
import { RightSidePanel } from 'components/NotificationsPanel/NotificationsPanel';

import SwitchWithLabel from 'components/SearchElements/SearchText/SearchSwitch';
import TabsNavigation from 'components/TabsNavigation/TabsNavigation';
import { AddButton } from 'components/Buttons/Buttons';
import PageHeader from 'components/PageHeader/PageHeader';
import PageTitle from 'components/PageTitle/PageTitle';
import Loader from 'components/Loader/Loader';
import ReloadButton from 'components/ReloadButton/ReloadButton';
import ShadowedButton from 'components/ShadowedButton/ShadowedButton';

import SearchText from 'components/SearchElements/SearchText/SearchText';
import FilterVirtualCheckboxes from 'components/SearchFilters/FilterVirtualCheckboxes';
import SearchFilters, { FilterButtonRefType, SearchFiltersButtonType } from 'components/SearchFilters/SearchFilters';
import FilterCheckboxes from 'components/SearchFilters/FilterCheckboxes';
import Pagination, { numberPerPages } from 'components/Pagination/Pagination';

import { CrossIcon } from 'icons/cross/cross';
import { GarbageIcon } from 'icons/garbage/garbage';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

/* utilities */
import { validatePassword } from 'components/EditProfile/EditProfile';
import { displayWarning } from 'utilities/Logger';

/* types */
import { OrganizationUnit, RoleType, UserType } from 'models/user';
import { validateEmail } from 'components/EditProfile/EditProfile';
import { GetAvatar, GetUser } from 'components/GetUser/GetUser';
import { SortedDirectionType } from 'components/TableResults/TableHead';
import { DateStringISOFormat } from 'models/utils';
import { TeamType } from 'models/teams';

interface PromiseResponse {
  status: 'error' | 'success';
}
interface ManageUsersLayoutProps {
  loading: boolean;
  handleCreateUser(): void;
  isError: boolean;
}
interface ManageUsersContentProps {
  loading: boolean;
  load: () => void;
  isError: boolean;
  handleCreateUser(user?: UserType): void;
  handleDeleteUser(user: UserType): void;
  setUser(data: Partial<UserType>, user?: UserType): Promise<PromiseResponse>;
}

interface EditUserContentProps {
  userId?: number;
  close(): void;
  setUser: ManageUsersContentProps['setUser'];
}
interface EditUserContentListProps {
  removingElements: number[];
  removeElement(elementId: number): void;
  addElement(elemenIds: number[]): Promise<PromiseResponse>;
  list: { id: number; name: string }[];
  activeContent: { id: number; name: string }[];
  minimumActive: number;
  emptyMessage: string;
  addButtonMessage: string;
}

interface UserRowProps {
  user: UserType;
  rowClick?(user: UserType): void;
  handleCreateUser: ManageUsersContentProps['handleCreateUser'];
  handleDeleteUser: ManageUsersContentProps['handleDeleteUser'];
}

interface EditUserProps {
  user?: UserType;
  setUser: ManageUsersContentProps['setUser'];
  organizations: OrganizationUnit[];
  roles: RoleType[];
}
interface EditUserPermissionsProps {
  creatingUser: boolean;
  userParams: Partial<UserType>;
  organizations: OrganizationUnit[];
  hanldeChangeOrganizations(newOrganizations: OrganizationUnit[]): void;
  roles: RoleType[];
  hanldeChangeRoles(newRoles: RoleType[]): void;
}
interface EditUserStatusProps {
  creatingUser: boolean;
  userParams: Partial<UserType>;
  handleChangeSwitch(event: React.ChangeEvent<HTMLInputElement>): void;
}
interface EditUserPasswordProps {
  creatingUser: boolean;
  userParams: Partial<UserType>;
  confirmPassword: string;
  handleChangeParams(event: React.ChangeEvent<HTMLInputElement>): void;
  hanldeChangeConfirmPassword(event: React.ChangeEvent<HTMLInputElement>): void;
}
interface DeleteUserProps {
  user: UserType;
  removeUser(removedId: number): Promise<{ status: string }>;
}

/* elements */
const ManageUsers = () => {
  const intl = useIntl();
  const { toggleModal } = useAddModal();

  const { loadingOrganizations, organizations, listOrganizations } = useContext(OrganizationsContext);
  const { loadingAllUsers, allUsers, listAllUsers, updateUser, createUser, deleteUser } = useContext(AllUsersContext);
  const { loadingRoles, roles, listRoles } = useContext(RolesContext);
  const { loadingTeams, teams, listTeams } = useContext(TeamsContext);
  const { currentUser, editCurrentUser } = useContext(UserContext);

  // const [roles, setRoles] = useState<RoleType[] | undefined>(undefined);
  // const [rolesError, setRolesError] = useState(false);

  const [initialLoading, setInitialLoading] = useState(true);

  // refresh data on page load
  const load = useCallback(() => {
    listAllUsers?.();
    listOrganizations?.();
    listRoles?.();
    listTeams?.();
  }, [listOrganizations, listAllUsers, listRoles, listTeams]);

  useEffect(() => {
    load();
    setInitialLoading(false);
  }, [load]);

  // methods
  const setUser = useCallback(
    async (data: Partial<UserType>, user?: UserType): Promise<PromiseResponse> => {
      const newData = {
        ...user,
        ...data,
      } as UserType;

      try {
        const caller = user ? updateUser : createUser;

        const updatedUser = await caller?.(newData);

        if (!updatedUser) {
          return { status: 'error' };
        }
        if (currentUser?.id === updatedUser.id) {
          editCurrentUser?.(updatedUser);
        }
        return { status: 'success' };
      } catch {
        return { status: 'error' };
      }
    },
    [updateUser, createUser, editCurrentUser, currentUser]
  );

  const handleCreateUser = useCallback(
    (user?: UserType) => {
      if (!organizations || !roles) return;
      if (!currentUser?.isAdmin) return;

      toggleModal?.({
        title: user ? intl.formatMessage({ id: 'editUser', defaultMessage: 'Edit user' }) : intl.formatMessage({ id: 'addUser', defaultMessage: 'Add user' }),
        modalContent: <EditUser roles={roles} {...{ user, setUser, organizations }} />,
      });
    },
    [intl, toggleModal, setUser, organizations, roles, currentUser]
  );

  const removeUser = useCallback(
    async (removedId: number): Promise<PromiseResponse> => {
      try {
        const deletedUser = await deleteUser?.(removedId);
        if (deletedUser) {
          return { status: 'success' };
        }
        return { status: 'error' };
      } catch {
        return { status: 'error' };
      }
    },
    [deleteUser]
  );

  const handleDeleteUser = useCallback(
    (user: UserType) => {
      if (!currentUser?.isAdmin) return;

      toggleModal?.({
        title: intl.formatMessage({ id: 'deleteUser', defaultMessage: 'Delete user' }),
        modalContent: <DeleteUser {...{ user, removeUser }} />,
      });
    },
    [intl, toggleModal, removeUser, currentUser]
  );

  const isError = (!loadingTeams && !teams) || (!loadingAllUsers && !allUsers) || (!loadingOrganizations && !organizations) || (!loadingRoles && !roles);
  const loading = initialLoading || loadingTeams || loadingAllUsers || loadingRoles || loadingOrganizations;

  return (
    <ManageUsersLayout {...{ loading, handleCreateUser, isError }}>
      <ManageUsersContent {...{ loading, isError, load, handleCreateUser, handleDeleteUser, setUser }} />
    </ManageUsersLayout>
  );
};

const ManageUsersLayout = ({ loading, handleCreateUser, isError, children }: PropsWithChildren<ManageUsersLayoutProps>) => {
  const intl = useIntl();
  const { currentUser } = useContext(UserContext);

  return (
    <>
      <PageHeader>
        <PageTitle title={intl.formatMessage({ id: 'ManageUsersTitle', defaultMessage: 'Users' })} />

        {currentUser?.isAdmin && (
          <AddButton
            onClick={() => {
              handleCreateUser();
            }}
            disabled={loading || isError}
          >
            <FormattedMessage id="addUser" defaultMessage={'Add user'} />
          </AddButton>
        )}
      </PageHeader>
      {children}
    </>
  );
};

const SearchPagination = memo(Pagination);

const getSortedUsers = (users: UserType[] | undefined, page: number, maxPerPage: number, sortedColumn: string, sortedDirection: SortedDirectionType) => {
  let sortedUsers = users;
  if (!users) return users;

  if (sortedDirection) {
    const direction = sortedDirection === 'asc' ? 1 : -1;
    switch (sortedColumn) {
      case 'name':
        sortedUsers = [...users].sort((u1, u2) => direction * (u1.fullName ?? u1.userName).localeCompare(u2.fullName ?? u2.userName));
        break;
      case 'updatedon':
        sortedUsers = [...users].sort((u1, u2) => direction * (u1.updatedDate ?? '').localeCompare(u2.updatedDate ?? ''));
        break;
      case 'createdon':
        sortedUsers = [...users].sort((u1, u2) => direction * (u1.creationDate ?? '').localeCompare(u2.creationDate ?? ''));
        break;
      case 'deactivatedon':
        sortedUsers = [...users].sort((u1, u2) => direction * (u1.deactivatedDtg ?? '').localeCompare(u2.deactivatedDtg ?? ''));
        break;
      default:
        displayWarning(`unknown sorting column: ${sortedColumn}`);
      // return sortedUsers;
    }
  }
  return sortedUsers?.slice(page * maxPerPage, (page + 1) * maxPerPage);
};

const DEBOUNCETIMING = 500;
const ManageUsersContent = ({ loading, isError, load, handleCreateUser, handleDeleteUser, setUser }: ManageUsersContentProps) => {
  const intl = useIntl();
  const tableRef = useRef<TableRefType>(null);

  const { allUsers } = useContext(AllUsersContext);

  const [openSide, setOpenSide] = useState<number | undefined>(undefined);

  const [sortedColumn, setSortedColumn] = useState<string>('');
  const [sortedDirection, setSortedDirection] = useState<SortedDirectionType>(false);

  const [visibleUsers, setVisibleUsers] = useState<typeof allUsers | undefined>();
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [maxPerPage, setMaxPerPage] = useState<string>(String(numberPerPages[0]));
  const [searching, setSearching] = useState<ReturnType<typeof setTimeout> | null>(null);
  // const [filters, setFilters] = useState<SelectedFiltersValues | null>(null);
  const searchFormRef = useRef<HTMLFormElement>(null);

  const makeSearch = useCallback(
    (filters: SelectedFiltersValues | null) => {
      let users = allUsers;

      if (filters) {
        if (filters?.status) {
          filters.status.forEach((status) => {
            switch (status) {
              case 'isAdmin':
                users = users?.filter((u) => u.isAdmin);
                break;
              case 'locked':
                users = users?.filter((u) => u.locked);
                break;
              case 'deactivated':
                users = users?.filter((u) => u.deactivated);
                break;
              default:
                displayWarning(`unknown status : ${status}`);
            }
          });
        }

        if (filters.organizations.length > 0) {
          users = users?.filter((u) => (u.organizationUnits || []).filter((v) => filters.organizations.includes(String(v.id))).length > 0);
        }
        if (filters.teams.length > 0) {
          users = users?.filter((u) => (u.groups || []).filter((v) => filters.teams.includes(String(v.id))).length > 0);
        }
        if (filters.roles.length > 0) {
          users = users?.filter((u) => (u.roles || []).filter((v) => filters.roles.includes(String(v.id))).length > 0);
        }
        if (filters.search) {
          users = users?.filter((u) => (u.fullName ?? '').toLowerCase().includes(filters.search.toLowerCase()) || u.userName.toLowerCase().includes(filters.search.toLowerCase()));
        }
      }

      setVisibleUsers(users);
    },
    [allUsers]
  );

  const setMaxAndSearch = (event: SelectChangeEvent) => {
    const newMaxPerPage = event.target.value;
    if (newMaxPerPage === maxPerPage) return;

    const newCurrentPage = 0;
    setMaxPerPage(newMaxPerPage);
    setCurrentPage(newCurrentPage);

    // take the lastest search payload
    // makeSearch(filters);
  };

  const changePage = (shift: 1 | -1) => {
    if (!allUsers) return;

    const newPage = currentPage + shift;

    // check page is in bounds
    if (newPage < 0) return;
    if (newPage > allUsers.length / Number(maxPerPage)) return;

    setCurrentPage(newPage);

    // const paginatedPayload = addPaginationToPayload({ payload: lastSearchQuery, currentPage: newPage, maxPerPage: maxPerPage });
    // makeSearch(filters);
  };

  const onChangeFilter = useCallback(
    (newFilters: SelectedFiltersValues, debounce = false) => {
      setSearching((currentSearch) => {
        if (currentSearch) {
          clearTimeout(currentSearch);
          return null;
        }
        return currentSearch;
      });

      // setFilters(newFilters);
      const applySearch = () => {
        setCurrentPage(0);
        makeSearch(newFilters);
      };

      if (debounce) {
        const filterTimeout = setTimeout(() => {
          applySearch();
          setSearching(null);
        }, DEBOUNCETIMING);

        setSearching(filterTimeout);
      } else {
        applySearch();
      }
    },
    [makeSearch]
  );

  const tableHeaders = useMemo((): TableHeadElement[] => {
    return [
      {
        id: 'name',
        label: intl.formatMessage({
          id: 'name',
          defaultMessage: 'Name',
        }),
        minWidth: '200px',
        sorted: () => {
          const newDirection = sortedColumn !== 'name' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc';
          setSortedColumn('name');
          setSortedDirection(newDirection);
        },
        direction: sortedColumn === 'name' ? sortedDirection : false,
      },
      {
        id: 'organization',
        label: intl.formatMessage({
          id: 'organization',
          defaultMessage: 'Organization',
        }),
        minWidth: '150px',
        // maxWidth: '250px',
      },
      {
        id: 'team',
        label: intl.formatMessage({
          id: 'team',
          defaultMessage: 'Team',
        }),
        minWidth: '150px',
        // maxWidth: '250px',
      },
      {
        id: 'createdon',
        label: intl.formatMessage({
          id: 'createdon',
          defaultMessage: 'Created on',
        }),
        minWidth: '180px',
        sorted: () => {
          const newDirection = sortedColumn !== 'createdon' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc';
          setSortedColumn('createdon');
          setSortedDirection(newDirection);
        },
        direction: sortedColumn === 'createdon' ? sortedDirection : false,
      },
      {
        id: 'updatedon',
        label: intl.formatMessage({
          id: 'updatedon',
          defaultMessage: 'Updated on',
        }),
        minWidth: '180px',
        sorted: () => {
          const newDirection = sortedColumn !== 'updatedon' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc';
          setSortedColumn('updatedon');
          setSortedDirection(newDirection);
        },
        direction: sortedColumn === 'updatedon' ? sortedDirection : false,
      },
      {
        id: 'deactivatedon',
        label: intl.formatMessage({
          id: 'deactivatedon',
          defaultMessage: 'Deactivated on',
        }),
        minWidth: '180px',
        sorted: () => {
          const newDirection = sortedColumn !== 'deactivatedon' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc';
          setSortedColumn('deactivatedon');
          setSortedDirection(newDirection);
        },
        direction: sortedColumn === 'deactivatedon' ? sortedDirection : false,
      },
      {
        id: 'edit',
        label: '',
      },
    ];
  }, [intl, sortedColumn, sortedDirection]);

  const rowClick = useCallback((user: UserType) => {
    // edit row on click?
    setOpenSide(user.id);
  }, []);

  if (isError) {
    return (
      <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <ReloadButton
          onClick={() => {
            load();
          }}
        />
      </Box>
    );
  }

  if (loading) {
    return (
      <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <Loader />
      </Box>
    );
  }

  return (
    <Box>
      {/* {visibleUsers && visibleUsers.length > 0 ? ( */}

      {/* Search Form */}
      <SearchForm ref={searchFormRef} {...{ onChangeFilter }} />

      {searching ? (
        <Box pt={'30vh'}>
          <Loader />
        </Box>
      ) : (
        <>
          <TableResults ref={tableRef} hasBoxTitle={false} borderBottom={false} hasUnread={false} hasSelection={false} tableData={[]} tableHead={tableHeaders}>
            {(visibleUsers?.length ?? 0) > 0 ? (
              getSortedUsers(visibleUsers, currentPage, Number(maxPerPage), sortedColumn, sortedDirection)?.map((user) => {
                return <UserRow key={user.id} {...{ user, rowClick, handleCreateUser, handleDeleteUser }} />;
              })
            ) : (
              <TableRow sx={{ borderBottom: '1px solid var(--color-grayHeaderBorder)' }}>
                <TableCell align={'left'}>
                  <Typography fontWeight={500} sx={{ color: 'var(--color-gray1)' }}>
                    <FormattedMessage id="noUsersToDisplay" defaultMessage={'No user'} />
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableResults>

          {(visibleUsers?.length ?? 0) > 0 && (
            <SearchPagination
              {...{
                maxPerPage: Number(maxPerPage),
                currentPage,
                nbResults: visibleUsers?.length ?? 0,
                setMaxAndCallback: setMaxAndSearch,
                changePageCallback: changePage,
              }}
            />
          )}
        </>
      )}

      <RightSidePanel
        open={openSide !== undefined}
        onClose={() => {
          setOpenSide(undefined);
        }}
      >
        <EditUserContent
          close={() => {
            setOpenSide(undefined);
          }}
          {...{ userId: openSide, setUser }}
        />
      </RightSidePanel>
    </Box>
  );
};

interface SelectedFiltersValues {
  search: string;
  status: string[];
  organizations: string[];
  teams: string[];
  roles: string[];
}
interface SearchFormType {
  onChangeFilter(filters: SelectedFiltersValues, debounce?: boolean): void;
}

const SearchForm = memo(
  forwardRef<HTMLFormElement, SearchFormType>((props, ref) => {
    const intl = useIntl();
    const { onChangeFilter } = props;

    const [searchInput, setSearchInput] = useState<string>('');
    const { organizations } = useContext(OrganizationsContext);
    const { teams } = useContext(TeamsContext);
    const { roles } = useContext(RolesContext);

    // const labels = useMemo(() => Array.from(new Set(datasets?.map((ds) => ds.label).filter((c) => c ?? false))), [datasets]);

    // // define required ref
    const searchInputRef = useRef<HTMLInputElement | null>(null);
    const statusRef = useRef<FilterButtonRefType | null>(null);
    const organizationsRef = useRef<FilterButtonRefType | null>(null);
    const teamsRef = useRef<FilterButtonRefType | null>(null);
    const rolesRef = useRef<FilterButtonRefType | null>(null);

    const searchFiltersButtons: SearchFiltersButtonType[] = useMemo(
      () => [
        {
          ref: statusRef,
          text: <FormattedMessage id="status" defaultMessage="Status" />,
          inputName: 'status',
          filterContent: (
            <FilterCheckboxes
              title={intl.formatMessage({ id: 'status', defaultMessage: 'Status' })}
              list={[
                { key: 'isAdmin', value: intl.formatMessage({ id: 'admin', defaultMessage: 'Admin' }) },
                { key: 'locked', value: intl.formatMessage({ id: 'locked', defaultMessage: 'Locked' }) },
                { key: 'deactivated', value: intl.formatMessage({ id: 'deactivated', defaultMessage: 'Deactivated' }) },
              ]}
            />
          ),
        },
        {
          ref: organizationsRef,
          text: <FormattedMessage id="organizations" defaultMessage="Organizations" />,
          inputName: 'organizations',
          filterContent: (
            <FilterVirtualCheckboxes
              title={intl.formatMessage({ id: 'organizations', defaultMessage: 'Organizations' })}
              list={(organizations ?? []).map((org) => ({ key: org.id.toString(), value: org.name }))}
            />
          ),
        },
        {
          ref: teamsRef,
          text: <FormattedMessage id="teams" defaultMessage="Teams" />,
          inputName: 'teams',
          filterContent: (
            <FilterVirtualCheckboxes title={intl.formatMessage({ id: 'teams', defaultMessage: 'Teams' })} list={(teams ?? []).map((org) => ({ key: org.id.toString(), value: org.name }))} />
          ),
        },
        {
          ref: rolesRef,
          text: <FormattedMessage id="roles" defaultMessage="Roles" />,
          inputName: 'roles',
          filterContent: (
            <FilterVirtualCheckboxes title={intl.formatMessage({ id: 'roles', defaultMessage: 'Roles' })} list={(roles ?? []).map((org) => ({ key: org.id.toString(), value: org.name }))} />
          ),
        },
      ],
      [intl, organizations, roles, teams]
    );

    const onActivate = useCallback(
      (searchTerm?: string, debounce: boolean = false) => {
        const selectedFilters: SelectedFiltersValues = {
          search: searchTerm ?? searchInputRef.current?.value ?? '',
          status: statusRef.current?.getValue().values || [],
          organizations: organizationsRef.current?.getValue().values || [],
          teams: teamsRef.current?.getValue().values || [],
          roles: rolesRef.current?.getValue().values || [],
        };
        onChangeFilter(selectedFilters, debounce);
      },
      [onChangeFilter]
    );

    const updateSearchInput = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const searchTerm = event.target.value;
        setSearchInput(searchTerm);
        onActivate(searchTerm, searchTerm ? true : false);
      },
      [onActivate]
    );

    const clearSearchInput = useCallback(() => {
      const searchTerm = '';
      setSearchInput(searchTerm);

      onActivate(searchTerm);
    }, [onActivate]);

    const searchInputProps = useMemo(() => {
      return { name: 'searchInput' };
    }, []);

    return (
      <Box width={'100%'} display={'flex'} px={0} py={4}>
        <form ref={ref} action="" style={{ width: '100%' }}>
          <Box display={'flex'} gap={'1.5rem'} alignItems={'center'} sx={{ flexFlow: 'row wrap' }}>
            <Box flex={1} sx={{ minWidth: 150, maxWidth: { xs: '100%', md: '450px' } }}>
              <SearchText
                ref={searchInputRef}
                fullWidth
                value={searchInput}
                onChange={updateSearchInput}
                placeholder={intl.formatMessage({ id: 'searchPlaceholder', defaultMessage: 'Search' })}
                inputProps={searchInputProps}
                disabled={false}
                clearAction={clearSearchInput}
                // sx={{ minWidth: 100, maxWidth: { xs: '100%', md: '200px' } }}
              />
            </Box>

            <Divider orientation="vertical" flexItem sx={{ borderColor: 'var(--color-grayHeaderBorder)' }} />

            <Box>
              <SearchFiltersMemo disabled={false} {...{ searchFiltersButtons, onActivate }} />
            </Box>
          </Box>
        </form>
      </Box>
    );
  })
);

const UserRow = memo(({ user, rowClick, handleCreateUser, handleDeleteUser }: UserRowProps) => {
  const intl = useIntl();
  const [avatar, name] = GetAvatar(user.userName, [user], true) as [ReactNode, string];

  const { currentUser } = useContext(UserContext);

  return (
    <TableRow
      hover
      onClick={(_) => {
        rowClick?.(user);
      }}
      role="checkbox"
      aria-checked={false}
      tabIndex={-1}
      //   selected={isItemSelected}
      sx={{ cursor: rowClick ? 'pointer' : 'cursor' }}
    >
      <TableCell align={'left'}>
        <Box display="flex" gap={'.5rem'}>
          <Box sx={{ width: '20px', fontSize: '20px', mt: 1, color: 'var(--color-lightgray4)' }}>{avatar}</Box>
          <Box sx={{ minwidth: 0, flex: 1, wordBreak: 'break-word' }}>
            <Typography sx={{ fontSize: 'inherit', color: 'inherit' }}>
              {name} {currentUser?.id === user.id && `(${intl.formatMessage({ id: 'you', defaultMessage: 'you' })})`}
            </Typography>
            <Typography sx={{ fontSize: 'var(--fs-12)', color: 'var(--color-lightgray5)' }}>{user.email}</Typography>
          </Box>
        </Box>
      </TableCell>
      <TableCell align={'left'}>{user.organizationUnits ? <OrgList userOrganizations={user.organizationUnits} /> : <span>&mdash;</span>}</TableCell>
      <TableCell align={'left'}>{user.groups ? <TeamList userTeams={user.groups} /> : <span>&mdash;</span>}</TableCell>
      <TableCell align={'left'}>{user.creationDate}</TableCell>
      <TableCell align={'left'}>{user.updatedDate}</TableCell>
      <TableCell align={'left'}>{user.deactivatedDtg ? dayjs(user.deactivatedDtg, DateStringISOFormat).format('YYYY-MM-DD HH:mm:ss') : <span>&mdash;</span>}</TableCell>
      <TableCell align={'left'}>
        <Box display={'flex'} alignItems={'center'}>
          {currentUser?.isAdmin && (
            <>
              <ShadowedButton
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  handleCreateUser(user);
                }}
                sx={{ whiteSpace: 'nowrap', ml: 'auto' }}
              >
                <FormattedMessage id="edit" defaultMessage="Edit" />
              </ShadowedButton>
              <Box ml={1} width={'30px'}>
                {currentUser?.id !== user.id && (
                  <IconButton
                    sx={{ fontSize: 'var(--fs-14)' }}
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      handleDeleteUser(user);
                    }}
                  >
                    <GarbageIcon fontSize="inherit" sx={{ color: 'var(--color-lightgray4)' }} />
                  </IconButton>
                )}
              </Box>
            </>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
});

const MAXORGANIZATIONS = 2;
const OrgList = memo(({ userOrganizations }: { userOrganizations: OrganizationUnit[] }) => {
  const { organizations } = useContext(OrganizationsContext);
  const validOrganizationIds = organizations?.map((t) => t.id) ?? [];

  const validOrganizations = userOrganizations.filter((org) => validOrganizationIds.includes(org.id));

  return (
    <Box sx={{ wordBreak: 'break-word' }}>
      {validOrganizations.length > 0 ? (
        <Box>
          {validOrganizations
            .slice(0, MAXORGANIZATIONS)
            .map((t) => t.name)
            .join(', ')}
          {validOrganizations.length > MAXORGANIZATIONS && (
            <ContentWithDarkTooltip
              tooltip={
                <Box px={1} py={0} className="custom-scrollbar" display="flex" flexDirection="column" rowGap={'.5rem'} sx={{ maxHeight: '200px', overflowY: 'auto' }}>
                  {validOrganizations.slice(MAXORGANIZATIONS).map((l) => (
                    <Box key={l.id}>{l.name}</Box>
                  ))}
                </Box>
              }
              boxProps={{ ml: 0.5, display: 'inline-block' }}
            >
              <StatusTag tag_label={`+${validOrganizations.length - MAXORGANIZATIONS}`} hideActive={true} bg={'white'} textColor="#6E6C6B" />
            </ContentWithDarkTooltip>
          )}
        </Box>
      ) : (
        <span>&mdash;</span>
      )}
    </Box>
  );
});

const MAXTEAMS = 3;
const TeamList = memo(({ userTeams }: { userTeams: TeamType[] }) => {
  const { teams } = useContext(TeamsContext);
  const validTeamIds = teams?.map((t) => t.id) ?? [];

  const validTeams = userTeams.filter((t) => validTeamIds.includes(t.id));

  return (
    <Box sx={{ wordBreak: 'break-word' }}>
      {validTeams.length > 0 ? (
        <Box>
          {validTeams
            .slice(0, MAXTEAMS)
            .map((t) => t.name)
            .join(', ')}
          {validTeams.length > MAXTEAMS && (
            <ContentWithDarkTooltip
              tooltip={
                <Box px={1} py={0} className="custom-scrollbar" display="flex" flexDirection="column" rowGap={'.5rem'} sx={{ maxHeight: '200px', overflowY: 'auto' }}>
                  {validTeams.slice(MAXTEAMS).map((l) => (
                    <Box key={l.id}>{l.name}</Box>
                  ))}
                </Box>
              }
              boxProps={{ ml: 0.5, display: 'inline-block' }}
            >
              <StatusTag tag_label={`+${validTeams.length - MAXTEAMS}`} hideActive={true} bg={'white'} textColor="#6E6C6B" />
            </ContentWithDarkTooltip>
          )}
        </Box>
      ) : (
        <span>&mdash;</span>
      )}
    </Box>
  );
});

const EditUserContent = memo(({ userId, close, setUser }: EditUserContentProps) => {
  const intl = useIntl();
  const addSnackbar = useAddSnackbar();

  const { organizations } = useContext(OrganizationsContext);
  const { roles } = useContext(RolesContext);
  const { teams } = useContext(TeamsContext);
  const { allUsers } = useContext(AllUsersContext);
  const { currentUser } = useContext(UserContext);

  const [removingElements, setRemovingElements] = useState<{
    organizations: number[];
    roles: number[];
    teams: number[];
  }>({
    organizations: [],
    roles: [],
    teams: [],
  });

  const user = allUsers?.filter((u) => u.id === userId)[0];

  const [activeTab, setActiveTab] = useState<'organizations' | 'roles' | 'teams'>('organizations');

  const handleTabChange = (_: React.SyntheticEvent, tabValue: typeof activeTab) => {
    const newTab = tabValue;

    setActiveTab(newTab);
  };
  const tabs = useMemo(
    () => [
      {
        text: intl.formatMessage({ id: 'organizations', defaultMessage: 'Organizations' }),
        value: 'organizations',
      },
      {
        text: intl.formatMessage({ id: 'teams', defaultMessage: 'Teams' }),
        value: 'teams',
      },
      {
        text: intl.formatMessage({ id: 'roles', defaultMessage: 'Roles' }),
        value: 'roles',
      },
    ],
    [intl]
  );

  const handleRemoveElement = useCallback(
    (type: 'organizations' | 'roles' | 'teams') => {
      const removeElement = async (elementId: number) => {
        if (!user) return;
        if (!currentUser?.isAdmin) return;

        let newData: Partial<UserType>;
        // let userKey: keyof UserType;

        switch (type) {
          case 'organizations':
            // userKey = 'organizationUnits';
            newData = {
              organizationUnits: (user.organizationUnits || []).filter((org) => org.id !== elementId),
            };
            break;
          case 'roles':
            // userKey = 'roles';
            newData = {
              roles: (user.roles || []).filter((role) => role.id !== elementId),
            };
            break;
          case 'teams':
            // userKey = 'groups';
            newData = {
              groups: (user.groups || []).filter((group) => group.id !== elementId),
            };
            break;
          default:
            return;
        }

        if (!newData) return;

        setRemovingElements((currentElements) => ({ ...currentElements, [type]: [...currentElements[type], elementId] }));

        const result = await setUser(newData, user);

        if (result.status !== 'success') {
          const updateErrorMessage = user
            ? intl.formatMessage({
                id: 'updateUserError',
                defaultMessage: 'An error occured while updating the user',
              })
            : intl.formatMessage({
                id: 'createUserError',
                defaultMessage: 'An error occured while creating the user',
              });
          addSnackbar(updateErrorMessage, 'error');
        }

        setRemovingElements((currentElements) => ({ ...currentElements, [type]: currentElements[type].filter((e) => e !== elementId) }));
      };
      return removeElement;
    },
    [user, setUser, addSnackbar, intl, currentUser]
  );

  const handleAddElement = useCallback(
    (type: 'organizations' | 'roles' | 'teams') => {
      const addElement = async (elementIds: number[]) => {
        const defaultReturnError = { status: 'error' } as PromiseResponse;

        if (!user) return defaultReturnError;
        if (!currentUser?.isAdmin) return defaultReturnError;
        let newData: Partial<UserType> | null = null;
        // let userKey: keyof UserType;

        switch (type) {
          case 'organizations':
            if (organizations) {
              newData = {
                organizationUnits: [...(user.organizationUnits || []), ...organizations.filter((org) => elementIds.includes(org.id))],
              };
            }
            break;
          case 'roles':
            if (roles) {
              newData = {
                roles: [...(user.roles || []), ...roles.filter((role) => elementIds.includes(role.id))],
              };
            }
            break;
          case 'teams':
            if (teams) {
              newData = {
                groups: [...(user.groups || []), ...teams.filter((team) => elementIds.includes(team.id))],
              };
            }
            break;
          // default:
          //   return defaultReturnError;
        }

        if (!newData) return defaultReturnError;

        return await setUser(newData, user);
      };
      return addElement;
    },
    [user, setUser, organizations, roles, teams, currentUser]
  );

  let avatar, name;
  if (user) {
    [avatar, name] = GetAvatar(user.userName, [user], true) as [ReactNode, string];
  }

  let contentList: OrganizationUnit[] | TeamType[] | RoleType[] | undefined;
  let activeContent: OrganizationUnit[] | TeamType[] | RoleType[] = [];
  let emptyMessage = '';
  let addButtonMessage = '';
  let minimumActive = 0;

  switch (activeTab) {
    case 'organizations':
      contentList = organizations;
      activeContent = user?.organizationUnits ?? [];
      emptyMessage = intl.formatMessage({ id: 'noOrganizationForUser', defaultMessage: 'User is not assigned to any organization' });
      addButtonMessage = intl.formatMessage({ id: 'addOrganizations', defaultMessage: 'Add organizations' });
      // addButtonMessage = intl.formatMessage({ id: 'organizations', defaultMessage: 'organizations' });
      minimumActive = 1;
      break;
    case 'teams':
      contentList = teams;
      activeContent = user?.groups ?? [];
      emptyMessage = intl.formatMessage({ id: 'noTeamForUser', defaultMessage: 'User is not assigned to any team' });
      addButtonMessage = intl.formatMessage({ id: 'addTeams', defaultMessage: 'Add teams' });
      // addButtonMessage = intl.formatMessage({ id: 'teams', defaultMessage: 'teams' });
      break;
    case 'roles':
      contentList = roles;
      activeContent = user?.roles ?? [];
      emptyMessage = intl.formatMessage({ id: 'noRoleForUser', defaultMessage: 'User is not assigned to any role' });
      addButtonMessage = intl.formatMessage({ id: 'addRoles', defaultMessage: 'Add roles' });
      // addButtonMessage = intl.formatMessage({ id: 'roles', defaultMessage: 'roles' });
      break;
  }

  return (
    <Box p={4} width={'min(85vw, 400px)'} display="flex" flexDirection={'column'} height={'100%'}>
      {user && (
        <>
          <Box display="flex" width="100%" gap={'1rem'} alignItems={'flex-start'}>
            <Box display="flex" gap={'.5rem'} sx={{ flex: 1 }}>
              <Box sx={{ width: '40px', fontSize: '40px', color: 'var(--color-lightgray4)' }}>{avatar}</Box>
              <Box sx={{ flex: 1, minWidth: 0, overflow: 'hidden' }}>
                <Typography sx={{ wordBreak: 'break-word', fontWeight: 600, lineHeight: 1.2, fontSize: 'var(--fs-20)', color: 'var(--color-darkgray)' }}> {name}</Typography>
                <Typography sx={{ wordBreak: 'break-word', lineHeight: 1.2, fontSize: 'var(--fs-14)', color: '#7A7E85' }}>{user.email}</Typography>
              </Box>
            </Box>
            <IconButton
              aria-label="close"
              onClick={close}
              sx={{
                fontSize: '1rem',
                color: 'var(--color-lightgray4)',
              }}
            >
              <CrossIcon fontSize="inherit" />
            </IconButton>
          </Box>
          <Box display="flex" columnGap={1} sx={{ flexFlow: 'row wrap' }}>
            {user.locked && (
              <Box mt={1} className="rounded-border" sx={{ backgroundColor: 'var(--color-fushia)', color: 'white', fontSize: 'var(--fs-12)', fontWeight: 600, px: 1, py: 0.5 }}>
                <FormattedMessage id={'locked'} defaultMessage={'Locked'} />
              </Box>
            )}
            {user.deactivated && (
              <Box mt={1} className="rounded-border" sx={{ backgroundColor: 'var(--color-fushia)', color: 'white', fontSize: 'var(--fs-12)', fontWeight: 600, px: 1, py: 0.5 }}>
                <FormattedMessage id={'deactivated'} defaultMessage={'Deactivated'} />
              </Box>
            )}
          </Box>
          <Box mb={3} mt={2} display="grid" gridTemplateColumns={'auto 1fr'} columnGap={2} rowGap={1}>
            <Typography sx={{ fontSize: 'var(--fs-14)', color: 'var(--color-gray2)' }}>
              <FormattedMessage id="login" defaultMessage={'Login'} />:
            </Typography>
            <Box>
              <Typography sx={{ fontSize: 'var(--fs-14)', color: 'var(--color-darkgray)', wordBreak: 'break-word' }}>{user.userName}</Typography>
            </Box>
            <Typography sx={{ fontSize: 'var(--fs-14)', color: 'var(--color-gray2)' }}>
              <FormattedMessage id="createdOn" defaultMessage={'CreatedOn'} />:
            </Typography>
            <Box>
              <Typography sx={{ fontSize: 'var(--fs-14)', color: 'var(--color-darkgray)', wordBreak: 'break-word' }}>{user.creationDate}</Typography>
            </Box>
            <Typography sx={{ fontSize: 'var(--fs-14)', color: 'var(--color-gray2)' }}>
              <FormattedMessage id="deactivatedOn" defaultMessage={'Deactivated on'} />:
            </Typography>
            <Box>
              <Typography sx={{ fontSize: 'var(--fs-14)', color: 'var(--color-darkgray)', wordBreak: 'break-word' }}>
                {user.deactivatedDtg ? dayjs(user.deactivatedDtg, DateStringISOFormat).format('YYYY-MM-DD HH:mm:ss') : <span>&mdash;</span>}
              </Typography>
            </Box>
          </Box>

          <TabsNavigation activeTab={activeTab} onChange={handleTabChange} skipAll tabTitles={tabs} label={'user content'} />

          <Box flex={1}>
            {contentList && (
              <EditUserContentList
                list={contentList}
                removeElement={handleRemoveElement(activeTab)}
                addElement={handleAddElement(activeTab)}
                minimumActive={minimumActive}
                removingElements={removingElements[activeTab]}
                {...{ activeContent, emptyMessage, addButtonMessage }}
              />
            )}
          </Box>
        </>
      )}
    </Box>
  );
});

const EditUserContentList = memo(({ removingElements, removeElement, list, addElement, activeContent, minimumActive, emptyMessage, addButtonMessage }: EditUserContentListProps) => {
  const { toggleModal } = useAddModal();
  const { currentUser } = useContext(UserContext);

  const validIds = list.map((l) => l.id);
  const includedIds = activeContent.map((ac) => ac.id);

  const validContent = activeContent.filter((c) => validIds.includes(c.id));
  const mayAddContent = list.filter((c) => !includedIds.includes(c.id));

  const handleAddContentToUser = useCallback(
    (contentToAdd: typeof list, title: string) => {
      if (!currentUser?.isAdmin) return;
      toggleModal?.({
        title: title,
        modalContent: (
          <AddContentToUser
            {...{
              contentToAdd,
              buttonText: title,
              addContent: addElement,
            }}
          />
        ),
        maxWidth: 'sm',
      });
    },
    [toggleModal, addElement, currentUser]
  );

  return (
    <Box height={'100%'} display="flex" flexDirection={'column'}>
      <Box my={3} flex={1} sx={{ minHeight: 0, overflowY: 'auto' }} className="custom-scrollbar">
        {validContent.length <= 0 ? (
          <Box fontWeight={500} textAlign={'center'} sx={{ fontSize: 'var(--fs-14)', color: 'var(--color-gray2)' }}>
            {emptyMessage}
          </Box>
        ) : (
          <Box sx={{ fontSize: 'var(--fs-14)', color: 'var(--color-darkgray)' }}>
            {validContent.map((content) => {
              return (
                <Box
                  key={content.id}
                  display={'flex'}
                  alignItems={'center'}
                  px={1}
                  py={1}
                  sx={{
                    boxSizing: 'content-box',
                    minHeight: '30px',
                    '& .delete-container': { display: 'none' },
                    '&:hover': {
                      '& .delete-container': { display: 'block' },
                      backgroundColor: 'var(--color-lightgray6)',
                    },
                  }}
                >
                  <Box display="flex" sx={{ flex: 1, minWidth: 0, overflow: 'hidden' }}>
                    {content.name}
                  </Box>

                  {currentUser?.isAdmin && validContent.length > minimumActive && (
                    <Box className="delete-container">
                      {removingElements.includes(content.id) ? (
                        <Box>
                          <Loader cssStyle={{ width: '20px' }} />
                        </Box>
                      ) : (
                        <ShadowedButton onClick={() => removeElement(content.id)} sx={{ fontSize: 'var(--fs-12)', px: '4px', py: '8px', lineHeight: 1, backgroundColor: 'white' }}>
                          <FormattedMessage id="delete" defaultMessage="Delete" />
                        </ShadowedButton>
                      )}
                    </Box>
                  )}
                </Box>
              );
            })}
          </Box>
        )}
      </Box>

      {currentUser?.isAdmin && (
        <Box textAlign={'center'}>
          <AddButton
            disabled={mayAddContent.length <= 0}
            onClick={() => {
              handleAddContentToUser(mayAddContent, addButtonMessage);
            }}
            // disabled={usersToAdd.length <= 0}
          >
            {addButtonMessage}
          </AddButton>
        </Box>
      )}
    </Box>
  );
});

const AddContentToUser = ({ contentToAdd, addContent, buttonText }: { contentToAdd: EditUserContentListProps['list']; addContent: EditUserContentListProps['addElement']; buttonText: string }) => {
  const { closeModal, blockModal } = useAddModal();
  const addSnackbar = useAddSnackbar();
  const intl = useIntl();

  const [addingContent, setAddingContent] = useState(false);
  const [selectedContent, setSelectedContent] = useState<{ nbValues: number; values: string[] }>({ nbValues: 0, values: [] });

  const setSelected = useCallback(({ nbValues, values }: { nbValues: number; values: string[] }) => {
    setSelectedContent({ nbValues, values });
  }, []);

  const handleAddContent = useCallback(
    async (selection: typeof selectedContent) => {
      if (selection.nbValues <= 0) {
        closeModal?.();
        return;
      }
      blockModal?.(true);
      setAddingContent(true);

      const result = await addContent(selection.values.map((s) => Number(s)));

      blockModal?.(false);
      setAddingContent(false);

      if (result.status === 'success') {
        closeModal?.();
      } else {
        const updateErrorMessage = intl.formatMessage({
          id: 'updateUserError',
          defaultMessage: 'An error occured while updating the user',
        });

        addSnackbar(updateErrorMessage, 'error');
      }
    },
    [closeModal, addContent, addSnackbar, blockModal, intl]
  );

  const virtualCheckboxList = useMemo(() => {
    return contentToAdd.map((c) => ({ key: String(c.id), value: c.name }));
  }, [contentToAdd]);

  return (
    <Box width={'min(85vw, 450px)'}>
      <Box px={3}>
        <FilterVirtualCheckboxes title={''} list={virtualCheckboxList} hideTitle setNewFilterValue={setSelected} setOnSelection noBorder virtualWidth={'100%'} />
      </Box>
      <Box display={'flex'} justifyContent={'flex-end'} gap={'1rem'} px={3} pt={2} mt={2} sx={{ borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
        {addingContent ? (
          <Box>
            <Loader cssStyle={{ width: '33px' }} />
          </Box>
        ) : (
          <>
            <ShadowedButton
              onClick={() => {
                closeModal?.();
              }}
              sx={{ whiteSpace: 'nowrap' }}
            >
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </ShadowedButton>

            <Button type="button" variant="contained" disabled={selectedContent.nbValues <= 0} onClick={() => handleAddContent(selectedContent)} disableElevation sx={{ textTransform: 'none' }}>
              {buttonText}
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

const EditUser = memo(({ user, setUser, organizations, roles }: EditUserProps) => {
  const { closeModal, blockModal } = useAddModal();
  const addSnackbar = useAddSnackbar();
  const intl = useIntl();

  const [userParams, setUserParams] = useState<Partial<UserType>>(user ?? {});
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [creatingUser, setCreatingUser] = useState(false);

  const [activeTab, setActiveTab] = useState<string>('');

  const handleTabChange = (_: React.SyntheticEvent, tabValue: 'string') => {
    const newTab = tabValue;
    setActiveTab(newTab);
  };

  const handleChangeParams = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setUserParams((params) => ({ ...params, [event.target.name]: event.target.value }));
  }, []);

  const handleChangeSwitch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setUserParams((params) => ({ ...params, [event.target.name]: event.target.checked }));
  }, []);

  const hanldeChangeConfirmPassword = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(event.target.value);
  }, []);

  const hanldeChangeOrganizations = useCallback((newOrganizations: OrganizationUnit[]) => {
    setUserParams((params) => ({ ...params, organizationUnits: newOrganizations }));
  }, []);
  const hanldeChangeRoles = useCallback((newRoles: RoleType[]) => {
    setUserParams((params) => ({ ...params, roles: newRoles }));
  }, []);

  const addOrCreateUser = useCallback(async () => {
    blockModal?.(true);
    setCreatingUser(true);

    const result = await setUser(userParams, user);

    setCreatingUser(false);
    blockModal?.(false);

    if (result.status === 'success') {
      const updateSuccessMessage = user
        ? intl.formatMessage({
            id: 'updateUserSuccess',
            defaultMessage: 'User updated',
          })
        : intl.formatMessage({
            id: 'createUserSuccess',
            defaultMessage: 'New user created',
          });

      addSnackbar(updateSuccessMessage, 'success');
      closeModal?.();
    } else {
      const updateErrorMessage = user
        ? intl.formatMessage({
            id: 'updateUserError',
            defaultMessage: 'An error occured while updating the user',
          })
        : intl.formatMessage({
            id: 'createUserError',
            defaultMessage: 'An error occured while creating the user',
          });
      addSnackbar(updateErrorMessage, 'error');
    }
  }, [blockModal, closeModal, user, userParams, setUser, addSnackbar, intl]);

  const setMessage = user ? intl.formatMessage({ id: 'save', defaultMessage: 'Save' }) : intl.formatMessage({ id: 'create', defaultMessage: 'Create' });
  const MemoInputProps = useMemo(
    () => ({
      startAdornment: null,
    }),
    []
  );

  const tabs = useMemo(
    () => [
      {
        text: intl.formatMessage({ id: 'status', defaultMessage: 'Status' }),
        value: 'status',
      },
      {
        text: intl.formatMessage({ id: 'password', defaultMessage: 'Password' }),
        value: 'password',
      },
    ],
    [intl]
  );

  const isEmailError = !!userParams.email?.trim() && !validateEmail(userParams.email?.trim());
  const isValidPassword = validatePassword(userParams.password ?? '', intl);
  let isPasswordError =
    (!user && !userParams.password) || (userParams.password && confirmPassword && userParams.password !== confirmPassword) || (!!userParams.password && !!validatePassword(userParams.password, intl));
  let passwordError = '';
  if (!user && !userParams.password) {
    passwordError = intl.formatMessage({ id: 'passwordRequiredForUserCreation', defaultMessage: 'You must define a password' });
  } else if (userParams.password && isValidPassword) {
    passwordError = isValidPassword;
  } else if (userParams.password && !confirmPassword) {
    passwordError = intl.formatMessage({ id: 'missingConfirmPassword', defaultMessage: 'You must confirm the password' });
  } else if (userParams.password && confirmPassword && userParams.password !== confirmPassword) {
    passwordError = intl.formatMessage({ id: 'passwordMismatch', defaultMessage: 'Password mismatch' });
  }

  const missingOrganizations = !userParams.organizationUnits || userParams.organizationUnits.length <= 0;

  let buttonTooltip = passwordError;
  if (missingOrganizations) {
    buttonTooltip = intl.formatMessage({ id: 'mustChooseOrganization', defaultMessage: 'You must choose an organization' });
  }

  const disableSave =
    !userParams.firstname?.trim() ||
    !userParams.lastname?.trim() ||
    !userParams.userName?.trim() ||
    !userParams.email?.trim() ||
    !validateEmail(userParams.email.trim()) ||
    isPasswordError ||
    missingOrganizations;

  let tabContent;

  switch (activeTab) {
    case '':
      tabContent = <EditUserPermissions {...{ creatingUser, userParams, organizations, hanldeChangeOrganizations, roles, hanldeChangeRoles }} />;
      break;
    case 'status':
      tabContent = <EditUserStatus {...{ creatingUser, userParams, handleChangeSwitch }} />;
      break;
    case 'password':
      tabContent = <EditUserPassword {...{ creatingUser, userParams, confirmPassword, handleChangeParams, hanldeChangeConfirmPassword }} />;
      break;
    default:
      break;
  }

  return (
    <Box width={'min(85vw, 450px)'} display="flex" flexDirection="column" sx={{ minHeight: 'min(80vh, 580px)' }}>
      <form autoComplete="off" style={{ flex: 1 }}>
        <Box px={3}>
          <Typography className="modal-label">
            <FormattedMessage id="firstname" defaultMessage={'Firstname'} />
          </Typography>
          <SearchText
            fullWidth
            name={'firstname'}
            value={userParams.firstname ?? ''}
            onChange={handleChangeParams}
            placeholder={intl.formatMessage({ id: 'chooseFirstname', defaultMessage: 'Firstname' })}
            InputProps={MemoInputProps}
            disabled={creatingUser}
            autoComplete="new-password"
          />

          <Typography className="modal-label" mt={1}>
            <FormattedMessage id="lastname" defaultMessage={'Lastname'} />
          </Typography>
          <SearchText
            fullWidth
            name={'lastname'}
            value={userParams.lastname ?? ''}
            onChange={handleChangeParams}
            placeholder={intl.formatMessage({ id: 'chooseLastname', defaultMessage: 'Lastname' })}
            InputProps={MemoInputProps}
            disabled={creatingUser}
            autoComplete="new-password"
          />

          <Typography className="modal-label" mt={1}>
            <FormattedMessage id="login" defaultMessage={'Login'} />
          </Typography>
          <SearchText
            fullWidth
            name={'userName'}
            value={userParams.userName ?? ''}
            onChange={handleChangeParams}
            placeholder={intl.formatMessage({ id: 'chooselogin', defaultMessage: 'Login' })}
            InputProps={MemoInputProps}
            disabled={creatingUser}
            autoComplete="new-password"
          />

          <Typography className="modal-label" mt={1}>
            <FormattedMessage id="email" defaultMessage={'Email'} />
          </Typography>
          <SearchText
            fullWidth
            name={'email'}
            value={userParams.email ?? ''}
            onChange={handleChangeParams}
            placeholder={intl.formatMessage({ id: 'chooseemail', defaultMessage: 'Email' })}
            InputProps={MemoInputProps}
            autoComplete="new-password"
            // inputProps={MemoEmail_inputProps}
            disabled={creatingUser}
            error={isEmailError}
          />

          <TabsNavigation
            allLabel={intl.formatMessage({ id: 'status', defaultMessage: 'Status' })}
            activeTab={activeTab}
            tabTitles={tabs}
            onChange={handleTabChange}
            label={'user content'}
            disabled={creatingUser}
            alwaysShowScrollButton={false}
            shiftByMargin={false}
          />
          <Box mt={2}>{tabContent}</Box>
        </Box>
      </form>
      <Box display={'flex'} justifyContent={'flex-end'} gap={'1rem'} px={3} pt={2} mt={2} sx={{ borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
        {creatingUser ? (
          <Box>
            <Loader cssStyle={{ width: '33px' }} />
          </Box>
        ) : (
          <>
            <ShadowedButton
              onClick={() => {
                closeModal?.();
              }}
              sx={{ whiteSpace: 'nowrap' }}
            >
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </ShadowedButton>

            <ContentWithDarkTooltip tooltip={buttonTooltip} placement="top">
              <Button type="button" variant="contained" disabled={disableSave} onClick={addOrCreateUser} disableElevation sx={{ textTransform: 'none' }}>
                {setMessage}
              </Button>
            </ContentWithDarkTooltip>
          </>
        )}
      </Box>
    </Box>
  );
});

const SearchFiltersMemo = memo(SearchFilters);
const EditUserPermissions = memo(({ creatingUser, userParams, organizations, hanldeChangeOrganizations, roles, hanldeChangeRoles }: EditUserPermissionsProps) => {
  const intl = useIntl();

  const organizatinonsRef = useRef<FilterButtonRefType | null>(null);
  const rolesRef = useRef<FilterButtonRefType | null>(null);

  const organizationsButtons: SearchFiltersButtonType[] = useMemo(() => {
    // const filteredPerm = getFilteredPermissions(role?.permissions || []);
    const validOrganizationIds = organizations?.map((t) => t.id) ?? [];

    const filteredOrganizations = userParams.organizationUnits?.filter((org) => validOrganizationIds.includes(org.id)) ?? [];

    return [
      {
        ref: organizatinonsRef,
        text: <FormattedMessage id="chooseOrganizations" defaultMessage="Choose organizations" />,
        inputName: 'organizations',
        filterContent: (
          <FilterCheckboxes
            maxHeight="min(30vh, 200px)"
            title={intl.formatMessage({ id: 'organizations', defaultMessage: 'Organizations' })}
            list={organizations.map((org) => ({ key: String(org.id), value: org.name }))}
          />
        ),
        initialValue: {
          nbValues: filteredOrganizations.length ?? 0,
          values: filteredOrganizations.map((org) => String(org.id)),
        },
      },
    ];
  }, [intl, organizations, userParams]);

  const onActivateOrgUnits = useCallback(() => {
    const selectedValues = organizatinonsRef.current?.getValue();
    if (selectedValues) {
      const newOrganizations = organizations.filter((org) => (selectedValues.values || []).includes(String(org.id)));
      hanldeChangeOrganizations(newOrganizations);
    }
  }, [organizations, hanldeChangeOrganizations]);

  const rolesButtons: SearchFiltersButtonType[] = useMemo(() => {
    // const filteredPerm = getFilteredPermissions(role?.permissions || []);
    const validRolesIds = roles?.map((t) => t.id) ?? [];

    const filteredRoles = userParams.roles?.filter((org) => validRolesIds.includes(org.id)) ?? [];

    return [
      {
        ref: rolesRef,
        text: <FormattedMessage id="chooseRoles" defaultMessage="Choose Roles" />,
        inputName: 'roles',
        filterContent: (
          <FilterCheckboxes
            maxHeight="min(30vh, 200px)"
            title={intl.formatMessage({ id: 'roles', defaultMessage: 'Roles' })}
            list={roles.map((role) => ({ key: String(role.id), value: role.name }))}
          />
        ),
        initialValue: {
          nbValues: filteredRoles.length ?? 0,
          values: filteredRoles.map((role) => String(role.id)),
        },
      },
    ];
  }, [intl, roles, userParams]);

  const onActivateRoles = useCallback(() => {
    const selectedValues = rolesRef.current?.getValue();
    if (selectedValues) {
      const newRoles = roles.filter((role) => (selectedValues.values || []).includes(String(role.id)));
      hanldeChangeRoles(newRoles);
    }
  }, [roles, hanldeChangeRoles]);

  return (
    <Box px={2}>
      <Typography className="modal-label">
        <FormattedMessage id="organizations" defaultMessage={'Organizations'} />
      </Typography>
      <SearchFiltersMemo disabled={creatingUser} hideClearButton={true} onActivate={onActivateOrgUnits} searchFiltersButtons={organizationsButtons} />

      <Typography className="modal-label" mt={1}>
        <FormattedMessage id="roles" defaultMessage={'Roles'} />
      </Typography>
      <SearchFiltersMemo disabled={creatingUser} hideClearButton={true} onActivate={onActivateRoles} searchFiltersButtons={rolesButtons} />
    </Box>
  );
});

const EditUserStatus = memo(({ creatingUser, userParams, handleChangeSwitch }: EditUserStatusProps) => {
  const intl = useIntl();

  return (
    <Box px={2}>
      <Box display="flex" sx={{ '& label': { flex: 1 } }}>
        <SwitchWithLabel
          disabled={creatingUser}
          label={intl.formatMessage({ id: 'admin', defaultMessage: 'Admin' })}
          // switchColor={locInfo.color}
          // switchPosition={'left'}
          inputSwitchProps={{ checked: userParams.isAdmin ?? false, onChange: handleChangeSwitch, name: 'isAdmin' }}
        />
      </Box>

      <Box display="flex" sx={{ '& label': { flex: 1 } }}>
        <SwitchWithLabel
          disabled={creatingUser}
          label={intl.formatMessage({ id: 'locked', defaultMessage: 'Locked' })}
          // switchColor={locInfo.color}
          // switchPosition={'left'}
          inputSwitchProps={{ checked: userParams.locked ?? false, onChange: handleChangeSwitch, name: 'locked' }}
        />
      </Box>

      <Box display="flex" sx={{ '& label': { flex: 1 } }}>
        <SwitchWithLabel
          disabled={creatingUser}
          label={intl.formatMessage({ id: 'deactivated', defaultMessage: 'Deactivated' })}
          // switchColor={locInfo.color}
          // switchPosition={'left'}
          inputSwitchProps={{ checked: userParams.deactivated ?? false, onChange: handleChangeSwitch, name: 'deactivated' }}
        />
      </Box>
    </Box>
  );
});

const EditUserPassword = memo(({ creatingUser, userParams, confirmPassword, handleChangeParams, hanldeChangeConfirmPassword }: EditUserPasswordProps) => {
  const intl = useIntl();

  const [passwordError, setPasswordError] = useState<string>('');

  const memoizedProps = useMemo(() => {
    return {
      startAdornment: null,
    };
  }, []);

  return (
    <Box>
      <Box mt={1}>
        <Typography className="modal-label">
          <FormattedMessage id="password" defaultMessage={'Password'} />
        </Typography>
        <SearchText
          fullWidth
          name={'password'}
          value={userParams.password ?? ''}
          onChange={handleChangeParams}
          placeholder="********"
          InputProps={memoizedProps}
          type="password"
          autoComplete="new-password"
          disabled={creatingUser}
          onBlur={() => {
            const pass = userParams.password;
            if (!pass) return setPasswordError('');
            const invalidPassword = validatePassword(pass ?? '', intl);
            if (invalidPassword) {
              return setPasswordError(invalidPassword);
            }
            setPasswordError('');
          }}
        />
        {passwordError && (
          <Typography mt={1} fontWeight="medium" color={'error'} sx={{ fontSize: 'var(--fs-12)' }}>
            {passwordError}
          </Typography>
        )}
      </Box>
      <Box mt={1}>
        <Typography className="modal-label">
          <FormattedMessage id="confirmPassword" defaultMessage={'Confirm password'} />
        </Typography>
        <SearchText
          fullWidth
          value={confirmPassword}
          onChange={hanldeChangeConfirmPassword}
          placeholder="********"
          InputProps={memoizedProps}
          type="password"
          autoComplete="new-password"
          disabled={creatingUser}
          error={!!userParams.password && !!confirmPassword && userParams.password !== confirmPassword}
        />
      </Box>
    </Box>
  );
});

const DeleteUser = memo(({ user, removeUser }: DeleteUserProps) => {
  const { closeModal, blockModal } = useAddModal();
  const addSnackbar = useAddSnackbar();
  const intl = useIntl();

  const [deletingUser, setDeletingUser] = useState(false);

  const deleteUser = useCallback(async () => {
    blockModal?.(true);
    setDeletingUser(true);

    const result = await removeUser(user.id);
    setDeletingUser(false);
    blockModal?.(false);

    if (result.status === 'success') {
      const deleteSuccessMessage = intl.formatMessage({
        id: 'deleteUserSuccess',
        defaultMessage: 'User deleted',
      });

      addSnackbar(deleteSuccessMessage, 'success');
      closeModal?.();
    } else {
      const deleteErrorMessage = intl.formatMessage({
        id: 'deleteUserError',
        defaultMessage: 'An error occured while deleting the user',
      });
      addSnackbar(deleteErrorMessage, 'error');
    }
  }, [blockModal, closeModal, user, removeUser, intl, addSnackbar]);

  return (
    <Box width={'min(85vw, 450px)'}>
      <Box px={3}>
        <Typography className="modal-label" sx={{ wordBreak: 'break-word' }}>
          User: {GetUser(user.userName, [user], false)}
        </Typography>

        <Typography className="modal-label">
          <FormattedMessage id="deleteUserMessage" defaultMessage={'Are you sure that you want to delete this user?'} />
        </Typography>
      </Box>

      <Box display={'flex'} justifyContent={'flex-end'} gap={'1rem'} px={3} pt={2} mt={2} sx={{ borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
        {deletingUser ? (
          <Box>
            <Loader cssStyle={{ width: '33px' }} />
          </Box>
        ) : (
          <>
            <ShadowedButton
              onClick={() => {
                closeModal?.();
              }}
              sx={{ whiteSpace: 'nowrap' }}
            >
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </ShadowedButton>

            <Button type="button" variant="contained" disabled={deletingUser} onClick={deleteUser} disableElevation sx={{ textTransform: 'none' }}>
              <FormattedMessage id="confirm" defaultMessage="Confirm" />
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
});

export default ManageUsers;
