/**
 * Registered.tsx
 * Main route for registered users
 */
/* packages */
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { Outlet, Navigate, ScrollRestoration } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

/* components */
import Loader from 'components/Loader/Loader';
import FullPageBox from 'components/FullPageBox/FullPageBox';
import GenericError from 'components/GenericError/GenericError';
import AppMenu from 'components/AppMenu/AppMenu';
import Footer from 'components/Footer/Footer';

import Box from '@mui/material/Box';

/* contexts */
import { useIsAuthenticated } from 'contextProviders/AuthProvider';

/* providers */
import { CombineProviders } from 'utilities/CombineProviders';
import { UserContext, UserProvider } from 'contextProviders/UserProvider';
import { SearchDatasetsProvider } from 'contextProviders/SearchDatasetsProvider';
import { CountriesProvider } from 'contextProviders/CountriesProvider';
import { WorkflowProvider } from 'contextProviders/WorkflowProvider';
import { TeamsProvider } from 'contextProviders/TeamsProvider';
import { AllUsersProvider } from 'contextProviders/AllUsersProvider';
import { SavedSearchFilterProvider } from 'contextProviders/SavedSearchFilterProvider';
import { UnreadAlertsProvider } from 'contextProviders/UnreadAlertsProvider';
import { DatasetsProvider } from 'contextProviders/DatasetsProvider';
import { NotificationsProvider } from 'contextProviders/NotificationsProvider';
import { NotificationsPanelProvider } from 'components/NotificationsPanel/NotificationsPanel';
import { PageModalProvider } from 'contextProviders/PageModalProvider';

/* components */
import PageContent from 'components/PageContent/PageContent';

/* utilities */
import { routerPages } from 'AppRouter';

/* elements */
const RegisteredProviders = CombineProviders([
  [UserProvider],
  [SearchDatasetsProvider],
  [CountriesProvider],
  [WorkflowProvider],
  [TeamsProvider],
  [AllUsersProvider],
  [SavedSearchFilterProvider],
  [UnreadAlertsProvider],
  [DatasetsProvider],
  [NotificationsProvider],
  [NotificationsPanelProvider],
  [PageModalProvider], // must be kept as the last provider
]);

const Registered = () => {
  const { isAuthenticated } = useIsAuthenticated();

  // redirect non logged in users
  if (!isAuthenticated) return <Navigate to={routerPages.loginPage} replace />;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <RegisteredProviders>
        <ScrollRestoration />
        <RegisteredLayout />
      </RegisteredProviders>
    </LocalizationProvider>
  );
};

const RegisteredLayout = () => {
  const { loading, currentUser } = useContext(UserContext);
  const intl = useIntl();

  if (loading)
    return (
      <FullPageBox>
        <Loader />
      </FullPageBox>
    );

  if (!currentUser)
    return (
      <FullPageBox>
        <GenericError message={intl.formatMessage({ id: 'currentUserError', defaultMessage: 'Current user data could not be retrieved. Contact your network administrator.' })} />
      </FullPageBox>
    );

  return (
    <Box sx={{ display: 'flex' }}>
      <AppMenu />

      <Box component="main" display={'grid'} gridTemplateRows={'1fr auto'} flexDirection={'column'} minHeight={'100vh'} flexGrow={1}>
        <PageContent>
          <Outlet />
        </PageContent>
        <Footer />
      </Box>
    </Box>
  );
};

/* exports */
export default Registered;
