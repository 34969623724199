/*
TermMatching.tsx
Define function to perform term matching
 */
/* packages*/
import { FormattedMessage } from 'react-intl';

/* elements */
export const GetMatchType = (matchType?: string) => {
  if (!matchType) return matchType;

  switch (matchType) {
    case 'st_match':
      return <FormattedMessage id="RuleBased" defaultMessage={'Rule based'} />;

    case 'ai_accept':
      return <FormattedMessage id="AIAssisted" defaultMessage={'AI - Assisted'} />;

    default:
      return matchType;
  }
};

export const GetGender = (gender?: string) => {
  if (!gender) return gender;
  const lowerGender = gender?.toLowerCase();
  switch (lowerGender) {
    case 'm':
      return <FormattedMessage id="male" defaultMessage={'Male'} />;

    case 'f':
      return <FormattedMessage id="female" defaultMessage={'Female'} />;

    default:
      return gender;
  }
};
