/**
 * TabsNavigation.tsx
 */
/* packages */
import { PropsWithoutRef } from 'react';

/* components */
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

/* types */
interface TabsNavigationProps {
  activeTab: string;
  tabTitles: { text?: string; value?: string | number; disabled?: boolean }[];
  onChange(event: React.SyntheticEvent, tabValue: string): void;
  label: string;
  disabled?: boolean;
  allLabel?: string;
  skipAll?: boolean;
  alwaysShowScrollButton?: boolean;
  shiftByMargin?: boolean;
}
/* elements */

const a11yProps = (cat: string) => {
  return {
    id: `tab-${cat}`,
    'aria-controls': `tabpanel-${cat}`,
    sx: {
      // marginRight: '1rem',
      minWidth: 0,
      minHeight: 0,
      paddingBlock: '.5rem',
      paddingInline: '0.25rem',
      color: '#7A7E85',
      '&.Mui-selected': {
        color: '#333333',
      },
      '&.Mui-disabled': { color: '#DAD8D8' },
    },
  };
};

const TabsNavigation = (props: PropsWithoutRef<TabsNavigationProps>) => {
  const { activeTab, tabTitles, onChange, label, allLabel, disabled, skipAll, alwaysShowScrollButton, shiftByMargin } = props;
  return (
    <Tabs
      className={'navigation-tabs'}
      value={activeTab}
      onChange={onChange}
      aria-label={`Tabulation for ${label}`}
      sx={{
        marginLeft: shiftByMargin ?? true ? '-40px' : 'unset',
        marginRight: shiftByMargin ?? true ? '-40px' : 'unset',
        minHeight: 0,
        '& .MuiTabs-flexContainer': {
          gap: '1rem',
        },
        '& .MuiTabScrollButton-horizontal': {
          width: '40px',
        },
        '& .MuiTabs-indicator': {
          zIndex: 5,
        },
        '& .MuiTabs-scroller': {
          '&:after': {
            content: '""',
            position: 'absolute',
            left: 0,
            width: '100%',
            zIndex: 0,
            bottom: 0,
            height: '1px',
            backgroundColor: 'var(--color-grayHeaderBorder)',
            transform: 'translateY(-0.5px)',
          },
        },
      }}
      variant="scrollable"
      scrollButtons={alwaysShowScrollButton ?? true}
      allowScrollButtonsMobile
      selectionFollowsFocus
    >
      {!skipAll && <Tab disableRipple label={allLabel ?? 'All'} value="" {...a11yProps(allLabel ?? 'all')} disabled={disabled} />}

      {tabTitles
        .filter((c) => c !== undefined)
        .map((c, cIndex) => {
          return (
            <Tab
              key={c.value}
              disableRipple
              label={c.text}
              value={c.value}
              {...a11yProps(String(cIndex))}
              disabled={disabled || c.disabled}
              // sx={{ minWidth: 0, '&.Mui-disabled': { color: '#DAD8D8' } }}
            />
          );
        })}
    </Tabs>
  );
};

export default TabsNavigation;
