import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const LinkIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
        <path
          d="M10.207,5.793a4.748,4.748,0,0,1,.011,6.706l-.011.012-2.1,2.1A4.75,4.75,0,0,1,1.389,7.893l1.16-1.16a.5.5,0,0,1,.853.331,5.758,5.758,0,0,0,.3,1.648.5.5,0,0,1-.118.519l-.409.409a2.25,2.25,0,1,0,3.162,3.2l2.1-2.1a2.249,2.249,0,0,0,0-3.182,2.341,2.341,0,0,0-.323-.268A.5.5,0,0,1,7.9,6.9a1.245,1.245,0,0,1,.366-.931l.658-.658a.5.5,0,0,1,.643-.054,4.765,4.765,0,0,1,.641.537Zm4.4-4.4a4.755,4.755,0,0,0-6.718,0l-2.1,2.1L5.782,3.5a4.751,4.751,0,0,0,.653,7.243.5.5,0,0,0,.643-.054l.658-.658A1.245,1.245,0,0,0,8.1,9.1a.5.5,0,0,0-.217-.394,2.341,2.341,0,0,1-.323-.268,2.249,2.249,0,0,1,0-3.182l2.1-2.1a2.25,2.25,0,1,1,3.162,3.2l-.409.409a.5.5,0,0,0-.118.519,5.758,5.758,0,0,1,.3,1.648.5.5,0,0,0,.853.331l1.16-1.16a4.755,4.755,0,0,0,0-6.717Z"
          transform="translate(0 0)"
          fill="currentColor"
        />
      </svg>
    </SvgIcon>
  );
};
