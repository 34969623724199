/**
 * StatusTag.tsx
 * Display a status tag
 */
/* packages */
import { CSSProperties } from 'react';

/* components */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

/* types */
interface StatusTagProps {
  tag_label?: string;
  active?: boolean;
  hideActive?: boolean;
  bg?: string;
  type?: 'label' | 'category';
  activeColor?: string;
  withoutOverflow?: boolean;
  textColor?: string;
  hideBorder?: boolean;
}

/* elements */
const StatusTag = ({ tag_label, active, bg, hideActive, type, activeColor, withoutOverflow, textColor, hideBorder }: StatusTagProps) => {
  const labelSx: CSSProperties = {};

  labelSx.color = 'var(--color-gray2)';
  if (bg) {
    labelSx.background = bg;
  }
  if (type === 'label') {
    labelSx.background = 'white';
  }
  if (type === 'category') {
    labelSx.background = '#E5E2E2';
    labelSx.color = '#151414';
  }

  if (withoutOverflow) {
    labelSx.maxWidth = '100%';
    labelSx.overflow = 'hidden';
  }

  if (textColor) {
    labelSx.color = textColor;
  }

  if (hideBorder) {
    labelSx.border = 'none!important';
  }

  return (
    <Box className="label-tag" sx={labelSx}>
      {!hideActive && <Box borderRadius={'50%'} mr={1} sx={{ width: '8px', aspectRatio: '1 / 1', backgroundColor: active ? activeColor ?? '#A7A7A7' : '#2FB6B4' }}></Box>}
      <Typography
        whiteSpace={'nowrap'}
        sx={{ color: 'inherit', fontSize: 'inherit', fontWeight: 'inherit', maxWidth: '100%', overflow: withoutOverflow ? 'hidden' : 'unset', textOverflow: 'ellipsis' }}
      >
        {tag_label}
      </Typography>
    </Box>
  );
};

export default StatusTag;
