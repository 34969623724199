/**
 ImportAlertScreening.tsx
 * Import alert screening modal
 */
/* packages */
import { useState, useCallback, memo, useRef, SyntheticEvent, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

/* context */

/* hooks */
import { useAddModal } from 'contextProviders/ModalProvider';
import { useAddSnackbar } from 'contextProviders/SnackbarProvider';

/* components */
import Input from '@mui/material/Input';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import ShadowedButton from 'components/ShadowedButton/ShadowedButton';
import Loader from 'components/Loader/Loader';

import { ValidatedIcon } from 'icons/validated/validated';

/* utilities */
import { StatusPromiseResponse } from 'models/utils';
import { displayError } from 'utilities/Logger';
import { AlertScreeningType, ImportAlertScreeningReponse } from 'models/alertScreening';
import { Audit } from 'models/audit';

/* types */
interface ImportAlertScreeningProps {
  alertScreening: AlertScreeningType;
  exportAlertScreening?(alertScreening: AlertScreeningType, abortController?: AbortController): Promise<StatusPromiseResponse>;
  importAlertScreening?(file: File, alertScreening: AlertScreeningType): Promise<ImportAlertScreeningReponse>;
}

/* elements */
const allowedFileExtension = ['.json'];
let closingTimeout: ReturnType<typeof setTimeout> | null = null;
const ImportAlertScreening = memo(({ alertScreening, exportAlertScreening, importAlertScreening }: ImportAlertScreeningProps) => {
  const { closeModal, blockModal } = useAddModal();
  const addSnackbar = useAddSnackbar();
  const intl = useIntl();

  const [step, setStep] = useState<number>(1);
  const [imported, setImported] = useState<boolean>(false);
  const [importing, setImporting] = useState<boolean>(false);

  // const [selectedFile, setSelectedFile] = useState<File | undefined>();
  const [error, setError] = useState<string>('');
  const [errorContent, setErrorContent] = useState<Audit[] | undefined>(undefined);
  const inputRef = useRef<HTMLInputElement>(null);
  // const [closingTimeout, setClosingTimeout] = useState<ReturnType<typeof setTimeout> | null>(null);
  // const closingTimeout = useRef<ReturnType<typeof setTimeout>>(null);

  useEffect(() => {
    // nothing to do, just cancel any exisinting timeout on close
    return () => {
      if (closingTimeout) {
        clearTimeout(closingTimeout);
      }
    };
  }, []);

  const handleChangePage = useCallback((shift: -1 | 1) => {
    setStep((step) => step + shift);
    setError('');
    setErrorContent(undefined);
    // setSelectedFile(undefined);
  }, []);

  const handleExportAlertScreening = useCallback(async () => {
    if (!exportAlertScreening) return;

    blockModal?.(true);
    setImporting(true);
    const result = await exportAlertScreening(alertScreening);

    blockModal?.(false);
    setImporting(false);

    if (result.status === 'success') {
      setStep((step) => step + 1);
    } else {
      const downloadAlertScreeningErrorMessage = intl.formatMessage({
        id: 'downloadAlertScreeningError',
        defaultMessage: 'An error occured while downloading the alert screening',
      });
      addSnackbar(downloadAlertScreeningErrorMessage, 'error');
    }
  }, [blockModal, intl, addSnackbar, alertScreening, exportAlertScreening]);

  const handleImportAlertScreening = useCallback(
    async (file: File) => {
      if (!importAlertScreening) {
        displayError('invalid function: importAlertScreening');
        return;
      }

      blockModal?.(true);
      setImporting(true);
      setError('');
      setErrorContent(undefined);

      try {
        const importedAlertScreening = await importAlertScreening(file, alertScreening);

        if (importedAlertScreening.audits) {
          const errorMessage =
            intl.formatMessage({ id: 'fileUploadHas', defaultMessage: 'The file you uploaded has' }) +
            ` ${importedAlertScreening.audits.length} ` +
            intl.formatMessage({ id: 'erros', defaultMessage: 'errors.' });
          setError(errorMessage);
          setErrorContent(importedAlertScreening.audits);
        } else {
          setImported(true);
          const newTimeout = setTimeout(() => {
            closeModal?.();
          }, 1500);
          closingTimeout = newTimeout;
          // setClosingTimeout(closingTimeout);

          const importAlertScreeningSuccessMessage = intl.formatMessage({
            id: 'importAlertScreeningSuccessMessage',
            defaultMessage: 'Alert screening imported',
          });
          addSnackbar(importAlertScreeningSuccessMessage, 'success');
        }
      } catch (uploadError: any) {
        const uploadErrorMessage =
          uploadError.message ??
          intl.formatMessage({
            id: 'uploadAlertScreeningError',
            defaultMessage: 'An error occurred while importing data',
          });
        setError(uploadErrorMessage);
        // errorUpload = true;
      }

      blockModal?.(false);
      setImporting(false);
    },
    [blockModal, closeModal, addSnackbar, alertScreening, intl, importAlertScreening]
  );

  const clearInputs = useCallback(() => {
    // setSelectedFile(undefined);
  }, []);

  const validateFile = useCallback(
    (receivedFile: File | null | undefined) => {
      if (!receivedFile) {
        return clearInputs();
      }

      const fileExtension = receivedFile.name.split('.').pop();
      if (!allowedFileExtension.includes(`.${fileExtension}`)) {
        const fileErrorMessage = intl.formatMessage({
          id: 'fileInvalidExtension',
          defaultMessage: 'Invalid file type',
        });
        // addSnackbar(fileErrorMessage, 'error');
        setError(fileErrorMessage);

        return clearInputs();
      }

      // setSelectedFile(receivedFile);

      handleImportAlertScreening(receivedFile);
    },
    [intl, clearInputs, handleImportAlertScreening]
  );

  const checkFile = useCallback(
    (event: SyntheticEvent) => {
      const target = event.target as HTMLInputElement;
      const receivedFile = target.files?.[0];
      setError('');
      validateFile(receivedFile);
    },
    [validateFile]
  );

  return (
    <Box width={'min(85vw, 550px)'}>
      <Box px={3} sx={{ backgroundColor: step === 1 ? 'var(--color-lightgray6)' : 'white' }}>
        <Box py={3} display="flex" gap={'1rem'} alignItems={'center'}>
          <ValidatedIcon sx={{ fontSize: 'var(--fs-16)', color: step === 1 ? 'var(--color-lightgray5)' : 'var(--color-green)' }} />
          <Box flex={1}>
            <Typography sx={{ fontSize: 'var(--fs-14)', color: 'var(--color-gray2)' }}>
              <FormattedMessage id="importAlertScreeningStep1Title" defaultMessage={'Step 1: Download latest version'} />
            </Typography>
          </Box>
        </Box>
        {!imported && step === 1 && (
          <Box textAlign={'center'}>
            <Button
              type="button"
              variant="contained"
              onClick={() => {
                handleExportAlertScreening();
              }}
              disabled={importing}
              disableElevation
              sx={{ textTransform: 'none' }}
            >
              <FormattedMessage id="downloadAlertScreening" defaultMessage={'Download alert screening latest version'} />
            </Button>
            <Box py={3} textAlign={'center'} mx={'auto'} maxWidth={'60%'} sx={{ fontSize: 'var(--fs-12)', color: 'var(--color-gray2)' }}>
              <Typography sx={{ fontSize: 'inherit', color: 'inherit' }}>
                <span style={{ fontWeight: 700 }}>
                  <FormattedMessage id="warning" defaultMessage={'Warning'} />!
                </span>{' '}
                <FormattedMessage id="downloadAlertScreeningWarningMessage" defaultMessage={'It is your responsibility to keep a backup of the last version of your alert screening.'} />
              </Typography>
              <Typography mt={3} sx={{ fontSize: 'inherit', color: 'inherit' }}>
                <FormattedMessage id="downloadAlertScreeningHelpMessage" defaultMessage={'If you have any question about the structure of the json file ? Please consult our '} />
                <Link target="_blank" rel="noreferrer" href={process.env.REACT_APP_HELP_URL}>
                  help center.
                </Link>
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
      <Box px={3} sx={{ backgroundColor: imported || step === 2 ? 'var(--color-lightgray6)' : 'white' }}>
        <Box py={3} display="flex" gap={'1rem'} alignItems={'center'}>
          <ValidatedIcon sx={{ fontSize: 'var(--fs-16)', color: !imported ? 'var(--color-lightgray5)' : 'var(--color-green)' }} />
          <Box flex={1}>
            <Typography sx={{ fontSize: 'var(--fs-14)', color: 'var(--color-gray2)' }}>
              <FormattedMessage id="importAlertScreeningStep2Title" defaultMessage={'Step 2: Upload edited file'} />
            </Typography>
          </Box>
        </Box>
        {(imported || step === 2) && (
          <Box>
            {imported && (
              <Box pt={2} pb={4} textAlign="center" mx={'auto'} maxWidth={'60%'} sx={{ fontSize: 'var(--fs-12)', color: 'var(--color-gray2)' }}>
                <ValidatedIcon sx={{ fontSize: '50px', color: 'var(--color-green)' }} />
                <Typography mt={2} sx={{ fontSize: 'inherit', color: 'inherit' }}>
                  <FormattedMessage id={'importedAlertScreeningMessage'} defaultMessage={'Congratulations, your alert screening configuration has no error and has been imported.'} />
                </Typography>
              </Box>
            )}
            {!imported && (
              <Box pt={2} pb={4} textAlign={'center'} sx={{ fontSize: 'var(--fs-12)', color: 'var(--color-gray2)' }}>
                {importing ? (
                  <Loader />
                ) : (
                  <form>
                    {error && (
                      <>
                        <Box mb={2} textAlign={'left'} mx={'auto'} maxWidth={'60%'} sx={{ fontSize: 'var(--fs-12)', color: 'var(--color-fushia)' }}>
                          <Typography sx={{ fontSize: 'inherit', color: 'inherit', fontWeight: 700 }}>{error}</Typography>
                          <Typography sx={{ fontSize: 'inherit', color: 'inherit' }}>
                            <FormattedMessage id="pleaseResolveErrors" defaultMessage={'Please resolve the errors and upload the file again'} />
                          </Typography>
                        </Box>
                        {errorContent && (
                          <Box
                            mb={2}
                            display="grid"
                            rowGap={'1px'}
                            className="custom-scrollbar custom-scrollbar-horizontal"
                            maxHeight={'min(200px, 50vh)'}
                            sx={{ backgroundColor: 'var(--color-grayHeaderBorder)', overflow: 'auto' }}
                          >
                            {errorContent.map((audit, auditIndex) => {
                              return (
                                <Box key={audit.id ?? `${audit.error}_${auditIndex}`} px={1} textAlign="left" sx={{ fontSize: 'var(--fs-11)', backgroundColor: 'white' }}>
                                  <pre style={{ marginBlock: '5px' }}>{audit.error}</pre>
                                </Box>
                              );
                            })}
                          </Box>
                        )}
                      </>
                    )}
                    <Box mx={'auto'} maxWidth={'60%'}>
                      <Input
                        id="inputJSON"
                        onChange={checkFile}
                        disableUnderline
                        type={'file'}
                        name={'inputJSON'}
                        inputProps={{
                          ref: inputRef,
                          accept: allowedFileExtension.join(', '),
                          hidden: true,
                          sx: {
                            display: 'none',
                          },
                        }}
                      />
                      <label htmlFor={'inputJSON'}>
                        <ShadowedButton
                          component={'span'}
                          // onClick={() => {
                          //   handleImportDataset();
                          // }}
                          disabled={imported}
                          sx={{ whiteSpace: 'nowrap', backgroundColor: 'white' }}
                        >
                          <FormattedMessage id="uploadJSONFile" defaultMessage="Upload .json file" />
                        </ShadowedButton>
                      </label>

                      <Typography mt={3} sx={{ fontSize: 'inherit', color: 'inherit' }}>
                        <span style={{ fontWeight: 700 }}>
                          <FormattedMessage id="warning" defaultMessage={'Warning'} />!
                        </span>{' '}
                        <FormattedMessage id="downloadAlertScreeningWarningMessage" defaultMessage={'It is your responsibility to keep a backup of the last version of your alert screening.'} />
                      </Typography>
                    </Box>
                  </form>
                )}
              </Box>
            )}
          </Box>
        )}
      </Box>

      <Box display={'flex'} justifyContent={'flex-end'} gap={'1rem'} px={3} py={2} sx={{ borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
        {/* {importing ? (
          <Box>
            <Loader cssStyle={{ width: '33px' }} />
          </Box>
        ) : ( */}
        <>
          <ShadowedButton
            onClick={() => {
              closeModal?.();
            }}
            disabled={imported || importing}
            sx={{ whiteSpace: 'nowrap' }}
          >
            <FormattedMessage id="cancel" defaultMessage="Cancel" />
          </ShadowedButton>

          <Box flex={1} display="flex" gap={'1rem'} justifyContent={'flex-end'}>
            <ShadowedButton
              onClick={() => {
                handleChangePage(-1);
              }}
              disabled={step === 1 || imported || importing}
              sx={{ whiteSpace: 'nowrap' }}
            >
              <FormattedMessage id="previous" defaultMessage="Previous" />
            </ShadowedButton>
            {/* {step < 2 && ( */}
            <ShadowedButton
              onClick={() => {
                handleChangePage(1);
              }}
              sx={{ whiteSpace: 'nowrap' }}
              disabled={step === 2 || imported || importing}
            >
              <FormattedMessage id="next" defaultMessage="Next" />
            </ShadowedButton>
            {/* )} */}
            {/* {step === 2 && (
                <Button
                  type="submit"
                  variant="contained"
                  onClick={() => {
                    console.log('save - NYI');
                  }}
                  disabled={imported}
                  disableElevation
                  sx={{ textTransform: 'none' }}
                >
                  <FormattedMessage id="import" defaultMessage={'Import'} />
                </Button>
              )} */}
          </Box>
        </>
        {/* )} */}
      </Box>
    </Box>
  );
});

export default ImportAlertScreening;
