/**
 * PropertiesContent.tsx
 * Display the properties tab of view profile page
 */
/* packages */
import { memo, useContext } from 'react';
import { FormattedMessage } from 'react-intl';

/* context */
import { CountriesContext } from 'contextProviders/CountriesProvider';
import { PersonContext } from './PersonProvider';

/* components */
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import GaugeMeter from 'components/GaugeMeter/GaugeMeter';

/* utilities */
import { MatchingIdDoc, MatchingLocation, formatMatchingName } from 'models/matchingData';
import { GetGender } from 'utilities/TermMatching';
import { UnknownMessage, Flag } from 'components/InvestigateSearch/utils';
import { getCountryFromIso } from 'components/InvestigateSearch/IndividualContent';
import { internalPageMaxWidth } from './PersonProfile';

/* types */
import { searchConstant } from 'models/searchDatasets';

interface PropertiesContentProps {
  // details?: PersonType;
}

/* elements */
//
const CategoryTitle = styled(Typography)(({ theme }) => ({
  color: 'var(--color-gray2)',
  fontSize: 'var(--fs-14)',
}));
const CategoryEntry = styled(Typography)(({ theme }) => ({
  color: 'var(--color-darkgray)',
  fontWeight: 500,
  fontSize: 'var(--fs-14)',
}));
const BoxCategoryEntry = styled(Box)(({ theme }) => ({
  fontWeight: 500,
  fontSize: 'var(--fs-14)',
}));

const UnkownBox = () => {
  return (
    <BoxCategoryEntry>
      <UnknownMessage color={'var(--color-darkgray)'} />
    </BoxCategoryEntry>
  );
};

const DisplayAddress = ({ address }: { address: MatchingLocation }) => {
  const { countries } = useContext(CountriesContext);
  let countryName = null;
  if (address.country) {
    countryName = getCountryFromIso(address.country, countries);
  }

  return (
    <Box display="grid" gridTemplateColumns={'auto 1fr'} gap={'.5rem'}>
      {/* street */}
      {address.street && (
        <>
          <CategoryTitle>
            <FormattedMessage id="street" defaultMessage={'Street'} />:
          </CategoryTitle>
          <CategoryEntry>{address.street}</CategoryEntry>
        </>
      )}
      {/* city */}
      {address.poBox && (
        <>
          <CategoryTitle>
            <FormattedMessage id="poBox" defaultMessage={'PO box'} />:
          </CategoryTitle>
          <CategoryEntry>{address.poBox}</CategoryEntry>
        </>
      )}
      {/* city */}
      {address.city && (
        <>
          <CategoryTitle>
            <FormattedMessage id="city" defaultMessage={'City'} />:
          </CategoryTitle>
          <CategoryEntry>{address.city}</CategoryEntry>
        </>
      )}
      {/* zipCode */}
      {address.zipCode && (
        <>
          <CategoryTitle>
            <FormattedMessage id="zipCode" defaultMessage={'ZIP code'} />:
          </CategoryTitle>
          <CategoryEntry>{address.zipCode}</CategoryEntry>
        </>
      )}
      {/* state */}
      {address.state && (
        <>
          <CategoryTitle>
            <FormattedMessage id="state" defaultMessage={'State'} />:
          </CategoryTitle>
          <CategoryEntry>{address.state}</CategoryEntry>
        </>
      )}
      {/* country */}
      {address.country && (
        <>
          <CategoryTitle>
            <FormattedMessage id="country" defaultMessage={'Country'} />:
          </CategoryTitle>
          <Box>
            {countryName ? (
              <Box flex={1} display="flex" alignItems={'center'}>
                <Box component={'span'} display={'inline-flex'} sx={{ height: '.8rem', verticalAlign: 'middle', mr: '5px' }}>
                  <Flag country={address.country} />
                </Box>
                <Box display="inline" className="capitalize" sx={{ verticalAlign: 'middle' }}>
                  <CategoryEntry>{countryName}</CategoryEntry>
                </Box>
              </Box>
            ) : (
              <CategoryEntry>{address.country}</CategoryEntry>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};
const DisplayIdDoc = ({ doc }: { doc: MatchingIdDoc }) => {
  const { countries } = useContext(CountriesContext);
  let countryName = null;
  let issuingCountryName = null;

  if (doc.country) {
    countryName = getCountryFromIso(doc.country, countries);
  }
  if (doc.issuingCountry) {
    issuingCountryName = getCountryFromIso(doc.issuingCountry, countries);
  }

  return (
    <Box>
      {/* type */}
      {doc.type && (
        <Box display="flex" gap=".5rem">
          <CategoryTitle>
            <FormattedMessage id="type" defaultMessage={'Type'} />:
          </CategoryTitle>
          <CategoryEntry>{doc.type}</CategoryEntry>
        </Box>
      )}
      {/* description */}
      {doc.description && (
        <Box display="flex" gap=".5rem">
          <CategoryTitle>
            <FormattedMessage id="description" defaultMessage={'Description'} />:
          </CategoryTitle>
          <CategoryEntry>{doc.description}</CategoryEntry>
        </Box>
      )}
      {/* city */}
      {doc.number && (
        <Box display="flex" gap=".5rem">
          <CategoryTitle>
            <FormattedMessage id="number" defaultMessage={'Number'} />:
          </CategoryTitle>
          <CategoryEntry>{doc.number}</CategoryEntry>
        </Box>
      )}
      {/* authority */}
      {doc.authority && (
        <Box display="flex" gap=".5rem">
          <CategoryTitle>
            <FormattedMessage id="authority" defaultMessage={'Authority'} />:
          </CategoryTitle>
          <CategoryEntry>{doc.authority}</CategoryEntry>
        </Box>
      )}
      {/* dateOfIssue */}
      {doc.dateOfIssue && (
        <Box display="flex" gap=".5rem">
          <CategoryTitle>
            <FormattedMessage id="dateOfIssue" defaultMessage={'Date of issue'} />:
          </CategoryTitle>
          <CategoryEntry>{doc.dateOfIssue}</CategoryEntry>
        </Box>
      )}
      {/* dateOfExpiry */}
      {doc.dateOfExpiry && (
        <Box display="flex" gap=".5rem">
          <CategoryTitle>
            <FormattedMessage id="dateOfExpiry" defaultMessage={'Date of expiry'} />:
          </CategoryTitle>
          <CategoryEntry>{doc.dateOfExpiry}</CategoryEntry>
        </Box>
      )}

      {/* country */}
      {doc.country && (
        <Box display="flex" gap=".5rem">
          <CategoryTitle>
            <FormattedMessage id="country" defaultMessage={'Country'} />:
          </CategoryTitle>
          {countryName ? (
            <Box flex={1} display="flex" alignItems={'center'}>
              <Box component={'span'} display={'inline-flex'} sx={{ height: '.8rem', verticalAlign: 'middle', mr: '5px' }}>
                <Flag country={doc.country} />
              </Box>
              <Box display="inline" className="capitalize" sx={{ verticalAlign: 'middle' }}>
                <CategoryEntry>{countryName}</CategoryEntry>
              </Box>
            </Box>
          ) : (
            <CategoryEntry>{doc.country}</CategoryEntry>
          )}
        </Box>
      )}

      {/* issuingCountry */}
      {doc.issuingCountry && (
        <Box display="flex" gap=".5rem">
          <CategoryTitle>
            <FormattedMessage id="country" defaultMessage={'Country'} />:
          </CategoryTitle>
          {issuingCountryName ? (
            <Box flex={1} display="flex" alignItems={'center'}>
              <Box component={'span'} display={'inline-flex'} sx={{ height: '.8rem', verticalAlign: 'middle', mr: '5px' }}>
                <Flag country={doc.issuingCountry} />
              </Box>
              <Box display="inline" className="capitalize" sx={{ verticalAlign: 'middle' }}>
                <CategoryEntry>{issuingCountryName}</CategoryEntry>
              </Box>
            </Box>
          ) : (
            <CategoryEntry>{doc.issuingCountry}</CategoryEntry>
          )}
        </Box>
      )}
    </Box>
  );
};

const PropertiesContent = memo((props: PropertiesContentProps) => {
  const { details } = useContext(PersonContext);
  // const { details: inputDetails } = useContext(PersonContext);

  // const [details, setDetails] = useState<PersonType | undefined>(undefined);

  const { countries } = useContext(CountriesContext);

  // useEffect(() => {
  //   const t = setTimeout(() => {
  //     setDetails(inputDetails);
  //   }, 200);
  //   return () => {
  //     clearTimeout(t);
  //   };
  // }, [inputDetails]);

  if (!details) return <></>;

  const type = details.entityType;

  return (
    <Box px={2} py={2} width={'100%'} maxWidth={internalPageMaxWidth} mx={'auto'} borderRadius={'5px'} sx={{ border: '1px solid var(--color-grayHeaderBorder)' }}>
      <Box display="grid" gridTemplateColumns={'auto 1fr'} columnGap={'1.5rem'} rowGap={'1.25rem'}>
        {/* categories */}
        {details.categories && details.categories.length > 0 && (
          <>
            <CategoryTitle>
              <FormattedMessage id="categories" defaultMessage={'Categories'} />:
            </CategoryTitle>
            <Box>
              {details.categories.map((cat, cat_index) => {
                return (
                  <Box key={cat_index}>
                    <CategoryEntry>{cat}</CategoryEntry>
                  </Box>
                );
              })}
            </Box>
          </>
        )}

        {/* dataset */}
        {details.dataset && (
          <>
            <CategoryTitle>
              <FormattedMessage id="dataset" defaultMessage={'Dataset'} />:
            </CategoryTitle>
            <Box>
              <CategoryEntry>{details.dataset.label}</CategoryEntry>
            </Box>
          </>
        )}

        {/* creation date */}
        {details.creationDate && (
          <>
            <CategoryTitle>
              <FormattedMessage id="creationDate" defaultMessage={'Creation Date'} />:
            </CategoryTitle>
            <Box>
              <CategoryEntry>{details.creationDate}</CategoryEntry>
            </Box>
          </>
        )}

        {/* updated date */}
        {details.updatedDate && (
          <>
            <CategoryTitle>
              <FormattedMessage id="updatedDate" defaultMessage={'Updated Date'} />:
            </CategoryTitle>
            <Box>
              <CategoryEntry>{details.updatedDate}</CategoryEntry>
            </Box>
          </>
        )}

        {/* label */}
        {details.label && (
          <>
            <CategoryTitle>
              <FormattedMessage id="label" defaultMessage={'Label'} />:
            </CategoryTitle>
            <Box>
              <CategoryEntry>{details.label}</CategoryEntry>
            </Box>
          </>
        )}

        {/* type */}
        {details.type && (
          <>
            <CategoryTitle>
              <FormattedMessage id="type" defaultMessage={'Type'} />:
            </CategoryTitle>
            <Box>
              <CategoryEntry>{details.type}</CategoryEntry>
            </Box>
          </>
        )}

        {/* names */}
        {details.names && details.names.length > 0 && (
          <>
            <CategoryTitle>
              <FormattedMessage id="fullName" defaultMessage={'Full name'} />:
            </CategoryTitle>
            <Box sx={{ '& > div:not(:last-of-type)': { mb: '.25rem' } }}>
              {details.names.map((name, name_index) => {
                return (
                  <Box key={name_index}>
                    <CategoryEntry>
                      {formatMatchingName(name)}
                      {name.type && ` (${name.type})`}
                    </CategoryEntry>
                  </Box>
                );
              })}
            </Box>
          </>
        )}
        {/* titles */}
        {details.titles && details.titles.length > 0 && (
          <>
            <CategoryTitle>
              <FormattedMessage id="titles" defaultMessage={'Titles'} />:
            </CategoryTitle>
            <Box>
              {details.titles.map((title, index) => {
                return (
                  <Box key={index}>
                    <CategoryEntry>{title}</CategoryEntry>
                  </Box>
                );
              })}
            </Box>
          </>
        )}

        {/* gender */}
        {type === searchConstant.ENTITIES.INDIVIDUAL && (
          <>
            <CategoryTitle>
              <FormattedMessage id="sex" defaultMessage={'Sex'} />:
            </CategoryTitle>
            <Box>{details.sex ? <CategoryEntry>{GetGender(details.sex.toUpperCase())}</CategoryEntry> : <UnkownBox />}</Box>
          </>
        )}

        {/* nationalities */}
        {details.nationalities && details.nationalities.length > 0 && (
          <>
            <CategoryTitle>
              <FormattedMessage id="nationalities" defaultMessage={'Nationalities'} />:
            </CategoryTitle>
            <Box sx={{ '& > div:not(:last-of-type)': { mb: '1rem' } }}>
              {details.nationalities.map((nationality, index) => {
                let countryName = null;
                const code = nationality.country ?? null;
                if (code) {
                  countryName = getCountryFromIso(code, countries);
                }

                return countryName ? (
                  <Box key={index} flex={1} display="flex" alignItems={'center'}>
                    <Box component={'span'} display={'inline-flex'} sx={{ height: '.8rem', verticalAlign: 'middle', mr: '5px' }}>
                      <Flag country={code ?? undefined} />
                    </Box>
                    <Box display="inline" className="capitalize" sx={{ verticalAlign: 'middle' }}>
                      <CategoryEntry>{countryName}</CategoryEntry>
                    </Box>
                  </Box>
                ) : (
                  <UnkownBox />
                );
              })}
            </Box>
          </>
        )}

        {/* dates of birth */}
        {type === searchConstant.ENTITIES.INDIVIDUAL && (
          <>
            <CategoryTitle>
              <FormattedMessage id="datesOfBirth" defaultMessage={'Dates of Birth'} />:
            </CategoryTitle>
            <Box>
              {details.datesOfBirth ? (
                details.datesOfBirth.map((dob, dob_index) => {
                  return <Box key={dob_index}>{dob.date ? <CategoryEntry>{dob.date} </CategoryEntry> : <UnkownBox />}</Box>;
                })
              ) : (
                <UnkownBox />
              )}
            </Box>
          </>
        )}

        {/* placesOfBirth */}
        {details.placesOfBirth && details.placesOfBirth.length > 0 && (
          <>
            <CategoryTitle>
              <FormattedMessage id="placesOfBirth" defaultMessage={'Places of birth'} />:
            </CategoryTitle>
            <Box sx={{ '& > div:not(:last-of-type)': { mb: '1rem' } }}>
              {details.placesOfBirth.map((pob, index) => {
                let countryName = null;
                const code = pob.country ?? null;
                if (code) {
                  countryName = getCountryFromIso(code, countries);
                }

                return countryName ? (
                  <Box key={index} flex={1} display="flex" alignItems={'center'}>
                    <Box component={'span'} display={'inline-flex'} sx={{ height: '.8rem', verticalAlign: 'middle', mr: '5px' }}>
                      <Flag country={code ?? undefined} />
                    </Box>
                    <Box display="inline" className="capitalize" sx={{ verticalAlign: 'middle' }}>
                      <CategoryEntry>{countryName}</CategoryEntry>
                    </Box>
                  </Box>
                ) : (
                  <UnkownBox />
                );
              })}
            </Box>
          </>
        )}

        {/* deceased */}
        {'deceased' in details && (
          <>
            <CategoryTitle>
              <FormattedMessage id="deceased" defaultMessage={'Deceased'} />:
            </CategoryTitle>
            <Box>
              <CategoryEntry>{details.deceased}</CategoryEntry>
            </Box>
          </>
        )}

        {/* dates of death */}
        {details.datesOfDeath && details.datesOfDeath.length > 0 && (
          <>
            <CategoryTitle>
              <FormattedMessage id="datesOfDeath" defaultMessage={'Dates of death'} />:
            </CategoryTitle>
            <Box>
              {details.datesOfDeath ? (
                details.datesOfDeath.map((dod, index) => {
                  return <Box key={index}>{dod.date ? <CategoryEntry>{dod.date}</CategoryEntry> : <UnkownBox />}</Box>;
                })
              ) : (
                <UnkownBox />
              )}
            </Box>
          </>
        )}

        {/* addresses */}
        {details.addresses && details.addresses.length > 0 && (
          <>
            <CategoryTitle>
              <FormattedMessage id="addresses" defaultMessage={'Addresses'} />:
            </CategoryTitle>
            <Box sx={{ '& > div:not(:last-of-type)': { mb: '1rem' } }}>
              {details.addresses.map((address, index) => {
                return <DisplayAddress key={index} address={address} />;
              })}
            </Box>
          </>
        )}

        {/* datesOfRegistry */}
        {details.datesOfRegistry && details.datesOfRegistry.length > 0 && (
          <>
            <CategoryTitle>
              <FormattedMessage id="datesOfRegistry" defaultMessage={'Dates of registry'} />:
            </CategoryTitle>
            <Box>
              {details.datesOfRegistry.map((dor, index) => {
                return <Box key={index}>{dor.date ? <CategoryEntry>{dor.date}</CategoryEntry> : <UnkownBox />}</Box>;
              })}
            </Box>
          </>
        )}

        {/* placesOfRegistry */}
        {details.placesOfRegistry && details.placesOfRegistry.length > 0 && (
          <>
            <CategoryTitle>
              <FormattedMessage id="placesOfRegistry" defaultMessage={'Places of registry'} />:
            </CategoryTitle>
            <Box sx={{ '& > div:not(:last-of-type)': { mb: '1rem' } }}>
              {details.placesOfRegistry.map((address, index) => {
                return <DisplayAddress key={index} address={address} />;
              })}
            </Box>
          </>
        )}

        {/* identityDocuments */}
        {details.identityDocuments && details.identityDocuments.length > 0 && (
          <>
            <CategoryTitle>
              <FormattedMessage id="identityDocuments" defaultMessage={'Identity documents'} />:
            </CategoryTitle>
            <Box sx={{ '& > div:not(:last-of-type)': { mb: '1rem' } }}>
              {details.identityDocuments.map((doc, index) => {
                return <DisplayIdDoc key={index} doc={doc} />;
              })}
            </Box>
          </>
        )}

        {/* leis */}
        {details.leis && details.leis.length > 0 && (
          <>
            <CategoryTitle>
              <FormattedMessage id="leis" defaultMessage={'LEIS'} />:
            </CategoryTitle>
            <Box>
              {details.leis.map((lei, index) => {
                return (
                  <Box key={index}>
                    <CategoryEntry>{lei}</CategoryEntry>
                  </Box>
                );
              })}
            </Box>
          </>
        )}
        {/* bics */}
        {details.bics && details.bics.length > 0 && (
          <>
            <CategoryTitle>
              <FormattedMessage id="bics" defaultMessage={'BICS'} />:
            </CategoryTitle>
            <Box>
              {details.bics.map((bic, index) => {
                return (
                  <Box key={index}>
                    <CategoryEntry>{bic}</CategoryEntry>
                  </Box>
                );
              })}
            </Box>
          </>
        )}

        {/* digitalCurrencies */}
        {details.digitalCurrencies && details.digitalCurrencies.length > 0 && (
          <>
            <CategoryTitle>
              <FormattedMessage id="digitalCurrencies" defaultMessage={'Digital currencies'} />:
            </CategoryTitle>
            <Box>
              {details.digitalCurrencies.map((dc, index) => {
                return (
                  <Box key={index}>
                    {dc.currency && (
                      <Box display="flex" gap=".5rem">
                        <CategoryTitle>
                          <FormattedMessage id="currency" defaultMessage={'Currency'} />:
                        </CategoryTitle>
                        <CategoryEntry>{dc.currency}</CategoryEntry>
                      </Box>
                    )}
                    {dc.identifier && (
                      <Box display="flex" gap=".5rem">
                        <CategoryTitle>
                          <FormattedMessage id="identifier" defaultMessage={'Identifier'} />:
                        </CategoryTitle>
                        <CategoryEntry>{dc.identifier}</CategoryEntry>
                      </Box>
                    )}
                  </Box>
                );
              })}
            </Box>
          </>
        )}

        {/* flags */}
        {details.flags && details.flags.length > 0 && (
          <>
            <CategoryTitle>
              <FormattedMessage id="flags" defaultMessage={'Flags'} />:
            </CategoryTitle>
            <Box sx={{ '& > div:not(:last-of-type)': { mb: '1rem' } }}>
              {details.flags.map((flag, index) => {
                let countryName = null;
                const code = flag.country ?? null;
                if (code) {
                  countryName = getCountryFromIso(code, countries);
                }

                return countryName ? (
                  <Box key={index} flex={1} display="flex" alignItems={'center'}>
                    <Box component={'span'} display={'inline-flex'} sx={{ height: '.8rem', verticalAlign: 'middle', mr: '5px' }}>
                      <Flag country={code ?? undefined} />
                    </Box>
                    <Box display="inline" className="capitalize" sx={{ verticalAlign: 'middle' }}>
                      <CategoryEntry>{countryName}</CategoryEntry>
                    </Box>
                  </Box>
                ) : (
                  <UnkownBox />
                );
              })}
            </Box>
          </>
        )}

        {/* datesOfBuild */}
        {details.datesOfBuild && details.datesOfBuild.length > 0 && (
          <>
            <CategoryTitle>
              <FormattedMessage id="datesOfBuild" defaultMessage={'Dates of build'} />:
            </CategoryTitle>
            <Box>
              {details.datesOfBuild.map((dob, index) => {
                return <Box key={index}>{dob.date ? <CategoryEntry>{dob.date}</CategoryEntry> : <UnkownBox />}</Box>;
              })}
            </Box>
          </>
        )}

        {/* placeOfBuild */}
        {details.placeOfBuild && details.placeOfBuild.length > 0 && (
          <>
            <CategoryTitle>
              <FormattedMessage id="placeOfBuild" defaultMessage={'Places of build'} />:
            </CategoryTitle>
            <Box sx={{ '& > div:not(:last-of-type)': { mb: '1rem' } }}>
              {details.placeOfBuild.map((address, index) => {
                return <DisplayAddress key={index} address={address} />;
              })}
            </Box>
          </>
        )}

        {/* positions */}
        {details.positions && details.positions.length > 0 && (
          <>
            <CategoryTitle>
              <FormattedMessage id="positions" defaultMessage={'Positions'} />:
            </CategoryTitle>
            <Box sx={{ '& > div:not(:last-of-type)': { mb: '.25rem' } }}>
              {details.positions.map((position, index) => {
                return (
                  <Box key={index}>
                    <CategoryEntry>{position}</CategoryEntry>
                  </Box>
                );
              })}
            </Box>
          </>
        )}

        {/* sectors */}
        {details.sectors && details.sectors.length > 0 && (
          <>
            <CategoryTitle>
              <FormattedMessage id="sectors" defaultMessage={'Sectors'} />:
            </CategoryTitle>
            <Box>
              {details.sectors.map((sector, index) => {
                return (
                  <Box key={index}>
                    <CategoryEntry>{sector}</CategoryEntry>
                  </Box>
                );
              })}
            </Box>
          </>
        )}

        {/* classifications */}
        {details.classifications && details.classifications.length > 0 && (
          <>
            <CategoryTitle>
              <FormattedMessage id="classifications" defaultMessage={'Classifications'} />:
            </CategoryTitle>
            <Box>
              {details.classifications.map((classification, index) => {
                return (
                  <Box key={index}>
                    <CategoryEntry>{classification}</CategoryEntry>
                  </Box>
                );
              })}
            </Box>
          </>
        )}

        {/* dissolved */}
        {'dissolved' in details && (
          <>
            <CategoryTitle>
              <FormattedMessage id="dissolved" defaultMessage={'Dissolved'} />:
            </CategoryTitle>
            <Box>
              <CategoryEntry>{details.dissolved ? 'Yes' : 'No'}</CategoryEntry>
            </Box>
          </>
        )}

        {/* datesOfDissolution */}
        {details.datesOfDissolution && details.datesOfDissolution.length > 0 && (
          <>
            <CategoryTitle>
              <FormattedMessage id="datesOfDissolution" defaultMessage={'Dates of dissolution'} />:
            </CategoryTitle>
            <Box>
              {details.datesOfDissolution.map((dod, index) => {
                return <Box key={index}>{dod.date ? <CategoryEntry>{dod.date}</CategoryEntry> : <UnkownBox />}</Box>;
              })}
            </Box>
          </>
        )}

        {/* keywords */}
        {details.keywords && details.keywords.length > 0 && (
          <>
            <CategoryTitle>
              <FormattedMessage id="keywords" defaultMessage={'Keywords'} />:
            </CategoryTitle>
            <Box>
              {details.keywords.map((keyword, index) => {
                return (
                  <Box key={index}>
                    <CategoryEntry>{keyword}</CategoryEntry>
                  </Box>
                );
              })}
            </Box>
          </>
        )}

        {/* riskSinc */}
        {details.riskSince && (
          <>
            <CategoryTitle>
              <FormattedMessage id="riskSince" defaultMessage={'Risk Since'} />:
            </CategoryTitle>
            <Box>
              <CategoryEntry>{details.riskSince}</CategoryEntry>
            </Box>
          </>
        )}

        {/* risks */}
        {details.risks && details.risks.length > 0 && (
          <>
            <CategoryTitle>
              <FormattedMessage id="risks" defaultMessage={'Risks'} />:
            </CategoryTitle>
            <Box sx={{ '& > div:not(:last-of-type)': { mb: '1rem' } }}>
              {details.risks.map((risk, index) => {
                return (
                  <Box key={index} display="grid" gridTemplateColumns={'auto 1fr'} columnGap={'.5rem'}>
                    {risk.score && (
                      <>
                        <CategoryTitle>
                          <FormattedMessage id="score" defaultMessage={'Score'} />:
                        </CategoryTitle>
                        <GaugeMeter value={risk.score} backgroundColor={'white'} />
                      </>
                    )}

                    {risk.categories && risk.categories.length > 0 && (
                      <>
                        <CategoryTitle>
                          <FormattedMessage id="categories" defaultMessage={'Categories'} />:
                        </CategoryTitle>

                        <Box>
                          {risk.categories.map((cat, index) => {
                            return (
                              <Box key={index}>
                                <CategoryEntry>{cat}</CategoryEntry>
                              </Box>
                            );
                          })}
                        </Box>
                      </>
                    )}

                    {risk.subcategories && risk.subcategories.length > 0 && (
                      <>
                        <CategoryTitle>
                          <FormattedMessage id="subCategories" defaultMessage={'Subcategories'} />:
                        </CategoryTitle>

                        <Box>
                          {risk.subcategories.map((subcat, index) => {
                            return (
                              <Box key={index}>
                                <CategoryEntry>{subcat}</CategoryEntry>
                              </Box>
                            );
                          })}
                        </Box>
                      </>
                    )}

                    {risk.periodsOfRisk && risk.periodsOfRisk.length > 0 && (
                      <>
                        <CategoryTitle>
                          <FormattedMessage id="periodsOfRisk" defaultMessage={'Periods of risk'} />:
                        </CategoryTitle>

                        <Box>
                          {risk.periodsOfRisk.map((period, index) => {
                            return (
                              <Box key={index} display={'flex'} alignItems="center" gap={'.5ch'}>
                                {period.started ? <CategoryEntry>{period.started}</CategoryEntry> : <CategoryEntry>.</CategoryEntry>}
                                {period.ended && (
                                  <>
                                    <Box>-</Box>
                                    <CategoryEntry>{period.ended}</CategoryEntry>
                                  </>
                                )}
                              </Box>
                            );
                          })}
                        </Box>
                      </>
                    )}

                    {risk.riskInformations && risk.riskInformations.length > 0 && (
                      <>
                        <CategoryTitle>
                          <FormattedMessage id="informations" defaultMessage={'Informations'} />:
                        </CategoryTitle>

                        <Box>
                          {risk.riskInformations.map((information, index) => {
                            let countryName = null;
                            const countryKeys = ['COUNTRY OF AUTHORITY', 'COUNTRY CODE'];
                            if (information.type && countryKeys.includes(information.type.toUpperCase())) {
                              countryName = getCountryFromIso(information.value, countries);
                            }

                            return (
                              <Box key={index} display={'flex'} gap=".5rem">
                                {information.type && <CategoryEntry fontStyle={'italic'}>{information.type}:</CategoryEntry>}
                                {information.value &&
                                  (countryName ? (
                                    <Box flex={1} display="flex" alignItems={'center'}>
                                      <Box component={'span'} display={'inline-flex'} sx={{ height: '.8rem', verticalAlign: 'middle', mr: '5px' }}>
                                        <Flag country={information.value ?? undefined} />
                                      </Box>
                                      <Box display="inline" className="capitalize" sx={{ verticalAlign: 'middle' }}>
                                        <CategoryEntry>{countryName}</CategoryEntry>
                                      </Box>
                                    </Box>
                                  ) : (
                                    <CategoryEntry flex={1}>{information.value}</CategoryEntry>
                                  ))}
                              </Box>
                            );
                          })}
                        </Box>
                      </>
                    )}
                  </Box>
                );
              })}
            </Box>
          </>
        )}

        {/* medias */}
        {details.medias && details.medias.length > 0 && (
          <>
            <CategoryTitle>
              <FormattedMessage id="medias" defaultMessage={'Medias'} />:
            </CategoryTitle>
            <Box sx={{ '& > div:not(:last-of-type)': { mb: '1rem' } }}>
              {details.medias.map((media, index) => {
                return (
                  <Box key={index} display={'grid'} gridTemplateColumns={'auto 1fr'} columnGap={'.5rem'}>
                    {media.description && (
                      <>
                        <CategoryTitle>
                          <FormattedMessage id="description" defaultMessage={'Description'} />:
                        </CategoryTitle>
                        <CategoryEntry>{media.description}</CategoryEntry>
                      </>
                    )}

                    {media.type && (
                      <>
                        <CategoryTitle>
                          <FormattedMessage id="type" defaultMessage={'Type'} />:
                        </CategoryTitle>
                        <CategoryEntry>{media.type}</CategoryEntry>
                      </>
                    )}

                    {media.value && (
                      <>
                        <CategoryTitle>
                          <FormattedMessage id="value" defaultMessage={'Value'} />:
                        </CategoryTitle>
                        <CategoryEntry>{media.value}</CategoryEntry>
                      </>
                    )}
                  </Box>
                );
              })}
            </Box>
          </>
        )}

        {/* contactInformations */}
        {details.contactInformations && details.contactInformations.length > 0 && (
          <>
            <CategoryTitle>
              <FormattedMessage id="medias" defaultMessage={'Medias'} />:
            </CategoryTitle>
            <Box sx={{ '& > div:not(:last-of-type)': { mb: '1rem' } }}>
              {details.contactInformations.map((contact, index) => {
                return (
                  <Box key={index} display={'grid'} gridTemplateColumns={'auto 1fr'} columnGap={'.5rem'}>
                    {contact.type && (
                      <>
                        <CategoryTitle>
                          <FormattedMessage id="type" defaultMessage={'Type'} />:
                        </CategoryTitle>
                        <CategoryEntry>{contact.type}</CategoryEntry>
                      </>
                    )}

                    {contact.value && (
                      <>
                        <CategoryTitle>
                          <FormattedMessage id="value" defaultMessage={'Value'} />:
                        </CategoryTitle>
                        <CategoryEntry>{contact.value}</CategoryEntry>
                      </>
                    )}
                  </Box>
                );
              })}
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
});

export default PropertiesContent;
