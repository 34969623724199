/**
 * EditWorkflowStatus.tsx
 * Edit dataset modal
 */
/* packages */
import { useState, useCallback, useMemo, memo, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

/* context */

/* hooks */
import { useAddModal } from 'contextProviders/ModalProvider';
import { useAddSnackbar } from 'contextProviders/SnackbarProvider';

/* components */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import SearchText from 'components/SearchElements/SearchText/SearchText';
import SwitchWithLabel from 'components/SearchElements/SearchText/SearchSwitch';
import SearchFilters, { FilterButtonRefType, SearchFiltersButtonType } from 'components/SearchFilters/SearchFilters';
import FilterCheckboxes from 'components/SearchFilters/FilterCheckboxes';
import ShadowedButton from 'components/ShadowedButton/ShadowedButton';
import { ContentWithDarkTooltip } from 'components/InvestigateSearch/utils';
import Loader from 'components/Loader/Loader';

/* utilities */
import { StatusPromiseResponse } from 'models/utils';
import { WorfklowAction, WorkflowStatusType } from 'models/workflow';
// import { AllowedJobParamsKeyType, AllowedJobType, AllowedJobTypeTypeT, JobType, paramsFromJobType } from 'models/job';
// import { Dataset } from 'models/datasets';

/* types */
interface EditWorkflowStatusProps {
  workflowStatus?: WorkflowStatusType;
  setWorkflowStatus(data: Partial<WorkflowStatusType>, workflowStatus?: WorkflowStatusType): Promise<StatusPromiseResponse>;
  workflowActions?: WorfklowAction[];
  listOfWorkflowStatus?: WorkflowStatusType[];
  // classifications?: string[];
  // folders?: DatasetFolder[];
  // allUsers?: UserType[];
  // teams?: TeamType[];
  // organizations?: OrganizationUnit[];
}

/* elements */
const SearchFiltersMemo = memo(SearchFilters);

const EditWorkflowStatus = memo(({ workflowStatus, setWorkflowStatus, workflowActions, listOfWorkflowStatus }: EditWorkflowStatusProps) => {
  const { closeModal, blockModal } = useAddModal();
  const addSnackbar = useAddSnackbar();
  const intl = useIntl();

  const [workflowStatusParams, setWorkflowStatusParams] = useState<Partial<WorkflowStatusType>>(workflowStatus ?? {});
  const [creatingWorkflowStatus, setCreatingWorkflowStatus] = useState(false);

  const formRef = useRef<HTMLFormElement>(null);
  const nextStepsRef = useRef<FilterButtonRefType | null>(null);
  const actionsRef = useRef<FilterButtonRefType | null>(null);

  const handleChangeText = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setWorkflowStatusParams((params) => ({ ...params, [event.target.name]: event.target.value }));
  }, []);

  const handleChangeSwitch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setWorkflowStatusParams((params) => ({
      ...params,
      [event.target.name]: event.target.checked,
      // ...(event.target.name === 'keepCurrentStatus' && event.target.checked ? { nextSteps: [] } : {}),
    }));

    if (event.target.name === 'keepCurrentStatus' && event.target.checked) {
      nextStepsRef.current?.setFilter({ nbValues: 0 });
    }
  }, []);

  const handleChangeNextSteps = useCallback((newNextSteps: WorkflowStatusType[]) => {
    setWorkflowStatusParams((params) => ({ ...params, nextSteps: newNextSteps }));
  }, []);

  const handleChangeActions = useCallback((newActions: WorfklowAction[]) => {
    setWorkflowStatusParams((params) => ({ ...params, actions: newActions }));
  }, []);

  const actionsButtons: SearchFiltersButtonType[] = useMemo(() => {
    const validActionIds = workflowActions?.map((t) => t.id) ?? [];
    const filteredActions = workflowStatus?.actions?.filter((act) => validActionIds.includes(act.id)) ?? [];

    return [
      {
        ref: actionsRef,
        text: <FormattedMessage id="chooseActions" defaultMessage="Choose actions" />,
        inputName: 'actions',
        fullWidth: true,
        filterContent: (
          <FilterCheckboxes
            maxHeight="min(30vh, 200px)"
            title={intl.formatMessage({ id: 'workflowActions', defaultMessage: 'Workflow actions' })}
            list={workflowActions?.map((act) => ({ key: String(act.id), value: act.type })) ?? []}
          />
        ),
        initialValue: {
          nbValues: filteredActions.length ?? 0,
          values: filteredActions.map((s) => String(s.id)),
        },
      },
    ];
  }, [intl, workflowActions, workflowStatus?.actions]);

  const onActivateActions = useCallback(() => {
    const selectedValues = actionsRef.current?.getValue();
    if (selectedValues) {
      const newActions = workflowActions?.filter((act) => (selectedValues.values || []).includes(String(act.id))) ?? [];
      handleChangeActions(newActions);
    }
  }, [workflowActions, handleChangeActions]);

  const nextStepsButtons: SearchFiltersButtonType[] = useMemo(() => {
    const validNextStepsIds = listOfWorkflowStatus?.map((t) => t.id) ?? [];

    const filteredNextSteps = workflowStatus?.nextSteps?.filter((org) => validNextStepsIds.includes(org.id)) ?? [];

    return [
      {
        ref: nextStepsRef,
        text: <FormattedMessage id="chooseNextSteps" defaultMessage="Choose next steps" />,
        inputName: 'organizations',
        fullWidth: true,
        filterContent: (
          <FilterCheckboxes
            maxHeight="min(30vh, 200px)"
            title={intl.formatMessage({ id: 'organizations', defaultMessage: 'Organizations' })}
            list={listOfWorkflowStatus?.filter((ws) => ws.id !== workflowStatusParams.id).map((ws) => ({ key: String(ws.id), value: ws.code })) ?? []}
          />
        ),
        initialValue: {
          nbValues: filteredNextSteps.length ?? 0,
          values: filteredNextSteps.map((s) => String(s.id)),
        },
      },
    ];
  }, [intl, workflowStatusParams.id, listOfWorkflowStatus, workflowStatus?.nextSteps]);

  const onActivateNextSteps = useCallback(() => {
    const selectedValues = nextStepsRef.current?.getValue();
    if (selectedValues) {
      const newNextSteps = listOfWorkflowStatus?.filter((ws) => (selectedValues.values || []).includes(String(ws.id))) ?? [];
      handleChangeNextSteps(newNextSteps);
    }
  }, [listOfWorkflowStatus, handleChangeNextSteps]);

  const createOrUpdateWorkflowStatus = useCallback(async () => {
    if (!formRef.current) return;

    blockModal?.(true);
    setCreatingWorkflowStatus(true);

    const result = await setWorkflowStatus(workflowStatusParams, workflowStatus);

    setCreatingWorkflowStatus(false);
    blockModal?.(false);

    if (result.status === 'success') {
      const updateSuccessMessage = workflowStatus
        ? intl.formatMessage({
            id: 'updateWorkflowStatusSuccess',
            defaultMessage: 'Workflow status updated',
          })
        : intl.formatMessage({
            id: 'createWorkflowStatusSuccess',
            defaultMessage: 'New workflow status created',
          });

      addSnackbar(updateSuccessMessage, 'success');
      closeModal?.();
    } else {
      const updateErrorMessage = workflowStatus
        ? intl.formatMessage({
            id: 'updateWorkflowStatusError',
            defaultMessage: 'An error occured while updating the workflow status',
          })
        : intl.formatMessage({
            id: 'createWorkflowStatusError',
            defaultMessage: 'An error occured while creating the workflow status',
          });
      addSnackbar(updateErrorMessage, 'error');
    }
  }, [blockModal, closeModal, workflowStatusParams, workflowStatus, setWorkflowStatus, addSnackbar, intl]);

  const setMessage = workflowStatus ? intl.formatMessage({ id: 'save', defaultMessage: 'Save' }) : intl.formatMessage({ id: 'create', defaultMessage: 'Create' });
  const MemoInputProps = useMemo(
    () => ({
      startAdornment: null,
    }),
    []
  );
  const MemoSmallInputProps = useMemo(
    () => ({
      maxLength: 150,
    }),
    []
  );

  const saveDisabled =
    creatingWorkflowStatus ||
    !workflowStatusParams.code ||
    !workflowStatusParams.description ||
    (!workflowStatusParams.keepCurrentStatus && (!workflowStatusParams.nextSteps || workflowStatusParams.nextSteps.length <= 0));

  let buttonTooltip: string = '';
  if (!workflowStatusParams.code) {
    buttonTooltip = intl.formatMessage({ id: 'missingWorkflowStatusName', defaultMessage: 'You must set a name' });
  } else if (!workflowStatusParams.description) {
    buttonTooltip = intl.formatMessage({ id: 'missingWorkflowStatusDescription', defaultMessage: 'You must set a description' });
  } else if (!workflowStatusParams.keepCurrentStatus && (!workflowStatusParams.nextSteps || workflowStatusParams.nextSteps.length <= 0)) {
    buttonTooltip = intl.formatMessage({ id: 'missingWorkflowStatusNext', defaultMessage: 'You must set either keep current status or at least one next step' });
  }

  return (
    <Box width={'min(85vw, 450px)'}>
      <form ref={formRef}>
        <Box px={3}>
          <Box>
            <Typography className="modal-label">
              <FormattedMessage id="workflowStatusName" defaultMessage={'Status name'} />
            </Typography>
            <SearchText
              fullWidth
              name="code"
              value={workflowStatusParams.code ?? ''}
              onChange={handleChangeText}
              placeholder={intl.formatMessage({ id: 'chooseScheduleName', defaultMessage: 'Status name' })}
              InputProps={MemoInputProps}
              disabled={creatingWorkflowStatus}
            />
          </Box>

          <Box mt={2}>
            <Typography className="modal-label">
              <FormattedMessage id="workflowStatusDescription" defaultMessage={'Status description'} />
            </Typography>
            <SearchText
              fullWidth
              name="description"
              value={workflowStatusParams.description ?? ''}
              onChange={handleChangeText}
              // placeholder={intl.formatMessage({ id: 'chooseScheduleName', defaultMessage: 'Status name' })}
              InputProps={MemoInputProps}
              inputProps={MemoSmallInputProps}
              disabled={creatingWorkflowStatus}
              multiline
              rows={3}
            />
          </Box>

          <Box mt={2} display="flex" flexDirection={'column'}>
            <Typography className="modal-label">
              <FormattedMessage id="workflowStatuusActions" defaultMessage={'Workflow actions'} />
            </Typography>
            <SearchFiltersMemo disabled={creatingWorkflowStatus || !workflowActions} hideClearButton={true} onActivate={onActivateActions} searchFiltersButtons={actionsButtons} />
          </Box>

          <Box mt={2} display="grid">
            <SwitchWithLabel
              disabled={creatingWorkflowStatus}
              label={intl.formatMessage({ id: 'close', defaultMessage: 'Close' })}
              labelClassName="modal-label"
              inputSwitchProps={{ checked: workflowStatusParams?.closeStatus ?? false, onChange: handleChangeSwitch, name: 'closeStatus' }}
            />

            <SwitchWithLabel
              disabled={creatingWorkflowStatus}
              label={intl.formatMessage({ id: 'assignToUser', defaultMessage: 'Assign to user' })}
              labelClassName="modal-label"
              inputSwitchProps={{ checked: workflowStatusParams?.assignToUser ?? false, onChange: handleChangeSwitch, name: 'assignToUser' }}
            />

            <SwitchWithLabel
              disabled={creatingWorkflowStatus}
              label={intl.formatMessage({ id: 'assignToGroup', defaultMessage: 'Assign to group' })}
              labelClassName="modal-label"
              inputSwitchProps={{ checked: workflowStatusParams?.assignToGroup ?? false, onChange: handleChangeSwitch, name: 'assignToGroup' }}
            />

            <SwitchWithLabel
              disabled={creatingWorkflowStatus}
              label={intl.formatMessage({ id: 'keepCurrentStatus', defaultMessage: 'Keep current status' })}
              labelClassName="modal-label"
              inputSwitchProps={{ checked: workflowStatusParams?.keepCurrentStatus ?? false, onChange: handleChangeSwitch, name: 'keepCurrentStatus' }}
            />
          </Box>

          <Box display="flex" flexDirection={'column'}>
            <Typography className="modal-label">
              <FormattedMessage id="nexSteps" defaultMessage={'Next steps'} />
            </Typography>
            <SearchFiltersMemo
              disabled={creatingWorkflowStatus || !listOfWorkflowStatus || workflowStatusParams.keepCurrentStatus}
              hideClearButton={true}
              onActivate={onActivateNextSteps}
              searchFiltersButtons={nextStepsButtons}
            />
          </Box>
        </Box>

        <Box display={'flex'} justifyContent={'flex-end'} gap={'1rem'} px={3} pt={2} mt={2} sx={{ borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
          {creatingWorkflowStatus ? (
            <Box>
              <Loader cssStyle={{ width: '33px' }} />
            </Box>
          ) : (
            <>
              <ShadowedButton
                onClick={() => {
                  closeModal?.();
                }}
                sx={{ whiteSpace: 'nowrap' }}
              >
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </ShadowedButton>

              <ContentWithDarkTooltip tooltip={buttonTooltip} placement="top">
                <Button type="button" variant="contained" onClick={() => createOrUpdateWorkflowStatus()} disabled={saveDisabled} disableElevation sx={{ textTransform: 'none' }}>
                  {setMessage}
                </Button>
              </ContentWithDarkTooltip>
            </>
          )}
        </Box>
      </form>
    </Box>
  );
});

export default EditWorkflowStatus;
