/**
 * InvestigateSearch.tsx
 */
/* packages */
import { PropsWithChildren, useContext, useEffect, useState, useCallback, useMemo, memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { styled } from '@mui/material/styles';

/* context */
import { AllUsersContext } from 'contextProviders/AllUsersProvider';
import { OrganizationsContext } from 'contextProviders/OrganizationsProvider';
import { UserContext } from 'contextProviders/UserProvider';

/* hooks */
import { useAddModal } from 'contextProviders/ModalProvider';
import { useAddSnackbar } from 'contextProviders/SnackbarProvider';

/* components */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import SearchSelect from 'components/SearchElements/SearchText/SearchSelect';
import { SelectChangeEvent } from '@mui/material/Select';
import SearchText from 'components/SearchElements/SearchText/SearchText';

import ShadowedButton from 'components/ShadowedButton/ShadowedButton';

import { TableHeadElement } from 'components/TableResults/TableHead';

import { DoubleChevronIcon } from 'icons/doubleChevron/doubleChevron';
import { ChevronIcon } from 'icons/chevron/chevron';
import { GarbageIcon } from 'icons/garbage/garbage';

import PageHeader from 'components/PageHeader/PageHeader';
import PageTitle from 'components/PageTitle/PageTitle';

import Loader from 'components/Loader/Loader';
import { GetUser } from 'components/GetUser/GetUser';

import { AddButton } from 'components/Buttons/Buttons';
import ReloadButton from 'components/ReloadButton/ReloadButton';

/* utilities */
import { displayWarning } from 'utilities/Logger';

/* types */
import { SortedDirectionType } from 'components/TableResults/TableHead';
import { OrganizationUnit } from 'models/user';

interface ManageSetupOrganizationsLayoutProps {
  disableAddButton: boolean;
  handleCreateOrganization(organization?: OrganizationUnit): void;
}
interface ManageSetupOrganizationsContentProps {
  loading: boolean;
  load: () => void;
  organizationsRoot: OrganizationUnit[];
  organizationsChildren: { [key: number]: OrganizationUnit[] };
  handleCreateOrganization(organization?: OrganizationUnit): void;
  handleDeleteOrganization(organization: OrganizationUnit): void;
}

interface DisplayOrganizationProps {
  organization: OrganizationUnit;
  organizationsChildren: ManageSetupOrganizationsContentProps['organizationsChildren'];
  level: number;
  sortedColumn: string;
  sortedDirection: SortedDirectionType;
  handleCreateOrganization: ManageSetupOrganizationsContentProps['handleCreateOrganization'];
  handleDeleteOrganization: ManageSetupOrganizationsContentProps['handleDeleteOrganization'];
  topBorderOnly?: boolean;
}
interface EditOrganizationProps {
  organization?: OrganizationUnit;
  setOrganization(data: Partial<OrganizationUnit>, organization?: OrganizationUnit): Promise<{ status: string }>;
  // organizationParentsAllowed: { id: number; name: string }[];
  organizationParentsAllowed: OrganizationUnit[];
}
interface DeleteOrganizationProps {
  organization: OrganizationUnit;
  removeOrganization(removedId: number): Promise<{ status: string }>;
}

/* elements */
const MAXORGANIZATIONSLEVEL = 3;

const ManageSetupOrganizations = () => {
  const intl = useIntl();
  const { toggleModal } = useAddModal();

  const { loadingOrganizations, organizations, listOrganizations, addOrUpdateOrganization, deleteOrganization } = useContext(OrganizationsContext);
  const { loadingAllUsers, listAllUsers } = useContext(AllUsersContext);
  const { currentUser } = useContext(UserContext);
  // const { toggleModal } = useAddModal();
  const [initialLoading, setInitialLoading] = useState(true);

  // refresh users and organizations on page load
  const load = useCallback(() => {
    listAllUsers?.();
    listOrganizations?.();
  }, [listOrganizations, listAllUsers]);

  useEffect(() => {
    load();
    setInitialLoading(false);
  }, [load]);

  const organizationsChildren = useMemo(() => {
    const organizationsChildren: { [key: number]: OrganizationUnit[] } = {};
    organizations
      ?.map((org) => [org.parent?.id, org] as [number | undefined, OrganizationUnit])
      .reduce((orgChildren, cur) => {
        if (!cur[0]) return orgChildren;

        const parentId = cur[0];
        if (!(parentId in orgChildren)) {
          orgChildren[parentId] = [];
        }
        orgChildren[cur[0]].push(cur[1]);

        return orgChildren;
      }, organizationsChildren);
    return organizationsChildren;
  }, [organizations]);

  const organizationsRoot = useMemo(() => {
    const organizationsIds = organizations?.map((org) => org.id) ?? [];
    const organizationsRoot = organizations?.filter((org) => !org.deleted && (!org.parent || !organizationsIds.includes(org.parent.id)));
    return organizationsRoot ?? [];
  }, [organizations]);

  const setOrganization = useCallback(
    async (data: Partial<OrganizationUnit>, organization?: OrganizationUnit): Promise<{ status: string }> => {
      const newData = {
        ...organization,
        ...data,
      };

      if (organization?.parent && !data.parent) {
        delete newData.parent;
      }

      try {
        await addOrUpdateOrganization?.(newData, organization ? false : true);
        return { status: 'success' };
      } catch {
        return { status: 'error' };
      }
    },
    [addOrUpdateOrganization]
  );

  const removeOrganization = useCallback(
    async (removedId: number): Promise<{ status: string }> => {
      try {
        const deletedOrganization = await deleteOrganization?.(removedId);

        if (deletedOrganization) {
          return { status: 'success' };
        }
        return { status: 'error' };
      } catch {
        return { status: 'error' };
      }
    },
    [deleteOrganization]
  );

  const computeDepth = useCallback(
    (orgId: number | undefined): number => {
      if (!orgId) return 0;
      if (!organizationsChildren[orgId]) return 0;
      return 1 + Math.max(...organizationsChildren[orgId].map((o) => computeDepth(o.id)));
    },
    [organizationsChildren]
  );

  const computeAllowedParents = useCallback(
    (orgId: number | undefined) => {
      const organizationParentsAllowed: OrganizationUnit[] = [];

      const depth = computeDepth(orgId);

      let level = 0;
      let toAdd: OrganizationUnit[] = organizationsRoot.filter((org) => org.id !== orgId);
      while (level < Math.max(MAXORGANIZATIONSLEVEL - depth, 0) && toAdd.length > 0) {
        level += 1;

        const newToAdd: OrganizationUnit[] = [];
        toAdd.forEach((orgAdd) => {
          if (orgAdd.id !== orgId) {
            organizationParentsAllowed.push(orgAdd);

            if (organizationsChildren[orgAdd.id]) {
              newToAdd.push(...organizationsChildren[orgAdd.id]);
            }
          }
        });

        toAdd = newToAdd;
      }

      return organizationParentsAllowed;
    },
    [organizationsChildren, organizationsRoot, computeDepth]
  );

  const handleCreateOrganization = useCallback(
    (organization?: OrganizationUnit) => {
      // compute allowed parents
      if (!currentUser?.isAdmin) return;
      const organizationParentsAllowed = computeAllowedParents(organization?.id);
      toggleModal?.({
        title: organization ? intl.formatMessage({ id: 'editOrganization', defaultMessage: 'Edit organization' }) : intl.formatMessage({ id: 'addOrganization', defaultMessage: 'Add organization' }),
        modalContent: <EditOrganization {...{ organization, setOrganization, organizationParentsAllowed }} />,
      });
    },
    [intl, toggleModal, setOrganization, computeAllowedParents, currentUser]
  );

  const handleDeleteOrganization = useCallback(
    (organization: OrganizationUnit) => {
      if (!currentUser?.isAdmin) return;

      toggleModal?.({
        title: intl.formatMessage({ id: 'deleteOrganization', defaultMessage: 'Delete organization' }),
        modalContent: <DeleteOrganization {...{ organization, removeOrganization }} />,
      });
    },
    [intl, toggleModal, removeOrganization, currentUser]
  );

  const loading = initialLoading || loadingOrganizations || loadingAllUsers;

  return (
    <ManageSetupOrganizationsLayout {...{ disableAddButton: loading, handleCreateOrganization }}>
      <ManageSetupOrganizationsContent {...{ loading, load, organizationsRoot, organizationsChildren, handleCreateOrganization, handleDeleteOrganization }} />
    </ManageSetupOrganizationsLayout>
  );
};

const ManageSetupOrganizationsLayout = ({ disableAddButton, handleCreateOrganization, children }: PropsWithChildren<ManageSetupOrganizationsLayoutProps>) => {
  const intl = useIntl();
  const { currentUser } = useContext(UserContext);

  return (
    <>
      <PageHeader>
        <PageTitle title={intl.formatMessage({ id: 'ManageSetupOrganizationsTitle', defaultMessage: 'Organizations' })} />

        {currentUser?.isAdmin && (
          <AddButton
            onClick={() => {
              handleCreateOrganization();
            }}
            disabled={disableAddButton}
          >
            <FormattedMessage id="addOrganizationUnit" defaultMessage={'Add organization'} />
          </AddButton>
        )}
      </PageHeader>
      {children}
    </>
  );
};

const getSortedOrganization = (
  organizations: OrganizationUnit[] | undefined,
  organizationsChildren: ManageSetupOrganizationsContentProps['organizationsChildren'],
  sortedColumn: string,
  sortedDirection: SortedDirectionType
) => {
  if (!organizations) return organizations;

  const validOrganization = organizations.filter((org) => !org.deleted);

  if (!sortedDirection) {
    return validOrganization.sort((o1, o2) => {
      if (o1.id in organizationsChildren) return 1;
      if (o2.id in organizationsChildren) return -1;
      return 0;
    });
  }

  const direction = sortedDirection === 'asc' ? 1 : -1;
  switch (sortedColumn) {
    case 'organization':
      return validOrganization.sort((o1, o2) => direction * o1.name.localeCompare(o2.name));
    case 'updatedon':
      return validOrganization.sort((o1, o2) => direction * (o1.updatedDate ?? '').localeCompare(o2.updatedDate ?? ''));
    case 'createdon':
      return validOrganization.sort((o1, o2) => direction * (o1.creationDate ?? '').localeCompare(o2.creationDate ?? ''));
    default:
      displayWarning(`unknown sorting column: ${sortedColumn}`);
      return organizations;
  }
};

const DEFAULTCOLUMNWIDTH = '180px';
const COLUMNTEMPLATE = 'minmax(250px, 1fr) repeat(3, minmax(180px, 1fr)) 100px';

const ManageSetupOrganizationsContent = ({ loading, load, organizationsRoot, organizationsChildren, handleCreateOrganization, handleDeleteOrganization }: ManageSetupOrganizationsContentProps) => {
  const intl = useIntl();
  const { organizations } = useContext(OrganizationsContext);
  const { allUsers } = useContext(AllUsersContext);

  const [sortedColumn, setSortedColumn] = useState<string>('');
  const [sortedDirection, setSortedDirection] = useState<SortedDirectionType>(false);

  const tableHeaders = useMemo((): TableHeadElement[] => {
    return [
      {
        id: 'organization',
        label: intl.formatMessage({
          id: 'organization',
          defaultMessage: 'Organization',
        }),
        sorted: () => {
          const newDirection = sortedColumn !== 'organization' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc';
          setSortedColumn('organization');
          setSortedDirection(newDirection);
        },
        direction: sortedColumn === 'organization' ? sortedDirection : false,
        minWidth: '250px',
      },
      {
        id: 'createdby',
        label: intl.formatMessage({
          id: 'createdBy',
          defaultMessage: 'Created by',
        }),
        minWidth: DEFAULTCOLUMNWIDTH,
      },
      {
        id: 'createdon',
        label: intl.formatMessage({
          id: 'createdon',
          defaultMessage: 'Created on',
        }),
        minWidth: DEFAULTCOLUMNWIDTH,
        sorted: () => {
          const newDirection = sortedColumn !== 'createdon' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc';
          setSortedColumn('createdon');
          setSortedDirection(newDirection);
        },
        direction: sortedColumn === 'createdon' ? sortedDirection : false,
      },
      {
        id: 'updatedon',
        label: intl.formatMessage({
          id: 'updatedon',
          defaultMessage: 'Updated on',
        }),
        minWidth: DEFAULTCOLUMNWIDTH,
        sorted: () => {
          const newDirection = sortedColumn !== 'updatedon' ? 'asc' : sortedDirection === 'asc' ? 'desc' : 'asc';
          setSortedColumn('updatedon');
          setSortedDirection(newDirection);
        },
        direction: sortedColumn === 'updatedon' ? sortedDirection : false,
      },
      {
        id: 'edit',
        label: '',
      },
    ];
  }, [intl, sortedColumn, sortedDirection]);

  const isError = !loading && (!organizations || !allUsers);
  if (isError) {
    return (
      <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <ReloadButton
          onClick={() => {
            load();
          }}
        />
      </Box>
    );
  }

  if (loading) {
    return (
      <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'}>
        <Loader />
      </Box>
    );
  }

  return (
    <Box>
      {organizationsRoot.length > 0 ? (
        <Box className="custom-scrollbar-horizontal table-results no-border " display="grid" gridTemplateColumns={COLUMNTEMPLATE} sx={{ rowGap: '1rem', borderRadius: '5px', overflowX: 'auto' }}>
          {tableHeaders.map((th, thIndex) => {
            return (
              <Box
                key={th.id}
                className={`header-element with-border ${thIndex === 0 ? 'first' : ''} ${thIndex === tableHeaders.length - 1 ? 'last' : ''}`}
                {...(th.sorted
                  ? {
                      onClick: th.sorted,
                    }
                  : {})}
              >
                {th.sorted &&
                  (th.direction ? (
                    <ChevronIcon sx={{ fontSize: 10, color: th.direction ? 'var(--color-azure)' : 'inherit', rotate: th.direction === 'asc' ? '-90deg' : '90deg' }} />
                  ) : (
                    <DoubleChevronIcon sx={{ fontSize: 10, color: th.direction ? 'var(--color-azure)' : 'inherit' }} />
                  ))}{' '}
                {th.label.toUpperCase()}
              </Box>
            );
          })}

          {getSortedOrganization(organizationsRoot, organizationsChildren, sortedColumn, sortedDirection)?.map((org) => {
            return <DisplayOrganization level={0} key={org.id} organization={org} {...{ organizationsChildren, sortedColumn, sortedDirection, handleCreateOrganization, handleDeleteOrganization }} />;
          })}
        </Box>
      ) : (
        <Box p={1} mb={2}>
          <Typography fontWeight={500} sx={{ color: 'var(--color-gray1)' }}>
            <FormattedMessage id="noOrgnizationUnit" defaultMessage={'No Organization has been defined'} />
          </Typography>
        </Box>
      )}
    </Box>
  );
};

const StyledOrganizationElement = styled(Box)(({ theme }) => ({
  color: 'var(--color-darkgray)',
  fontSize: 'var(--fs-14)',
  padding: theme.spacing(2),
  wordBreak: 'break-word',
}));
const DisplayOrganization = memo(
  ({ organization, level, organizationsChildren, sortedColumn, sortedDirection, handleCreateOrganization, handleDeleteOrganization, topBorderOnly }: DisplayOrganizationProps) => {
    const { allUsers } = useContext(AllUsersContext);
    const { currentUser } = useContext(UserContext);

    const orgChildren = organizationsChildren[organization.id];

    const borderKey = topBorderOnly ? 'borderTop' : 'border';
    return (
      <Box sx={{ gridColumn: '1/-1' }}>
        {!orgChildren ? (
          <Box
            display={'grid'}
            gridTemplateColumns={COLUMNTEMPLATE}
            alignItems={'center'}
            sx={{ borderRadius: '5px', [borderKey]: '1px solid var(--color-grayHeaderBorder)', '&:hover': { backgroundColor: 'var(--color-lightgray2)', cursor: 'pointer' } }}
            onClick={() => {
              if (!currentUser?.isAdmin) return;
              handleCreateOrganization(organization);
            }}
          >
            <StyledOrganizationElement ml={2 * level}>
              <span>{organization.name}</span>
            </StyledOrganizationElement>
            <StyledOrganizationElement>{GetUser(organization.createdBy ?? '', allUsers, true, '20px')}</StyledOrganizationElement>
            <StyledOrganizationElement>{organization.creationDate ?? ''}</StyledOrganizationElement>
            <StyledOrganizationElement>{organization.updatedDate ?? ''}</StyledOrganizationElement>
            <StyledOrganizationElement sx={{ textAlign: 'right' }}>
              {currentUser?.isAdmin && (
                <IconButton
                  sx={{ ml: 1, fontSize: 'var(--fs-14)' }}
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    handleDeleteOrganization(organization);
                  }}
                >
                  <GarbageIcon fontSize="inherit" sx={{ color: 'var(--color-lightgray4)' }} />
                </IconButton>
              )}
            </StyledOrganizationElement>
          </Box>
        ) : (
          <Accordion elevation={0} sx={{ borderRadius: '5px', [borderKey]: '1px solid var(--color-grayHeaderBorder)' }}>
            <AccordionSummary
              expandIcon={null}
              sx={{
                px: 0,
                minHeight: 0,
                '&.Mui-expanded': {
                  minHeight: 0,
                },
                '& .MuiAccordionSummary-content': {
                  m: 0,
                  '& .expand-icon': {
                    rotate: '180deg',
                  },
                  '&.Mui-expanded': {
                    m: 0,
                    '& .expand-icon': {
                      rotate: '-90deg',
                    },
                  },
                },
                '& .MuiAccordionSummary-expandIconWrapper': { order: -1 },
              }}
            >
              <Box display={'grid'} gridTemplateColumns={COLUMNTEMPLATE} alignItems={'center'} sx={{ width: '100%' }}>
                <StyledOrganizationElement ml={2 * level} display="grid" gridTemplateColumns={'auto 1fr'} alignItems={'flex-start'}>
                  <span>
                    <ChevronIcon className="expand-icon" sx={{ fontSize: 'var(--fs-10)', mr: '.5rem' }} />
                  </span>
                  <Box flex={1}>
                    <span>{organization.name}</span>
                  </Box>
                </StyledOrganizationElement>
                <StyledOrganizationElement>{GetUser(organization.createdBy ?? '', allUsers, true, '20px')}</StyledOrganizationElement>
                <StyledOrganizationElement>{organization.creationDate ?? ''}</StyledOrganizationElement>
                <StyledOrganizationElement>{organization.updatedDate ?? ''}</StyledOrganizationElement>
                <StyledOrganizationElement sx={{ textAlign: 'right' }}>
                  {currentUser?.isAdmin && (
                    <ShadowedButton
                      onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                        handleCreateOrganization(organization);
                      }}
                      sx={{ fontSize: 'var(--fs-12)', px: '4px', py: '8px', lineHeight: 1, backgroundColor: 'white' }}
                    >
                      <FormattedMessage id="edit" defaultMessage="Edit" />
                    </ShadowedButton>
                  )}
                </StyledOrganizationElement>
              </Box>
            </AccordionSummary>
            <AccordionDetails sx={{ p: 0 }}>
              {getSortedOrganization(orgChildren, organizationsChildren, sortedColumn, sortedDirection)?.map((org) => {
                return (
                  <DisplayOrganization
                    topBorderOnly
                    level={level + 1}
                    key={org.id}
                    organization={org}
                    {...{ organizationsChildren, sortedColumn, sortedDirection, handleCreateOrganization, handleDeleteOrganization }}
                  />
                );
              })}
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
    );
  }
);
const EditOrganization = memo(({ organization, setOrganization, organizationParentsAllowed }: EditOrganizationProps) => {
  const { closeModal, blockModal } = useAddModal();
  const addSnackbar = useAddSnackbar();
  const intl = useIntl();

  const [name, setName] = useState<string>(organization?.name ?? '');
  const [description, setDescription] = useState<string>(organization?.description ?? '');
  const [code, setCode] = useState<string>(organization?.code ?? '');
  const [apiKey, setApiKey] = useState<string>(organization?.apiKey ?? '');
  const [parentId, setParentId] = useState<string>(organization?.parent?.id.toString() || '');

  const [threshold, setThreshold] = useState<string>(organization?.defaultSearchScore?.toString() ?? '');
  const [validThreshold, setValidThreshold] = useState<boolean>(true);

  const [creatingOrganization, setCreatingOrganization] = useState(false);

  const handleChangeName = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }, []);
  const handleChangeDescription = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(event.target.value);
  }, []);
  const handleChangeCode = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setCode(event.target.value);
  }, []);
  const handleChangeApiKey = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setApiKey(event.target.value);
  }, []);
  const handleChangeParent = useCallback((event: SelectChangeEvent<unknown>) => {
    const parentId = event.target.value as string;
    setParentId(parentId);
  }, []);

  const handleChangeThreshold = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const newT = event.target.value;
    setThreshold(newT);
    setValidThreshold(newT ? newT === Number(newT).toString() : true);
  }, []);

  const createOrUpdateOrganization = useCallback(
    async (data: Partial<OrganizationUnit>) => {
      blockModal?.(true);
      setCreatingOrganization(true);

      const result = await setOrganization(data, organization);

      setCreatingOrganization(false);
      blockModal?.(false);

      if (result.status === 'success') {
        const updateSuccessMessage = organization
          ? intl.formatMessage({
              id: 'updateOrganizationSuccess',
              defaultMessage: 'Organization updated',
            })
          : intl.formatMessage({
              id: 'createOrganizationSuccess',
              defaultMessage: 'New organization created',
            });

        addSnackbar(updateSuccessMessage, 'success');
        closeModal?.();
      } else {
        const updateErrorMessage = organization
          ? intl.formatMessage({
              id: 'updateOrganizationError',
              defaultMessage: 'An error occured while updating the organization',
            })
          : intl.formatMessage({
              id: 'createOrganizationError',
              defaultMessage: 'An error occured while creating the organization',
            });
        addSnackbar(updateErrorMessage, 'error');
      }
    },
    [blockModal, closeModal, organization, setOrganization, addSnackbar, intl]
  );

  const setMessage = organization ? intl.formatMessage({ id: 'save', defaultMessage: 'Save' }) : intl.formatMessage({ id: 'create', defaultMessage: 'Create' });
  const MemoInputProps = useMemo(
    () => ({
      startAdornment: null,
      pattern: '[0-9]*',
    }),
    []
  );

  const saveDisabled = creatingOrganization || !name || !code || !validThreshold;

  const creationObject: Partial<OrganizationUnit> = {
    name,
    code,
    apiKey,
    description,
    defaultSearchScore: Math.min(1, Math.max(0, Number(threshold))),
  };

  if (parentId) {
    creationObject.parent = organizationParentsAllowed.filter((org) => org.id === Number(parentId))[0];
  }

  return (
    <Box width={'min(85vw, 450px)'}>
      <form>
        <Box px={3}>
          <Typography className="modal-label">
            <FormattedMessage id="organizationName" defaultMessage={'Organization name'} />
          </Typography>
          <SearchText
            fullWidth
            value={name}
            onChange={handleChangeName}
            placeholder={intl.formatMessage({ id: 'chooseOrganizationName', defaultMessage: 'Choose a name for the organization' })}
            InputProps={MemoInputProps}
            disabled={creatingOrganization}
          />

          <Typography mt={2} className="modal-label">
            <FormattedMessage id="organizationCode" defaultMessage={'Organization code'} />
          </Typography>
          <SearchText
            fullWidth
            value={code}
            onChange={handleChangeCode}
            placeholder={intl.formatMessage({ id: 'chooseOrganizationCode', defaultMessage: 'Organization code' })}
            InputProps={MemoInputProps}
            disabled={creatingOrganization}
          />

          <Typography mt={2} className="modal-label">
            <FormattedMessage id="organizationApiKey" defaultMessage={'API key'} />
          </Typography>
          <SearchText
            fullWidth
            value={apiKey}
            onChange={handleChangeApiKey}
            placeholder={intl.formatMessage({ id: 'chooseOrganizationApiKey', defaultMessage: 'Api key' })}
            InputProps={MemoInputProps}
            disabled={creatingOrganization}
            type="password"
          />

          <Typography mt={2} className="modal-label">
            <FormattedMessage id="organizationSearchThreshold" defaultMessage={'Search threshold'} />
          </Typography>
          <SearchText
            fullWidth
            value={threshold}
            onChange={handleChangeThreshold}
            placeholder={intl.formatMessage({ id: 'chooseOrganizationThreshold', defaultMessage: 'Search threshold' })}
            InputProps={MemoInputProps}
            disabled={creatingOrganization}
            inputMode="numeric"
            error={!validThreshold}
          />

          <Typography className="modal-label" mt={2}>
            <FormattedMessage id="organizationDescription" defaultMessage={'Description'} />
          </Typography>
          <SearchText
            fullWidth
            value={description}
            onChange={handleChangeDescription}
            placeholder={'(' + intl.formatMessage({ id: 'optional', defaultMessage: 'optional' }) + ')'}
            multiline
            rows={4}
            InputProps={MemoInputProps}
            disabled={creatingOrganization}
          />

          <Typography className="modal-label" mt={2}>
            <FormattedMessage id="organizationParent" defaultMessage={'Parent organization'} />
          </Typography>
          <SearchSelect
            value={parentId}
            onChange={handleChangeParent}
            fullWidth
            placeholder={intl.formatMessage({ id: 'selectParentOrganization', defaultMessage: 'Select organization' })}
            choices={[{ key: '', value: 'No parent' }].concat(
              organizationParentsAllowed.map((org) => ({
                key: org.id.toString(),
                value: org.name,
              }))
            )}
            MenuProps={{ classes: { paper: 'custom-scrollbar' }, sx: { maxHeight: 350 } }}
          />
        </Box>

        <Box display={'flex'} justifyContent={'flex-end'} gap={'1rem'} px={3} pt={2} mt={2} sx={{ borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
          {creatingOrganization ? (
            <Box>
              <Loader cssStyle={{ width: '33px' }} />
            </Box>
          ) : (
            <>
              <ShadowedButton
                onClick={() => {
                  closeModal?.();
                }}
                sx={{ whiteSpace: 'nowrap' }}
              >
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </ShadowedButton>

              <Button type="submit" variant="contained" onClick={() => createOrUpdateOrganization(creationObject)} disabled={saveDisabled} disableElevation sx={{ textTransform: 'none' }}>
                {setMessage}
              </Button>
            </>
          )}
        </Box>
      </form>
    </Box>
  );
});

const DeleteOrganization = memo(({ organization, removeOrganization }: DeleteOrganizationProps) => {
  const { closeModal, blockModal } = useAddModal();
  const addSnackbar = useAddSnackbar();
  const intl = useIntl();

  const [deletingOrganization, setDeletingOrganization] = useState(false);

  const deleteOrganization = useCallback(async () => {
    blockModal?.(true);
    setDeletingOrganization(true);

    const result = await removeOrganization(organization.id);
    setDeletingOrganization(false);
    blockModal?.(false);

    if (result.status === 'success') {
      const deleteSuccessMessage = intl.formatMessage({
        id: 'deleteOrganizationSuccess',
        defaultMessage: 'Organization deleted',
      });

      addSnackbar(deleteSuccessMessage, 'success');
      closeModal?.();
    } else {
      const deleteErrorMessage = intl.formatMessage({
        id: 'deleteOrganizationError',
        defaultMessage: 'An error occured while deleting the organization',
      });
      addSnackbar(deleteErrorMessage, 'error');
    }
  }, [blockModal, closeModal, organization, removeOrganization, intl, addSnackbar]);

  return (
    <Box width={'min(85vw, 450px)'}>
      <Box px={3}>
        <Typography className="modal-label">Organization: {organization?.name}</Typography>

        <Typography className="modal-label">
          <FormattedMessage id="deleteOrganizationMessage" defaultMessage={'Are you sure that you want to delete this organization?'} />
        </Typography>
      </Box>

      <Box display={'flex'} justifyContent={'flex-end'} gap={'1rem'} px={3} pt={2} mt={2} sx={{ borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
        {deletingOrganization ? (
          <Box>
            <Loader cssStyle={{ width: '33px' }} />
          </Box>
        ) : (
          <>
            <ShadowedButton
              onClick={() => {
                closeModal?.();
              }}
              sx={{ whiteSpace: 'nowrap' }}
            >
              <FormattedMessage id="cancel" defaultMessage="Cancel" />
            </ShadowedButton>

            <Button type="button" variant="contained" disabled={deletingOrganization} onClick={deleteOrganization} disableElevation sx={{ textTransform: 'none' }}>
              <FormattedMessage id="confirm" defaultMessage="Confirm" />
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
});

export default ManageSetupOrganizations;
