/**
 * PersonProfile.tsx
 */
/* packages */
// import { useState, useCallback, PropsWithChildren, memo, useMemo, useRef, useContext, useEffect, ReactNode, Fragment } from 'react';
import { useState, useCallback, PropsWithChildren, memo, useMemo, useContext, useRef, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

/* contexts */
// import { AllUsersContext } from 'contextProviders/AllUsersProvider';
// import { useAuthenticatedRequest } from 'contextProviders/AuthProvider';
// import { useAddSnackbar } from 'contextProviders/SnackbarProvider';
// import { TeamsContext } from 'contextProviders/TeamsProvider';
// import { useAddModal } from 'contextProviders/ModalProvider';
import { PersonProvider, PersonContext } from './PersonProvider';
// import { AlertContext, AlertContextType, AlertProvider, EditAlertStatusPayload } from 'components/InvestigateAlert/alertProvider';

/* hooks */

/* components */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
// import Collapse from '@mui/material/Collapse';
// import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';

import Loader from 'components/Loader/Loader';
import PageHeader from 'components/PageHeader/PageHeader';
import PageTitle from 'components/PageTitle/PageTitle';

// import GaugeMeter from 'components/GaugeMeter/GaugeMeter';

import TabsNavigation from 'components/TabsNavigation/TabsNavigation';

// import TableResults from 'components/TableResults/TableResults';
// import { TableHeadElement } from 'components/TableResults/TableHead';
// import { TableRowData } from 'components/TableResults/TableRow';

// import GeneralContent from 'components/InvestigateAlert/GeneralContent';
// import DocumentsContent from 'components/InvestigateAlert/DocumentsContent';
// import { WorkflowContent } from 'components/InvestigateAlert/WorkflowContent';

import GeneralContent from './GeneralContent';
import PropertiesContent from './PropertiesContent';
import LinksContent from './LinksContent';
import MapContent from './MapContent';
import MoreContent from './MoreContent';
import SourcesContent from './SourcesContent';
import TimelineContent from './TimelineContent';

import ShadowedButton from 'components/ShadowedButton/ShadowedButton';
import StatusTag from 'components/StatusTag/StatusTag';

import { ExportIcon } from 'icons/export/export';
import { RefreshIcon } from 'icons/refresh/refresh';
import { CrossIcon } from 'icons/cross/cross';
import { ChevronIcon } from 'icons/chevron/chevron';
import { AvatarIcon } from 'icons/avatar/avatar';
import { AvatarMaleIcon } from 'icons/avatarMale/avatarMale';
import { AvatarFemaleIcon } from 'icons/avatarFemale/avatarFemale';
import { OrganisationIcon } from 'icons/organisation/organisation';
import { ShipIcon } from 'icons/ship/ship';
import { UnknownIcon } from 'icons/unknown/unknown';

// import SearchText from 'components/SearchElements/SearchText/SearchText';
// import SwitchWithLabel from 'components/SearchElements/SearchText/SearchSwitch';
// import SearchSelect from 'components/SearchElements/SearchText/SearchSelect';
// import { SelectChangeEvent } from '@mui/material/Select';

import { usePageModal } from 'contextProviders/PageModalProvider';

/* utilities */
// import { GetTeam, GetUser } from 'components/GetUser/GetUser';
// import { URLConstants } from 'common/URLconstants';

/* types */
import { MatchingDataType, formatMatchingName } from 'models/matchingData';
// import { UserType } from 'models/user';
// import { TeamType } from 'models/teams';
import { PersonType } from 'models/person';
import { searchConstant } from 'models/searchDatasets';

interface PersonProfileLayoutProps {
  person?: PersonType;
  details?: PersonType;
  asModal?: boolean;
  // history: MatchingDataType[];
}

interface ProfileContentProps {
  // details: PersonType;
  // addProfileToHistory(profile: MatchingDataType): void;
}

// /* elements */
const PersonProfile = ({ person, asModal }: { person: MatchingDataType; asModal?: boolean }) => {
  return (
    <PersonProvider person={person}>
      <PersonProfileContainer asModal={asModal} />
    </PersonProvider>
  );
};

export const internalPageMaxWidth = 1350;

const PersonProfileContainer = ({ asModal }: { asModal?: boolean }) => {
  const { person, details, loading, getPersonDetails } = useContext(PersonContext);

  const content = useMemo(() => {
    if (loading) {
      return (
        <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'} sx={{ height: '100%' }}>
          <Loader />
        </Box>
      );
    }

    if (!details) {
      return (
        <Box flex={1} display={'flex'} alignItems={'center'} justifyContent={'center'} sx={{ height: '100%' }}>
          <Button sx={{ color: 'var(--color-gray2)', flexDirection: 'column' }} onClick={() => getPersonDetails?.(person)}>
            <RefreshIcon sx={{ mb: 2 }} />
            <Box>
              <Typography>
                {/* <FormattedMessage id="personDetailsError" defaultMessage={'An error occured while loading profile details'} /> */}
                <FormattedMessage id="personDetailsError" defaultMessage={'This profile details could not be loaded'} />
              </Typography>
            </Box>
          </Button>
        </Box>
      );
    }

    return <ProfileContent />;
  }, [loading, details, getPersonDetails, person]);

  return (
    <Box display="flex" flexDirection="column" sx={{ '--main-inline-padding': 0, px: '50px', height: '100%' }}>
      <PersonProfileLayout person={person} asModal={asModal} details={details}>
        <Box flex={1} minHeight={0}>
          {content}
        </Box>
      </PersonProfileLayout>
    </Box>
  );
};

const MAX_HISTORY = 3;

const PersonProfileLayout = memo(({ person, details, asModal, children }: PropsWithChildren<PersonProfileLayoutProps>) => {
  const { closePageModal } = usePageModal();

  const { profileHistory, setFromHistory } = useContext(PersonContext);

  const profileContent = details ? details : person;

  const title = formatMatchingName(profileContent?.names[0]);
  let headerIcon;
  const fs = '45px';
  switch (profileContent?.entityType) {
    case searchConstant.ENTITIES.INDIVIDUAL:
      const avatarSx: React.CSSProperties = { fontSize: fs, color: 'var(--color-lightgray4)' };
      switch (profileContent.sex?.toLowerCase()) {
        case 'm':
          headerIcon = <AvatarMaleIcon className="avatar" sx={avatarSx} />;
          break;
        case 'f':
          headerIcon = <AvatarFemaleIcon className="avatar" sx={avatarSx} />;
          break;
        default:
          headerIcon = <AvatarIcon className="avatar" sx={avatarSx} />;
      }

      break;
    case searchConstant.ENTITIES.ORGANIZATION:
      headerIcon = <OrganisationIcon className="avatar" sx={{ fontSize: fs, color: 'var(--color-lightgray4)' }} />;
      break;
    case searchConstant.ENTITIES.VESSEL:
      headerIcon = <ShipIcon className="avatar" sx={{ fontSize: fs, color: 'var(--color-lightgray4)' }} />;
      break;
    default:
      headerIcon = <UnknownIcon className="avatar" sx={{ fontSize: fs, color: 'var(--color-lightgray4)' }} />;
  }

  // get category of dataset
  let category = profileContent?.dataset?.category;
  if (!category) {
    category = profileContent?.categories?.[0];
  }

  return (
    <>
      <PageHeader variableHeight noBorder noMarginBottom>
        {asModal && (
          <Box sx={{ alignSelf: 'flex-start' }}>
            <Box sx={{ pt: `calc( 0.5* (${fs} - 2rem))` }}>
              <IconButton color={'gray2'} className={'button-trigger'} sx={{ fontSize: '1rem' }} onClick={closePageModal}>
                {<CrossIcon fontSize="inherit" />}
              </IconButton>
            </Box>
          </Box>
        )}
        <Box flex={1} display="flex" gap={'1rem'} sx={{ minWidth: 0, flexFlow: 'row wrap' }}>
          <Box sx={{ minWidth: 0, overflow: 'hidden', textOverflow: 'ellipsis', '& h1': { flex: 'unset' } }}>
            <PageTitle
              title={
                <Box flex={1} display={'flex'} gap={'1rem'} alignItems={'center'} sx={{ minWidth: 0 }}>
                  {headerIcon}
                  <Box flex={1} sx={{ minWidth: 0 }}>
                    <Typography fontSize={'var(--fs-28)'} fontWeight={600} color={'var(--color-darkgray)'} className="one-line">
                      {title}
                    </Typography>
                    <Typography sx={{ color: 'var(--color-gray-new)', fontSize: 'var(--fs-14)' }}>{profileContent?.dataID ?? ''}</Typography>
                  </Box>
                </Box>
              }
            />
          </Box>

          {profileHistory && profileHistory.length > 0 && (
            <Box display={'flex'} gap={'.5rem'}>
              {profileHistory.slice(0, MAX_HISTORY).map((p, p_ind) => {
                let tagIcon;
                const fs = 'var(--fs-14)';
                switch (p?.entityType) {
                  case searchConstant.ENTITIES.INDIVIDUAL:
                    const avatarSx: React.CSSProperties = { fontSize: fs, color: 'var(--color-lightgray4)' };
                    switch (p?.sex?.toLowerCase()) {
                      case 'm':
                        tagIcon = <AvatarMaleIcon className="avatar" sx={avatarSx} />;
                        break;
                      case 'f':
                        tagIcon = <AvatarFemaleIcon className="avatar" sx={avatarSx} />;
                        break;
                      default:
                        tagIcon = <AvatarIcon className="avatar" sx={avatarSx} />;
                    }
                    break;
                  case searchConstant.ENTITIES.ORGANIZATION:
                    tagIcon = <OrganisationIcon className="avatar" sx={{ fontSize: fs, color: 'var(--color-lightgray4)' }} />;
                    break;
                  case searchConstant.ENTITIES.VESSEL:
                    tagIcon = <ShipIcon className="avatar" sx={{ fontSize: fs, color: 'var(--color-lightgray4)' }} />;
                    break;
                  default:
                    tagIcon = <UnknownIcon className="avatar" sx={{ fontSize: fs, color: 'var(--color-lightgray4)' }} />;
                }

                return (
                  <Box key={`${p_ind}_${p.id ?? p.dataID}`} display="flex" gap={'.5rem'} alignItems="center">
                    <ChevronIcon sx={{ color: 'var(--color-lightgray4)', fontSize: '1rem' }} />
                    <Box
                      className="label-tag"
                      display="flex"
                      gap={'.25rem'}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        setFromHistory?.(p_ind);
                      }}
                    >
                      {tagIcon}
                      <Typography component="span" color="var(--color-gray2)" fontSize={'var(--fs-12)'} sx={{ whiteSpace: 'nowrap', maxWidth: '180px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {formatMatchingName(p.names[0])}
                      </Typography>
                    </Box>
                  </Box>
                );
              })}
            </Box>
          )}

          <Box mt={0.5} flex={1} display="flex" alignItems="center" gap={'1rem'} sx={{ flexBasis: '100%' }}>
            {category && <StatusTag tag_label={category} hideActive type="category" />}
            {profileContent?.dataset?.label && <StatusTag tag_label={profileContent?.dataset.label} hideActive type="label" />}
          </Box>
        </Box>

        {details && (
          <Box pt={1} sx={{ alignSelf: 'flex-start' }}>
            <ShadowedButton>
              <ExportIcon fontSize="inherit" sx={{ mr: 1 }} />
              <FormattedMessage id="exportProfile" defaultMessage="Export profile" />
            </ShadowedButton>
          </Box>
        )}
      </PageHeader>
      {children}
    </>
  );
});

// const TableResultsMemo = memo(TableResults);

const ProfileContent = memo((props: ProfileContentProps) => {
  const { hasSources, hasLinks, hasMore, hasMap, hasTimeline } = useContext(PersonContext);
  //   const { allUsers } = useContext(AllUsersContext);
  //   const { teams } = useContext(TeamsContext);

  //   const { toggleModal } = useAddModal();
  const scrollContainer = useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] = useState<string>('');

  const handleTabChange = useCallback((_: React.SyntheticEvent, tabValue: 'string') => {
    const newTab = tabValue;
    setActiveTab(newTab);
  }, []);

  useEffect(() => {
    if (scrollContainer.current) {
      scrollContainer.current.scrollTo({
        top: 0,
        behavior: 'auto',
      });
    }
  }, [activeTab]);

  const tabs = useMemo(
    () => [
      {
        text: 'Properties',
        value: 'properties',
      },
      {
        text: 'Sources',
        value: 'sources',
        disabled: !hasSources,
      },
      {
        text: 'Links',
        value: 'links',
        disabled: !hasLinks,
      },
      {
        text: 'Map',
        value: 'map',
        disabled: !hasMap,
      },
      {
        text: 'Timeline',
        value: 'timeline',
        disabled: !hasTimeline,
      },
      {
        text: 'More',
        value: 'more',
        disabled: !hasMore,
      },
    ],
    [hasSources, hasLinks, hasMap, hasTimeline, hasMore]
  );

  const setTab = useCallback(
    (tab: string) => {
      const allowedTabs = tabs.map((t) => t.value);
      if (!allowedTabs.includes(tab) && tab !== '') {
        console.warn('invalid tab', tab);
        return;
      }
      setActiveTab(tab);
    },
    [tabs]
  );

  let tabContent;

  switch (activeTab) {
    case '':
      tabContent = <GeneralContent {...{ setTab }} />;
      break;
    case 'properties':
      tabContent = <PropertiesContent />;
      break;
    case 'sources':
      tabContent = <SourcesContent />;
      break;
    case 'links':
      tabContent = <LinksContent />;
      break;
    case 'map':
      tabContent = <MapContent />;
      break;
    case 'timeline':
      tabContent = <TimelineContent />;
      break;
    case 'more':
      tabContent = <MoreContent />;
      break;
    default:
      tabContent = <GeneralContent {...{ setTab }} />;
      break;
  }

  return (
    <Box flex={1} display={'flex'} flexDirection={'column'} pb={4} height={'100%'}>
      <Box display="flex" alignItems="center">
        <Box flex={1} mb={3}>
          <TabsNavigation allLabel="Overview" activeTab={activeTab} tabTitles={tabs} onChange={handleTabChange} label={'profile tabs'} disabled={false} />
        </Box>
      </Box>

      <Box ref={scrollContainer} flex={1} minHeight={0} overflow={'auto'} className="custom-scrollbar">
        {tabContent}
      </Box>
    </Box>
  );
});

// export default PersonProfile;

const allowedEntity = [searchConstant.ENTITIES.INDIVIDUAL, searchConstant.ENTITIES.ORGANIZATION, searchConstant.ENTITIES.VESSEL];
export const useViewProfile = () => {
  const { togglePageModal } = usePageModal();

  const viewProfile = useCallback(
    (person: MatchingDataType | PersonType) => {
      if (!person.dataID && !person.id) return 0;
      // if (!person.entityType || !allowedEntity.includes(person.entityType)) return 0;
      if (person.entityType && !allowedEntity.includes(person.entityType)) return 0;

      togglePageModal?.({ modalContent: <PersonProfile person={person as MatchingDataType} asModal /> });
    },
    [togglePageModal]
  );
  return viewProfile;
};
