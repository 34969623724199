import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

export const PokeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45">
        <g transform="translate(-412.25 -1377.828)">
          <g transform="translate(412.25 1377.828)">
            <circle cx="22.5" cy="22.5" r="22.5" transform="translate(0 0)" fill="currentColor" />
          </g>
          <g transform="translate(426.831 1392.409)">
            <circle cx="7.919" cy="7.919" r="7.919" transform="translate(0 0)" fill="#fff" />
          </g>
          <line x2="37" transform="translate(416.5 1400.5)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="3" />
        </g>
      </svg>
    </SvgIcon>
  );
};
