/**
 * InvestigateSearch.tsx
 */
/* packages */
import { useState, useCallback, useMemo, memo, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

/* context */

/* hooks */
import { useAddModal } from 'contextProviders/ModalProvider';
import { useAddSnackbar } from 'contextProviders/SnackbarProvider';

/* components */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import SearchFilters, { FilterButtonRefType, SearchFiltersButtonType } from 'components/SearchFilters/SearchFilters';
import SearchText from 'components/SearchElements/SearchText/SearchText';
import FilterCheckboxes from 'components/SearchFilters/FilterCheckboxes';
import ShadowedButton from 'components/ShadowedButton/ShadowedButton';
// import { GetUser } from 'components/GetUser/GetUser';
import { ContentWithDarkTooltip } from 'components/InvestigateSearch/utils';
import Loader from 'components/Loader/Loader';

/* utilities */
import { DatasetFolder } from 'models/datasets';
import { StatusPromiseResponse } from 'models/utils';
import { OrganizationUnit, UserType } from 'models/user';
import { TeamType } from 'models/teams';

/* types */
interface EditFolderProps {
  folder?: DatasetFolder;
  setFolder(data: Partial<DatasetFolder>, folder?: DatasetFolder): Promise<StatusPromiseResponse>;
  allUsers?: UserType[];
  teams?: TeamType[];
  organizations?: OrganizationUnit[];
}
/* elements */
const SearchFiltersMemo = memo(SearchFilters);
const EditFolder = memo(({ folder, setFolder, allUsers, teams, organizations }: EditFolderProps) => {
  const { closeModal, blockModal } = useAddModal();
  const addSnackbar = useAddSnackbar();
  const intl = useIntl();

  const formRef = useRef<HTMLFormElement>(null);

  const [folderParams, setFolderParams] = useState<Partial<DatasetFolder>>(folder ?? {});

  const [creatingFolder, setCreatingFolder] = useState(false);

  const handleChangeText = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFolderParams((params) => ({ ...params, [event.target.name]: event.target.value }));
  }, []);

  const handleChangeOrganizations = useCallback((newOrganizations: OrganizationUnit[]) => {
    setFolderParams((params) => ({ ...params, organizations: newOrganizations }));
  }, []);
  const handleChangeTeams = useCallback((newTeams: TeamType[]) => {
    setFolderParams((params) => ({ ...params, groups: newTeams }));
  }, []);
  const handleChangeUsers = useCallback((newUsers: UserType[]) => {
    setFolderParams((params) => ({ ...params, users: newUsers }));
  }, []);

  const organizatinonsRef = useRef<FilterButtonRefType | null>(null);
  const teamsRef = useRef<FilterButtonRefType | null>(null);
  const usersRef = useRef<FilterButtonRefType | null>(null);

  const organizationsButtons: SearchFiltersButtonType[] = useMemo(() => {
    // const filteredPerm = getFilteredPermissions(role?.permissions || []);
    const validOrganizationIds = organizations?.map((t) => t.id) ?? [];

    const filteredOrganizations = folderParams.organizations?.filter((org) => validOrganizationIds.includes(org.id)) ?? [];

    return [
      {
        ref: organizatinonsRef,
        text: <FormattedMessage id="chooseOrganizations" defaultMessage="Choose organizations" />,
        inputName: 'organizations',
        filterContent: (
          <FilterCheckboxes
            maxHeight="min(30vh, 200px)"
            title={intl.formatMessage({ id: 'organizations', defaultMessage: 'Organizations' })}
            list={organizations?.map((org) => ({ key: String(org.id), value: org.name })) ?? []}
          />
        ),
        initialValue: {
          nbValues: filteredOrganizations.length ?? 0,
          values: filteredOrganizations.map((org) => String(org.id)),
        },
      },
    ];
  }, [intl, organizations, folderParams.organizations]);

  const onActivateOrgUnits = useCallback(() => {
    const selectedValues = organizatinonsRef.current?.getValue();
    if (selectedValues) {
      const newOrganizations = organizations?.filter((org) => (selectedValues.values || []).includes(String(org.id))) ?? [];
      handleChangeOrganizations(newOrganizations);
    }
  }, [organizations, handleChangeOrganizations]);

  const teamsButtons: SearchFiltersButtonType[] = useMemo(() => {
    // const filteredPerm = getFilteredPermissions(role?.permissions || []);
    const validTeamIds = teams?.map((t) => t.id) ?? [];

    const filteredTeams = folderParams.groups?.filter((group) => validTeamIds.includes(group.id)) ?? [];

    return [
      {
        ref: teamsRef,
        text: <FormattedMessage id="chooseTeams" defaultMessage="Choose teams" />,
        inputName: 'teams',
        filterContent: (
          <FilterCheckboxes
            maxHeight="min(30vh, 200px)"
            title={intl.formatMessage({ id: 'teams', defaultMessage: 'Teams' })}
            list={teams?.map((team) => ({ key: String(team.id), value: team.name })) ?? []}
          />
        ),
        initialValue: {
          nbValues: filteredTeams.length ?? 0,
          values: filteredTeams.map((team) => String(team.id)),
        },
      },
    ];
  }, [intl, teams, folderParams.groups]);

  const onActivateTeams = useCallback(() => {
    const selectedValues = teamsRef.current?.getValue();
    if (selectedValues) {
      const newTeams = teams?.filter((team) => (selectedValues.values || []).includes(String(team.id))) ?? [];
      handleChangeTeams(newTeams);
    }
  }, [teams, handleChangeTeams]);

  const usersButtons: SearchFiltersButtonType[] = useMemo(() => {
    const validUserIds = allUsers?.map((t) => t.id) ?? [];

    const filteredUsers = folderParams.users?.filter((user) => validUserIds.includes(user.id)) ?? [];

    return [
      {
        ref: usersRef,
        text: <FormattedMessage id="chooseUsers" defaultMessage="Choose users" />,
        inputName: 'users',
        filterContent: (
          <FilterCheckboxes
            maxHeight="min(30vh, 200px)"
            title={intl.formatMessage({ id: 'users', defaultMessage: 'Users' })}
            list={
              allUsers?.map((user) => ({
                key: String(user.id),
                value: user.fullName ?? user.userName,
                // displayValue: GetUser(user.id, [user]),
              })) ?? []
            }
          />
        ),
        initialValue: {
          nbValues: filteredUsers.length ?? 0,
          values: filteredUsers.map((user) => String(user.id)),
        },
      },
    ];
  }, [intl, allUsers, folderParams.users]);

  const onActivateUsers = useCallback(() => {
    const selectedValues = usersRef.current?.getValue();
    if (selectedValues) {
      const newUsers = allUsers?.filter((user) => (selectedValues.values || []).includes(String(user.id))) ?? [];
      handleChangeUsers(newUsers);
    }
  }, [allUsers, handleChangeUsers]);

  const createOrUpdateFolder = useCallback(async () => {
    if (!formRef.current) return;

    blockModal?.(true);
    setCreatingFolder(true);

    // const formData = new FormData(formRef.current);
    // const data: Partial<Dataset> = Object.fromEntries(formData);

    const result = await setFolder(folderParams, folder);

    setCreatingFolder(false);
    blockModal?.(false);

    if (result.status === 'success') {
      const updateSuccessMessage = folder
        ? intl.formatMessage({
            id: 'updateFolderSuccess',
            defaultMessage: 'Folder updated',
          })
        : intl.formatMessage({
            id: 'createFolderSuccess',
            defaultMessage: 'New folder created',
          });

      addSnackbar(updateSuccessMessage, 'success');
      closeModal?.();
    } else {
      const updateErrorMessage = folder
        ? intl.formatMessage({
            id: 'updateFolderError',
            defaultMessage: 'An error occured while updating the folder',
          })
        : intl.formatMessage({
            id: 'createFolderError',
            defaultMessage: 'An error occured while creating the folder',
          });
      addSnackbar(updateErrorMessage, 'error');
    }
  }, [blockModal, closeModal, folderParams, folder, setFolder, addSnackbar, intl]);

  const setMessage = folder ? intl.formatMessage({ id: 'save', defaultMessage: 'Save' }) : intl.formatMessage({ id: 'create', defaultMessage: 'Create' });
  const MemoInputProps = useMemo(
    () => ({
      startAdornment: null,
    }),
    []
  );

  const saveDisabled = creatingFolder || !folderParams.label || !folderParams.organizations || folderParams.organizations.length <= 0;

  let buttonTooltip: string = '';
  if (!folderParams.organizations || folderParams.organizations.length <= 0) {
    buttonTooltip = intl.formatMessage({ id: 'missingDatasetFolderOrganization', defaultMessage: 'You must choose an organization' });
  }

  return (
    <Box width={'min(85vw, 550px)'}>
      <form ref={formRef}>
        <Box px={3} display="grid" gridTemplateColumns={'1fr'} columnGap={'2rem'} rowGap={'1rem'}>
          <Box>
            <Typography className="modal-label">
              <FormattedMessage id="name" defaultMessage={'Name'} />
            </Typography>
            <SearchText
              fullWidth
              name="label"
              value={folderParams.label ?? ''}
              onChange={handleChangeText}
              placeholder={intl.formatMessage({ id: 'chooseFolderName', defaultMessage: 'Folder name' })}
              InputProps={MemoInputProps}
              disabled={creatingFolder}
            />
          </Box>

          <Box display="grid" gridTemplateColumns={'auto 1fr'} alignItems={'center'} columnGap={'1rem'} rowGap={'.5rem'}>
            {organizations && (
              <>
                <Typography className="modal-label" sx={{ mb: '0!important' }}>
                  <FormattedMessage id="organizations" defaultMessage={'Organizations'} />
                </Typography>
                <SearchFiltersMemo disabled={creatingFolder} hideClearButton={true} onActivate={onActivateOrgUnits} searchFiltersButtons={organizationsButtons} />
              </>
            )}
            {teams && (
              <>
                <Typography className="modal-label" sx={{ mb: '0!important' }}>
                  <FormattedMessage id="teams" defaultMessage={'Teams'} />
                </Typography>
                <SearchFiltersMemo disabled={creatingFolder} hideClearButton={true} onActivate={onActivateTeams} searchFiltersButtons={teamsButtons} />
              </>
            )}

            {allUsers && (
              <>
                <Typography className="modal-label" sx={{ mb: '0!important' }}>
                  <FormattedMessage id="users" defaultMessage={'Users'} />
                </Typography>
                <SearchFiltersMemo disabled={creatingFolder} hideClearButton={true} onActivate={onActivateUsers} searchFiltersButtons={usersButtons} />
              </>
            )}
          </Box>
        </Box>

        <Box display={'flex'} justifyContent={'flex-end'} gap={'1rem'} px={3} pt={2} mt={2} sx={{ borderTop: '1px solid var(--color-grayHeaderBorder)' }}>
          {creatingFolder ? (
            <Box>
              <Loader cssStyle={{ width: '33px' }} />
            </Box>
          ) : (
            <>
              <ShadowedButton
                onClick={() => {
                  closeModal?.();
                }}
                sx={{ whiteSpace: 'nowrap' }}
              >
                <FormattedMessage id="cancel" defaultMessage="Cancel" />
              </ShadowedButton>

              <ContentWithDarkTooltip tooltip={buttonTooltip} placement="top">
                <Button type="submit" variant="contained" onClick={() => createOrUpdateFolder()} disabled={saveDisabled} disableElevation sx={{ textTransform: 'none' }}>
                  {setMessage}
                </Button>
              </ContentWithDarkTooltip>
            </>
          )}
        </Box>
      </form>
    </Box>
  );
});

export default EditFolder;
