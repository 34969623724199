/**
 * NotificationsContent.tsx
 * Display the notification content
 */

/* packages */
import React, { PropsWithChildren, memo, useContext, useState, useMemo } from 'react';

/* components */
import { FormattedMessage } from 'react-intl';

import Box from '@mui/material/Box';
import { IconButton, Typography } from '@mui/material';
import { CrossIcon } from 'icons/cross/cross';
import { NotificationsContext } from 'contextProviders/NotificationsProvider';
import Loader from 'components/Loader/Loader';

import TabsNavigation from 'components/TabsNavigation/TabsNavigation';

/* types */
import { Notification } from 'models/notifications';

const NotificationsContent = memo(({ setShowNotifications }: { setShowNotifications: React.Dispatch<React.SetStateAction<boolean>> }) => {
  const { loadingNotifications, notifications } = useContext(NotificationsContext);

  return (
    <Box px={'40px'} py={4} display="flex" flexDirection={'column'} sx={{ width: 'max(30vw, 400px)', maxWidth: '90vw', height: '100%' }}>
      <Box display="flex" alignItems={'center'} mb={3}>
        <Box flex={1}>
          <Typography color="darkgray" fontSize={'1.25rem'} fontWeight={600}>
            <FormattedMessage id="notifications" defaultMessage={'Notifications'} />
          </Typography>
        </Box>
        <IconButton
          onClick={() => {
            setShowNotifications(false);
          }}
          sx={{ fontSize: '1rem' }}
        >
          {<CrossIcon fontSize={'inherit'} sx={{ color: 'var(--color-lightgray4)' }} />}
        </IconButton>
      </Box>

      <Box flex={1} display={'flex'} minHeight={0}>
        {loadingNotifications ? (
          <Box flex={1} py={5}>
            <Loader />
          </Box>
        ) : (
          <NotificationsTabs notifications={notifications} />
        )}
      </Box>
    </Box>
  );
});

const NotificationsTabs = memo(({ notifications }: PropsWithChildren<{ notifications?: Notification[] }>) => {
  const [activeTab, setActiveTab] = useState<string>('Investigation');

  const tabs = useMemo(
    () => [
      {
        text: 'Investigation',
        value: 'Investigation',
      },
      {
        text: 'List Import',
        value: 'ListImport',
      },
    ],
    []
  );

  const handleTabChange = (_: React.SyntheticEvent, tabValue: 'string') => {
    const newTab = tabValue;
    setActiveTab(newTab);
  };

  return (
    <Box width={'100%'} display="flex" flexDirection={'column'}>
      <Box mb={2}>
        <TabsNavigation skipAll={true} activeTab={activeTab} tabTitles={tabs} onChange={handleTabChange} label={'Notification tab'} disabled={false} />
      </Box>

      <Box flex={1} className="custom-scrollbar" sx={{ overflowY: 'auto' }}>
        <NotificationsList {...{ activeTab, notifications }} />
      </Box>
    </Box>
  );
});

const NotificationsList = memo(({ activeTab, notifications }: { activeTab: string; notifications?: Notification[] }) => {
  return (
    <Box>
      <Typography fontSize={'1rem'} textAlign={'center'} fontWeight={500} mt={2} sx={{ color: 'var(--color-gray2)' }}>
        <FormattedMessage id="noNotifications" defaultMessage={"You don't have any notification yet"} />
      </Typography>
    </Box>
  );
});

export default NotificationsContent;
