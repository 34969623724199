/**
 *  EN language file
 */

const lang_en: {} = {
  configReadError: 'Configuration file could not be read. Contact your network administrator.',

  // login
  loginTitle: 'Sign in',
  loginUsername: 'User name',
  loginPassword: 'Password',
  loginButton: 'Sign in',
  loginNeedHelp: 'Do you need help?',
  loginContact: 'Contact us',
  loginTerms: 'Terms of Use',
  loginPrivacy: 'Privacy & security',
  loginHelpCenter: 'Help Center',
  loginAuthenticationError: 'Authentication failed',
  loginAuthenticationSuccess: 'Authentication success',

  // menu
  helpButton: 'Help',
  signoutButton: 'Sign out',

  // search constants
  idnumber: 'Identification Number',
  lei: 'LEI',
  bic: 'BIC',
  individual: 'Individual',
  organization: 'Organization',
  vessel: 'Vessel',
  unknown: 'Unknown',

  // country fields
  nationality: 'Nationality',
  placeOfBirth: 'Place of birth',
  placeOfRegistry: 'Place of registry',
  address: 'Address',
  flag: 'Flags',

  // country algo
  same_country: 'Same country',
  same_subregion: 'Same subregion',
  same_region: 'Same region',

  // date fields
  datesOfBirth: 'Date Of Birth',
  datesOfBuild: 'Date Of Build',
  datesOfRegistry: 'Date Of Registry',

  //date algo
  same_day_month_year: 'Same Day/Month/Year',
  same_month_year: 'Same Month/Year',
  same_year: 'Same Year',
  same_quadrennium: 'Same Quadrennium (source date +/-2 year)',
  same_decade: 'Same Decade (source date +/-5 year)',

  // schedules
  startScheduleSuccess: 'The schedule has been started',
  stopScheduleSuccess: 'The schedule has been stopped',
  startScheduleError: 'The schedule could not be started',
  stopScheduleError: 'The schedule could not be stopped',

  label_sourceDataSet: 'Source dataset',
  label_matchingProfile: 'Matching profile',
};

export default lang_en;
