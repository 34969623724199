/**
 * SerachFilter.tsx
 */
/* packages */
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

/* contexts */

/* hooks */

/* components */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import SearchText from 'components/SearchElements/SearchText/SearchText';

import ShadowedButton from 'components/ShadowedButton/ShadowedButton';

/* utilities */

/* types */
import { FilterValueType } from './SearchFilters';

interface FilterCheckboxesProps {
  title: string;
  placeholder?: string;
  currentValue?: { value: string };
  setNewFilterValue?(newValue: FilterValueType): void;
}

/* elements */
const FilterTextfield = ({ title, currentValue, placeholder, setNewFilterValue }: FilterCheckboxesProps) => {
  const [selectedValue, setSelectedValue] = useState<string>(currentValue?.value ?? '');

  const applyFilterValue = useCallback(
    (newValue: typeof selectedValue) => {
      const trimedValue = newValue.trim();

      if (setNewFilterValue) setNewFilterValue({ nbValues: trimedValue ? 1 : 0, value: trimedValue });
    },
    [setNewFilterValue]
  );

  const clearFilter = () => {
    const newValue: typeof selectedValue = '';
    setSelectedValue(newValue);
    applyFilterValue(newValue);
  };

  // const checkboxChanged = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
  //   const checkboxName = event.target.name;
  //   const checked = event.target.checked;

  //   setSelectedValues((currentValues) => {
  //     if (checked) return [...currentValues, checkboxName];
  //     else return currentValues.filter((cv) => cv !== checkboxName);
  //   });
  // }, []);

  // propagate on value change
  const validateSelection = () => {
    applyFilterValue(selectedValue);
    // if (setNewFilterValue) setNewFilterValue({ nbValues: selectedValues.length, values: selectedValues });
  };

  return (
    <Box borderRadius={'5px'} sx={{ border: '1px solid var(--color-grayHeaderBorder)', background: 'white', mt: 0.5 }}>
      {/* filter title */}
      <Box display="flex" alignItems={'center'} gap={'1rem'} px={2} py={1} sx={{ borderBottom: '1px solid var(--color-grayHeaderBorder)' }}>
        <ShadowedButton onClick={clearFilter} size="small" disabled={selectedValue === ''}>
          <FormattedMessage id="Clear" defaultMessage="Clear" />
        </ShadowedButton>

        <Typography color={'darkgray'} flex={1} fontSize={14} px={2} fontWeight={500} textAlign={'center'}>
          {title}
        </Typography>

        <Button variant="contained" disableElevation size="small" onClick={validateSelection} disabled={!selectedValue}>
          <FormattedMessage id="done" defaultMessage="Done" />
        </Button>
      </Box>

      <Box px={2} py={1} display={'flex'} flexDirection={'column'}>
        <FormGroup>
          <SearchText
            fullWidth
            value={selectedValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSelectedValue(event.target.value);
            }}
            placeholder={placeholder}
            inputProps={{ style: { fontSize: '0.8rem' } }}
            sx={{ pl: 1 }}
            adormentSize={14}
            onKeyDown={(ev) => {
              if (selectedValue && ev.key === 'Enter') {
                ev.stopPropagation();
                ev.preventDefault();
                validateSelection();
              }
            }}
          />
        </FormGroup>
      </Box>
    </Box>
  );
};

export default FilterTextfield;
