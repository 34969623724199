/**
 * GetUser.tsx
 * get a user display name with avatar
 */

/* packages */
import { ReactNode } from 'react';

/* components */
import Box from '@mui/material/Box';
import { AvatarIcon } from 'icons/avatar/avatar';
import { TeamIcon } from 'icons/team/team';

/* types */
import { UserType, prependBase64Photo } from 'models/user';
import { TeamType } from 'models/teams';

/* elements */
export const GetUser = (userName: string | number, allUsers?: UserType[], withAvatar: boolean = true, avatarSize: string = '24px'): ReactNode | string | number => {
  const user = allUsers?.filter((u) => (typeof userName === 'number' ? u.id === userName : u.userName === userName))[0];

  if (withAvatar) {
    return (
      <Box display="inline-flex" alignItems={'center'}>
        <Box textAlign={'left'} mr={1} display={'flex'} alignItems={'center'} sx={{ fontSize: avatarSize, width: avatarSize }}>
          {user?.photo ? (
            <img src={prependBase64Photo + user.photo} alt="" className="user-avatar" />
          ) : (
            <AvatarIcon className="avatar" sx={{ fontSize: 'inherit', color: 'var(--color-lightgray4)' }} />
          )}
        </Box>
        <Box className="username" flex={1} textAlign={'left'} maxWidth={200} overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipsis'} sx={{ textTransform: 'none' }}>
          {user?.fullName ?? user?.userName ?? userName}
        </Box>
      </Box>
    );
  }

  return user?.fullName ?? user?.userName ?? userName;
};

export const GetAvatar = (userName: string | number, allUsers?: UserType[], withUsername = false): ReactNode | [ReactNode, string] => {
  // console.warn('Get avatar NYI');
  const user = allUsers?.filter((u) => (typeof userName === 'number' ? u.id === userName : u.userName === userName))[0];

  let content;
  if (user && user.photo) content = <img src={prependBase64Photo + user.photo} alt="" className="user-avatar" />;
  else content = <AvatarIcon sx={{ fontSize: 'inherit', color: 'inherit' }} />;

  if (withUsername) {
    return [content, user?.fullName ?? user?.userName ?? userName];
  }
  return content;
};

export const GetTeam = (teamName: string, allTeams?: TeamType[], withAvatar = true): ReactNode => {
  const team = allTeams?.filter((t) => t.name === teamName)[0];

  if (withAvatar) {
    return (
      <Box display="inline-flex" alignItems={'center'}>
        <Box textAlign={'left'} mr={1} display={'flex'} alignItems={'center'}>
          <TeamIcon className="avatar" sx={{ fontSize: '1.5rem', color: 'var(--color-lightgray4)' }} />
        </Box>
        <Box className="username" flex={1} textAlign={'left'} maxWidth={200} overflow={'hidden'} whiteSpace={'nowrap'} textOverflow={'ellipsis'} sx={{ textTransform: 'none' }}>
          {team?.name ?? teamName}
        </Box>
      </Box>
    );
  }
  return team?.name ?? teamName;
};
