/**
 * OrganisationContent.tsx
 * content of organisation card in search results
 */
/* packages */
import { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

/* contexts */
import { CountriesContext } from 'contextProviders/CountriesProvider';

/* hooks */

/* components */
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import { StyledBadge, Flag, UnknownMessage } from './utils';

/* utilities */
import { GenerateTooltip, getCountryFromIso, maxTooltipElements, FullNameTooltip } from './IndividualContent';

/* types */
import { formatMatchingName, MatchingDataType } from 'models/matchingData';

// import { SearchCardType } from './utils';

const OrganisationContent = (props: { data: MatchingDataType }) => {
  const { countries } = useContext(CountriesContext);
  const intl = useIntl();

  const { data } = props;

  const fullName = data.names[0];
  const nbFullName = data.names.length - 1 ?? 0;

  let nameTooltip = null;
  if (nbFullName > 0 && data.names) {
    nameTooltip = GenerateTooltip(
      data.names.slice(1, maxTooltipElements + 1).map((name) => formatMatchingName(name)),
      nbFullName - maxTooltipElements
    );
  }

  let fullNameTooltip = null;
  if (data.names[0]) {
    const curName = data.names[0];
    if (curName.fullName) {
      fullNameTooltip = <FullNameTooltip content={[[intl.formatMessage({ id: 'fullName', defaultMessage: 'Full name' }), curName.fullName]]} />;
    } else {
      const content: [string, string][] = [];
      if (curName.givenName) {
        content.push([intl.formatMessage({ id: 'givenName', defaultMessage: 'Given name' }), curName.givenName]);
      }
      if (curName.fatherName) {
        content.push([intl.formatMessage({ id: 'fatherName', defaultMessage: 'Father name' }), curName.fatherName]);
      }
      if (curName.motherName) {
        content.push([intl.formatMessage({ id: 'motherName', defaultMessage: 'Mother name' }), curName.motherName]);
      }
      if (curName.surname) {
        content.push([intl.formatMessage({ id: 'surname', defaultMessage: 'Surname' }), curName.surname]);
      }

      if (content.length > 0) {
        fullNameTooltip = <FullNameTooltip content={content} />;
      }
    }
  }

  const registryDate = data.datesOfRegistry ? data.datesOfRegistry[0]?.date : null;
  const nbRegistryDate = (data.datesOfRegistry ?? [0]).length - 1;

  let registryTooltip = null;
  if (nbRegistryDate > 0 && data.datesOfRegistry) {
    registryTooltip = GenerateTooltip(
      data.datesOfRegistry.slice(1, maxTooltipElements + 1).map((dor) => dor.date),
      nbFullName - maxTooltipElements
    );
  }

  const placeOfRegistry = data.placesOfRegistry ? data.placesOfRegistry[0]?.country : null;
  const countryOfRegistry = getCountryFromIso(placeOfRegistry, countries);
  // const countryIndex = placeOfRegistry ? countries?.findIndex((c) => c.iso2 === placeOfRegistry) : -1;
  // const countryOfRegistry = countries && countryIndex && countryIndex >= 0 ? countries[countryIndex].name : null;
  const nbPlaceOfRegistry = (data.placesOfRegistry ?? [0]).length - 1;

  let placeOfRegistryTooltip = null;
  if (nbPlaceOfRegistry > 0 && data.placesOfRegistry) {
    placeOfRegistryTooltip = GenerateTooltip(
      data.placesOfRegistry.slice(1, maxTooltipElements + 1).map((por) => getCountryFromIso(por.country, countries) ?? 'unkown'),
      nbPlaceOfRegistry - maxTooltipElements
    );
  }

  const addressCountryCode = data.addresses ? data.addresses[0]?.country : null;
  const addressCountry = getCountryFromIso(addressCountryCode, countries);
  // const addressCountryIndex = addressCountryCode ? countries?.findIndex((c) => c.iso2 === addressCountryCode) : -1;
  // const addressCountry = countries && addressCountryIndex && addressCountryIndex >= 0 ? countries[addressCountryIndex].name : null;
  const nbAddresses = (data.addresses ?? [0]).length - 1;

  let addressTooltip = null;
  if (nbAddresses > 0 && data.addresses) {
    addressTooltip = GenerateTooltip(
      data.addresses.slice(1, maxTooltipElements + 1).map((ad) => getCountryFromIso(ad.country, countries) ?? 'unkown'),
      nbAddresses - maxTooltipElements
    );
  }

  // const identityDocument = data.identityDocuments ? data.identityDocuments[0]?.number : null;
  // const nbIdentityDocument = (data.identityDocuments ?? [0]).length - 1;

  // let identityDocumentTooltip = null;
  // if (nbIdentityDocument > 0 && data.identityDocuments) {
  //   identityDocumentTooltip = GenerateTooltip(
  //     data.identityDocuments.slice(1, maxTooltipElements + 1).map((id) => id.number ?? 'unknown'),
  //     nbIdentityDocument - maxTooltipElements
  //   );
  // }

  // const bic = data.bic;
  // const nbBic = 0;

  // const lei = data.lei;
  // const nbLei = 0;

  // const digitalCurrency = data.digitalCurrencies ? data.digitalCurrencies[0]?.identifier : null;
  // const nbDigitalCurrency = (data.digitalCurrencies ?? [0]).length - 1;

  // let digitalCurrencyTooltip = null;
  // if (nbDigitalCurrency > 0 && data.digitalCurrencies) {
  //   digitalCurrencyTooltip = GenerateTooltip(
  //     data.digitalCurrencies.slice(1, maxTooltipElements + 1).map((id) => id.identifier ?? 'unknown'),
  //     nbDigitalCurrency - maxTooltipElements
  //   );
  // }

  return (
    <>
      <Box className="search-card-content">
        <Typography fontSize="inherit" className="search-card-content-title">
          <FormattedMessage id="fullName" defaultMessage={'Full name'} />:
        </Typography>
        <Box className="search-card-content-text">
          <StyledBadge badgeContent={nbFullName} tooltip={nameTooltip}>
            {fullName ? (
              <Tooltip
                placement="bottom"
                title={fullNameTooltip}
                slotProps={{ tooltip: { sx: { mt: '5px!important', px: 2, py: 2, backgroundColor: 'var(--color-darkgray)', fontSize: 'var(--fs-12)', fontWeight: 400 } } }}
              >
                <Box sx={{ width: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{formatMatchingName(fullName)}</Box>
              </Tooltip>
            ) : (
              <UnknownMessage />
            )}
          </StyledBadge>
        </Box>
      </Box>

      <Box className="search-card-content">
        <Typography fontSize="inherit" className="search-card-content-title">
          <FormattedMessage id="placeOfRegistry" defaultMessage={'Place of registry'} />:
        </Typography>
        <Box className="search-card-content-text">
          <Box className="search-card-content-text capitalize">
            <StyledBadge badgeContent={nbPlaceOfRegistry} tooltip={placeOfRegistryTooltip}>
              {countryOfRegistry ? (
                <Box>
                  <Box component={'span'} display={'inline-flex'} sx={{ height: '.8rem', verticalAlign: 'middle', mr: '5px' }}>
                    <Flag country={placeOfRegistry ?? undefined} />
                  </Box>
                  <Box display="inline" sx={{ verticalAlign: 'middle' }}>
                    {countryOfRegistry.toLowerCase()}
                  </Box>
                </Box>
              ) : (
                <UnknownMessage />
              )}
            </StyledBadge>
          </Box>
        </Box>
      </Box>

      <Box className="search-card-content">
        <Typography fontSize="inherit" className="search-card-content-title">
          <FormattedMessage id="dateOfRegistry" defaultMessage={'Date of registry'} />:
        </Typography>
        <Box className="search-card-content-text">
          <StyledBadge badgeContent={nbRegistryDate} tooltip={registryTooltip}>
            {registryDate ? registryDate : <UnknownMessage />}
          </StyledBadge>
        </Box>
      </Box>

      <Box className="search-card-content">
        <Typography fontSize="inherit" className="search-card-content-title">
          <FormattedMessage id="address" defaultMessage={'Address'} />:
        </Typography>
        <Box className="search-card-content-text capitalize">
          <StyledBadge badgeContent={nbAddresses} tooltip={addressTooltip}>
            {addressCountry ? (
              <Box>
                <Box component={'span'} display={'inline-flex'} sx={{ height: '.8rem', verticalAlign: 'middle', mr: '5px' }}>
                  <Flag country={addressCountryCode ?? undefined} />
                </Box>
                <Box display="inline" sx={{ verticalAlign: 'middle' }}>
                  {addressCountry.toLowerCase()}
                </Box>
              </Box>
            ) : (
              <UnknownMessage />
            )}
          </StyledBadge>
        </Box>
      </Box>

      {/* <Box className="search-card-content">
        <Typography fontSize="inherit" className="search-card-content-title">
          <FormattedMessage id="idDocumentNumber" defaultMessage={'Identification number'} />:
        </Typography>
        <Box className="search-card-content-text">
          <StyledBadge badgeContent={nbIdentityDocument} tooltip={identityDocumentTooltip}>
            {identityDocument ? identityDocument : <UnknownMessage />}
          </StyledBadge>
        </Box>
      </Box> */}

      {/* <Box className="search-card-content">
        <Typography fontSize="inherit" className="search-card-content-title">
          <FormattedMessage id="bic" defaultMessage={'BIC'} />:
        </Typography>
        <Box className="search-card-content-text">
          <StyledBadge badgeContent={nbBic}>{bic ? bic : <UnknownMessage />}</StyledBadge>
        </Box>
      </Box> */}

      {/* <Box className="search-card-content">
        <Typography fontSize="inherit" className="search-card-content-title">
          <FormattedMessage id="lei" defaultMessage={'LEI'} />:
        </Typography>
        <Box className="search-card-content-text">
          <StyledBadge badgeContent={nbLei}>{lei ? lei : <UnknownMessage />}</StyledBadge>
        </Box>
      </Box> */}

      {/* <Box className="search-card-content">
        <Typography fontSize="inherit" className="search-card-content-title">
          <FormattedMessage id="digitalCurrency" defaultMessage={'Digital currency'} />:
        </Typography>
        <Box className="search-card-content-text">
          <StyledBadge badgeContent={nbDigitalCurrency} tooltip={digitalCurrencyTooltip}>
            {digitalCurrency ? digitalCurrency : <UnknownMessage />}
          </StyledBadge>
        </Box>
      </Box> */}
    </>
  );
};

export default OrganisationContent;
